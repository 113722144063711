import * as React from 'react';
import { Redirect } from 'react-router';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Icon,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { DEFAULT_CONTENT_TYPE, WEBSITE_NAME } from '../../../src/commons/constants';
import './style.css';
import { Page } from 'src/routes';

const graph = require('./Omni.png');

interface HomeProps {
  allowListed: boolean;
  setContentType: any;
  setNavigationOpen: any;
  getStartedTarget: string;
  getStartedBtnText?: string;
  setDisplaySplitPanel: any;
}

interface HomeState {
  redirect: string;
}

export class Home extends React.Component<HomeProps, HomeState> {
  state = {
    redirect: undefined,
  };
  componentDidMount() {
    const { setContentType, setNavigationOpen } = this.props;
    setContentType(DEFAULT_CONTENT_TYPE);
    setNavigationOpen(false);
  }

  onGetStarted = () => {
    this.setState({ redirect: this.props.getStartedTarget });
    this.props.setNavigationOpen(true);
    if (this.state.redirect?.endsWith(Page.SEARCH)) this.props.setDisplaySplitPanel(true);
  };

  onOnboarding = () => {
    this.setState({ redirect: '/onboarding' });
    this.props.setNavigationOpen(true);
  };

  static defaultProps = {
    getStartedBtnText: 'Discover data',
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div className='awsui-grid'>
        <div className='custom-home__header awsui-row'>
          <div className='col-xxs-12'>
            <div className='awsui-row'>
              <div className='custom-home__category col-xxs-10 offset-xxs-1 col-l-8 offset-l-1'>AWS Data Platform</div>
            </div>
            <div className='awsui-row'>
              <div className='custom-home__header-title col-xxs-10 offset-xxs-1 col-s-6 col-l-6 offset-l-1 col-xl-6'>
                <div className='awsui-text-large'>
                  <div>
                    <strong>{WEBSITE_NAME}</strong>
                  </div>
                  <div>Easily discover and securely share data with fine-grained access control in AWS.</div>
                </div>
                <p>
                  {WEBSITE_NAME} is a one-stop AWS wide consolidated data catalog and an enterprise data management
                  service that makes it faster and easier to discover, share, access, and govern data stored across AWS
                  Data Lakes and Data Warehouses.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='awsui-util-pt-xxl awsui-row'>
          <div className='col-xxs-10 offset-xxs-1 col-s-6 col-l-6 offset-l-1 col-xl-6'>
            <h1>How it works</h1>
            <div className='awsui-util-container awsui-util-no-gutters how-it-works'>
              <div>
                <div className='how-it-works-svg awsui-util-m-xl'>
                  <img src={graph} />
                </div>
              </div>
            </div>
            <h1>Benefits</h1>
            <Container>
              <ColumnLayout columns={2} borders='vertical' className='awsui-util-no-gutters'>
                <TextContent>
                  <Box variant={'h3'}>Easier data discovery</Box>
                  <Box variant={'p'}>
                    Discover datasets by searching with familiar business keywords and get the latest metadata such as
                    schema, versions, SLA, etc.
                  </Box>
                </TextContent>
                <TextContent>
                  <Box variant={'h3'}>Faster data sharing</Box>
                  <Box variant={'p'}>
                    Simple, self-service, and customer-focused user interface to register your Lake Formation and
                    Redshift catalogs.
                  </Box>
                </TextContent>
                <TextContent>
                  <Box variant={'h3'}>Fine-grained data access permission management</Box>
                  <Box variant={'p'}>
                    Tag controls at various levels such as database, schema, dataset, and attribute level.
                  </Box>
                </TextContent>
                <TextContent>
                  <Box variant={'h3'}>Secure data governance</Box>
                  <Box variant={'p'}>
                    Provide isolated, secure, and managed Omni workspaces for data consumers to access without bypassing
                    security controls, while enforcing security guardrails and capturing all user actions and query logs
                    for audit.
                  </Box>
                </TextContent>
              </ColumnLayout>
            </Container>
          </div>
          <div className='custom-home__sidebar col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2'>
            <div className='awsui-util-mt-xxl'>
              <Container header={<Header>Discover data</Header>}>
                <SpaceBetween size={'s'} direction={'vertical'}>
                  Start browsing the datasets that are available in AWS.
                  <Button variant='primary' onClick={this.onGetStarted}>
                    {this.props.getStartedBtnText}
                  </Button>
                </SpaceBetween>
              </Container>
              {!this.props.allowListed && (
                <Container header={<Header>Onboard</Header>}>
                  Ready to use Omni? Submit an onboarding request.
                  <Button onClick={this.onOnboarding}>Onboard</Button>
                </Container>
              )}
            </div>
            <div className='awsui-util-mt-xxl'>
              <div className='awsui-util-container'>
                <div className='awsui-util-container-header'>
                  <h2>Learn more</h2>
                </div>
                <div>
                  <div className='custom-inner-table-row'>
                    <span>
                      <a
                        href='https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Omni'
                        target='_blank'
                        className='custom-home__external'
                      >
                        What is Omni?
                        <Icon name='external' />
                      </a>
                    </span>
                  </div>
                  <div className='custom-inner-table-row'>
                    <span>
                      <a
                        href='https://w.amazon.com/bin/view/AWSDataPlatform/Omni/Workspaces'
                        target='_blank'
                        className='custom-home__external'
                      >
                        Getting started with Omni workspace.
                        <Icon name='external' />
                      </a>
                    </span>
                  </div>
                  {/* TODO: enable this after we support publisher for new customers */}
                  {/* <div className='custom-inner-table-row'>
                    <span>
                      <a
                        href='#'
                        target='_blank'
                        className='custom-home__external'
                      >
                        Publish Data into AWS Data Lake
                        <Icon name='external' />
                      </a>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
