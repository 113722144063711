import { Box, Header, Button, Container, ColumnLayout } from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router';
import { CopyText } from '../common/copyText';

import React, { Dispatch, SetStateAction, useState } from 'react';
import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';
import { Page } from 'src/routes';

interface iContractHeader {
  dataset: GetDataSetResponse;
}

export const ContractHeader = ({ dataset, setVisible }: iContractHeader) => {
  const [redirect, setRedirect] = useState<string>();
  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Header variant='h1'>{dataset.TableName}</Header>
      <Container
        header={
          <Header
            variant='h2'
            actions={
              <Button
                variant={'normal'}
                onClick={() => {
                  setRedirect(Page.INGESTION.MONITORING_V2.replace(':arn/:database/:dataset', dataset.DataSetId));
                }}
              >
                Monitor dataset
              </Button>
            }
          >
            General information
          </Header>
        }
      >
        <ColumnLayout columns={3} variant='text-grid'>
          <div>
            <Box variant='awsui-key-label'> Version </Box>
            <div> {dataset.LatestVersion} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'> Last update </Box>
            <div> {dataset.LastUpdatedAt} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'> Dataset ID </Box>
            <div>
              <CopyText
                copyText={dataset.DataSetId}
                copyButtonLabel='Copy dataset ID'
                successText='Dataset ID copied'
                errorText='Failed to copy dataset ID'
              />
            </div>
          </div>
        </ColumnLayout>
      </Container>
    </>
  );
};
