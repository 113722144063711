import * as React from 'react';
import { useState, useEffect } from 'react';

import { SpaceBetween, Header, SelectProps, Wizard, Alert } from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { createWorkflowStatusLink, Page } from 'src/routes';
import { getUser } from 'src/api/auth';
import {
  getUserInfo,
  getWorkspace,
  getWorkspacesForGroups,
  listDataZoneEntities,
  startGladstoneWorkflow,
} from 'src/api/permissions';
import {
  DataZoneMembershipDesignationInfoList,
  DZEntity,
  StartGladstoneWorkflowResult,
  Workspace,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { ReviewAndSubmitContent } from 'src/components/datazone/onboard/reviewAndSubmitContent';
import { ConfigureEnvironmentContent } from 'src/components/datazone/onboard/configureEnvironmentContent';
import { ValidatePrerequisitesContent } from 'src/components/datazone/onboard/validatePrerequisitesContent';
import { ConfigureProjectMembershipContent } from 'src/components/datazone/onboard/configureProjectMembershipContent';
import { Simulate } from 'react-dom/test-utils';
import error = Simulate.error;
import { dataZoneIsAvailable, DataZoneUnavailableHeader } from 'src/components/datazone/constants';

export interface DataZoneOnboardPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
}

export const DataZoneOnboardPage = (props: DataZoneOnboardPageProps) => {
  const [errorMessage, setErrorMessage] = useState<string>(undefined);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState<string>(undefined);
  const [environmentType, setEnvironmentType] = useState<SelectProps.Option>(undefined);
  const [group, setGroup] = useState<SelectProps.Option>(undefined);
  const [workspace, setWorkspace] = useState<SelectProps.Option>(undefined);
  const [clusterId, setClusterId] = useState<string>(undefined);
  const [region, setRegion] = useState<string>(undefined);
  const [databaseName, setDatabaseName] = useState<string>(undefined);
  const [secretArn, setSecretArn] = useState<string>(undefined);
  const [workgroupName, setWorkgroupName] = useState<string>(undefined);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [prerequisitesPassed, setPrerequisitesPassed] = useState<boolean>(false);
  const [validatePrerequisitesErrorMessage, setValidatePrerequisitesErrorMessage] = useState<string>(undefined);
  const [membershipList, setMembershipList] = useState<DataZoneMembershipDesignationInfoList>([]);

  const [configureEnvironmentErrorMessage, setConfigureEnvironmentErrorMessage] = useState<string>(undefined);

  let existingAccountEntities: DZEntity[] = [];
  let existingProjectEntities: DZEntity[] = [];
  let existingEnvironmentEntities: DZEntity[] = [];

  useEffect(() => {
    handleRefresh();
  }, []);

  // when selected group is updated, set the membershipList accordingly
  useEffect(() => {
    const projectsForGroup = existingProjectEntities.filter((entity) => entity.groupId == group.value);

    if (projectsForGroup.length == 0) {
      // project doesn't exist
      setMembershipList([]);
    } else {
      setMembershipList(projectsForGroup[0].projectMetadata.membershipList);
    }
  }, [group]);

  const handleRefresh = async () => {
    props.setContentType('wizard');
    setLoading(true);

    try {
      const user = await getUser();
      const userInfo = await getUserInfo({ userId: user });
      const groupIds = userInfo.groupIds;

      const workspacesMap = (
        await getWorkspacesForGroups({
          groupIds,
        })
      ).workspaces;
      let workspaces: Workspace[] = [];
      for (const groupId of groupIds) {
        const workspacesInGroup = workspacesMap[groupId];
        workspaces.push(...workspacesInGroup.workspaces);
      }
    } catch (e) {
      setErrorMessage('Failed to get group and workspace info.');
    }

    try {
      existingProjectEntities = (
        await listDataZoneEntities({
          entityType: 'Project',
          groupId: props.activeGroup,
        })
      ).entities;
      existingAccountEntities = (
        await listDataZoneEntities({
          entityType: 'AssociatedAccount',
          groupId: props.activeGroup,
        })
      ).entities;
      existingEnvironmentEntities = (
        await listDataZoneEntities({
          entityType: 'Environment',
          groupId: props.activeGroup,
        })
      ).entities;
    } catch (e) {
      setErrorMessage('Failed to get DataZone entity metadata.');
    }

    setLoading(false);
  };

  const DZ_ONBOARD_WIZARD_STEPS = () => {
    let steps = [
      {
        title: 'Configure environment',
        //info: <Link variant='info'>Info</Link>,
        description: 'Provide details for the account, project, and environment to be created or updated.',
        content: (
          <ConfigureEnvironmentContent
            {...props}
            environmentType={environmentType}
            setEnvironmentType={setEnvironmentType}
            group={group}
            setGroup={setGroup}
            workspace={workspace}
            setWorkspace={setWorkspace}
            clusterId={clusterId}
            setClusterId={setClusterId}
            region={region}
            setRegion={setRegion}
            databaseName={databaseName}
            setDatabaseName={setDatabaseName}
            secretArn={secretArn}
            workgroupName={workgroupName}
            setWorkgroupName={setWorkgroupName}
            setSecretArn={setSecretArn}
            errorMessage={undefined}
          />
        ),
        errorText: configureEnvironmentErrorMessage,
      },
      {
        title: 'Configure project membership',
        //info: <Link variant='info'>Info</Link>,
        description: 'Provide POSIX groups and user designation mappings.',
        content: (
          <ConfigureProjectMembershipContent
            {...props}
            membershipList={membershipList}
            setMembershipList={setMembershipList}
            errorMessage={undefined}
          />
        ),
        errorText: configureEnvironmentErrorMessage,
      },
      {
        title: 'Prerequisites check',
        //info: <Link variant='info'>Info</Link>,
        description: 'Check if the provided details are valid and ready to onboard.',
        content: (
          <ValidatePrerequisitesContent
            {...props}
            workspaceId={workspace?.value}
            awsAccountId={workspace?.value.substring(4, 16)}
            region={region}
            clusterId={clusterId}
            databaseName={databaseName}
            secretArn={secretArn}
            workgroupName={workgroupName}
            setPrerequisitesPassed={setPrerequisitesPassed}
            errorMessage={undefined}
            membershipList={membershipList}
          />
        ),
        errorText: validatePrerequisitesErrorMessage,
        isOptional: false,
      },
      {
        title: 'Review and submit',
        //info: <Link variant='info'>Info</Link>,
        description: 'Review the details and begin the onboarding process.',
        content: (
          <ReviewAndSubmitContent
            {...props}
            groupId={group?.value}
            workspaceId={workspace?.value}
            clusterId={clusterId}
            workgroupName={workgroupName}
          />
        ),
        isOptional: false,
        //errorText: ticketErrorText,
      },
    ];
    return steps;
  };

  const DZ_ONBOARD_WIZARD_I18N_STRINGS = {
    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    skipToButtonLabel: (step) => `Skip to ${step.title}`,
    navigationAriaLabel: 'Steps',
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Submit',
    optional: 'optional',
  };

  const handleNavigate = (requestedStepIndex) => {
    if (activeStepIndex == 0) {
      // All fields must be provided before proceeding
      if (!(environmentType && group && workspace && (clusterId || workgroupName) && databaseName && secretArn)) {
        setConfigureEnvironmentErrorMessage('Please provide all fields.');
        return;
      } else {
        setConfigureEnvironmentErrorMessage(undefined);
      }
    } else if (activeStepIndex == 2) {
      if (!prerequisitesPassed && requestedStepIndex == 3) {
        setValidatePrerequisitesErrorMessage('Cannot continue until prerequisites have succeeded.');
        return;
      }
    }
    setActiveStepIndex(requestedStepIndex);
  };

  const handleCancel = () => {
    setRedirect(Page.DATAZONE_MANAGE);
  };

  const handleSubmit = async () => {
    const workspaceInfo = await getWorkspace({ workspaceId: workspace.value });
    const awsAccountId = workspaceInfo.workspace.accountId;
    try {
      const startGladstoneWorkflowResponse: StartGladstoneWorkflowResult = await startGladstoneWorkflow({
        workflowType: 'DataZoneOnboard',
        dataZoneOnboardMetadata: {
          workspaceId: workspace.value,
          groupId: group.value,
          membershipList: membershipList,
          redshiftInfo: {
            clusterName: clusterId,
            databaseName: databaseName,
            workgroupName: workgroupName,
            secretArn: secretArn,
            region: region,
            awsAccountId: awsAccountId,
          },
        },
      });
      const workflowId = startGladstoneWorkflowResponse.parentTaskId;

      setRedirect(createWorkflowStatusLink(workflowId));
    } catch (e) {
      console.log('Failed to submit onboard workflow', e);
      setErrorMessage('Failed to onboard! cause: ' + e.message);
    }
  };

  if (redirect) {
    if (redirect) return <Redirect push to={redirect} />;
  }
  if (!dataZoneIsAvailable()) {
    return <DataZoneUnavailableHeader />;
  }
  return (
    <SpaceBetween size={'l'} direction={'vertical'}>
      {errorMessage && (
        <Alert type={'error'} header={'Error'}>
          {errorMessage}
        </Alert>
      )}
      <Header variant={'h1'}>Onboard to Omni-DataZone</Header>
      <Wizard
        steps={DZ_ONBOARD_WIZARD_STEPS()}
        i18nStrings={DZ_ONBOARD_WIZARD_I18N_STRINGS}
        activeStepIndex={activeStepIndex}
        onNavigate={({ detail }) => handleNavigate(detail.requestedStepIndex)}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        //isLoadingNextStep={loading}
      />
    </SpaceBetween>
  );
};
