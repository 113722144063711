import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  EXCLUDE_COLUMNS_FILTER,
  fgaPolicyStatusSelection,
  fgaPolicyTypeSelection,
  FORM_CONTENT_TYPE,
  INCLUDE_COLUMNS_FILTER,
  NO_COLUMN_FILTER,
  REDSHIFT_FGA_POLICY_TYPE,
  visibilitySelection,
} from 'src/commons/constants';
import { Page } from 'src/routes';
import { editFgaPolicy, listFgaPolicies } from 'src/api/permissions';
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react-v3';
import { getRegion } from 'src/api/config';
import { getLatestDataSetDetail } from 'src/api/catalog';
import { Column, ColumnList } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';

export interface EditFgaPolicyProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const EditFgaPolicy = (props: EditFgaPolicyProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const { state } = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [fgaPolicyId, setFgaPolicyId] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [policyType, setPolicyType] = useState(null);
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState(undefined);
  const [visibility, setVisibility] = useState(null);
  const [policyName, setPolicyName] = useState(undefined);
  const [policyPhysicalName, setPolicyPhysicalName] = useState(undefined);
  const [tableName, setTableName] = useState(null);
  const [schemaName, setSchemaName] = useState(null);
  const [databaseName, setDatabaseName] = useState(null);
  const [clusterId, setClusterId] = useState(null);
  const [catalogId, setCatalogId] = useState(null);
  const [rowFilterExpression, setRowFilterExpression] = useState(undefined);
  const [columnListAsOptions, setColumnListAsOptions] = useState(undefined);
  const [, setSelectedColumns] = useState(undefined);
  const [selectedColumnsList, setSelectedColumnsList] = useState(undefined);
  const [selectColumnFilterType, setSelectColumnFilterType] = useState(NO_COLUMN_FILTER);

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);

    if (!state) {
      setRedirect(Page.FINE_GRAIN_ACCESS_POLICIES);
    }

    const activeWorkspace = props.activeWorkspace;

    if (activeWorkspace) {
      const ownerId = props.match.params.id ? props.match.params.id : props.activeWorkspace.workspaceId;
      setOwnerId(ownerId);

      if (state && state.fgaPolicyId) {
        setFgaPolicyId(state.fgaPolicyId);
      } else {
        setRedirect(Page.FINE_GRAIN_ACCESS_POLICIES);
      }
    } else {
      setRedirect(Page.HOME);
    }
  }, []);

  useEffect(() => {
    if (ownerId && fgaPolicyId) {
      fetchFgaPolicyToEdit(fgaPolicyId);
    }
  }, [fgaPolicyId, ownerId]);

  const createDataSetId = (fgaPolicyToEdit) => {
    let dataSetIdList = [];
    dataSetIdList.push('DS-' + (fgaPolicyToEdit.type === 'FineGrainedAccess-LF' ? 'glueLF' : 'redshift') + '|');
    dataSetIdList.push('A-' + fgaPolicyToEdit.tableData.tableCatalogId + '|');
    if (fgaPolicyToEdit.tableData.clusterId !== null)
      dataSetIdList.push('CI-' + fgaPolicyToEdit.tableData.clusterId + '|');
    dataSetIdList.push('DN-' + fgaPolicyToEdit.tableData.databaseName + '|');
    if (fgaPolicyToEdit.tableData.schemaName !== null)
      dataSetIdList.push('SN-' + fgaPolicyToEdit.tableData.schemaName + '|');
    dataSetIdList.push('TN-' + fgaPolicyToEdit.tableData.tableName + '|');
    dataSetIdList.push('R-' + fgaPolicyToEdit.tableData.region);
    return dataSetIdList.join('');
  };

  const getColumnsForDataSet = async (fgaPolicyToEdit, selectedColumns) => {
    let dataSetId = createDataSetId(fgaPolicyToEdit);
    try {
      let getLatestDatasetDetail = await getLatestDataSetDetail({
        Filter: {
          IdList: [dataSetId],
        },
      });
      if (getLatestDatasetDetail.DataSetDetailList.length > 0) {
        const datasetDetail = getLatestDatasetDetail.DataSetDetailList[0];
        const columnListAsOptions = convertTableColumnListToOptions(datasetDetail.Columns);
        setColumnListAsOptions(columnListAsOptions);
        getSelectedColumnsFromOptions(selectedColumns, columnListAsOptions);
      } else {
        setNotification('error', 'No details found for selected dataset. Perform a sync to generate the details');
      }
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const getSelectedColumnsFromOptions = async (selectedColumns, columnListAsOptions) => {
    let selectedColumnsList = [];
    if (columnListAsOptions) {
      columnListAsOptions.forEach((column) => {
        if (selectedColumns) {
          selectedColumns.forEach((selectedColumn) => {
            if (selectedColumn === column.value) {
              selectedColumnsList.push(column);
            }
          });
        }
      });
    }
    setSelectedColumnsList(selectedColumnsList);
  };

  const convertTableColumnListToOptions = (columnList: ColumnList): SelectProps.Option[] => {
    return columnList.map((column: Column) => {
      return {
        label: column.Name,
        value: column.Name,
        type: column.Type,
        description: column.Type,
        comment: column.Comment,
      };
    });
  };

  const fetchFgaPolicyToEdit = async (fgaPolicyId) => {
    try {
      let listFgaPoliciesForWorkspaceResult = await listFgaPolicies({
        ownerId: ownerId,
      });
      let policies = listFgaPoliciesForWorkspaceResult.FineGrainAccess;
      while (listFgaPoliciesForWorkspaceResult.nextToken != undefined) {
        listFgaPoliciesForWorkspaceResult = await listFgaPolicies({
          ownerId: ownerId,
          nextToken: listFgaPoliciesForWorkspaceResult.nextToken,
        });
        policies.push(...listFgaPoliciesForWorkspaceResult.FineGrainAccess);
      }
      const fgaPolicyToEdit = policies.filter((policy) => {
        return policy.id === fgaPolicyId;
      });
      if (fgaPolicyToEdit.length == 0) {
        setNotification('error', 'No policy found with id:' + fgaPolicyId);
      } else {
        setDescription(fgaPolicyToEdit[0].description);
        setStatus(
          fgaPolicyStatusSelection.filter((policyStatusSelection) => {
            return policyStatusSelection.value === fgaPolicyToEdit[0].status;
          })[0],
        );
        setVisibility(
          visibilitySelection.filter((policyVisibilitySelection) => {
            return policyVisibilitySelection.value === fgaPolicyToEdit[0].visibility;
          })[0],
        );
        setPolicyType(
          fgaPolicyTypeSelection.filter((policyTypeSelection) => {
            return policyTypeSelection.value === fgaPolicyToEdit[0].type;
          })[0],
        );
        setDatabaseName(fgaPolicyToEdit[0].tableData.databaseName);
        setTableName(fgaPolicyToEdit[0].tableData.tableName);
        setCatalogId(fgaPolicyToEdit[0].tableData.tableCatalogId);
        setSchemaName(fgaPolicyToEdit[0].tableData.schemaName);
        setClusterId(fgaPolicyToEdit[0].tableData.clusterId);
        setRowFilterExpression(fgaPolicyToEdit[0].tableData.rowFilter.filterExpression);
        setSelectedColumnsList(fgaPolicyToEdit[0].tableData.columnNames);
        setPolicyName(fgaPolicyToEdit[0].tableData.name);
        setPolicyPhysicalName(fgaPolicyToEdit[0].tableData.physicalName);
        let selectedColumns = [];
        if (
          fgaPolicyToEdit[0].tableData.columnNames !== undefined &&
          fgaPolicyToEdit[0].tableData.columnNames?.length >= 1
        ) {
          setSelectColumnFilterType(INCLUDE_COLUMNS_FILTER);
          selectedColumns = fgaPolicyToEdit[0].tableData.columnNames;
          setSelectedColumns(selectedColumns);
        } else if (fgaPolicyToEdit[0].tableData.columnWildcard !== null) {
          if (
            fgaPolicyToEdit[0].tableData.columnWildcard.excludedColumnNames !== undefined &&
            fgaPolicyToEdit[0].tableData.columnWildcard.excludedColumnNames?.length >= 1
          ) {
            setSelectColumnFilterType(EXCLUDE_COLUMNS_FILTER);
            selectedColumns = fgaPolicyToEdit[0].tableData.columnWildcard.excludedColumnNames;
            setSelectedColumns(selectedColumns);
          } else {
            setSelectColumnFilterType(NO_COLUMN_FILTER);
          }
        }
        await getColumnsForDataSet(fgaPolicyToEdit[0], selectedColumns);
      }
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const createRedshiftEditPolicyStructurePayload = () => {
    if (policyType?.value === REDSHIFT_FGA_POLICY_TYPE) {
      return {
        editedIncludeColumns:
          selectColumnFilterType === INCLUDE_COLUMNS_FILTER
            ? selectedColumnsList.map((column) => {
                return column.value;
              })
            : null,
        editedExcludeColumns:
          selectColumnFilterType === EXCLUDE_COLUMNS_FILTER
            ? selectedColumnsList.map((column) => {
                return column.value;
              })
            : null,
        editedAllColumns: selectColumnFilterType === NO_COLUMN_FILTER ? true : false,
      };
    } else {
      return null;
    }
  };

  const handleEditFgaPolicy = async () => {
    setButtonLoading(true);
    try {
      let rowFilterPayload = rowFilterExpression
        ? { filterExpression: rowFilterExpression, AllRowsWildcard: null }
        : { AllRowsWildcard: {} };
      let columnWildCardPayload =
        selectColumnFilterType === INCLUDE_COLUMNS_FILTER
          ? null
          : selectColumnFilterType === NO_COLUMN_FILTER
          ? {}
          : {
              excludedColumnNames: selectedColumnsList.map((column) => {
                return column.value;
              }),
            };

      let editFgaPolicyRequest = {
        id: fgaPolicyId,
        description: description,
        visibility: visibility.value,
        tableData: {
          tableCatalogId: catalogId,
          clusterId: clusterId,
          databaseName: databaseName,
          schemaName: schemaName,
          tableName: tableName,
          name: policyName,
          physicalName: policyPhysicalName,
          region: getRegion(),
          rowFilter: rowFilterPayload,
          columnNames:
            selectColumnFilterType === INCLUDE_COLUMNS_FILTER
              ? selectedColumnsList.map((column) => {
                  return column.value;
                })
              : null,
          columnWildcard: columnWildCardPayload,
          redshiftEditPolicyStructure: createRedshiftEditPolicyStructurePayload(),
        },
      };
      console.log(editFgaPolicyRequest);
      const editFgaPolicyResponse = await editFgaPolicy(editFgaPolicyRequest);
      if (editFgaPolicyResponse.failures) {
        setNotification('error', 'Failure in creating policy');
      } else {
        setVisibility(null);
        setPolicyName(undefined);
        setPolicyPhysicalName(undefined);
        setPolicyType(null);
        setColumnListAsOptions(undefined);
        setCatalogId(null);
        setDatabaseName(null);
        setClusterId(null);
        setSchemaName(null);
        setTableName(null);
        setSelectColumnFilterType(NO_COLUMN_FILTER);
        setSelectedColumns([]);
        setSelectedColumnsList([]);
        setDescription(undefined);
        setRowFilterExpression(undefined);
        setRedirect({
          pathname: Page.FINE_GRAIN_ACCESS_POLICIES,
          state: {
            status: 'success',
            message: 'Policy created successfully',
          },
        });
      }
    } catch (err) {
      setNotification('error', `Error in editing policy:${err.message}`);
    } finally {
      setButtonLoading(false);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>

      <Form
        header={
          <Header variant='h1' description='Provide the details to edit policy.'>
            Edit policy
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.FINE_GRAIN_ACCESS_POLICIES)}>
              Cancel
            </Button>
            <Button variant='primary' loading={buttonLoading} onClick={handleEditFgaPolicy}>
              {'Edit policy'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header variant='h2'>Policy details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Policy ID</div>} description='Fine grain access policy getting modified.'>
                <Input name='policyId' value={fgaPolicyId} ariaRequired={true} disabled={true} />
              </FormField>

              <FormField label={<div>Policy name</div>} description='Physical name of policy modified.'>
                <Input name='physicalName' value={policyPhysicalName} ariaRequired={true} disabled={true} />
              </FormField>

              <FormField label={<div>Owner ID</div>} description='Owner of the access policy.'>
                <Input name='ownerId' value={ownerId} ariaRequired={true} disabled={true} />
              </FormField>

              <FormField label={<div>Policy type</div>} description='Type of access policy.'>
                <Select
                  name='policyType'
                  selectedOption={policyType}
                  ariaRequired={true}
                  placeholder='Choose a type'
                  onChange={({ detail }) => setPolicyType(detail.selectedOption)}
                  disabled={true}
                  options={fgaPolicyTypeSelection}
                  selectedAriaLabel='Selected'
                />
              </FormField>

              <FormField label='Status' description='Status of the policy.'>
                <Select
                  name='status'
                  placeholder='Active'
                  selectedOption={status}
                  options={fgaPolicyStatusSelection}
                  ariaRequired={true}
                  onChange={({ detail }) => {
                    setStatus(detail.selectedOption);
                  }}
                  disabled={true}
                />
              </FormField>

              <FormField label='Visibility' description='Mark it private to disable consumers requesting it..'>
                <Select
                  name='visibility'
                  placeholder='Public'
                  selectedOption={visibility}
                  options={visibilitySelection}
                  ariaRequired={true}
                  onChange={({ detail }) => {
                    setVisibility(detail.selectedOption);
                  }}
                  disabled={false}
                />
              </FormField>

              <FormField label={<div>Catalog Id</div>} description='AWS account Id'>
                <Input name='CatalogId' value={catalogId} ariaRequired={true} disabled={true} />
              </FormField>

              {policyType?.value === REDSHIFT_FGA_POLICY_TYPE && (
                <FormField label={<div>Cluster Id</div>} description='Cluster Id'>
                  <Input name='Cluster Id' value={clusterId} ariaRequired={true} disabled={true} />
                </FormField>
              )}

              <FormField label={<div>Database name</div>} description='Database name'>
                <Input name='Database name' value={databaseName} ariaRequired={true} disabled={true} />
              </FormField>

              {policyType?.value === REDSHIFT_FGA_POLICY_TYPE && (
                <FormField label={<div>Schema name</div>} description='Schema name'>
                  <Input name='Schema name' value={schemaName} ariaRequired={true} disabled={true} />
                </FormField>
              )}

              <FormField label={<div>Table name</div>} description='Table name'>
                <Input name='Table name' value={tableName} ariaRequired={true} disabled={true} />
              </FormField>

              <FormField
                label={<div>Column level access</div>}
                description='Choose whether this policy should have column level permissions'
              >
                <RadioGroup
                  onChange={({ detail }) => setSelectColumnFilterType(detail.value)}
                  value={selectColumnFilterType}
                  items={[
                    {
                      value: NO_COLUMN_FILTER,
                      label: 'All Columns',
                      description: 'Filter will not have any column restrictions',
                    },
                    {
                      value: INCLUDE_COLUMNS_FILTER,
                      label: 'Include Columns',
                      description: 'Policy only allow access to specific columns',
                    },
                    {
                      value: EXCLUDE_COLUMNS_FILTER,
                      label: 'Exclude Columns',
                      description: 'Policy will allow access to all but specific columns',
                    },
                  ]}
                />
              </FormField>

              {(selectColumnFilterType === INCLUDE_COLUMNS_FILTER ||
                selectColumnFilterType === EXCLUDE_COLUMNS_FILTER) && (
                <FormField
                  label={<div>Select columns</div>}
                  description='Select the columns on which the policy is to be applied'
                >
                  <Multiselect
                    selectedOptions={selectedColumnsList}
                    onChange={({ detail }) => setSelectedColumnsList(detail.selectedOptions)}
                    deselectAriaLabel={(e) => `Remove ${e.label}`}
                    options={columnListAsOptions}
                    placeholder='Choose columns'
                    statusType={columnListAsOptions ? null : 'loading'}
                    loadingText='Fetching columns'
                    empty='No columns found'
                    filteringType='auto'
                    selectedAriaLabel='Selected'
                  />
                </FormField>
              )}

              <FormField label={<div>Row filter expression</div>} description='See documentation for more details.'>
                <Textarea
                  onChange={({ detail }) => setRowFilterExpression(detail.value)}
                  value={rowFilterExpression}
                  placeholder="column_name='some_value'"
                  disabled={policyType?.value === REDSHIFT_FGA_POLICY_TYPE}
                />
              </FormField>

              <FormField label='Description' description='Details about the policy.'>
                <Textarea
                  name='description'
                  onChange={({ detail }) => setDescription(detail.value)}
                  value={description}
                  placeholder='Enter a description'
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};

export default EditFgaPolicy;
