import * as React from 'react';
import * as _ from 'lodash';
import { useState, useEffect } from 'react';

import {
  editGroup,
  getGroupInfo,
  getGroupMembers,
  isMemberOfGroupingMechanismId,
  listRoles,
} from '../../api/permissions';

import {
  Box,
  Button,
  ButtonDropdown,
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  Container,
  Flashbar,
  FormField,
  Header,
  Input,
  Link,
  Modal,
  Multiselect,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TableProps,
  Tabs,
  Textarea,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import * as validate from '../../commons/validationUtils';
import { isValidUser } from '../../commons/validationUtils';
import {
  CopiableText,
  getGroupingMechanismType,
  getOmniOncallLink,
  getPhoneToolLink,
  getRequestSubmittedWithAutoApproval,
  groupingMechanismIdUrl,
} from 'src/commons/common';
import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';
import { DATAPROVIDER_AND_CONSUMER } from 'src/commons/constants';
import { EmptyState } from 'src/commons/EmptyState';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { DGS_ONCALL_URL, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { ManageRoles } from 'src/components/groups/manageRoles';
import { PageHeader } from 'src/components/common/PageHeader';

export interface MyGroupsProps {
  username: string;
  setContentType: any;
  match: any;
  activeGroup: string;
}

const MyGroups = (props: MyGroupsProps) => {
  const [teamWiki, setTeamWiki] = useState('');
  const [teamEmail, setTeamEmail] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [item, setItem] = useState('');
  const [useCase, setUseCase] = useState('');
  const [groupId, setGroupId] = useState(undefined);
  const [isOwner, setIsOwner] = useState(false);
  const [dataAccessAndProcessing, setDataAccessAndProcessing] = useState('');
  const [optionLabel, setOptionLabel] = useState('');
  const [customerType, setCustomerType] = useState('');
  const [, setCustomerTypeOptionLabel] = useState('');
  const [activeGroupInfo, setActiveGroupInfo] = useState(undefined);
  const [groupMembers, setGroupMembers] = useState([]);
  const [, setGroupMemberIds] = useState([]);
  const [loadingGroupMembers, setLoadingGroupMembers] = useState(true);
  const [, setLoadingGroupInfo] = useState(true);

  const [teamWikiValidation, setTeamWikiValidation] = useState(false);
  const [categoryValidation, setCategoryValidation] = useState(false);
  const [typeValidation, setTypeValidation] = useState(false);
  const [itemValidation, setItemValidation] = useState(false);
  const [teamWikiErrorText, setTeamWikiErrorText] = useState('');
  const [teamEmailValidation, setTeamEmailValidation] = useState(false);
  const [teamEmailErrorText, setTeamEmailErrorText] = useState('');
  const [useCaseValidation, setUseCaseValidation] = useState(false);
  const [dataAccessAndProcessingOption, setDataAccessAndProcessingOption] = useState(undefined);
  const [dataAccessAndProcessingValidation, setDataAccessAndProcessingValidation] = useState(false);
  const [dataAccessAndProcessingErrorText, setDataAccessAndProcessingErrorText] = useState('');
  const [customerTypeOption, setCustomerTypeOption] = useState(undefined);
  const [customerTypeValidation, setCustomerTypeValidation] = useState(false);
  const [customerTypeErrorText] = useState('');
  const [groupingMechanismId, setGroupingMechanismId] = useState('');

  const [editGroupModalVisible, setEditGroupModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonText] = useState('Submit request');
  const [notifications, setNotifications] = useState([]);
  const [tableMessage, setTableMessage] = useState('No group members');
  const [teamGroupingMechanismIdValidation, setTeamGroupingMechanismIdValidation] = useState(false);
  const [teamGroupingMechanismIdErrorText, setTeamGroupingMechanismIdErrorText] = useState('');

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const [roleOptions, setRoleOptions] = useState(undefined);
  const [selectedRoleOptions, setSelectedRoleOptions] = useState(undefined);
  const [addMembersModalVisible, setAddMembersModalVisible] = useState(false);
  const [iamPrincipalsValidation, setIamPrincipalsValidation] = useState(false);
  const [iamPrincipalErrorText, setIamPrincipalErrorText] = useState('');
  const [membersToAdd, setMembersToAdd] = useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [redirect, setRedirect] = useState('');
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'userId',
      header: 'Member ID',
      cell: (item) => {
        if (isValidUser(item.userId)) return getPhoneToolLink(item.userId);
        return item.userId;
      },
      minWidth: '200px',
      sortingField: 'userId',
    },
    {
      id: 'updatedDate',
      header: 'Last updated',
      cell: (item) => item.updatedDate,
      minWidth: '200px',
      sortingField: 'updatedDate',
    },
  ];

  const readableCustomerTypeMap = {
    DATAPROVIDER_AND_DATACONSUMER: 'Data provider and consumer',
    DATAPROVIDER: 'Data provider',
    DATACONSUMER: 'Data consumer',
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);

    const groupId = props.match.params.id ? props.match.params.id : props.activeGroup;

    setGroupId(groupId);
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup, groupId]);

  useEffect(() => {
    initializeEditableParameters();
  }, [activeGroupInfo]);

  const handleRefresh = async () => {
    if (!groupId) return;

    if (props.match.params.id === undefined || props.match.params.id === props.activeGroup) {
      setIsOwner(true);
    }

    await fetchGroupInfo();
    await fetchGroupMembers();
  };

  const fetchGroupInfo = async () => {
    setLoadingGroupInfo(true);

    try {
      const activeGroupInfo = await getGroupInfo({
        groupId,
      });
      setActiveGroupInfo(activeGroupInfo);

      const listRolesResult = await listRoles({ ownerId: groupId });
      if (listRolesResult && listRolesResult['roles']) {
        const roleOptions = [];
        for (let role of listRolesResult['roles']) {
          roleOptions.push({
            label: role.roleName,
            value: role.roleId,
            description: role.description,
          });
        }
        setRoleOptions(roleOptions);
      }
    } catch (err) {
      console.error('Failed to get group info.', err);
    }

    setLoadingGroupInfo(false);
  };

  const fetchGroupMembers = async () => {
    setLoadingGroupMembers(true);
    try {
      const groupMembers = await getGroupMembers({
        groupId,
      });
      const existingUserIds = [];
      groupMembers.groupMembers.forEach((member) => existingUserIds.push(member['userId']));

      setGroupMembers(groupMembers.groupMembers);
      setGroupMemberIds(existingUserIds);
      setMembersToAdd([]);
    } catch (err) {
      setTableMessage('Unable to load group members.');
    }
    setLoadingGroupMembers(false);
  };

  const submitEditGroupRequest = async () => {
    if (validate.isFalsy(teamWiki)) {
      setTeamWiki(undefined);
    }
    if (validate.isFalsy(groupingMechanismId)) {
      setGroupingMechanismId(undefined);
    }
    if (validate.isFalsy(teamEmail)) {
      setTeamEmail(undefined);
    }
    if (validate.isFalsy(useCase)) {
      setUseCase(undefined);
    }
    if (validate.isFalsy(dataAccessAndProcessing)) {
      setDataAccessAndProcessing(undefined);
    }
    if (validate.isFalsy(customerType)) {
      setCustomerType(undefined);
    }
    await editGroup({
      groupId,
      teamWiki,
      groupingMechanismId,
      teamDistributionList: teamEmail,
      useCase,
      dataAccessAndProcessing,
      customerType,
      primaryCTI: {
        category: category,
        type: type,
        item: item,
      },
    });
  };

  const validateEditGroupRequest = async () => {
    if (
      validate.isFalsy(teamWiki) ||
      validate.isFalsy(teamEmail) ||
      validate.isFalsy(useCase) ||
      validate.isFalsy(dataAccessAndProcessing) ||
      validate.isFalsy(customerType) ||
      validate.isFalsy(groupingMechanismId) ||
      validate.isFalsy(category) ||
      validate.isFalsy(type) ||
      validate.isFalsy(item)
    ) {
      setNotification('Empty request fields found', '');
      return false;
    }
    if (validate.isNotFalsy(teamWiki)) {
      const validWiki = validate.isValidUrl(teamWiki);
      if (!validWiki) {
        setTeamWikiValidation(true);
        setTeamWikiErrorText('Invalid URL found');
        return false;
      }
    }
    // If there was a change of ID
    if (validate.isNotFalsy(groupingMechanismId) && activeGroupInfo?.groupingMechanismId != groupingMechanismId) {
      // Calling Gladstone to check if current user is members of
      // the given grouping mechanism ID
      const result = await isMemberOfGroupingMechanismId({
        groupingMechanismId,
      });
      // If the current user is not a member of the provided group
      if (!result.memberOf) {
        setTeamGroupingMechanismIdValidation(true);
        setTeamGroupingMechanismIdErrorText('You are not a member of the provided group ID');
        return false;
      }
    }

    if (validate.isNotFalsy(teamEmail)) {
      const validTeamEmail = validate.isValidAmazonEmail(teamEmail);
      if (!validTeamEmail) {
        setTeamEmailValidation(true);
        setTeamEmailErrorText('Invalid team email found');
        return false;
      }
    }

    if (optionLabel === 'Other' && (dataAccessAndProcessing.length === 0 || !dataAccessAndProcessing.trim())) {
      setDataAccessAndProcessingValidation(true);
      setDataAccessAndProcessingErrorText('Data access and processing description must not be empty');
      return false;
    }
    return true;
  };

  // this will initialize editable fields with the current fields, so they can be prefilled in form
  const initializeEditableParameters = () => {
    if (!activeGroupInfo) return;
    const { teamInfo } = activeGroupInfo;

    const currentDataAccessAndProcessing = teamInfo.DataAccessAndProcessing;
    // Default as DATAPROVIDER_AND_CONSUMER if CustomerType field is not associated with the group
    const currentCustomerType = teamInfo.CustomerType ? teamInfo.CustomerType : DATAPROVIDER_AND_CONSUMER;
    // setting up the retrieved value for DataClassification to be displayed

    const customerTypeIndex = customerTypeOptions.find((item) => item.id === currentCustomerType);
    const dataAccessId = findDataAccessId(currentDataAccessAndProcessing);
    const dataAccessAndProcessing = dataAccessOptions.find((item) => item.id === dataAccessId);

    setUseCase(teamInfo.UseCase);
    setTeamEmail(teamInfo.DistributionList);
    setTeamWiki(teamInfo.Wiki);
    setGroupingMechanismId(activeGroupInfo?.groupingMechanismId);
    setOptionLabel(dataAccessAndProcessing.label);
    setDataAccessAndProcessingOption(dataAccessAndProcessing);
    setCategory(activeGroupInfo.primaryCTI == undefined ? '' : activeGroupInfo.primaryCTI.category);
    setType(activeGroupInfo.primaryCTI == undefined ? '' : activeGroupInfo.primaryCTI.type);
    setItem(activeGroupInfo.primaryCTI == undefined ? '' : activeGroupInfo.primaryCTI.item);

    setDataAccessAndProcessing(teamInfo.DataAccessAndProcessing);
    setCustomerTypeOption(customerTypeIndex);
    setCustomerType(customerTypeIndex.id);
  };

  const findDataAccessId = (dataAccessAndProcessing) => {
    const option = dataAccessOptions.filter((item) => item.label === dataAccessAndProcessing);

    return option.length !== 0 ? option[0].id : dataAccessOptions.filter((item) => item.label === 'Other')[0].id;
  };

  const handleEditGroup = async () => {
    setButtonLoading(true);
    const validated = await validateEditGroupRequest();
    if (!validated) {
      setButtonLoading(false);
      return;
    }
    try {
      await submitEditGroupRequest();
      setEditGroupModalVisible(false);
      setNotification('', getRequestSubmittedWithAutoApproval());
    } catch (e) {
      setEditGroupModalVisible(false);
      let errorMessage = 'Please contact Data Lake oncall to help you with your request';
      setNotification('Failed to submit your request', errorMessage);
    }
    setButtonLoading(false);
  };

  const submitAddMembersRequest = async () => {
    // Edit roleMapping will perform a full replace in the backend, therefore, deep copy existing and append new role members.
    const roleMapping = _.cloneDeep(activeGroupInfo.roleMapping);
    for (let option of selectedRoleOptions) {
      const roleId = option.value;
      if (!(roleId in roleMapping)) {
        roleMapping[roleId] = { roleMembers: [] };
      }
      roleMapping[roleId].roleMembers = roleMapping[roleId].roleMembers ? roleMapping[roleId].roleMembers : [];
      roleMapping[roleId].roleMembers.push(...membersToAdd);
    }
    await editGroup({
      groupId,
      roleMapping,
    });
  };

  const getButtonList = () => {
    if (!activeGroupInfo) return [];

    let buttonsList = [];
    const refreshButton = {
      text: '',
      icon: 'refresh',
      onItemClick: handleRefresh,
    };
    const addUserButton = {
      text: 'Add IAM principals',
      variant: 'normal',
      onItemClick: () => {
        setAddMembersModalVisible(true);
      },
    };

    const isValidRoleOptions = roleOptions && roleOptions.length;
    if (isOwner && isValidRoleOptions) buttonsList.push(addUserButton);

    buttonsList.push(refreshButton);
    return buttonsList;
  };

  const setNotification = (header, message) => {
    if (header === '') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const formatCTI = () => {
    if (
      activeGroupInfo?.primaryCTI == undefined ||
      activeGroupInfo?.primaryCTI.category == undefined ||
      activeGroupInfo?.primaryCTI.type == undefined ||
      activeGroupInfo?.primaryCTI.item == undefined
    ) {
      return 'N/A';
    }
    return (
      activeGroupInfo?.primaryCTI.category +
      ' / ' +
      activeGroupInfo?.primaryCTI.type +
      ' / ' +
      activeGroupInfo?.primaryCTI.item
    );
  };

  const validateAddIamPrincipalsRequest = () => {
    if (membersToAdd.length === 0) {
      setNotification('Invalid IAM principal found', 'At least one IAM principal is required');
      setIamPrincipalsValidation(true);
      setIamPrincipalErrorText('New members must not be empty');
      return false;
    }

    const invalidIamPrincipals = membersToAdd.filter((principal) => !validate.isValidIAMPrincipal(principal));
    if (invalidIamPrincipals.length > 0) {
      setNotification('Invalid IAM principal found', invalidIamPrincipals.join(' : '));
      setIamPrincipalErrorText('Invalid IAM principal found');
      setIamPrincipalsValidation(true);
      return false;
    }

    let groupMembersId = groupMembers.map((memberInfo) => memberInfo.userId);
    const existingUserIds = membersToAdd.filter((userId) => groupMembersId.includes(userId));
    if (existingUserIds.length > 0) {
      setNotification('Existing group member found', existingUserIds.join(' : '));
      setIamPrincipalErrorText('Existing group member found');
      setIamPrincipalsValidation(true);
      return false;
    }

    if (!selectedRoleOptions || !selectedRoleOptions.length) {
      setNotification('Invalid role found', 'At least one role is required');
      return false;
    }

    return true;
  };

  const handleAddIAMPrincipals = async () => {
    setButtonLoading(true);

    if (!validateAddIamPrincipalsRequest()) {
      setButtonLoading(false);
      setAddMembersModalVisible(false);
      return;
    }

    try {
      await submitAddMembersRequest();
      setNotification('', getRequestSubmittedWithAutoApproval());
    } catch (e) {
      let errorMessage = <>Please contact {getOmniOncallLink()} to help you with your request.</>;
      setNotification('Failed to submit your request.', errorMessage);
      console.error(e);
    }
    setAddMembersModalVisible(false);
    setButtonLoading(false);
  };

  const usersStringToArray = (textAreaString) => {
    const usersArray = textAreaString.split('\n').filter((user) => user != '');
    setMembersToAdd(Array.from(new Set(usersArray)));
    setIamPrincipalsValidation(false);
    setInputValue(textAreaString);
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(groupMembers, {
    filtering: {
      noMatch: '',
      empty: <EmptyState title={tableMessage} subtitle={'No group members to display.'} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const dataAccessOptions = [
    {
      label: 'Amazon EMR',
      id: '1',
    },
    {
      label: 'Amazon Redshift',
      id: '2',
    },
    {
      label: 'Amazon Redshift Spectrum',
      id: '3',
    },
    {
      label: 'Other',
      id: '4',
    },
  ];

  const customerTypeOptions = [
    {
      label: "I'm a Data Consumer",
      id: 'DATACONSUMER',
      value: 'DATACONSUMER',
    },
    {
      label: "I'm a Data Provider",
      id: 'DATAPROVIDER',
      value: 'DATAPROVIDER',
    },
    {
      label: 'Both',
      id: 'DATAPROVIDER_AND_DATACONSUMER',
      value: 'DATAPROVIDER_AND_DATACONSUMER',
    },
  ];

  const getGroupDetails = () => (
    <Container
      header={
        <Header
          variant='h2'
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <ButtonDropdown
                variant='normal'
                items={[
                  {
                    text: 'Create new group',
                    id: 'createnew',
                  },
                ]}
                onItemClick={() => {
                  setWarningModalVisible(true);
                }}
              >
                Actions
              </ButtonDropdown>
              {isOwner && (
                <Button
                  variant='normal'
                  onClick={() => {
                    setEditGroupModalVisible(true);
                  }}
                >
                  {' '}
                  Edit{' '}
                </Button>
              )}
            </SpaceBetween>
          }
        >
          {' '}
          Group details{' '}
        </Header>
      }
    >
      <ColumnLayout columns={3} borders='horizontal'>
        <CopiableText name='Team name' value={activeGroupInfo?.teamInfo.Name} />
        <CopiableText name='Team email' value={activeGroupInfo?.teamInfo.DistributionList} />
        {activeGroupInfo?.groupingMechanismId != null ? (
          <CopiableText
            name={`Grouping ID (${getGroupingMechanismType(activeGroupInfo?.groupingMechanismId)})`}
            value={groupingMechanismId}
            url={groupingMechanismIdUrl(groupingMechanismId)}
            copiable={false}
            external={true}
          />
        ) : (
          <CopiableText
            name={`Grouping ID`}
            value={'Please provide a POSIX or Teams group ID.'}
            copiable={false}
            external={true}
          />
        )}
        <CopiableText
          name={`Team wiki`}
          value={activeGroupInfo?.teamInfo.Wiki}
          url={activeGroupInfo?.teamInfo.Wiki}
          copiable={false}
          external={true}
        />
        <CopiableText
          name='Customer type'
          value={readableCustomerTypeMap[activeGroupInfo?.teamInfo.CustomerType]}
          copiable={false}
        />
        <CopiableText
          name='Data access and processing'
          value={activeGroupInfo?.teamInfo.DataAccessAndProcessing}
          copiable={false}
        />

        <CopiableText name='Last updated date' value={activeGroupInfo?.updatedDate} />
        <CopiableText name={`Use case`} value={activeGroupInfo?.teamInfo.UseCase} copiable={false} />
        <CopiableText name={`Existing use case`} value={activeGroupInfo?.teamInfo.ExistingUseCase} copiable={false} />
        <CopiableText name='CTI' value={formatCTI()} />
      </ColumnLayout>
    </Container>
  );

  const getGroupMembersTable = () => (
    <Table
      {...collectionProps}
      loadingText='Loading group members...'
      loading={loadingGroupMembers}
      columnDefinitions={columnDefinitions}
      items={items}
      wrapLines={false}
      resizableColumns={true}
      empty={
        <EmptyState title={'No match'} subtitle={"No users found or you don't have permissions to see the users"} />
      }
      header={
        <>
          <PageHeader buttons={getButtonList()} counter={`(${groupMembers.length})`} header={'Members'} />
        </>
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel='Filter resources'
          filteringPlaceholder='Find resources'
          countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
        />
      }
      preferences={
        <CollectionPreferences
          title={'Preferences'}
          confirmLabel={'Confirm'}
          cancelLabel={'Cancel'}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: [
              { value: 10, label: '10 items' },
              { value: 20, label: '20 items' },
              { value: 30, label: '30 items' },
            ],
          }}
          wrapLinesPreference={defaultWrapLinesPreference}
        />
      }
    />
  );

  const getGroupPageTabs = () => {
    const tabs = [
      {
        label: 'Details',
        id: 'details',
        content: getGroupDetails(),
      },
      {
        label: 'Members',
        id: 'members',
        content: getGroupMembersTable(),
      },
      {
        label: 'Roles',
        id: 'roles',
        content: (
          <ManageRoles ownerId={groupId} roleMapping={activeGroupInfo ? activeGroupInfo.roleMapping : undefined} />
        ),
      },
    ];

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  if (redirect !== '') return <Redirect push to={redirect} />;

  return (
    <div>
      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>
      <Header variant={'h1'}>{groupId}</Header>

      <div>{getGroupPageTabs()}</div>
      {/*<ManageRoles ownerId={groupId} roleMapping={activeGroupInfo ? activeGroupInfo.roleMapping : undefined }/>*/}
      <Modal
        onDismiss={() => {
          setAddMembersModalVisible(false);
        }}
        visible={addMembersModalVisible}
        size='medium'
        footer={
          <Box float='right'>
            <Button
              variant='link'
              onClick={() => {
                setAddMembersModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={handleAddIAMPrincipals} loading={buttonLoading}>
              {buttonText}
            </Button>
          </Box>
        }
        header='Add IAM principals'
      >
        <ColumnLayout>
          <FormField
            label='New members'
            description='You can only add IAM users and roles here. For Midway users, please add to your POSIX/Teams under grouping ID.'
            errorText={iamPrincipalsValidation ? iamPrincipalErrorText : false}
          >
            <Textarea
              onChange={(e) => usersStringToArray(e.detail.value)}
              value={inputValue}
              placeholder='123456789012:role:Admin'
            />
          </FormField>
          <FormField
            label='Role'
            description='Select role(s) that will be granted to the new members.'
            errorText={
              selectedRoleOptions != undefined && !selectedRoleOptions.length ? 'At least one role is required.' : ''
            }
          >
            <Multiselect
              options={roleOptions}
              placeholder='Choose options'
              selectedAriaLabel='Selected'
              selectedOptions={selectedRoleOptions}
              onChange={({ detail }) => setSelectedRoleOptions(detail.selectedOptions)}
            />
          </FormField>
        </ColumnLayout>
      </Modal>
      <Modal
        onDismiss={() => {
          setWarningModalVisible(false);
        }}
        visible={warningModalVisible}
        size='medium'
        footer={
          <Box float='right'>
            <Button
              variant='link'
              onClick={() => {
                setWarningModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={() => setRedirect(Page.CREATE_NEW_GROUP)}>
              Confirm
            </Button>
          </Box>
        }
        header='Confirm'
      >
        Are you sure you want to create a new group? This is generally not an encouraged use case, and you should be
        able to accomplish whatever you need from one group. If you have questions, please contact our{' '}
        <Link href={DGS_ONCALL_URL} external={true}>
          oncall
        </Link>{' '}
        for details. If you are sure, click Confirm below to continue.
      </Modal>
      <Modal
        onDismiss={() => {
          setEditGroupModalVisible(false);
        }}
        visible={editGroupModalVisible}
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  setEditGroupModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' onClick={handleEditGroup} loading={buttonLoading}>
                {buttonText}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Edit details'
      >
        <ColumnLayout>
          <FormField
            label='Customer type'
            description='What type of customer do you plan on being?'
            errorText={customerTypeValidation ? customerTypeErrorText : false}
          >
            <Select
              selectedOption={customerTypeOption}
              options={customerTypeOptions}
              selectedAriaLabel='Selected'
              onChange={(e) => {
                setCustomerTypeOptionLabel(e.detail.selectedOption.label);
                setCustomerTypeOption(e.detail.selectedOption);
                setCustomerType(e.detail.selectedOption.value);
                setCustomerTypeValidation(false);
              }}
            />
          </FormField>
          <FormField
            label={'Grouping ID'}
            description='Please enter either a POSIX group or a Teams ID. This ID will be used to determine your group members.'
            errorText={teamGroupingMechanismIdValidation ? teamGroupingMechanismIdErrorText : false}
          >
            <Input
              ariaRequired={false}
              value={groupingMechanismId}
              onChange={(e) => {
                setGroupingMechanismId(e.detail.value);
                setTeamGroupingMechanismIdValidation(false);
              }}
            />
          </FormField>
          <FormField label='Team wiki' errorText={teamWikiValidation ? teamWikiErrorText : false}>
            <Input ariaRequired={true} value={teamWiki} onChange={(e) => setTeamWiki(e.detail.value)} />
          </FormField>

          <FormField label='Team email' errorText={teamEmailValidation ? teamEmailErrorText : false}>
            <Input
              ariaRequired={false}
              value={teamEmail}
              onChange={(e) => {
                setTeamEmail(e.detail.value);
                setTeamEmailValidation(false);
              }}
            />
          </FormField>

          <FormField label='Category (C)' errorText={categoryValidation ? 'Category not found' : false}>
            <Input
              ariaRequired={false}
              value={category}
              onChange={(e) => {
                setCategory(e.detail.value);
                setCategoryValidation(false);
              }}
            />
          </FormField>

          <FormField label='Type (T)' errorText={typeValidation ? 'Type not found' : false}>
            <Input
              ariaRequired={false}
              value={type}
              onChange={(e) => {
                setType(e.detail.value);
                setTypeValidation(false);
              }}
            />
          </FormField>

          <FormField label='Item (I)' errorText={itemValidation ? 'Item not found' : false}>
            <Input
              ariaRequired={false}
              value={item}
              onChange={(e) => {
                setItem(e.detail.value);
                setItemValidation(false);
              }}
            />
          </FormField>

          <FormField
            label='Data access and processing'
            description='How are you looking to access and process the data?'
            errorText={dataAccessAndProcessingValidation ? dataAccessAndProcessingErrorText : false}
          >
            <Select
              options={dataAccessOptions}
              selectedOption={dataAccessAndProcessingOption}
              selectedAriaLabel='Selected'
              onChange={(e) => {
                setOptionLabel(e.detail.selectedOption.label);
                setDataAccessAndProcessingOption(e.detail.selectedOption);
                setDataAccessAndProcessing('');
                setDataAccessAndProcessingValidation(false);
                if (optionLabel != 'Other') {
                  setDataAccessAndProcessing(e.detail.selectedOption.label);
                }
              }}
            />
          </FormField>
          {optionLabel === 'Other' && (
            <>
              <FormField
                description='Explain your way of data access and processing.'
                errorText={dataAccessAndProcessingValidation ? dataAccessAndProcessingErrorText : false}
              >
                <Textarea
                  ariaRequired={true}
                  value={dataAccessAndProcessing}
                  onChange={(e) => {
                    setDataAccessAndProcessing(e.detail.value);
                    setDataAccessAndProcessingValidation(false);
                  }}
                />
              </FormField>
            </>
          )}

          <FormField
            label='Use case'
            description='Explain your use case and the business problem that it is solving.'
            errorText={useCaseValidation ? 'Use case not found' : false}
          >
            <Textarea
              ariaRequired={false}
              placeholder={'Use case'}
              value={useCase}
              onChange={(e) => {
                setUseCase(e.detail.value);
                setUseCaseValidation(false);
              }}
            />
          </FormField>
        </ColumnLayout>
      </Modal>
    </div>
  );
};

export default MyGroups;
