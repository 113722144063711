import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Input,
  Header,
  Container,
  Select,
  SpaceBetween,
  TokenGroup,
} from '@amzn/awsui-components-react-v3';

import { Redirect, useLocation } from 'react-router-dom';
import * as validate from '../../commons/validationUtils';
import { editTag, getTags, listDataPermissions } from '../../api/permissions';
import { Page } from '../../routes/Paths';
import {
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  FORM_CONTENT_TYPE,
  LAKEFORMATION_TAG_TYPE,
  NUM_TAG_VALUES,
  REDSHIFT_TAG_TYPE,
  TABLE_RESOURCE_TYPE,
} from '../../commons/constants';

export interface EditTagProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const EditTag = (props: EditTagProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const { state } = useLocation();

  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [catalogId, setCatalogId] = useState('');
  const [tagKey, setTagKey] = useState('');
  const [tagType, setTagType] = useState('');
  const [tagValue, setTagValue] = useState('');
  const [tagValues, setTagValues] = useState([]);
  const [tagValuesTokens, setTagValuesTokens] = useState([]);

  // used as a way to differentiate the new and old values for EditTag API since it requires add/delete params
  const [originalTagValues, setOriginalTagValues] = useState([]);

  const [region, setRegion] = useState({
    label: 'us-east-1',
    value: 'us-east-1',
  });
  const [ownerId, setOwnerId] = useState('');

  const catalogIdPlaceholder = '12 digit account number, e.g. 123456789012';
  const tagKeyPlaceholder = 'MyTagName';
  const tagValuePlaceholder = 'MyTagValue';
  const regionPlaceholder = 'us-east-1';

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);

    // unlike create page, edit page needs to have a tag that was chosen first
    if (!state) {
      setRedirect(Page.TAGS);
    }

    const activeWorkspace = props.activeWorkspace;

    if (activeWorkspace) {
      const ownerId = props.match.params.id ? props.match.params.id : props.activeWorkspace.workspaceId;
      setOwnerId(ownerId);

      // defaulting and locking fields based on workspace or selected tag
      // but make sure it redirects properly when page is loaded directly through URL
      if (state && state.tagCatalogId && state.tagKey && state.tagRegion) {
        setCatalogId(state.tagCatalogId);
        setTagKey(state.tagKey);
        setRegion({
          label: state.tagRegion,
          value: state.tagRegion,
        });
        state.tagType !== undefined ? setTagType(state.tagType) : LAKEFORMATION_TAG_TYPE;
      } else {
        setRedirect(Page.TAGS);
      }
    } else {
      setRedirect(Page.HOME);
    }
  }, []);

  useEffect(() => {
    if (ownerId !== '') {
      fetchEditedTag(ownerId);
    }
  }, [catalogId, tagKey, region]);

  const fetchEditedTag = async (ownerId) => {
    const getTagsResponse = await getTags({ ownerId });
    const tagToEdit = getTagsResponse.tags.filter((tag) => {
      return (
        tag.statusCustomerType.includes('Publisher') &&
        tag.resourceId === 'ACCOUNT_TAG' &&
        tag.catalogId === catalogId &&
        tag.region === region.value &&
        tag.tagKey === tagKey &&
        tag.tagType === tagType
      );
    });

    // since the tags now all share the same attributes except for values, filter for values
    // that way when one tag pair is selected, all can be edited in one form
    const tagValuesToEdit = tagToEdit.map((tag) => tag.tagValue);
    setOriginalTagValues(tagValuesToEdit);
    setTagValues(tagValuesToEdit);

    // use a Promise.all since we need to call an async function inside a map
    // https://stackoverflow.com/a/48273841
    const tokens = await Promise.all(
      tagValuesToEdit.map(async (tagValue) => {
        try {
          const isPublished = await isTagValuePublished(tagValue);
          return {
            label: tagValue,
            dismissLabel: `Remove ${tagValue}`,
            disabled: isPublished,
          };
        } catch (err) {
          throw err;
        }
      }),
    );
    setTagValuesTokens(tokens);
  };

  const handleTagValue = async (value) => {
    // display the text on field
    setTagValue(value);

    // check for special characters (comma, space, or return) that signal when entry is complete
    // but entry must require at least one regular character (number or letter)
    if (tagValues.length < NUM_TAG_VALUES && /^\w+(,| )$/.test(value)) {
      // cutoff delimiter character and empty out the input field since delimiter was added
      value = value.slice(0, -1);
      setTagValue('');

      // add the value only if it's not already in the list
      // note: the API should already account for duplicates; this is more to avoid UI confusion
      if (!tagValues.includes(value)) {
        setTagValues(tagValues.concat(value));

        const isPublished = await isTagValuePublished(value);
        setTagValuesTokens(
          tagValuesTokens.concat({
            label: value,
            dismissLabel: `Remove ${value}`,
            disabled: isPublished,
          }),
        );
      }
    }
  };

  const isTagValuePublished = async (tagValue) => {
    // check if tag is already published; if it is, disable the delete button
    // TODO: one possible expansion is to check for option = Consumer, meaning publishers can delete tags if there are no consumer
    // but this would require EditTag to remove the "Publisher" entry from Data Permissions table as well, which is not implemented yet
    let dpResource = undefined;
    if (tagType === REDSHIFT_TAG_TYPE) {
      dpResource = {
        redshiftTagPolicy: {
          tagKey: tagKey,
          tagValue: tagValue,
          catalogId: catalogId,
        },
      };
    } else {
      dpResource = {
        lFTagPolicy: {
          resourceType: TABLE_RESOURCE_TYPE,
          expression: [
            {
              tagKey: tagKey,
              tagValues: [tagValue],
            },
          ],
          catalogId: catalogId,
        },
      };
    }
    const listTagPublished = await listDataPermissions({
      ownerId,
      region: region.value,
      resource: dpResource,
      status: DATA_PERMISSION_STATUS_ACTIVE, // inactive records exist for record audit history, cannot be turned into active, so we know only active entries matter for published check
      type: tagType,
      option: DATA_PERMISSION_PUBLISHER_OPTION,
    });

    // if tag has no data permissions (empty array), then it is not published
    const tagPermissions = listTagPublished.dataPermissionList;
    return !(!Array.isArray(tagPermissions) || !tagPermissions.length);
  };

  const handleConfirm = async () => {
    setButtonLoading(true);
    try {
      const editTagResponse = await editTag({
        ownerId,
        type: tagType,
        tagKey,
        catalogId,
        region: region.value,
        // if value changes, add the new values and delete the old values (api was written for bulk value edits)
        // using ES7, we can deduce the value to add/delete using set differences
        // between original tags and current tags (e.g. set difference on original tags = deleted)
        // https://stackoverflow.com/a/33034768
        tagValuesToAdd: tagValues.filter((value) => !originalTagValues.includes(value)),
        tagValuesToDelete: originalTagValues.filter((value) => !tagValues.includes(value)),
      });
      setButtonLoading(false);

      const status = editTagResponse.status == 'Success' ? 'success' : 'error';

      if (status === 'error') {
        if (editTagResponse.message.includes('Member must have length greater than or equal to 1')) {
          // common case where tagValuesToAdd and tagValuesToDelete arrays are empty since no change to values
          // so we make the error message very clear for end users
          setNotifications([
            {
              type: status,
              content: `Your new tag value must be different from your current tag value.`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } else {
          setNotifications([
            {
              type: status,
              content: ` ${editTagResponse.message}`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        }
      } else {
        setRedirect({
          pathname: Page.TAGS,
          state: {
            status,
            message: 'Tag updated successfully',
          },
        });
      }
    } catch (e) {
      setNotifications([
        {
          type: 'error',
          content: `Error when trying to edit your tag: ${e.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header variant='h1' description='Please specify the details of your Omni tag.'>
            Edit tag
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.TAGS)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={buttonLoading}
              onClick={handleConfirm}
              disabled={
                !validate.isValidAccountId(catalogId) ||
                !validate.isValidTagKey(tagKey) ||
                !validate.isValidTagValues(tagValues)
              }
            >
              {'Edit tag'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header variant='h2'>Tag details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Catalog ID</div>} description='AWS account that owns the tags/tagged resources.'>
                <Input
                  name='catalogId'
                  placeholder={catalogIdPlaceholder}
                  value={catalogId}
                  ariaRequired={true}
                  onChange={({ detail }) => setCatalogId(detail.value.trim())}
                  invalid={catalogId !== '' && !validate.isValidAccountId(catalogId)}
                  disabled={true} // tag form currently only supports editing tag values
                />
              </FormField>

              <FormField label='Tag key' description='Unique name of your tag.'>
                <Input
                  name='tagKey'
                  placeholder={tagKeyPlaceholder}
                  value={tagKey}
                  ariaRequired={true}
                  onChange={({ detail }) => setTagKey(detail.value.trim())}
                  invalid={!validate.isValidTagKey(tagKey)}
                  disabled={true} // tag form currently only supports editing tag values
                />
              </FormField>

              <FormField
                label={`Tag value(s) (${tagValues.length}/${NUM_TAG_VALUES})`}
                description='Subcategories of your tag: must be letters or numbers separated by commas or spaces. Published tag values may not be edited and are hidden.'
              >
                <Input
                  name='tagValue'
                  placeholder={tagValuePlaceholder}
                  value={tagValue}
                  ariaRequired={true}
                  onChange={({ detail }) => handleTagValue(detail.value)}
                  onKeyDown={(e) => e.detail.key === 'Enter' && tagValue && handleTagValue(tagValue + ',')}
                  invalid={
                    // field is red if there's too many values or the value itself is not valid
                    (tagValues.length >= NUM_TAG_VALUES && tagValue !== '') ||
                    (!validate.isValidTagValues([...tagValues, tagValue]) && tagValue !== '')
                  }
                />

                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setTagValues([...tagValues.slice(0, itemIndex), ...tagValues.slice(itemIndex + 1)]);
                    setTagValuesTokens([
                      ...tagValuesTokens.slice(0, itemIndex),
                      ...tagValuesTokens.slice(itemIndex + 1),
                    ]);
                  }}
                  items={tagValuesTokens}
                  limit={NUM_TAG_VALUES} // limit for Lake Formation tag: https://tiny.amazon.com/ho1clt35/docsawsamazlakelatedgTBAC
                />
              </FormField>

              <FormField label='Region' description='The region your tag will be stored in.'>
                <Select
                  name='region'
                  placeholder={regionPlaceholder}
                  selectedOption={region}
                  options={[
                    // https://docs.aws.amazon.com/general/latest/gr/lake-formation.html
                    // TODO: add more using RIPJavaScriptHelper (RIP 1.x) if Lambda size limits allow
                    // https://w.amazon.com/bin/view/Region_Information_Provider/RIP_Helper_Guide/JavaScript/
                    { label: 'us-east-1', value: 'us-east-1' },
                  ]}
                  ariaRequired={true}
                  onChange={({ detail }) => {
                    setRegion({
                      label: detail.selectedOption.label,
                      value: detail.selectedOption.value,
                    });
                  }}
                  disabled={true} // tag form currently only supports editing tag values
                />
              </FormField>
              <FormField label='Tag type' description='Type of the tag.'>
                <Input
                  name='tagType'
                  placeholder='REDSHIFT or LF'
                  value={tagType}
                  ariaRequired={true}
                  onChange={({ detail }) => setTagType(detail.value.trim())}
                  disabled={true} // tag form currently only supports editing tag values
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};

export default EditTag;
