import * as React from 'react';
import { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Checkbox,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3/polaris';

import { scrollUp } from '../../utils/navigation';

import { batchApprove, batchDeny, cancelRequest, getDataPermissionRequests } from '../../../api/permissions';
import { EmptyState } from 'src/commons/EmptyState';
import { DenyOrCancelModal, tagBasedColumnDefinition } from 'src/components/workspaces/requests/utils';
import { Flashbar } from '@amzn/awsui-components-react-v3';
import {
  APPROVALS_STATUS_APPROVED,
  APPROVALS_STATUS_APPROVED_WITH_CONDITION,
  APPROVALS_STATUS_CANCELLED,
  APPROVALS_STATUS_REJECTED,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { extractAccessManagementInfoFromRequest } from 'src/components/requests/utils';

import { PageHeader } from 'src/components/common/PageHeader';

const DEFAULT_CANCEL_REASON = 'Please cancel the request.';

interface BrowseTagBasedRequestsTable {
  isIncomingRequests: boolean;

  setContentType(contentType: string): void;

  activeGroup: string;
  activeWorkspace: any;
  match: any;
}

export function BrowseTagBasedRequestsTable({
  isIncomingRequests,
  setContentType,
  activeGroup,
  activeWorkspace,
}: BrowseTagBasedRequestsTable) {
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [columnDefinitions] = useState(tagBasedColumnDefinition(isIncomingRequests));
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [actionRequestLoading, setActionRequestLoading] = useState(false);
  const [reasonOfAction, setReasonOfAction] = useState('');
  const [includesRequestHistory, setIncludesRequestHistory] = useState(false);
  const [action, setAction] = useState('');

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: (
          <EmptyState
            title='No instances'
            subtitle='No instances to display.'
            action={<Button>Create instance</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title='No matches'
            subtitle='We can’t find a match.'
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: 'requestedTime',
          },
          isDescending: true,
        },
      },
      selection: {
        trackBy: 'requestId',
        defaultSelectedItems: [],
        keepSelection: false,
      },
    },
  );
  const { selectedItems } = collectionProps;

  // component did mount
  useEffect(() => {
    setContentType(TABLE_CONTENT_TYPE);
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, []);

  // component did update
  useEffect(() => {
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, [includesRequestHistory]);

  // component did update
  useEffect(() => {
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, [activeGroup, isIncomingRequests]);

  const transformData = (items) => {
    const transformedData = [];
    items.forEach((item) => {
      let transform = {};
      transform['requestId'] = item.requestId;
      transform['status'] = item.status;
      transform['statusReason'] = item.statusReason;
      if (isIncomingRequests) {
        transform['requestedWorkspace'] = item.ownerRequestedBy;
      } else {
        transform['requestedTo'] = item.ownerRequestedTo;
      }
      transform['requestedBy'] = item.requestedBy;
      transform['requestedTime'] = item.timeOfRequest;
      transform = extractAccessManagementInfoFromRequest(item, transform);
      transform['resourceTag'] = '';
      if (item.dataPermissionRequest.resource.redshiftTagPolicy != null) {
        let policy = item.dataPermissionRequest.resource.redshiftTagPolicy;
        let key = policy.tagKey;
        let value = policy.tagValue;
        transform['resourceTag'] += key + '.' + value;
        transformedData.push(transform);
      }

      if (item.dataPermissionRequest.resource.lFTagPolicy != null) {
        item.dataPermissionRequest.resource.lFTagPolicy.expression.forEach((expression) => {
          var resourceTag = '';
          var key = expression.tagKey;
          for (var i = 0; i < expression.tagValues.length; i++) {
            resourceTag += key + '.' + expression.tagValues[i] + ' ';
          }
          transform['resourceTag'] += resourceTag;
        });
        transformedData.push(transform);
      }
    });
    return transformedData;
  };

  const handleCancel = async () => {
    setActionRequestLoading(true);

    let finalCancelReason = DEFAULT_CANCEL_REASON;
    if (reasonOfAction !== '') {
      finalCancelReason = reasonOfAction;
    }
    try {
      await cancelRequest({
        requestId: selectedItems[0].requestId,
        reason: finalCancelReason,
      });
      await handleRefresh();
      successMessage('cancelled');
    } catch (err) {
      errorMessage(err.message, 'cancel');
      console.log(err);
    }
    setActionRequestLoading(false);
    setReasonOfAction('');
    setActionModalVisible(false);
    scrollUp();
  };

  const handleRefresh = async () => {
    if (!activeWorkspace) return;
    setRequestsLoading(true);
    const requests = [];
    try {
      let nextToken = undefined;
      do {
        let dataPermissionAccessRequest = {
          workspaceId: activeWorkspace.workspaceId,
          isIncomingRequests: isIncomingRequests,
          includesRequestHistory: includesRequestHistory,
          nextToken: null,
        };
        if (nextToken != undefined) {
          dataPermissionAccessRequest['nextToken'] = nextToken;
        }
        let getTagBasedAccessRequestResult = await getDataPermissionRequests(dataPermissionAccessRequest);

        nextToken = getTagBasedAccessRequestResult.nextToken;
        requests.push(...getTagBasedAccessRequestResult.requestList);
      } while (nextToken != undefined);
      setAllItems(transformData(requests));
      setRequestsLoading(false);
    } catch (err) {}
  };

  const successMessage = (action) => {
    setNotifications([
      {
        type: 'success',
        content: `Selected request was '${action}'`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const errorMessage = (message, action) => {
    setNotifications([
      {
        type: 'error',
        content: `There was an error '${action}' your selected request: '${message}'`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const handleApprove = async () => {
    setRequestsLoading(true);
    let requestList = [];
    selectedItems.forEach((item) =>
      requestList.push({
        requestId: item.requestId,
      }),
    );
    try {
      await batchApprove({
        approveRequestList: requestList,
      });
      await handleRefresh();
      successMessage('approved');
    } catch (err) {
      errorMessage(err.message, 'approve');
      console.log(err);
    }
    setRequestsLoading(false);
  };

  const handleDeny = async () => {
    setRequestsLoading(true);
    let requestList = [];
    selectedItems.forEach((item) =>
      requestList.push({
        requestId: item.requestId,
        reason: 'DENIED',
      }),
    );
    try {
      await batchDeny({
        denyRequestList: requestList,
      });
      await handleRefresh();
      successMessage('denied');
    } catch (err) {
      errorMessage(err.message, 'deny');
      console.log(err);
    }
    setRequestsLoading(false);
  };

  const handleAction = async (e) => {
    setAction(e.detail.id);
    if (e.detail.id == 'cancel' || e.detail.id == 'deny') {
      setActionModalVisible(true);
    } else {
      await handleApprove();
    }
  };

  const shouldDisableDenyButton = () => {
    // if no items are selected, no action can be taken
    if (!selectedItems) return true;
    if (selectedItems.length === 0) return true;
    // if any request is not pending, no action can be taken
    selectedItems.forEach((item) => {
      if (item.status != 'PENDING') return true;
    });
    return false;
  };

  const shouldDisableApproveButton = () => {
    // if no items are selected, no action can be taken
    if (!selectedItems) return true;
    if (selectedItems.length === 0) return true;
    // if any request is not pending, no action can be taken
    selectedItems.forEach((item) => {
      if (item.status != 'PENDING') return true;
    });
    // if any approval workflow is not approved, no action can be taken
    let anyApprovalWorkflowsAreNotApproved = false;
    selectedItems.forEach((item) => {
      if (item.approvalsWorkflow) {
        item.approvalsWorkflow.forEach((approvalWorkflow) => {
          const status = approvalWorkflow.approvalWorkflowStatus;
          if (status != APPROVALS_STATUS_APPROVED && status != APPROVALS_STATUS_APPROVED_WITH_CONDITION) {
            anyApprovalWorkflowsAreNotApproved = true;
          }
        });
      }
    });
    return anyApprovalWorkflowsAreNotApproved;
  };

  const options = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleAction,
        loading: requestsLoading,
        items: isIncomingRequests
          ? [
              {
                text: 'Approve',
                id: 'approve',
                disabled: shouldDisableApproveButton(),
              },
              {
                text: 'Deny',
                id: 'deny',
                disabled: shouldDisableDenyButton(),
              },
            ]
          : [
              {
                text: 'Cancel',
                id: 'cancel',
                disabled: selectedItems.length === 0 || selectedItems.length > 1,
              },
            ],
      },
    ];
  };

  const closeActionModal = () => {
    setActionModalVisible(false);
  };

  const submitModalAction = async (action) => {
    setActionModalVisible(false);
    if (action === 'cancel') {
      await handleCancel();
    } else if (action === 'approve') {
      await handleApprove();
    } else if (action === 'deny') {
      await handleDeny();
    }
  };

  const approvalWorkflowStatusOutOfSync = (desiredStatuses: string[]) => {
    let outOfSync = false;
    selectedItems.forEach((item) => {
      item['approvalsWorkflow']?.forEach((workflowItem) => {
        if (!desiredStatuses.includes(workflowItem.approvalWorkflowStatus)) {
          outOfSync = true;
        }
      });
    });
    return outOfSync;
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        loadingText='Loading requests...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={options()}
              header={
                <>
                  <Header counter={`(${items.length})`}>Tag-based access requests</Header>
                  <Checkbox
                    onChange={({ detail }) => setIncludesRequestHistory(detail.checked)}
                    checked={includesRequestHistory}
                  >
                    Show request history
                  </Checkbox>
                </>
              }
            />
          </>
        }
        empty={<EmptyState title='No requests' subtitle='No requests were found.' />}
        loading={requestsLoading}
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
                { value: 100, label: '100 items' },
              ],
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Enable to wrap table cell content, disable to truncate text.',
            }}
          />
        }
        trackBy='requestId'
        selectionType={isIncomingRequests ? 'multi' : 'single'}
        selectedItems={selectedItems}
        isItemDisabled={(item) => item.status != 'PENDING'}
        filter={
          <TextFilter
            {...filterProps}
            filteringPlaceholder='Find resources'
            filteringAriaLabel='Filter tag-based access requests'
            countText={`${filteredItemsCount} ${filteredItemsCount > 1 ? 'matches' : 'match'}`}
          />
        }
        pagination={<Pagination {...paginationProps} />}
      ></Table>
      <DenyOrCancelModal
        modalVisible={actionModalVisible}
        action={action}
        closeModal={closeActionModal}
        buttonLoading={actionRequestLoading}
        submitAction={submitModalAction}
        approvalWorkflowStatusOutOfSync={approvalWorkflowStatusOutOfSync([
          APPROVALS_STATUS_REJECTED,
          APPROVALS_STATUS_CANCELLED,
        ])}
        textAreaPlaceholder={''}
        reasonOfAction={reasonOfAction}
        setReasonOfAction={setReasonOfAction}
      />
    </>
  );
}
