import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  fetchBaselineNeededConsumerDataPermissions,
  getInfoFromDP,
} from 'src/components/permissions/myBaselining/baseliningUtils';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { StrikeThroughTable } from 'src/components/permissions/myBaselining/strikeThroughTable';
import { isDGSAdmin } from 'src/commons/common';

export interface BaselineOmniGrantsProps {
  catalogMap: any;
  setContentType: any;
  match: any;
  dataPermissions: any;
  setDataPermissions: any;
  setDpToKeep: any;
  setDpToRevoke: any;
  userInfo: any;
}

export const BaselineOmniGrants = (props: BaselineOmniGrantsProps) => {
  const [loadingBaseline, setLoadingBaseline] = useState(true);
  const [redirect] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.match.params.ownerId]);

  const handleRefresh = async () => {
    try {
      setLoadingBaseline(true);
      if (!props.match.params.ownerId) return;
      const ownerId = props.match.params.ownerId;

      const userAllOwnerIds = new Set([
        ...(props.userInfo.memberWorkspaceIds ?? []),
        ...(props.userInfo.memberGroupIds ?? []),
      ]);
      if (!isDGSAdmin(props.userInfo.memberGroupIds) && !userAllOwnerIds.has(ownerId)) {
        props.setDataPermissions([]);
        setLoadingBaseline(false);
        return;
      }

      let dps = await fetchBaselineNeededConsumerDataPermissions([ownerId]);
      dps = dps.filter(
        (dp) =>
          dp.baselineInfo?.baselineStatus === 'SCHEDULED' &&
          dp.baselineInfo?.useCase != null &&
          dp.baselineInfo?.baselineDueDate != null &&
          '2025-04-21' == dp.baselineInfo?.baselineDueDate,
      );
      props.setDataPermissions(dps);
      setLoadingBaseline(false);
    } catch (err) {
      console.error(err);
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <StrikeThroughTable
        data={props.dataPermissions}
        defaultSelectedItems={true}
        columnDefinitions={[
          {
            id: 'value',
            header: 'Resource',
            cell: (item) => getInfoFromDP(item, 'table', props.catalogMap),
          },
          {
            id: 'principal',
            header: 'Principal',
            cell: (item) => getInfoFromDP(item, 'principal', props.catalogMap),
            sortingField: 'dataLakePrincipal',
          },
          {
            id: 'variable',
            header: 'Access grant',
            cell: (item) => getInfoFromDP(item, 'grant', props.catalogMap),
            isRowHeader: true,
          },
        ]}
        trackBy={'dataPermissionId'}
        defaultSortingField={'dataLakePrincipal'}
        loading={loadingBaseline}
        title='Access grants to review'
        setSelectedItems={props.setDpToKeep}
        setDeselectedItems={props.setDpToRevoke}
      />
    </>
  );
};
