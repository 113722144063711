import * as React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Flashbar, FlashbarProps, SpaceBetween } from '@amzn/awsui-components-react-v3';

import { approveRequest, cancelRequest, denyRequest, getRequest } from '../../../api/permissions';
import { createRequestDetailsLink, Page } from '../../../routes/Paths';
import {
  DenyOrCancelModal,
  ResourceBasedRequestInformation,
  TagBasedRequestInformation,
} from 'src/components/workspaces/requests/utils';
import { APPROVALS_STATUS_CANCELLED, APPROVALS_STATUS_REJECTED, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { extractAccessManagementInfoFromRequest } from 'src/components/requests/utils';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';

import { PageHeader } from 'src/components/common/PageHeader';

export interface PermissionRequestDetailsProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

export interface PermissionRequestDetailsState {
  items: any;
  notifications: FlashbarProps.MessageDefinition[];
  action: string;
  actionLoading: boolean;
  buttonLoading: boolean;
  requestLoading: boolean;
  redirect: string;
  modalVisible: boolean;
  isInvalid: boolean;
  modalText: string;
  request: any;
  textAreaPlaceholder: string;
  reasonOfAction: any;
}

export class WSMyRequestsDetails extends Component<PermissionRequestDetailsProps, PermissionRequestDetailsState> {
  state = {
    items: [],
    notifications: [],
    action: undefined,
    actionLoading: false,
    buttonLoading: false,
    requestLoading: false,
    redirect: undefined,
    modalVisible: false,
    isInvalid: false,
    reasonOfAction: undefined,
    request: '',
    modalText: undefined,
    alertMessage: '',
    textAreaPlaceholder: undefined,
  };

  componentDidMount = async () => {
    // WS level page does not support legacy LF requests - just redirect to home
    if ((this.props.match.params.id as string).startsWith('lf-')) {
      this.setState({ redirect: Page.HOME });
    }
    this.props.setContentType(TABLE_CONTENT_TYPE);
    this.closeModal = this.closeModal.bind(this);
    this.submitAction = this.submitAction.bind(this);
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (!signedInWithWorkspace(this.props)) {
      this.setState({ redirect: createRequestDetailsLink(this.props.match.params.id) });
    }
    if (prevProps.activeWorkspace !== this.props.activeWorkspace) {
      await this.handleRefresh();
    }
  };

  transformData(item) {
    let transform = {};
    transform['requestId'] = item.requestId;
    transform['principal'] = item.dataPermissionRequest.dataLakePrincipal;
    transform['status'] = item.status;
    transform['statusReason'] = item.statusReason;
    transform['owner'] = item.ownerRequestedTo;
    transform['requestWorkspace'] = item.ownerRequestedBy;
    transform['requestedBy'] = item.requestedBy;
    transform['decidedBy'] = item.decidedBy;
    transform['timeOfRequest'] = item.timeOfRequest;
    transform['timeOfDecision'] = item.timeOfDecision;
    transform['useCase'] = item.useCase;
    transform['reasonOfAction'] = item.reasonOfDenial;
    transform = extractAccessManagementInfoFromRequest(item, transform);
    transform['permissions'] = item.dataPermissionRequest.permissions;
    transform['grantablePermissions'] = item.dataPermissionRequest.permissionsWithGrantOption;
    transform['resource'] = item.dataPermissionRequest.resource;
    if (transform['resource'].lFTagPolicy) {
      transform['resourceTag'] = '';
      transform['resource'].lFTagPolicy.expression.forEach((expression) => {
        var resourceTag = '';
        var key = expression.tagKey;
        for (var i = 0; i < expression.tagValues.length; i++) {
          resourceTag += key + '.' + expression.tagValues[i] + ' ';
        }
        transform['resourceTag'] += resourceTag;
      });
    } else if (transform['resource'].redshiftTagPolicy) {
      transform['resourceTag'] = '';
      let policy = transform['resource'].redshiftTagPolicy;
      transform['resourceTag'] = policy.tagKey + '.' + policy.tagValue;
    } else if (transform['resource'].table) {
      transform['type'] = 'table';
      transform['catalogId'] = transform['resource'].table.catalogId;
      transform['databaseName'] = transform['resource'].table.databaseName;
      transform['resource'].table.tableWildcard
        ? (transform['tableName'] = 'All Tables')
        : (transform['tableName'] = transform['resource'].table.name);
      transform['columnNames'] = 'All Columns';
    } else if (transform['resource'].dataCellsFilter) {
      transform['catalogId'] = transform['resource'].dataCellsFilter.tableCatalogId;
      transform['clusterId'] = transform['resource'].dataCellsFilter.clusterId;
      transform['databaseName'] = transform['resource'].dataCellsFilter.databaseName;
      transform['schemaName'] = transform['resource'].dataCellsFilter.schemaName;
      transform['tableName'] = transform['resource'].dataCellsFilter.tableName;
      transform['fgapName'] = transform['resource'].dataCellsFilter.name;
    } else if (transform['resource'].dataZoneResource) {
      transform['catalogId'] = transform['resource'].dataZoneResource.awsAccountId;
      transform['clusterId'] = transform['resource'].dataZoneResource.clusterId;
      transform['redshiftWorkgroupName'] = transform['resource'].dataZoneResource.redshiftWorkgroupName;
      transform['databaseName'] = transform['resource'].dataZoneResource.databaseName;
      transform['schemaName'] = transform['resource'].dataZoneResource.schemaName;
      transform['tableName'] = transform['resource'].dataZoneResource.tableName;
    } else {
      transform['type'] = 'column';
      transform['catalogId'] = transform['resource'].tableWithColumns.catalogId;
      transform['databaseName'] = transform['resource'].tableWithColumns.databaseName;
      transform['tableName'] = transform['resource'].tableWithColumns.name;
      if (transform['resource'].tableWithColumns.columnNames) {
        transform['columnNames'] = transform['resource'].tableWithColumns.columnNames.join(', ');
        transform['columnWildCard'] = 'Include';
      } else {
        transform['columnNames'] = transform['resource'].tableWithColumns.columnWildcard.excludedColumnNames.join(', ');
        transform['columnWildCard'] = 'Exclude';
      }
    }
    return transform;
  }

  handleRefresh = async () => {
    this.closeModal();
    if (!this.props.activeGroup) return;
    let requestResult;
    try {
      if (this.props.match.params.id) {
        requestResult = await getRequest({
          requestId: this.props.match.params.id,
        });
        this.setState({
          request: this.transformData(requestResult.requestItem),
        });
      } else {
        this.setState({
          notifications: [
            {
              type: 'error' as FlashbarProps.Type,
              content: 'No request found for given Id',
              dismissible: true,
              onDismiss: () => this.setState({ notifications: [] }),
            },
          ],
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        notifications: [
          {
            type: 'error' as FlashbarProps.Type,
            content: `Failed to load request detail`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  };

  openModal(clickedAction) {
    this.setState({ modalVisible: true, action: clickedAction });
  }

  closeModal() {
    this.setState({
      modalVisible: false,
      action: undefined,
      buttonLoading: false,
    });
  }

  successMessage(message, action) {
    if (action === 'approved') {
      this.setState({
        notifications: [
          {
            type: 'success',
            content: `Resource-based data permission request '${message}' was '${action}'`,
            dismissible: true,
            buttonText: 'View datasets',
            onButtonClick: () =>
              this.setState({
                redirect: Page.WORKSPACE_ALL_PERMISSIONS,
              }),
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } else {
      this.setState({
        notifications: [
          {
            type: 'success',
            content: `Resource-based data permission request '${message}' was '${action}'`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  }

  errorMessage(message, action) {
    this.setState({
      notifications: [
        {
          type: 'error',
          content: `There was an error '${action}' your data access request: '${message}'`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  submitAction = async (action) => {
    if (action === 'cancel') {
      if (!this.state.reasonOfAction) {
        this.setState({ isInvalid: true });
        return;
      }
      this.setState({ buttonLoading: true });
      try {
        await cancelRequest({
          requestId: this.state.request['requestId'],
          reason: this.state.reasonOfAction,
        });
        this.successMessage(this.state.request['requestId'], 'cancelled');
        await this.handleRefresh();
      } catch (err) {
        console.log(err);
        this.errorMessage(err.message, 'canceling');
      }
    } else if (action === 'approve') {
      this.setState({ actionLoading: true });
      try {
        await approveRequest({
          requestId: this.state.request['requestId'],
        });
        this.successMessage(this.state.request['requestId'], 'approved');
        await this.handleRefresh();
      } catch (err) {
        console.log(err);
        this.errorMessage(err.message, 'approving');
      }
      this.setState({ actionLoading: false });
    } else if (action === 'deny') {
      if (!this.state.reasonOfAction) {
        this.setState({ isInvalid: true });
        return;
      }
      this.setState({ buttonLoading: true });
      try {
        await denyRequest({
          requestId: this.state.request['requestId'],
          reason: this.state.reasonOfAction,
        });
        this.successMessage(this.state.request['requestId'], 'denied');
        await this.handleRefresh();
      } catch (err) {
        console.log(err);
        this.errorMessage(err.message, 'denying');
      }
    }
  };

  handleAction = async (e) => {
    if (e.detail.id === 'cancel') {
      this.openModal('cancel');
    } else if (e.detail.id === 'approve') {
      await this.submitAction(e.detail.id);
    } else if (e.detail.id === 'deny') {
      this.openModal('deny');
    } else if (e.detail.id === 'view') {
      await this.submitAction(e.detail.id);
    }
  };

  shouldDisableDenyButton = () => {
    if (this.state.request['status'] != 'PENDING') return true;
    return false;
  };

  shouldDisableApproveButton = () => {
    if (this.state.request['status'] != 'PENDING') return true;
    if (!this.state.request['approvalsWorkflow']) return false;
    let anyApprovalWorkflowsArePending = false;
    this.state.request['approvalsWorkflow'].forEach((item) => {
      if (item.approvalWorkflowStatus != 'APPROVED' && item.approvalWorkflowStatus != 'APPROVED_WITH_CONDITION') {
        anyApprovalWorkflowsArePending = true;
      }
    });
    return anyApprovalWorkflowsArePending;
  };

  approvalWorkflowStatusOutOfSync = (desiredStatuses: string[]) => {
    let outOfSync = false;
    if (this.state.request['approvalsWorkflow']) {
      this.state.request['approvalsWorkflow'].forEach((item) => {
        if (!desiredStatuses.includes(item.approvalWorkflowStatus)) {
          outOfSync = true;
        }
      });
    }
    return outOfSync;
  };

  options() {
    let actionItems = [];
    if (this.props.activeWorkspace?.workspaceId === this.state.request['requestWorkspace']) {
      actionItems = [
        {
          text: 'Cancel',
          id: 'cancel',
          disabled: this.state.request['status'] !== 'PENDING',
        },
      ];
    }
    if (this.props.activeWorkspace?.workspaceId === this.state.request['owner']) {
      actionItems.push(
        {
          text: 'Approve',
          id: 'approve',
          disabled: this.shouldDisableApproveButton(),
        },
        {
          text: 'Deny',
          id: 'deny',
          disabled: this.shouldDisableDenyButton(),
        },
      );
    }
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: this.handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: this.handleAction,
        loading: this.state.actionLoading,
        items: actionItems,
      },
    ];
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <SpaceBetween size='xs'>
        <Flashbar items={this.state.notifications} />
        <DenyOrCancelModal
          modalVisible={this.state.modalVisible}
          action={this.state.action}
          closeModal={this.closeModal}
          buttonLoading={this.state.buttonLoading}
          submitAction={this.submitAction}
          approvalWorkflowStatusOutOfSync={this.approvalWorkflowStatusOutOfSync([
            APPROVALS_STATUS_REJECTED,
            APPROVALS_STATUS_CANCELLED,
          ])}
          textAreaPlaceholder={this.state.textAreaPlaceholder}
          reasonOfAction={this.state.reasonOfAction}
          setReasonOfAction={(value) =>
            this.setState({
              reasonOfAction: value,
            })
          }
        />
        <PageHeader buttons={this.options()} header={`Request ID: ${this.props.match.params.id}`} />
        {!this.state.request['resourceTag'] && <ResourceBasedRequestInformation request={this.state.request} />}
        {this.state.request['resourceTag'] && <TagBasedRequestInformation request={this.state.request} />}
      </SpaceBetween>
    );
  }
}
