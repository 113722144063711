import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  CollectionPreferencesProps,
  Table,
  Header,
  Button,
  SpaceBetween,
  ButtonDropdown,
  Modal,
  Box,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import {
  activeGroupOrWorkspaceId,
  getResourceNameFromArn,
  getResourceTypeFromArn,
  linkForResource,
} from 'src/components/dataadvisory/constants';
import { listUserPreferences, updateUserPreference } from 'src/api/notifications';
import { UpdateUserPreferenceRequest } from 'aws-sdk/clients/awsdlomni';

export interface UserPreferencesTableProps {
  preferenceType: string; // AdvisoryNotification
  advisoryPreferenceType?: string; // opt-in | opt-out. Required if preferenceType == AdvisoryNotification
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: string;
  setNotifications: any;
}

export const UserPreferencesTable = (props: UserPreferencesTableProps) => {
  const [allItems, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableMessage, setTableMessage] = useState('No advisories');
  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'scope',
      header: 'Scope',
      cell: (item) => getResourceTypeFromArn(item.resourceArn),
      minWidth: 125,
      maxWidth: 125,
      sortingField: 'scope',
    },
    {
      id: 'value',
      header: 'Value',
      cell: linkForResource,
      minWidth: 300,
      maxWidth: 1000,
      sortingField: 'value',
    },
  ];

  useEffect(() => {
    //props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup, props.activeWorkspace]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const userPreferencesResult = await listUserPreferences({
        filter: {
          ownerId: activeGroupOrWorkspaceId(props),
          //types: [props.preferenceType],
        },
      });
      let userPreferences = userPreferencesResult.userPreferences;
      if (props.preferenceType == 'AdvisoryNotification') {
        userPreferences = userPreferences.filter((userPreference) => {
          const preferenceBody = JSON.parse(userPreference.preferenceBodyJson);
          console.log(preferenceBody);
          if (props.advisoryPreferenceType == 'opt-in') {
            return preferenceBody.optIn == 'true';
          } else {
            return preferenceBody.optOut == 'true';
          }
        });
      }
      setItems(userPreferences);
      setLoading(false);
    } catch (err) {
      setTableMessage(`Unable to load preferences: ${err.message}`);
      setLoading(false);
    }
  };

  const getHeader = () => {
    if (props.preferenceType == 'AdvisoryNotification') {
      if (props.advisoryPreferenceType == 'opt-in') {
        return 'Opt-in settings';
      } else {
        return 'Opt-out settings';
      }
    }
    return 'User preferences';
  };

  const getDescription = () => {
    if (props.preferenceType == 'AdvisoryNotification') {
      if (props.advisoryPreferenceType == 'opt-in') {
        return 'You will always receive notifications for these advisories.';
      } else {
        return 'You will not receive notifications for these advisories, even if the data provider adds you as a customer.';
      }
    }
    return;
  };

  const getConfirmDeleteText = () => {
    if (props.preferenceType == 'AdvisoryNotification') {
      if (props.advisoryPreferenceType == 'opt-in') {
        return (
          <TextContent>
            You will no longer be opted-in to notifications for{' '}
            <strong>{getResourceNameFromArn(selectedItems?.[0]?.resourceArn)}</strong>.
          </TextContent>
        );
      } else {
        return (
          <TextContent>
            You will no longer be opted-out from notifications for{' '}
            <strong>{getResourceNameFromArn(selectedItems?.[0]?.resourceArn)}</strong>.
          </TextContent>
        );
      }
    }
    return;
  };

  const deleteSelectedItem = async () => {
    // for advisory preferences, we don't delete the item, we set it to optIn=false and optOut=false.
    const request: UpdateUserPreferenceRequest = {
      ownerId: selectedItems[0].ownerId,
      resourceArn: selectedItems[0].resourceArn,
      type: selectedItems[0].type,
      preferenceBodyJson: '{"optIn":"false","optOut":"false"}',
    };
    try {
      await updateUserPreference(request);
      setNotificationForSuccessfulDelete();
    } catch (e) {
      setNotificationForFailedDelete(e);
    }
    setConfirmDeleteModalVisible(false);
    await handleRefresh();
  };

  const setNotificationForFailedDelete = (e) => {
    props.setNotifications([
      {
        type: 'error',
        content: 'Failed to delete the user preference: ' + e.message,
        dismissible: true,
      },
    ]);
  };

  const setNotificationForSuccessfulDelete = () => {
    props.setNotifications([
      {
        type: 'success',
        content: 'Successfully deleted the user preference.',
        dismissible: true,
      },
    ]);
  };

  const { items, collectionProps } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No preferences to display.</p>
        </div>
      ),
    },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <Modal
        visible={confirmDeleteModalVisible}
        onDismiss={() => setConfirmDeleteModalVisible(false)}
        header={'Confirm delete'}
        footer={
          <Header
            actions={
              <SpaceBetween size={'s'} direction={'horizontal'}>
                <Button variant={'link'} onClick={() => setConfirmDeleteModalVisible(false)}>
                  Cancel
                </Button>
                <Button variant={'primary'} onClick={() => deleteSelectedItem()}>
                  Delete
                </Button>
              </SpaceBetween>
            }
          />
        }
      >
        Are you sure you want to delete this preference? {getConfirmDeleteText()}
      </Modal>
      <Table
        {...collectionProps}
        loadingText='Loading preferences...'
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='m'>
              <b>No preferences</b>
              <p>You do not have any {getHeader().toLowerCase()}.</p>
            </SpaceBetween>
          </Box>
        }
        selectedItems={selectedItems}
        selectionType={'single'}
        onSelectionChange={(e) => setSelectedItems(e.detail.selectedItems)}
        wrapLines={preferences.wrapLines}
        header={
          <Header
            actions={
              <ButtonDropdown
                items={[
                  {
                    text: 'Delete',
                    id: 'delete',
                    disabled: selectedItems.length < 1,
                  },
                ]}
                onItemClick={() => setConfirmDeleteModalVisible(true)}
              >
                Actions
              </ButtonDropdown>
            }
            counter={'(' + items.length + ')'}
            description={getDescription()}
          >
            {getHeader()}
          </Header>
        }
      />
    </>
  );
};
