import { useEffect, useState } from 'react';
import { FORM_CONTENT_TYPE, hcMetadataFormType, hcMetadataStatus } from 'src/commons/constants';
import { Redirect } from 'react-router-dom';
import * as React from 'react';
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Page } from 'src/routes';
import { createGlossary } from 'src/api/catalog';

export interface CreateBusinessGlossaryProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const CreateBusinessGlossary = (props: CreateBusinessGlossaryProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [glossaryType, setGlossaryType] = useState(undefined);
  const [glossaryValue, setGlossaryValue] = useState(undefined);
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
  }, []);

  const handleCreateBusinessGlossary = async () => {
    try {
      let createGlossaryResult = await createGlossary({
        OwnerId: ownerId,
        Name: name,
        Description: description,
        Status: status.value,
        GlossaryType: glossaryType.value,
        GlossaryValue: glossaryValue,
      });
      if (createGlossaryResult.Message == 'Success') {
        setRedirect({
          pathname: Page.BUSINESS_GLOSSARIES,
          state: {
            status: 'success',
            message: 'Glossary created successfully',
          },
        });
      } else {
        setNotification('error', `Failed to create glossary. Reason: ${createGlossaryResult.Message}`);
      }
    } catch (err) {
      setNotification('error', `Error in creating glossary. ${err.message}`);
    } finally {
      setButtonLoading(false);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'error') {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      <Flashbar items={notifications} />
      <Form
        header={
          <Header variant='h1' description='Please specify the details of your glossary.'>
            Create business glossary
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.BUSINESS_GLOSSARIES)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={buttonLoading}
              onClick={handleCreateBusinessGlossary}
              disabled={!name || !description || !glossaryType || !status}
            >
              {'Create'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header variant='h2'>Glossary details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Owner</div>} description='Owner of the glossary.'>
                <Input
                  name='ownerId'
                  placeholder='Glossary owner'
                  value={ownerId}
                  ariaRequired={true}
                  disabled={true}
                />
              </FormField>
              <FormField label={<div>Name</div>} description='Name of the glossary.'>
                <Input
                  name='name'
                  placeholder='Name of the glossary'
                  value={name}
                  onChange={({ detail }) => setName(detail.value)}
                  ariaRequired={true}
                />
              </FormField>
              <FormField label={<div>Description</div>} description='Description of the glossary.'>
                <Input
                  name='description'
                  placeholder='Description of the glossary'
                  value={description}
                  onChange={({ detail }) => setDescription(detail.value)}
                  ariaRequired={true}
                />
              </FormField>
              <FormField label={<div>Type</div>} description='Select a glossary type'>
                <Select
                  name='glossaryType'
                  selectedOption={glossaryType}
                  ariaRequired={true}
                  placeholder='Choose a type'
                  onChange={({ detail }) => setGlossaryType(detail.selectedOption)}
                  options={hcMetadataFormType}
                  selectedAriaLabel='Selected'
                />
              </FormField>
              <FormField label={<div>Value</div>} description='Value for the glossary.'>
                <Input
                  name='value'
                  placeholder='Description of the glossary'
                  value={glossaryValue}
                  onChange={({ detail }) => setGlossaryValue(detail.value)}
                  ariaRequired={true}
                />
              </FormField>
              <FormField label={<div>Status</div>} description='Set it to enabled to be visible to consumers'>
                <Select
                  name='status'
                  selectedOption={status}
                  ariaRequired={true}
                  placeholder='Choose a type'
                  onChange={({ detail }) => setStatus(detail.selectedOption)}
                  options={hcMetadataStatus}
                  selectedAriaLabel='Selected'
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </>
  );
};

export default CreateBusinessGlossary;
