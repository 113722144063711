import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getDataSourceSchemas, getSchemasForDatabase } from 'src/api/catalog';
import { REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { registerStatus } from 'src/commons/common';
import { EmptyState } from 'src/commons/EmptyState';
import { PageHeader } from 'src/components/common/PageHeader';

export interface RedshiftSchemasProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  selectedDatabaseName: string;
  selectedDatabaseDescription: string;
  setSelectedSchemaDescription: any;
  setSelectedSchemaRegistered: any;
  clusterName: any;
  selectedSchemaName: string;
  setSelectedSchemaName: any;
  setNotifications: any;
}

export const RedshiftSchemas = (props: RedshiftSchemasProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingSchemas, setLoadingSchemas] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState(undefined);
  const [schemaDescription, setSchemaDescription] = useState(undefined);

  const handleRefresh = async () => {
    setLoadingSchemas(true);
    let schemaMap = new Map();
    let getDataSourceSchemasRequest = {
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      CatalogId: props.activeWorkspace.accountId,
      ClusterIdentifier: props.clusterName,
      DatabaseName: props.selectedDatabaseName,
      NextToken: null,
    };

    try {
      let getDataSourceSchemasResponse = await getDataSourceSchemas(getDataSourceSchemasRequest);
      for (let schemaName of getDataSourceSchemasResponse.SchemaList) {
        schemaMap.set(schemaName, { Name: schemaName });
      }
      while (getDataSourceSchemasResponse.NextToken != null) {
        getDataSourceSchemasRequest.NextToken = getDataSourceSchemasResponse.NextToken;
        getDataSourceSchemasResponse = await getDataSourceSchemas(getDataSourceSchemasRequest);
        for (let schemaName of getDataSourceSchemasResponse.SchemaList) {
          schemaMap.set(schemaName, { Name: schemaName });
        }
      }
      let getSchemasForDatabaseRequest = {
        DataSourceId: REDSHIFT_DATASOURCE_ID,
        DatabaseName: props.selectedDatabaseName,
        ClusterIdentifier: props.clusterName,
        CatalogId: props.activeWorkspace.accountId,
        Region: props.activeWorkspace.region,
        NextToken: null,
      };
      let schemaInfoList = [];
      let getSchemasForDatabaseResponse = await getSchemasForDatabase(getSchemasForDatabaseRequest);
      schemaInfoList = getSchemasForDatabaseResponse.SchemaInfoList;
      while (getSchemasForDatabaseResponse.NextToken != null) {
        getSchemasForDatabaseRequest.NextToken = getSchemasForDatabaseResponse.NextToken;
        getSchemasForDatabaseResponse = await getSchemasForDatabase(getSchemasForDatabaseRequest);
        schemaInfoList.push(...getSchemasForDatabaseResponse.SchemaInfoList);
      }

      for (let database of schemaInfoList) {
        if (
          database.CatalogId == props.activeWorkspace.accountId &&
          database.Region == props.activeWorkspace.region &&
          database.ClusterIdentifier == props.clusterName
        ) {
          if (schemaMap.has(database.Schema)) {
            let cur = schemaMap.get(database.Schema);
            cur.HCDescription = database.Description;
            cur.Registered = true;
          }
        }
      }
    } catch (err) {
      setLoadingSchemas(false);
      console.log('Exception loading schemas ', err);
      props.setNotifications([
        {
          type: 'error',
          content: `Failed to load schemas` + err.message,
          dismissible: true,
          onDismiss: () => props.setNotifications([]),
        },
      ]);
    }
    setItems(Array.from(schemaMap.values()));
    setLoadingSchemas(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'schemaName',
      header: 'Schema name',
      cell: (item) => item.Name,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Register status',
      cell: (item) => registerStatus(item.Registered !== undefined),
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Schema name',
          key: 'Name',
          groupValuesLabel: 'Schema names',
        },
      ],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedItem = selectedItems[selectedItems.length - 1];
    setSelectedSchema(selectedItem);
    props.setSelectedSchemaName(selectedItem?.Name);

    if (selectedItem?.HCDescription !== undefined) {
      props.setSelectedSchemaDescription(selectedItem?.HCDescription);
      props.setSelectedSchemaRegistered(true);
    } else {
      props.setSelectedSchemaRegistered(false);
    }

    setSchemaDescription(undefined);
  }, [collectionProps.selectedItems]);

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading schemas...'
        loading={loadingSchemas}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={<PageHeader buttons={[]} counter={`(${allItems.length})`} header={'Redshift schemas'} />}
        selectionType='single'
        isItemDisabled={(item) => item.status == 'Registered'}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={<EmptyState title={'No schemas'} subtitle={'No schemas were found.'} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingSchemas}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />

      <br />
      <br />

      {selectedSchema !== undefined && !selectedSchema.Registered && (
        <>
          <Container header={<Header variant='h2'>Schema details</Header>}>
            <ColumnLayout>
              <FormField label='Schema name'>
                <Input value={selectedSchema.Name} disabled />
              </FormField>
              <FormField label='Schema description'>
                {selectedSchema.Registered == undefined ? (
                  <Input
                    placeholder={'Schema description'}
                    value={schemaDescription}
                    onChange={(e) => {
                      setSchemaDescription(e.detail.value);
                      props.setSelectedSchemaDescription(e.detail.value);
                    }}
                  />
                ) : (
                  <Input placeholder={'Schema description'} value={selectedSchema.HCDescription} disabled />
                )}
              </FormField>
            </ColumnLayout>
          </Container>
        </>
      )}
    </>
  );
};
