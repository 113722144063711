import * as React from 'react';
import { useEffect } from 'react';
import { Box, Header, Pagination, StatusIndicator, Table, TableProps } from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { paginationLabels } from 'src/commons/tables';
import { EmptyState } from 'src/commons/EmptyState';

export interface StrikeThroughTableProps<T> {
  columnDefinitions: ReadonlyArray<TableProps.ColumnDefinition<T>>;
  data: ReadonlyArray<T>;
  defaultSelectedItems?: boolean;
  defaultSortingField: string;
  title: string;
  description?: string;
  loading?: boolean;
  trackBy: string;
  setSelectedItems?: (value: readonly T[]) => void;
  setDeselectedItems?: (value: readonly T[]) => void;
  enableStrikeThrough?: boolean;
  tableDisabled?: boolean;
  pageSize?: number;
  selectionType?: any;
}

export const StrikeThroughTable = <T,>({
  defaultSelectedItems = false,
  loading = false,
  enableStrikeThrough = true,
  tableDisabled = false,
  pageSize = 25,
  defaultSortingField,
  data,
  columnDefinitions,
  trackBy,
  title,
  description,
  setSelectedItems,
  setDeselectedItems,
  selectionType = 'multi',
}: StrikeThroughTableProps<T>) => {
  const { items, actions, collectionProps, paginationProps } = useCollection(data, {
    pagination: {
      pageSize: pageSize,
    },
    selection: {
      keepSelection: true,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: defaultSortingField,
        },
      },
    },
  });

  useEffect(() => {
    if (defaultSelectedItems) {
      actions.setSelectedItems(data);
    }
  }, [data]);

  useEffect(() => {
    if (setSelectedItems) {
      setSelectedItems(collectionProps.selectedItems);
    }
    if (setDeselectedItems) {
      setDeselectedItems(data.filter((item) => !isItemSelected(item)));
    }
  }, [collectionProps.selectedItems]);

  /**
   * Wraps table column definitions to strike through any row that is not selected.
   *
   * @param {ColumnDefinition[]} columnDefinitions - Array of table column definitions
   * @returns {ColumnDefinition[]} Modified column definitions with wrapped cell renderers
   *
   * @example
   * const enhancedColumns = wrapColumnDefinitions([
   *   { header: "Name", cell: (item) => item.name }
   * ]);
   */
  const wrapColumnDefinitions = (
    columnDefinitions: ReadonlyArray<TableProps.ColumnDefinition<T>>,
  ): ReadonlyArray<TableProps.ColumnDefinition<T>> => {
    return columnDefinitions.map((column) => {
      if (typeof column.cell !== 'function') {
        return column;
      }
      return {
        ...column,
        cell: (item) => {
          if (!isItemSelected(item) && enableStrikeThrough) {
            return addStrikethroughStyle(column.cell(item));
          } else {
            return column.cell(item);
          }
        },
      };
    });
  };

  const isItemSelected = (item: T) => {
    return collectionProps.selectedItems?.some((obj) => obj[trackBy] == item[trackBy]);
  };

  const addStrikethroughStyle = (content: React.ReactNode): React.ReactElement => (
    <div style={{ textDecoration: 'line-through' }}>{content}</div>
  );

  const getHeaderCounterText = (items: ReadonlyArray<T>, selectedItems: ReadonlyArray<T> | undefined) => {
    return selectedItems && selectedItems?.length > 0
      ? `(${selectedItems.length}/${items.length})`
      : `(${items.length})`;
  };

  return (
    <Table
      {...collectionProps}
      items={items}
      renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
        `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
      }
      renderLoaderLoading={() => <StatusIndicator type='loading'>Loading items</StatusIndicator>}
      renderLoaderError={() => <StatusIndicator type='error'>Loading error</StatusIndicator>}
      renderLoaderEmpty={() => <Box>No resources found</Box>}
      selectionType={selectionType}
      selectedItems={collectionProps.selectedItems}
      columnDefinitions={wrapColumnDefinitions(columnDefinitions)}
      enableKeyboardNavigation
      loadingText='Loading resources'
      loading={loading}
      isItemDisabled={(_) => tableDisabled}
      empty={<EmptyState title='No resources found' subtitle='No resources' />}
      header={
        <Header
          description={description}
          counter={getHeaderCounterText(data, collectionProps.selectedItems)}
          variant={'h3'}
        >
          {title}
        </Header>
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
    />
  );
};
