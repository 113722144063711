import * as React from 'react';
import { useState } from 'react';
import {
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  TableProps,
  Table,
  CollectionPreferences,
  Box,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import { EmptyState } from 'src/commons/EmptyState';

import { PageHeader } from 'src/components/common/PageHeader';

export interface TaggedResourcesTableProps {
  taggedResources: any;
  tableMessage: string;
  loadingTaggedResources: boolean;
}

const TaggedResourcesTable = (props: TaggedResourcesTableProps) => {
  const { taggedResources, tableMessage, loadingTaggedResources } = props;

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const taggedResourcesColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'resourceType',
      header: 'Resource Type',
      cell: (resource) => resource.resourceType,
      minWidth: '200px',
      sortingField: 'resourceType',
    },
    {
      id: 'resourceName',
      header: 'Resource Name',
      cell: (resource) => resource.resourceName, // TODO: add link to resource detail page for elements under this column
      minWidth: '200px',
      sortingField: 'resourceName',
    },
  ];

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(taggedResources, {
    filtering: {
      noMatch: '',
      empty: <EmptyState title={tableMessage} subtitle='No tags to display.' />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading tagged resources...'
        loading={loadingTaggedResources}
        columnDefinitions={taggedResourcesColumnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={<PageHeader buttons={[]} header={'Resources attached to selected tag'} />}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No resources</b>
            <Box variant='p' color='inherit'>
              No resources to display.
            </Box>
          </Box>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 5, label: '5 items' },
                { value: 10, label: '10 items' },
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
              ],
            }}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
      />
    </>
  );
};

export default TaggedResourcesTable;
