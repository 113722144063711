import * as React from 'react';
import { useState } from 'react';
import {
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  TableProps,
  Table,
  CollectionPreferences,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import { EmptyState } from 'src/commons/EmptyState';

import { PageHeader } from 'src/components/common/PageHeader';

export interface TagSharedWorkspacesTableProps {
  tagSharedWorkspaces: any;
  tableMessage: string;
  loadingTagSharedWorkspaces: boolean;
}

const TagSharedWorkspacesTable = (props: TagSharedWorkspacesTableProps) => {
  const { tagSharedWorkspaces, tableMessage, loadingTagSharedWorkspaces } = props;

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const tagSharedWorkspacesColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'catalogId',
      header: 'Account (Catalog)',
      cell: (permission) => permission.catalogId,
      minWidth: '200px',
      sortingField: 'catalogId',
    },
    {
      id: 'workspaceName',
      header: 'Workspace Name',
      cell: (permission) => permission.ownerId,
      minWidth: '200px',
      sortingField: 'workspaceName',
    },
  ];

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    tagSharedWorkspaces,
    {
      filtering: {
        noMatch: '',
        empty: <EmptyState title={tableMessage} subtitle='No tags to display.' />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading accessible workspaces...'
        loading={loadingTagSharedWorkspaces}
        columnDefinitions={tagSharedWorkspacesColumnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={<PageHeader buttons={[]} header={"Workspaces with access to the selected tag's resources"} />}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={<EmptyState title='No workspaces' subtitle='No workspaces to display.' />}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 5, label: '5 items' },
                { value: 10, label: '10 items' },
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
              ],
            }}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
      />
    </>
  );
};

export default TagSharedWorkspacesTable;
