import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Table,
  Header,
  Button,
  Flashbar,
  FlashbarProps,
  Modal,
  Box,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Link, Redirect } from 'react-router-dom';
import {
  ARIA_LABELS,
  PAGINATION_ARIA_LABELS,
  TABLE_EMPTY_STATE,
  TABLE_NO_MATCH_STATE,
} from 'src/components/permissions/myBaselining/baseliningUtils';
import { DATABASE_RESOURCE_TYPE, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { createSubscription, deleteSubscription, listSubscriptions } from 'src/api/catalog';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';
import { createEventSubscriptionDetailsLink } from 'src/routes';

export interface SubscriptionsTableProps {
  setContentType: any;
  activeWorkspace: object;
  resource: object;
  allowSNSEventSubscription: boolean;
}

export const SubscriptionsTable = (props: SubscriptionsTableProps) => {
  const rawColumns = [
    {
      id: 'subscriptionId',
      sortingField: 'subscriptionId',
      header: 'Subscription ID',
      cell: (item) => <Link to={createEventSubscriptionDetailsLink(item.Id)}>{item.Id}</Link>,
      minWidth: 250,
    },
    {
      id: 'subscriberOwnerId',
      header: 'Subscriber owner ID',
      cell: (item) => <Link to={getInfoFromSubscriptions(item, 'ownerIdLink')}>{item.OwnerId}</Link>,
      minWidth: 250,
    },
    {
      id: 'subscriberId',
      header: 'Subscriber ID',
      cell: (item) => item.SubscriberId,
      minWidth: 150,
    },
    {
      id: 'type',
      cell: (item) => item.Type,
      header: 'Type',
      minWidth: 75,
    },
    {
      id: 'status',
      cell: (item) => <StatusIcon status={item.Status} />,
      header: 'Status',
      minWidth: 75,
    },
    {
      id: 'createdOn',
      cell: (item) => item.CreatedOn,
      header: 'Created on',
      minWidth: 200,
    },
    {
      id: 'createdBy',
      cell: (item) => item.CreatedBy,
      header: 'Created by',
      minWidth: 200,
    },
  ];

  function createTableSortLabelFn(
    column: TableProps.ColumnDefinition<unknown>,
  ): TableProps.ColumnDefinition<unknown>['ariaLabel'] {
    if (!column.sortingField && !column.sortingComparator && !column.ariaLabel) {
      return;
    }
    return ({ sorted, descending }) => {
      return `${column.header}, ${sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted'}.`;
    };
  }

  const COLUMN_DEFINITIONS = rawColumns.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  const DEFAULT_PREFERENCES = {
    pageSize: 30,
    contentDisplay: [
      { id: 'subscriptionId', visible: true },
      { id: 'subscriberOwnerId', visible: true },
      { id: 'subscriberId', visible: true },
      { id: 'subscriptionType', visible: true },
      { id: 'subscriptionStatus', visible: true },
      { id: 'createdDate', visible: true },
      { id: 'createdBy', visible: true },
    ],
    wrapLines: false,
    stripedRows: false,
    stickyColumns: { first: 0, last: 0 },
  };

  const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Subscriptions' },
    { value: 30, label: '30 Subscriptions' },
    { value: 50, label: '50 Subscriptions' },
  ];

  const [allItems, setAllItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isNotWorkspace, setIsNotWorkspace] = useState(true);
  const [isLoadingUserSubscription, setIsLoadingUserSubscription] = useState(false);
  const [userSubscription, setUserSubscription] = useState(undefined);
  const [resourceId, setResourceId] = useState(undefined);
  const [unsubscribeModalVisible, setUnsubscribeModalVisible] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [redirect] = useState(undefined);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

  const { items, actions, collectionProps, paginationProps } = useCollection(allItems, {
    filtering: {
      empty: <TABLE_EMPTY_STATE resourceName='Subscriptions' />,
      noMatch: <TABLE_NO_MATCH_STATE onClearFilter={() => actions.setFiltering('')} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS[0] } },
    selection: {},
  });

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.resource]);

  const getInfoFromSubscriptions = (subscription, infoName) => {
    if (!subscription) return null;
    switch (infoName) {
      case 'ownerIdLink':
        if (subscription.OwnerId.startsWith('wks-')) return `/search/workspaces/${subscription.OwnerId}`;
        else return `/groups/${subscription.OwnerId}`;
    }
  };

  const handleRefresh = async () => {
    try {
      setIsAction(false);
      setLoadingSubscriptions(true);
      if (!props.resource) return;
      const subscriptions = await fetchSubscriptionsForResource(props.resource);
      setAllItems(subscriptions);
      setLoadingSubscriptions(false);
      if (!props.activeWorkspace) {
        setIsNotWorkspace(true);
        return;
      } else {
        setIsNotWorkspace(false);
      }
      await fetchSubscriptionsForResourceAndUser(props.resource, props.activeWorkspace);
    } catch (err) {
      console.error(err);
      setLoadingSubscriptions(false);
    }
  };

  const handleSubscriptionAction = async (workspace) => {
    if (isSubscribed && userSubscription !== undefined) setUnsubscribeModalVisible(true);
    else {
      await handleCreateSubscription(workspace);
    }
  };

  const handleDeleteSubscription = async () => {
    try {
      setIsAction(true);
      await deleteSubscription({ Id: userSubscription.Id });
    } catch (err) {
      setIsAction(false);
      setUnsubscribeModalVisible(false);
      setNotifications([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to delete subscription.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      console.error(err);
    }
    setIsAction(false);
    setUnsubscribeModalVisible(false);
    await handleRefresh();
    setNotifications([
      {
        type: 'success' as FlashbarProps.Type,
        content: `Successfully deleted subscription.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const handleCreateSubscription = async (workspace) => {
    setIsAction(true);
    try {
      await createSubscription({
        ResourceId: resourceId,
        Owner: workspace.workspaceId,
        SubscriberId: workspace.accountId,
        Type: 'Event',
      });
    } catch (err) {
      setIsAction(false);
      setNotifications([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to delete subscription.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      console.error(err);
    }
    await handleRefresh();
    setNotifications([
      {
        type: 'success' as FlashbarProps.Type,
        content: `Successfully created subscription.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const fetchSubscriptionsForResource = async (resource) => {
    let subscriptionList = [];
    if (resource) {
      try {
        let getSubscriptionsForResourceResponse = await listSubscriptions({
          ResourceId: getResourceId(resource),
          //Setting this to only Active subscriptions for now
          Status: 'Active',
        });
        subscriptionList.push(...getSubscriptionsForResourceResponse.Subscriptions);
        return subscriptionList;
      } catch (err) {
        console.log('Failed to fetch subscriptions for this database' + err.message);
      }
    }
  };

  const getResourceId = (resource) => {
    let rId = '';
    if (resource.type.toLowerCase() === DATABASE_RESOURCE_TYPE.toLowerCase())
      rId = `DS-${firstLetterLowerCare(resource?.dataCatalogObjectDetails?.dataSourceId)}|A-${resource?.accountId}|DN-${
        resource?.dataCatalogObjectDetails?.databaseName
      }|R-${resource?.region}`;
    setResourceId(rId);
    return rId;
  };

  const fetchSubscriptionsForResourceAndUser = async (resource, workspace) => {
    setIsLoadingUserSubscription(true);
    if (resource) {
      try {
        let getSubscriptionsForResourceResponse = await listSubscriptions({
          ResourceId: getResourceId(resource),
          OwnerId: workspace.workspaceId,
          Status: 'Active',
        });
        if (getSubscriptionsForResourceResponse.Subscriptions.length > 0) {
          setIsSubscribed(true);
          setUserSubscription(getSubscriptionsForResourceResponse.Subscriptions[0]);
          setIsLoadingUserSubscription(false);
        } else {
          setIsSubscribed(false);
          setUserSubscription(undefined);
          setIsLoadingUserSubscription(false);
        }
      } catch (err) {
        console.log('Failed to fetch subscriptions for this owner' + err.message);
      }
    }
  };

  const firstLetterLowerCare = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Flashbar items={notifications} />

      <Modal
        visible={unsubscribeModalVisible}
        header={[`Unsubscribe?`]}
        onDismiss={() => {
          setUnsubscribeModalVisible(false);
          setIsAction(false);
        }}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  setUnsubscribeModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' loading={isAction} onClick={handleDeleteSubscription}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <TextContent>
          Are you sure you want to unsubscribe from events for the resource: <strong>{resourceId}</strong> for{' '}
          <strong>{userSubscription?.SubscriberId}</strong> account?
          <br />
          <br />
          <p>
            This account will no longer receive events for the resource, which can cause issues with any dependencies on
            these events.
          </p>
        </TextContent>
      </Modal>

      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={COLUMN_DEFINITIONS}
        ariaLabels={ARIA_LABELS}
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        header={
          <Header
            variant='awsui-h1-sticky'
            actions={
              <Button
                onClick={() => {
                  handleSubscriptionAction(props.activeWorkspace);
                }}
                disabled={isNotWorkspace || loadingSubscriptions || (!isSubscribed && !props.allowSNSEventSubscription)}
                loading={isLoadingUserSubscription || isAction}
              >
                {!isSubscribed ? 'Subscribe' : 'Unsubscribe'}
              </Button>
            }
            counter={`(${items.length})`}
          >
            Subscriptions
          </Header>
        }
        loadingText='Loading subscriptions'
        loading={loadingSubscriptions}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='m'>
              <b>No subscriptions found</b>
            </SpaceBetween>
          </Box>
        }
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            disabled={loadingSubscriptions}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SIZE_OPTIONS,
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Select to see all the text and wrap the lines',
            }}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_ARIA_LABELS(paginationProps.pagesCount)} />}
      />
    </>
  );
};
