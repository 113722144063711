import * as React from 'react';
import { Component } from 'react';
import { Box } from '@amzn/awsui-components-react-v3';

interface ErrorBoundaryPrps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryPrps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // We should have an API to report errors, in the meantime just print them
    this.state = { hasError: true };
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Box variant={'h1'}>Something went wrong.</Box>;
    }
    return this.props.children;
  }
}
