import { Table, ColumnLayout, Header, Container } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { CopiableText } from 'src/commons/common';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { EmptyState } from 'src/commons/EmptyState';

export interface datasetsReviewProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  registerItems: any[];
  selectedDatabaseName: string;
  catalogName: string;
}

export const RedshiftDatasetsReview = (props: datasetsReviewProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const columnDefinitions = [
    {
      id: 'dataset',
      header: 'Dataset name',
      cell: (item) => item.label,
      minWidth: 250,
    },
    {
      id: 'schema',
      header: 'Schema name',
      cell: (item) => item.labelTag.split('.')[2],
      minWidth: 250,
    },
    {
      id: 'database',
      header: 'Database name',
      cell: (item) => item.labelTag.split('.')[1],
      minWidth: 250,
    },
    {
      id: 'cluster',
      header: 'Cluster name',
      cell: (item) => item.labelTag.split('.')[0],
      minWidth: 250,
    },
  ];

  const handleRefresh = async () => {
    setLoadingResource(true);
    setItems(props.registerItems);
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const { items, collectionProps } = useCollection(allItems, {
    filtering: {
      empty: <EmptyState title={'No datasets to register'} subtitle={'No datasets were found.'} />,
    },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const getRegisterMetadata = () => {
    const details = [];
    {
      props.activeWorkspace?.accountId !== undefined &&
        details.push(<CopiableText name={'Account ID'} key={'Account ID'} value={props.activeWorkspace?.accountId} />);
    }

    {
      props.catalogName !== undefined &&
        details.push(<CopiableText name={'Catalog name'} key={'Catalog name'} value={props.catalogName} />);
    }

    return details;
  };

  return (
    <>
      <Container header={<Header variant={'h2'}>Register dataset details</Header>}>
        <ColumnLayout columns={3} borders='horizontal'>
          {getRegisterMetadata()}
        </ColumnLayout>
      </Container>

      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        resizableColumns={true}
        header={
          <Header variant={'h2'} counter={`(${allItems.length})`}>
            Datasets to register
          </Header>
        }
        empty={<EmptyState title={'No datasets selected'} subtitle={'No datasets have been selected yet.'} />}
      />
    </>
  );
};
