import * as React from 'react';
import { useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import {
  Header,
  StatusIndicator,
  StatusIndicatorProps,
  SpaceBetween,
  Table,
  Button,
  Alert,
} from '@amzn/awsui-components-react-v3';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { StartGladstoneWorkflowResult, TaskSummary } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { getGladstoneWorkflow, startGladstoneWorkflow } from 'src/api/permissions';
import { createWorkflowStatusLink } from 'src/routes';

export interface WorkflowStatusPageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  environments: any[];
  loading: boolean;
}

export const WorkflowStatusPage = (props: WorkflowStatusPageProps) => {
  const [redirect, setRedirect] = useState<string>(undefined);
  const [parentTask, setParentTask] = useState<TaskSummary>(undefined);
  const [childTaskList, setChildTaskList] = useState<TaskSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>(undefined);

  const taskIdToTaskSummary = (taskSummaries: TaskSummary[], taskId: string) => {
    return taskSummaries.filter((taskSummary) => taskSummary.taskId == taskId)[0];
  };

  // This helper determines the minimum number of tasks that need to run before this task can run. It is used for
  // sorting the tasks into the order in which they execute.
  // memory usage is O(m^n), where m = avg number of parent tasks for a given task, and n = total num of tasks.
  // so it's not going to work with huge workflows!
  const distanceToRoot = (taskSummaries: TaskSummary[], taskSummary: TaskSummary) => {
    if (taskSummary.dependentIds.includes('ROOT')) {
      return 0;
    }
    return (
      1 +
      Math.max(
        ...taskSummary.dependentIds.map((id) => distanceToRoot(taskSummaries, taskIdToTaskSummary(taskSummaries, id))),
      )
    );
  };

  // Sort the tasks into the order they execute in, so root task is first and final leaf task is last.
  const sortTaskSummaries = (taskSummaries: TaskSummary[]) => {
    let sortedList: TaskSummary[] = [];
    let taskDistanceToRootMap: Map<string, string[]> = new Map<string, string[]>();
    taskSummaries.forEach((taskSummary) => {
      const key = `${distanceToRoot(taskSummaries, taskSummary)}`;
      if (!(key in taskDistanceToRootMap)) {
        taskDistanceToRootMap.set(key, []);
      }
      taskDistanceToRootMap.get(key).push(taskSummary.taskId);
    });
    let i = 0;
    while (taskDistanceToRootMap.has(`${i}`)) {
      sortedList.push(...taskDistanceToRootMap.get(`${i}`).map((id) => taskIdToTaskSummary(taskSummaries, id)));
      i += 1;
    }
    return sortedList;
  };

  const handleRefresh = async () => {
    setLoading(true);
    setRedirect(undefined);
    const id = props.match.params.id;
    const getGladstoneWorkflowResult = await getGladstoneWorkflow({
      parentTaskId: id,
    });
    setParentTask(getGladstoneWorkflowResult.parentTask);
    setChildTaskList(sortTaskSummaries(getGladstoneWorkflowResult.childTaskList));

    setLoading(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.match.params.id]);

  /*
  This defines a human-readable name and description for various Gladstone task types. For our purposes we are only
  using the DZ ones (at the bottom) but this can be filled out further as needed.
   */
  const readableProcessTypeMapping = {
    WorkspaceOnboard: {
      name: 'Onboard workspace',
      description: '',
    },
    WorkspaceSync: {
      name: 'Sync workspace',
      description: '',
    },
    WorkspaceAudit: {
      name: 'Audit workspace',
      description: '',
    },
    GlueCatalogOnboard: {
      name: 'Onboard Glue catalog',
      description: '',
    },
    RedshiftCatalogOnboard: {
      name: 'Onboard Redshift catalog',
      description: '',
    },
    CatalogDiscoveryCfnCreate: {
      name: 'Create catalog discovery stack',
      description: '',
    },
    CatalogDiscoveryCfnUpdate: {
      name: 'Update catalog discovery stack',
      description: '',
    },
    CatalogDiscoveryCfnDrift: {
      name: 'Discovery stack drift check',
      description: '',
    },
    CatalogManagerCfnCreate: {
      name: 'Create catalog manager stack',
      description: '',
    },
    CatalogManagerCfnUpdate: {
      name: 'Update catalog manager stack',
      description: '',
    },
    CatalogManagerCfnDrift: {
      name: 'Manager stack drift check',
      description: '',
    },
    AnalyticsManagerCfnCreate: {
      name: 'Create analytics manager stack',
      description: '',
    },
    AnalyticsManagerCfnUpdate: {
      name: 'Update analytics manager stack',
      description: '',
    },
    AnalyticsManagerCfnDrift: {
      name: 'Analytics stack drift check',
      description: '',
    },
    AccessLoggingCfnCreate: {
      name: 'Create access logging stack',
      description: '',
    },
    AccessLoggingCfnUpdate: {
      name: 'Update access logging stack',
      description: '',
    },
    AccessLoggingCfnDrift: {
      name: 'Logging stack drift check',
      description: '',
    },
    LakeFormationGrantsForAthena: {
      name: 'Create LF grants for Athena',
      description: '',
    },
    WorkspacePolicySync: {
      name: 'Workspace policy sync',
      description: '',
    },
    RedshiftSyncUsers: {
      name: 'Sync Redshift users',
      description: '',
    },
    RedshiftSyncUsersCreateRole: {
      name: 'Create Redshift role',
      description: '',
    },
    RedshiftSyncUsersCreateGrant: {
      name: 'Create Redshift grant',
      description: '',
    },
    RedshiftAssociateTag: {
      name: 'Associate Redshift tag',
      description: '',
    },
    RedshiftCreateTagRole: {
      name: 'Create Redshift tag role',
      description: '',
    },
    RedshiftTagGrant: {
      name: 'Create Redshift tag grant',
      description: '',
    },
    RedshiftDissociateTag: {
      name: 'Dissociate Redshift tag',
      description: '',
    },
    RedshiftTagRevoke: {
      name: 'Revoke Redshift tag',
      description: '',
    },
    RedshiftCreateDataPermission: {
      name: 'Create Redshift data permission',
      description: '',
    },
    RedshiftCreateConsumerRoleDataPermission: {
      name: 'Create Redshift consumer role data permission',
      description: '',
    },
    RedshiftConsumerGrantDataPermission: {
      name: 'Create Redshift consumer grant data permission',
      description: '',
    },
    RedshiftApproval: {
      name: 'Approve Redshift requests',
      description: '',
    },
    RedshiftCreateConsumerRole: {
      name: 'Create Redshift consumer role',
      description: '',
    },
    RedshiftConsumerGrant: {
      name: 'Create Redshift consumer grant',
      description: '',
    },
    RedshiftConsumerRevoke: {
      name: 'Revoke Redshift consumer grant',
      description: '',
    },
    RedshiftDeactivateDataPermission: {
      name: 'Deactivate Redshift data permission',
      description: '',
    },
    RedshiftRevokeDataPermissionConsumer: {
      name: 'Revoke Redshift consumer data permission',
      description: '',
    },
    RedshiftCreateFgaPolicy: {
      name: 'Create Redshift FGA policy',
      description: '',
    },
    RedshiftCreateRlsPolicy: {
      name: 'Create Redshift RLS policy',
      description: '',
    },
    RedshiftEditFgaPolicy: {
      name: 'Edit Redshift FGA policy',
      description: '',
    },
    RedshiftEditColumnFgaPolicy: {
      name: 'Edit Redshift column FGA policy',
      description: '',
    },
    RedshiftDeleteFgaPolicy: {
      name: 'Delete Redshift FGA policy',
      description: '',
    },
    RedshiftDeleteRlsPolicy: {
      name: 'Delete Redshift RLS policy',
      description: '',
    },
    RedshiftFgaApproval: {
      name: 'Approve Redshift FGA policy',
      description: '',
    },
    RedshiftFgaApprovalConsumerGrant: {
      name: 'Approve Redshift FGA consumer grant',
      description: '',
    },
    RedshiftCreateFgaDataPermission: {
      name: 'Create Redshift FGA data permission',
      description: '',
    },
    RedshiftConsumerGrantFgaDataPermission: {
      name: 'Grant Redshift consumer FGA data permission',
      description: '',
    },
    RedshiftDeactivateFgapDataPermission: {
      name: 'Deactivate Redshift FGAP data permission',
      description: '',
    },
    RedshiftRevokeFgapDataPermissionConsumer: {
      name: 'Revoke Redshift FGAP consumer data permission',
      description: '',
    },
    DataZoneOnboardCatalog: {
      name: 'Onboard to DataZone',
      description: 'Onboard a Redshift catalog to DataZone and create all the required resources.',
    },
    DataZoneAddAssociatedAccount: {
      name: 'Associate account',
      description:
        'Create a RAM share from the DataZone domain to the account, check required roles exist, and put blueprint configurations.',
    },
    DataZoneCreateDomainUnit: {
      name: 'Create domain unit',
      description: "Create a domain unit in the DataZone domain corresponding to the team's cost center.",
    },
    DataZoneCreateProject: {
      name: 'Create project',
      description: 'Create a DataZone project corresponding to the team name and sync the team membership.',
    },
    DataZoneAssignProjectMembership: {
      name: 'Sync project members',
      description: 'Sync the project membership and permissions according to the provided POSIX groups.',
    },
    DataZoneCreateEnvironmentProfile: {
      name: 'Create environment profile',
      description: 'Create a DataZone environment profile corresponding to the Redshift cluster and database.',
    },
    DataZoneCreateEnvironment: {
      name: 'Create environment',
      description: 'Create a DataZone environment corresponding to the Redshift cluster.',
    },
  };

  const statusIndicator = (status: string) => {
    // New | Running | Waiting | Completed | Failed
    let type: StatusIndicatorProps.Type = 'success';
    if (status == 'New') {
      type = 'pending';
    } else if (status == 'Running') {
      type = 'loading';
    } else if (status == 'Waiting') {
      type = 'in-progress';
    } else if (status == 'Completed') {
      type = 'success';
    } else if (status == 'Failed') {
      type = 'error';
    } else {
      return <></>;
    }
    return <StatusIndicator type={type}>{status}</StatusIndicator>;
  };

  const handleRetry = async () => {
    try {
      const parentTaskMetadataObj = JSON.parse(parentTask.taskMetadata);
      const startGladstoneWorkflowResponse: StartGladstoneWorkflowResult = await startGladstoneWorkflow({
        workflowType: 'DataZoneOnboard',
        dataZoneOnboardMetadata: {
          workspaceId: parentTaskMetadataObj.workspaceId,
          groupId: parentTaskMetadataObj.groupId,
          membershipList: parentTaskMetadataObj.dzMembershipMetadata,
          redshiftInfo: parentTaskMetadataObj.redshiftInfo,
        },
      });
      const workflowId = startGladstoneWorkflowResponse.parentTaskId;

      setErrorMessage(undefined);
      setRedirect(createWorkflowStatusLink(workflowId));
      await handleRefresh();
    } catch (e) {
      console.log('Failed to retry!', e);
      setErrorMessage('Failed to retry the workflow: ' + e.message);
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <SpaceBetween size={'l'}>
      {redirect && <Redirect to={redirect} />}
      {errorMessage && (
        <Alert statusIconAriaLabel={'Error'} type={'error'}>
          {errorMessage}
        </Alert>
      )}
      <Header
        variant={'h1'}
        description={
          <SpaceBetween direction={'horizontal'} size={'l'}>
            {statusIndicator(parentTask?.status)}
            {` Workflow ID: ${parentTask?.taskId}`}
          </SpaceBetween>
        }
        actions={
          parentTask?.status == 'Failed' &&
          parentTask?.processType == 'DataZoneOnboard' && (
            <Button iconName={'redo'} onClick={handleRetry}>
              Retry workflow
            </Button>
          )
        }
      >
        Workflow details
      </Header>
      <Table
        loading={loading}
        loadingText={'Loading workflow summary...'}
        items={childTaskList}
        header={
          <Header
            actions={<Button iconName={'refresh'} variant={'icon'} onClick={handleRefresh} />}
            counter={`(${childTaskList.length})`}
          >
            Task status
          </Header>
        }
        columnDefinitions={[
          {
            id: 'task',
            header: 'Task',
            cell: (item: TaskSummary) => readableProcessTypeMapping[item.processType]['name'],
            minWidth: 35,
          },
          {
            id: 'status',
            header: 'Status',
            cell: (item: TaskSummary) => statusIndicator(item.status),
            minWidth: 35,
          },
          {
            id: 'description',
            header: 'Description',
            cell: (item: TaskSummary) => readableProcessTypeMapping[item.processType]['description'],
            minWidth: 150,
          },
          {
            id: 'reason',
            header: 'Status reason',
            cell: (item: TaskSummary) => item.statusReason,
            minWidth: 150,
          },
        ]}
      />
    </SpaceBetween>
  );
};
