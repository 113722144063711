import * as React from 'react';
import { useState, useEffect } from 'react';

import { Alert, SpaceBetween, Header, Button, Tabs } from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { DataZoneProjectsTable } from 'src/components/datazone/projectsTable';
import { DataZoneEnvironmentsTable } from 'src/components/datazone/environmentsTable';
import { DataZoneAccountsTable } from 'src/components/datazone/accountsTable';
import { listDataZoneEntities } from 'src/api/permissions';
import { DZEntityList } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { Link } from '@amzn/awsui-components-react-v3/polaris';
import {
  dataZoneIsAvailable,
  dataZonePortalUrl,
  DataZoneUnavailableHeader,
  DZ_GET_STARTED_WIKI_URL,
} from 'src/components/datazone/constants';

export interface DataZoneManagePageProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
}

export const DataZoneManagePage = (props: DataZoneManagePageProps) => {
  const [errorMessage, setErrorMessage] = useState<string>(undefined);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState<string>(undefined);
  const [accountEntities, setAccountEntities] = useState<DZEntityList>([]);
  const [projectEntities, setProjectEntities] = useState<DZEntityList>([]);
  const [environmentEntities, setEnvironmentEntities] = useState<DZEntityList>([]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoading(true);
    props.setContentType('table');

    try {
      const projectEntities = await listDataZoneEntities({
        entityType: 'Project',
        groupId: props.activeGroup,
      });
      setProjectEntities(projectEntities.entities);
    } catch (e) {
      setErrorMessage('Error while fetching DataZone projects.');
    }

    try {
      const accountEntities = await listDataZoneEntities({
        entityType: 'AssociatedAccount',
        groupId: props.activeGroup,
      });
      setAccountEntities(accountEntities.entities);
    } catch (e) {
      setErrorMessage('Error while fetching DataZone accounts.');
    }

    try {
      const environmentEntities = await listDataZoneEntities({
        entityType: 'Environment',
        groupId: props.activeGroup,
      });
      setEnvironmentEntities(environmentEntities.entities);
    } catch (e) {
      setErrorMessage('Error while fetching DataZone environments.');
    }

    setLoading(false);
  };

  const onboard = () => {
    setRedirect(Page.DATAZONE_ONBOARD);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  if (!dataZoneIsAvailable()) {
    return <DataZoneUnavailableHeader />;
  }
  return (
    <SpaceBetween size={'m'}>
      <Header
        variant={'h1'}
        description={'Manage resources under your workspace in Amazon DataZone.'}
        actions={
          <SpaceBetween size={'m'} direction={'horizontal'}>
            <Button onClick={onboard}>Onboard</Button>
            <Button
              disabled={false}
              iconName={'external'}
              iconAlign={'right'}
              target={'_blank'}
              variant={'primary'}
              href={dataZonePortalUrl()}
            >
              Open DataZone portal
            </Button>
          </SpaceBetween>
        }
      >
        DataZone resources
      </Header>
      {errorMessage && (
        <Alert type={'error'} header={'Error'}>
          {errorMessage}
        </Alert>
      )}
      <Alert type={'info'} header={'Get started with Omni and DataZone'}>
        Click{' '}
        <Link external href={DZ_GET_STARTED_WIKI_URL} variant='secondary' target={'_blank'}>
          here
        </Link>{' '}
        to learn about onboarding with the AWS-wide DataZone instance to share and consume data.
      </Alert>
      <Tabs
        tabs={[
          {
            label: 'Account associations',
            id: 'accounts',
            content: <DataZoneAccountsTable {...props} accountEntities={accountEntities} loading={loading} />,
          },
          {
            label: 'Projects',
            id: 'projects',
            content: <DataZoneProjectsTable {...props} projectEntities={projectEntities} loading={loading} />,
          },
          {
            label: 'Environments',
            id: 'environments',
            content: (
              <DataZoneEnvironmentsTable {...props} environmentEntities={environmentEntities} loading={loading} />
            ),
          },
        ]}
      />
    </SpaceBetween>
  );
};
