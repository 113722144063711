import * as React from 'react';
import {
  Button,
  Form,
  FormField,
  Container,
  Input,
  Textarea,
  SpaceBetween,
  Header,
  Flashbar,
} from '@amzn/awsui-components-react-v3';
import { useState, useEffect } from 'react';

import * as validate from '../../../commons/validationUtils';
import { Redirect } from 'react-router-dom';
import { createTemplate, updateTemplate, getTemplate } from 'src/api/resourcesmanager';
import { Page } from 'src/routes';
import { createBootstrapError } from '../components';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';

export interface TemplateFormProps {
  setContentType: any;
  location: any;
  activeGroup: string;
  isUpdate: boolean;
  match: any;
}

export const TemplateForm = (props: TemplateFormProps) => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [values, setValues] = useState({
    name: undefined,
    description: undefined,
    templateJsonString: undefined,
    type: undefined,
  });
  const [validation, setValidation] = useState({
    name: false,
    type: false,
    templateJsonString: false,
  });

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    var updatedValues = { ...values };

    Object.keys(updatedValues).forEach(
      (key) =>
        props.location &&
        props.location.state &&
        props.location.state.values &&
        key in props.location.state.values &&
        (updatedValues[key] = props.location.state.values[key]),
    );

    if (props.isUpdate) {
      setLoading(true);
      try {
        const ct = await getTemplate({
          id: props.match.params.id,
        });
        Object.keys(updatedValues).forEach((key) => key in ct && (updatedValues[key] = ct[key]));
      } catch (err) {
        setNotifications(
          createBootstrapError({
            ...err,
            errorWhile: 'LOADING TEMPLATE',
          }),
        );
        return;
      }
    }
    setLoading(false);
    setValues(updatedValues);
  };

  /**
   * [UNGUARDED]: Asynchronous call to update the existing Template with the user-defined configuration.
   * If succesful, redirects to Temp. If failure, raises Error Flashbar.
   */
  const updateTemp = async (values) => {
    values.id = props.match.params.id;
    try {
      const output = await updateTemplate(values);
      setLoading(false);
      setRedirect(Page.RG_TEMPLATE_DETAILS.replace(':id', output.id));
    } catch (err) {
      setNotifications(
        createBootstrapError({
          ...err,
          errorWhile: 'UPDATING TEMPLATE',
        }),
      );
    }
  };

  /**
   * [UNGUARDED]: Asynchronous call to create a new Template with the user-defined configuration.
   * If succesful, redirects to Temp. If failure, raises Error Flashbar.
   */
  const createTemp = async (values) => {
    values.groupId = props.activeGroup;
    try {
      const output = await createTemplate(values);
      setLoading(false);
      setRedirect(Page.RG_TEMPLATE_DETAILS.replace(':id', output.id));
    } catch (err) {
      setLoading(false);
      setNotifications(
        createBootstrapError({
          ...err,
          errorWhile: 'CREATING TEMPLATE',
        }),
      );
    }
  };

  const validateForm = (): boolean => {
    const validation = {
      name: values.name !== null && validate.isFalsy(values.name),
      type: values.type !== null && validate.isFalsy(values.type),
      templateJsonString: values.templateJsonString !== null && !validate.isValidJSONString(values.templateJsonString),
    };
    setValidation(validation);
    return Object.values(validation).some((v) => v);
  };

  /**
   * A class that handles either creating a new, or updating the current Template.
   */
  const handleSubmit = async () => {
    setLoading(true);
    if (validateForm()) {
      setLoading(false);
      setNotifications(
        createBootstrapError({
          message: 'Please verify that all required fields are completed and correct. ',
          code: 'Invalid Input',
          errorWhile: 'SUBMITTING FORM',
        }),
      );
      return;
    }

    var updatedValues = { ...values };
    updatedValues['templateJsonString'] = JSON.stringify(JSON.parse(updatedValues['templateJsonString']));

    if (props.isUpdate) {
      delete updatedValues['type'];
      updateTemp(updatedValues);
    } else {
      createTemp(updatedValues);
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <Flashbar items={notifications} />

      <Form
        header={<Header variant='h1'>{props.isUpdate ? 'Update existing ' : 'Create new '} template</Header>}
        actions={
          <SpaceBetween size='s' direction='horizontal'>
            <Button
              variant='link'
              onClick={() =>
                setRedirect(
                  props.isUpdate ? Page.RG_TEMPLATE_DETAILS.replace(':id', props.match.params.id) : Page.RESOURCEGROUPS,
                )
              }
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={handleSubmit} loading={loading}>
              {props.isUpdate ? 'Update' : 'Create'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header variant='h2'>Template details</Header>}>
          <SpaceBetween size='m'>
            {props.isUpdate && (
              <FormField label='Template ID' description='ID cannot be edited.'>
                <Input
                  name='target-ct-id'
                  placeholder='Template ID'
                  value={props.match.params.id}
                  ariaRequired={false}
                  disabled
                />
              </FormField>
            )}
            <FormField label={'Template name'}>
              <Input
                name='target-ct-name'
                placeholder='Name of template'
                value={values.name}
                ariaRequired={true}
                onChange={(e) => {
                  setValues({ ...values, name: e.detail.value });
                }}
                invalid={validation.name}
              />
            </FormField>
            <FormField label={'Template description'}>
              <Textarea
                name='target-ct-description'
                placeholder='A brief description of the template'
                value={values.description}
                ariaRequired={true}
                onChange={(e) => {
                  setValues({
                    ...values,
                    description: e.detail.value,
                  });
                }}
              />
            </FormField>
            <FormField label={'Type'}>
              <Input
                name='target-ct-type'
                value={values.type}
                ariaRequired={true}
                onChange={(e) => {
                  setValues({ ...values, type: e.detail.value });
                }}
                invalid={validation.type}
              />
            </FormField>
            <FormField
              label={'Configuration JSON'}
              description='A JSON defining the configuration of the desired resource.'
            >
              <Textarea
                name='target-ct-template'
                placeholder='e.g. {\n\t key1: value1,\n\t key2: [val2a, val2b, val2c], \n\t key3: { \n\t\t key3a: value3a, \n\t\t key3b: value3b \n\t } \n }'
                value={values.templateJsonString}
                onChange={(e) => {
                  setValues({
                    ...values,
                    templateJsonString: e.detail.value,
                  });
                }}
                invalid={validation.templateJsonString}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
    </div>
  );
};
