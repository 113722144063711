import * as React from 'react';

import { useEffect, useState } from 'react';

import { Container, Flashbar, Header, KeyValuePairs, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { getGlossary } from 'src/api/catalog';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

import { PageHeader } from 'src/components/common/PageHeader';

export interface ViewBusinessGlossaryProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const ViewBusinessGlossary = (props: ViewBusinessGlossaryProps) => {
  const [businessGlossary, setBusinessGlossary] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [glossaryId, setGlossaryId] = useState(undefined);
  const [ownerId, setOwnerId] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    setOwnerId(props.match.params.glossaryOwnerId);
    setGlossaryId(props.match.params.id);
  }, []);

  useEffect(() => {
    if (glossaryId && ownerId) {
      getGlossaryForGivenId();
    }
  }, [glossaryId, ownerId]);

  const getGlossaryForGivenId = async () => {
    try {
      let getGlossaryResult = await getGlossary({
        GlossaryId: glossaryId,
        OwnerId: ownerId,
      });
      setBusinessGlossary(getGlossaryResult.GlossaryDetails);
    } catch (err) {
      setNotification('error', err.message);
    }
  };

  const buttonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: getGlossaryForGivenId,
      },
    ];
  };

  const setNotification = async (header, message) => {
    if (header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <SpaceBetween size={'m'} direction={'vertical'}>
      <Flashbar items={notifications} />
      <PageHeader buttons={buttonOptions()} header={`Glossary ID: ${glossaryId}`} />
      <Container header={<Header variant={'h2'}>Glossary details</Header>}>
        <KeyValuePairs
          columns={3}
          items={[
            {
              label: 'Owner',
              value: businessGlossary?.OwnerId,
            },
            {
              label: 'Name',
              value: businessGlossary?.Name,
            },
            {
              label: 'Description',
              value: businessGlossary?.Description,
            },
            {
              label: 'Status',
              value: businessGlossary?.Status,
            },
            {
              label: 'Type',
              value: businessGlossary?.GlossaryType.replace('Glossary#', ''),
            },
          ]}
        />
      </Container>
    </SpaceBetween>
  );
};

export default ViewBusinessGlossary;
