import { Container, KeyValuePairs, Modal } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

import { useEffect, useState } from 'react';

export interface ViewMetadataFormFieldModalProps {
  metadataFormField: any;
  close: () => void;
  visible: boolean;
}

export const ViewMetadataFieldModal = (props: ViewMetadataFormFieldModalProps) => {
  const [name, setName] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [owner, setOwner] = useState(undefined);
  const [formId, setFormId] = useState(undefined);
  const [fieldId, setFieldId] = useState(undefined);
  const [fieldType, setFieldType] = useState(undefined);
  const [_, setBooleanConfig] = useState(undefined);
  const [stringConfig, setStringConfig] = useState(undefined);
  const [numberConfig, setNumberConfig] = useState(undefined);
  const [bgConfig, setBgConfig] = useState(undefined);

  const formField = props.metadataFormField;
  useEffect(() => {
    if (formField) {
      setName(formField.Name);
      setDescription(formField.Description);
      setStatus(formField.Status);
      setOwner(formField.OwnerId);
      setFormId(formField.MetadataFormId);
      setFieldId(formField.MetadataFieldId);
      setFieldType(formField.FieldType.replace('Field#', ''));
      setBooleanConfig(formField?.FieldConfig?.BooleanConfig);
      setStringConfig(formField?.FieldConfig?.StringConfig);
      setNumberConfig(formField?.FieldConfig?.NumberConfig);
      setBgConfig(formField?.FieldConfig?.BusinessGlossaryConfig);
    }
  }, [formField]);

  const keyValuePairItems = () => {
    let items = [
      {
        label: 'Name',
        value: name,
      },
      {
        label: 'Description',
        value: description,
      },
      {
        label: 'Form ID',
        value: formId,
      },
      {
        label: 'Field ID',
        value: fieldId,
      },
      {
        label: 'Status',
        value: status,
      },
      {
        label: 'Owner',
        value: owner,
      },
      {
        label: 'Field type',
        value: fieldType,
      },
    ];
    if (stringConfig) {
      items.push({
        label: 'Format',
        value: stringConfig.StringConfigFormat,
      });
      items.push({
        label: 'Min length',
        value: stringConfig.MinLength,
      });
      items.push({
        label: 'Max length',
        value: stringConfig.MaxLength,
      });
    }
    if (numberConfig) {
      items.push({
        label: 'Min value',
        value: numberConfig.Min,
      });
      items.push({
        label: 'Max value',
        value: numberConfig.Max,
      });
    }
    if (bgConfig) {
      items.push({
        label: 'Min values',
        value: bgConfig.MinValue,
      });
      items.push({
        label: 'Max values',
        value: bgConfig.MaxValue,
      });
    }
    return items;
  };

  return (
    <Modal
      onDismiss={() => {
        props.close();
      }}
      visible={props.visible}
      size='medium'
      header='Metadata field'
    >
      <Container>
        <KeyValuePairs columns={2} items={keyValuePairItems()} />
      </Container>
    </Modal>
  );
};
