import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { defaultWrapLinesPreference, i18nStrings, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getSchemas, listDatabases } from 'src/api/catalog';
import {
  DATA_LOAD_LIMIT,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { createCatalogDetailLink, createDatabaseDetailLink } from 'src/routes';
import { typeToHumanReadableObject } from 'src/components/permissions/myDatasets/common';
import { DefaultRouteProps, keyForCatalogNameMap } from 'src/commons/common';
import { ownerLinkItem } from 'src/components/workspaces/common/common';
import { Link } from 'react-router-dom';
import { EmptyState } from 'src/commons/EmptyState';
import { PageHeader } from 'src/components/common/PageHeader';

export interface WSBrowseDatabasesProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  catalogMap: any;
}

export const WSBrowseDatabases = (props: WSBrowseDatabasesProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [databaseNextToken, setDatabaseNextToken] = useState(null);

  const [schemaNextToken, setSchemaNextToken] = useState(null);
  const [allDatabasesLoaded, setAllDatabasesLoaded] = useState(false);
  const [allSchemasLoaded, setAllSchemasLoaded] = useState(false);

  const handleRefresh = async () => {
    setLoadingTable(true);
    let databases = await listDatabases({ Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true });
    setDatabaseNextToken(databases.NextToken);
    let schemas = await getSchemas({ Limit: DATA_LOAD_LIMIT, IncludeTotalCount: true });
    setSchemaNextToken(schemas.NextToken);
    setTotalCount(databases.TotalCount + schemas.TotalCount);
    if (databases.NextToken == null) {
      setAllDatabasesLoaded(true);
    }
    if (databases.NextToken == null) {
      setAllSchemasLoaded(true);
    }
    let databaseList = databases.DatabaseInfoList;
    databaseList.push(...schemas.SchemaInfoList);
    for (let database of databaseList) {
      let catalogName = props.catalogMap.get(
        keyForCatalogNameMap(
          database?.DataSourceId,
          database?.CatalogId,
          database?.ClusterIdentifier,
          database?.RedshiftWorkgroupName,
          database?.Region,
        ),
      );
      database['CatalogName'] = catalogName == null ? database?.CatalogId : catalogName;
    }
    setItems(databaseList);
    setLoadingTable(false);
  };

  const loadMoreData = async () => {
    setLoadingTable(true);
    let databaseList = [];
    let schemaList = [];
    if (!allDatabasesLoaded) {
      let databases = await listDatabases({ NextToken: databaseNextToken, Limit: DATA_LOAD_LIMIT });
      setDatabaseNextToken(databases.NextToken);
      databaseList = databases.DatabaseInfoList;
      if (databases.NextToken == null) {
        setAllDatabasesLoaded(true);
      }
    }
    if (!allSchemasLoaded) {
      let schemas = await getSchemas({ NextToken: schemaNextToken, Limit: DATA_LOAD_LIMIT });
      setSchemaNextToken(schemas.NextToken);
      schemaList = schemas.SchemaInfoList;
      if (schemas.NextToken == null) {
        setAllSchemasLoaded(true);
      }
    }

    databaseList.push(...schemaList);
    for (let database of databaseList) {
      let catalogName = props.catalogMap.get(
        keyForCatalogNameMap(
          database?.DataSourceId,
          database?.CatalogId,
          database?.ClusterIdentifier,
          database?.RedshiftWorkgroupName,
          database?.Region,
        ),
      );
      database['CatalogName'] = catalogName == null ? database?.CatalogId : catalogName;
    }
    let currentItems = allItems;
    currentItems.push(...databaseList);
    setItems(currentItems);
    setLoadingTable(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const flatten = (items) => {
    let list = [];
    list.push(
      ...items.map((item) => ({
        catalogId: item?.CatalogId,
        databaseName: item?.DatabaseName,
        catalogName: item?.CatalogName,
        description: item?.Description,
        ownerId: item?.Owners != null && item.Owners.length > 0 ? item?.Owners[0] : undefined,
        region: item?.Region,
        clusterIdentifier: item?.ClusterIdentifier,
        redshiftWorkgroupName: item?.RedshiftWorkgroupName,
        schemaName: item?.Schema,
        dataSourceId:
          item?.DataSourceId ??
          (item?.ClusterIdentifier || item?.RedshiftWorkgroupName
            ? REDSHIFT_DATASOURCE_ID
            : LAKE_FORMATION_DATASOURCE_ID),
      })),
    );

    return list;
  };

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => (
        <Link
          to={createDatabaseDetailLink(
            item?.dataSourceId,
            item?.catalogId,
            item?.clusterIdentifier,
            item?.redshiftWorkgroupName,
            item?.databaseName,
            item?.region,
          )}
        >
          {item.databaseName}
        </Link>
      ),
      sortingField: 'databaseName',
      minWidth: '200px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: '200px',
    },
    {
      id: 'clusterIdentifier',
      header: 'Cluster identifier',
      cell: (item) => (
        <Link
          to={createCatalogDetailLink(
            item?.dataSourceId,
            item?.catalogId,
            item?.clusterIdentifier,
            item?.redshiftWorkgroupName,
            item?.region,
          )}
        >
          {item.clusterIdentifier}
        </Link>
      ),
      sortingField: 'clusterIdentifier',
      minWidth: '200px',
    },
    {
      id: 'catalogName',
      header: 'Catalog name',

      cell: (item) => (
        <Link
          to={createCatalogDetailLink(
            item?.dataSourceId,
            item?.catalogId,
            item?.clusterIdentifier,
            item?.redshiftWorkgroupName,
            item?.region,
          )}
        >
          {item.catalogName}
        </Link>
      ),
      sortingField: 'catalogName',
      minWidth: '200px',
    },
    {
      id: 'ownerId',
      header: 'Owner',
      cell: (item) => ownerLinkItem(item.ownerId, props.workspaceNameMap),
      sortingField: 'ownerId',
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => typeToHumanReadableObject[item.dataSourceId],
      minWidth: '200px',
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    flatten(allItems),
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      filtering: {
        noMatch: <EmptyState title={'No matches'} subtitle={"We can't find a match."} />,
        empty: <EmptyState title={'No databases'} subtitle={'No databases were found in the catalog.'} />,
      },
      selection: {},
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Catalog name',
            key: 'catalogName',
            groupValuesLabel: 'Catalog names',
          },
          {
            propertyLabel: 'Database name',
            key: 'databaseName',
            groupValuesLabel: 'Database names',
          },
          {
            propertyLabel: 'Catalog ID',
            key: 'catalogId',
            groupValuesLabel: 'Catalog IDs',
          },
        ],
      },
    },
  );

  const handlePageChange = async (e) => {
    if (paginationProps.currentPageIndex == paginationProps.pagesCount && (!allDatabasesLoaded || !allSchemasLoaded)) {
      await loadMoreData();
    }
    if (
      paginationProps.currentPageIndex != paginationProps.pagesCount ||
      (e.detail.currentPageIndex <= paginationProps.pagesCount &&
        e.detail.currentPageIndex != paginationProps.currentPageIndex)
    ) {
      paginationProps.onChange(e);
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading databases...'
        loading={loadingTable}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={<PageHeader buttons={[]} header={'Databases'} counter={`(${totalCount})`} />}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
            onChange={(e) => handlePageChange(e)}
            openEnd={!allDatabasesLoaded || !allSchemasLoaded}
            disabled={loadingTable}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={true}
            i18nStrings={{
              ...i18nStrings,
              filteringPlaceholder: 'To discover databases, use search page instead.',
            }}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
