import React, { useEffect, useState } from 'react';
import { StrikeThroughTable } from 'src/components/permissions/myBaselining/strikeThroughTable';
import { getInfoFromDP } from 'src/components/permissions/myBaselining/baseliningUtils';
import {
  Checkbox,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { MARKETPLACE_DATA_ACCESS_STANDARD_URL } from 'src/commons/constants';

export interface BaselineReviewProps {
  setContentType: any;
  dpToKeep: any;
  dpToRelinquish: any;
  catalogMap: any;
  username: string;
  allTermsAccepted: boolean;
  setAllTermsAccepted: any;
}

export const BaselineReview = (props: BaselineReviewProps) => {
  const [acceptedMDAS, setAcceptedMDAS] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);
  const [acceptedTermsErrorText, setAcceptedTermsErrorText] = useState('');

  useEffect(() => {
    if (!acceptedMDAS) {
      setAcceptedTermsErrorText('You must read and accept all terms to complete your access review.');
      props.setAllTermsAccepted(false);
    } else {
      setAcceptedTermsErrorText('');
      props.setAllTermsAccepted(true);
    }
  }, [acceptedMDAS]);

  return (
    <>
      <SpaceBetween size={'m'}>
        {props.dpToKeep?.length > 0 && (
          <StrikeThroughTable
            data={props.dpToKeep}
            defaultSelectedItems={false}
            columnDefinitions={[
              {
                id: 'value',
                header: 'Resource',
                cell: (item) => getInfoFromDP(item, 'table', props.catalogMap),
              },
              {
                id: 'principal',
                header: 'Principal',
                cell: (item) => getInfoFromDP(item, 'principal', props.catalogMap),
                sortingField: 'dataLakePrincipal',
              },
              {
                id: 'variable',
                header: 'Access grant',
                cell: (item) => getInfoFromDP(item, 'grant', props.catalogMap),
                isRowHeader: true,
              },
            ]}
            trackBy={'dataPermissionId'}
            defaultSortingField={'dataLakePrincipal'}
            loading={false}
            title='The following datasets will be kept'
            enableStrikeThrough={false}
            tableDisabled={true}
            pageSize={10}
            selectionType={null}
          />
        )}
        {props.dpToRelinquish?.length > 0 && (
          <StrikeThroughTable
            data={props.dpToRelinquish}
            defaultSelectedItems={false}
            columnDefinitions={[
              {
                id: 'value',
                header: 'Resource',
                cell: (item) => getInfoFromDP(item, 'table', props.catalogMap),
              },
              {
                id: 'principal',
                header: 'Principal',
                cell: (item) => getInfoFromDP(item, 'principal', props.catalogMap),
                sortingField: 'dataLakePrincipal',
              },
              {
                id: 'variable',
                header: 'Access grant',
                cell: (item) => getInfoFromDP(item, 'grant', props.catalogMap),
                isRowHeader: true,
              },
            ]}
            trackBy={'dataPermissionId'}
            defaultSortingField={'dataLakePrincipal'}
            loading={false}
            title='The access from the following datasets will be revoked'
            tableDisabled={true}
            pageSize={10}
            selectionType={null}
          />
        )}
        <Container header={<Header variant={'h2'}>Terms and conditions</Header>}>
          <ColumnLayout>
            <FormField
              label={'AWS Marketplace data access standard'}
              description={
                'You have access to AWS Marketplace data. Have you read the AWS Marketplace data access standard?'
              }
              errorText={acceptedTermsErrorText}
            >
              <Checkbox
                onChange={({ detail }) => setAcceptedMDAS(detail.checked)}
                checked={acceptedMDAS}
                disabled={!linkClicked}
              >
                I have read the&nbsp;
                <Link external={true} href={MARKETPLACE_DATA_ACCESS_STANDARD_URL} onClick={() => setLinkClicked(true)}>
                  AWS Marketplace data access standard
                </Link>{' '}
                and I understand that I will be responsible for compliance with the standard.
              </Checkbox>
            </FormField>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </>
  );
};
