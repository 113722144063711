import * as React from 'react';
import { ColumnLayout, Container, Header, Link } from '@amzn/awsui-components-react-v3';
import { Table } from '@amzn/awsui-components-react-v3/polaris';
import { getAmazonApprovalURL } from 'src/api/config';
import { deDupeApprovalsInfoByApprovalWorkflowId } from 'src/components/workspaces/requests/utils';

export const convertApprovalTemplateIDToLink = (approvalTemplateId: string) => {
  return getAmazonApprovalURL() + 'Template/Details/' + approvalTemplateId;
};

export const convertApprovalWorkflowIDToLink = (approvalWorkflowId: string) => {
  return getAmazonApprovalURL() + 'Approval/Details/' + approvalWorkflowId;
};

export const RequestInformation = ({ request }) => (
  <>
    <Container header={<Header variant={'h2'}>Request details</Header>}>
      <ColumnLayout columns={4} variant='text-grid'>
        <div>
          <div className='awsui-util-label'>Requested by</div>
          <div> {request.requestedBy}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Request group</div>
          <div> {request.requestGroup}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Decided by</div>
          <div> {request.decidedBy}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Data owner</div>
          <div> {request.owner}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Status</div>
          <div> {request.status}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Request date</div>
          <div> {request.timeOfRequest}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Decision date</div>
          <div> {request.timeOfDecision}</div>
        </div>
        <div>{/*         This is a space */}</div>
        {request.principal !== undefined && (
          <div>
            <div className='awsui-util-label'>External account</div>
            <div> {request.principal}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Catalog ID</div>
          <div> {request.catalogId}</div>
        </div>
        {request.clusterId !== undefined && (
          <div>
            <div className='awsui-util-label'>Cluster ID</div>
            <div> {request.clusterId}</div>
          </div>
        )}
        {request.redshiftWorkgroupName !== undefined && (
          <div>
            <div className='awsui-util-label'>Redshift workgroup name</div>
            <div> {request.redshiftWorkgroupName}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Database name</div>
          <div> {request.databaseName}</div>
        </div>
        {request.schemaName !== undefined && (
          <div>
            <div className='awsui-util-label'>Schema name</div>
            <div> {request.schemaName}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Table name</div>
          <div> {request.tableName}</div>
        </div>
        {request.columnNames !== undefined && (
          <div>
            <div className='awsui-util-label'>
              Columns {request.columnWildCard ? `(${request.columnWildCard})` : ''}
            </div>
            <div> {request.columnNames}</div>
          </div>
        )}
        {request.columnWildCard !== undefined && (
          <div>
            <div className='awsui-util-label'>Columns wildcard</div>
            <div> {request.columnWildCard}</div>
          </div>
        )}
        {request.permissions !== undefined && (
          <div>
            <div className='awsui-util-label'>Permissions</div>
            <div> {request.permissions}</div>
          </div>
        )}
        {request.grantablePermissions !== undefined && (
          <div>
            <div className='awsui-util-label'>Grantable permissions</div>
            <div> {request.grantablePermissions}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>SIM ticket links</div>
          <div>
            {request?.simTickets?.length > 0
              ? request?.simTickets?.map((st) => {
                  return (
                    <>
                      <Link href={st?.simTicketLink} external={true}>
                        {st?.simTemplateName}
                      </Link>{' '}
                      <br />
                    </>
                  );
                })
              : ' '}
          </div>
        </div>
      </ColumnLayout>
      <ColumnLayout columns={2} variant='text-grid'>
        <div data-awsui-column-layout-root='true'>
          <div className='awsui-util-label'>Use case</div>
          <div> {request.useCase}</div>
        </div>
        <div>
          <div>
            <div className='awsui-util-label'>Reason for action</div>
            <div> {request.reasonOfAction}</div>
          </div>
        </div>
      </ColumnLayout>
    </Container>
    {request.approvalsWorkflow?.length > 0 && (
      <Table
        columnDefinitions={[
          {
            id: 'approvalTemplateId',
            header: 'Aprpoval template ID',
            cell: (e) => (
              <a href={convertApprovalTemplateIDToLink(e['approvalTemplateId'])} target='_blank'>
                {e['approvalTemplateId']}
              </a>
            ),
          },
          {
            id: 'approvalworkflowId',
            header: 'Approval workflow ID',
            cell: (e) => (
              <a href={convertApprovalWorkflowIDToLink(e['approvalWorkflowId'])} target='_blank'>
                {e['approvalWorkflowId']}
              </a>
            ),
          },
          {
            id: 'approvalWorkflowStatus',
            header: 'Approval workflow status',
            cell: (e) => e['approvalWorkflowStatus'],
          },
        ]}
        items={deDupeApprovalsInfoByApprovalWorkflowId(request.approvalsWorkflow)}
        loadingText='Loading resources'
        header={<Header>Approval workflow details</Header>}
      />
    )}
  </>
);
