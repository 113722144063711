import {
  Box,
  Table,
  Button,
  Header,
  TableProps,
  Pagination,
  TextFilter,
  SpaceBetween,
  Link as Info,
  CollectionPreferences,
  CollectionPreferencesProps,
  ButtonDropdown,
} from '@amzn/awsui-components-react-v3';
import { listDatasets } from '../../../api/ingestion';

import { DataSet } from 'aws-sdk/clients/tethyscontractservicelambda';
import { IMPORT_MANIFEST_PARAM, iIngestionComponentProps } from '../registerContract';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { testStrings } from '../common/testStrings';
import React, { useEffect, useState } from 'react';
import { UX_WRITING } from '../common/uxWriting';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Page } from 'src/routes/Paths';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { docs } from '../common/constants';

const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<DataSet>[] = [
  {
    id: 'DataSetId',
    header: 'Name',
    cell: (item) => (
      <Link
        to={(l) => ({
          l,
          pathname: `${l.pathname.replace('/tables', '/dataset')}/${item.DataSetId}`,
        })}
      >
        {item.TableName}
      </Link>
    ),
    minWidth: '180px',
    sortingField: 'TableName',
  },
  {
    id: 'LatestVersionId',
    header: 'Database',
    cell: (item) => item.DatabaseName,
    minWidth: '100px',
    maxWidth: '200px',
    sortingField: 'DatabaseName',
  },
  {
    id: 'LastUpdatedAt',
    header: 'Create date',
    cell: (item) => item.CreatedAt,
    minWidth: '160px',
    sortingField: 'CreatedAt',
  },
];

const OnboardingDocs = () => (
  <Box textAlign='center' color='inherit'>
    <b>Ingest data with Tethys</b>
    <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
      To get started, create an AWS LakeFormation-Glue database.
    </Box>
    <Button onClick={() => window.open(docs.onboarding)}> Visit Docs </Button>
  </Box>
);

const ErrorMessage = () => (
  <Box textAlign='center' color='inherit'>
    <b>Unexpected error.</b>
    <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
      Please try again later or report the problem.
    </Box>
  </Box>
);

export const PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  { value: 25, label: '25 Datasets' },
  { value: 50, label: '50 Datasets' },
  { value: 100, label: '100 Datasets' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 25,
  wraplines: false,
};

export const ManageDatasets = ({ toggleHelp, activeGroup, setContentType }: iIngestionComponentProps) => {
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences<any>>(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState<string>();
  const [datasets, setDatasets] = useState<DataSet[]>([]);
  const [hasError, setError] = useState<boolean>();

  useEffect(() => {
    setContentType(TABLE_CONTENT_TYPE);
    recursiveFetch({ datasets });
  }, []);

  useEffect(() => {
    if (loading) return;
    if (datasets.length > 0) return;
    toggleHelp();
  }, [loading, datasets]);

  interface iFetchDatasets {
    token?: string;
    datasets: DataSet[];
  }
  const recursiveFetch = async ({ token, datasets }: iFetchDatasets) => {
    try {
      const request = { PrimaryOwner: activeGroup, NextToken: token };
      const { DataSets, NextToken } = await listDatasets(request);

      const fetchedDatasets = [...datasets, ...DataSets];
      setDatasets(fetchedDatasets);

      if (NextToken) {
        const newRequest = { token: NextToken, datasets: fetchedDatasets };
        return await recursiveFetch(newRequest);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const { items, actions, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(
    datasets,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      filtering: {
        noMatch: (
          <Box textAlign='center' color='inherit'>
            <b> No matches </b>
            <Box color='inherit' margin={{ top: 'xxs', bottom: 's' }}>
              No results match your query
            </Box>
            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
          </Box>
        ),
      },
    },
  );

  const filterCounter = (count) => {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loading={loading}
        loadingText='Loading datasets'
        empty={!hasError ? <OnboardingDocs /> : <ErrorMessage />}
        header={
          <Header
            variant='h2'
            counter={`(${datasets.length})`}
            data-testid={testStrings.manageDatasets.tableHeader}
            description={'Datasets ingested with Tethys.'}
            info={
              <Info variant='info' onFollow={toggleHelp}>
                Info
              </Info>
            }
            actions={
              <SpaceBetween direction='horizontal' size='s'>
                <ButtonDropdown
                  variant='primary'
                  items={[
                    {
                      text: 'Import manifest',
                      id: 'import',
                    },
                  ]}
                  onItemClick={(e) => {
                    if (e.detail.id === 'import') {
                      setRedirect(Page.INGESTION.REGISTER_CONTRACT + `?${IMPORT_MANIFEST_PARAM}=true`);
                    }
                  }}
                  mainAction={{
                    text: UX_WRITING.REGISTER_DATASET,
                    onClick: () => setRedirect(Page.INGESTION.REGISTER_CONTRACT),
                  }}
                />
              </SpaceBetween>
            }
          >
            Data lake tables
          </Header>
        }
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SELECTOR_OPTIONS,
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines',
            }}
          />
        }
        wrapLines={preferences.wrapLines}
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={filterCounter(filteredItemsCount)}
            filteringPlaceholder='Search datasets'
          />
        }
      />
    </>
  );
};
