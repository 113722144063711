import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  TextFilter,
  Pagination,
  CollectionPreferences,
  CollectionPreferencesProps,
  Table,
  Header,
  SpaceBetween,
  Button,
  StatusIndicator,
} from '@amzn/awsui-components-react-v3/polaris';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { EmptyState } from 'src/commons/EmptyState';
import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';

export interface DataQualityTableProps {
  dataQualityReports: any;
  loadingDataQuality: boolean;
  calculateDQScore: any;
  setSelectedItem: any;
}

export const DataQualityTable = (props: DataQualityTableProps) => {
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });

  const tableMessage = 'No data quality metrics';
  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => (item.Namespace ? item.Namespace : '-'),
      minWidth: 200,
      width: 250,
    },
    {
      id: 'dqscore',
      header: 'DQ Score',
      cell: (item) => {
        const result = props.calculateDQScore(item);
        if (result.score === 100) {
          return (
            <StatusIndicator>{`DQ score is ${result.score}% (${result.pass} / ${result.total} rules passed)`}</StatusIndicator>
          );
        }
        return (
          <StatusIndicator
            type={'error'}
          >{`DQ score is ${result.score}% (${result.pass} / ${result.total} rules passed)`}</StatusIndicator>
        );
      },
      minWidth: 250,
      width: 400,
      sortingField: 'DQ Score',
    },
    {
      id: 'version',
      header: 'Version',
      cell: (item) => (item.Version ? item.Version : '-'),
      minWidth: 125,
      width: 125,
      sortingField: 'Version',
    },
    {
      id: 'createDate',
      header: 'Create date',
      cell: (item) => (item.CreatedOn ? item.CreatedOn : '-'),
      minWidth: 175,
      sortingField: 'CreatedOn',
    },
  ];

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    props.dataQualityReports,
    {
      filtering: {
        empty: <EmptyState title={tableMessage} subtitle='No data quality versions to display.' />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  useEffect(() => {
    props.setSelectedItem(collectionProps.selectedItems);
  }, [collectionProps.selectedItems]);

  return (
    <Table
      {...collectionProps}
      loadingText='Loading data quality results...'
      loading={props.loadingDataQuality}
      columnDefinitions={columnDefinitions}
      items={items}
      wrapLines={preferences.wrapLines}
      resizableColumns={true}
      header={
        <Header
          counter={props.loadingDataQuality ? '' : ` (${props.dataQualityReports.length})`}
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button iconName='refresh' variant='icon' />
            </SpaceBetween>
          }
          description='Data quality results from recent runs'
        >
          Data quality
        </Header>
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel='Filter data quality by ruleset name, score, version, or create date'
          filteringPlaceholder='Filter data quality by ruleset name, score, version, or create date'
          countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
        />
      }
      empty={<EmptyState title={tableMessage} subtitle='No data quality results to display.' />}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <CollectionPreferences
          title={'Preferences'}
          confirmLabel={'Confirm'}
          cancelLabel={'Cancel'}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          wrapLinesPreference={defaultWrapLinesPreference}
        />
      }
      selectionType='single'
    />
  );
};
