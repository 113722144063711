import { ColumnLayout, Container, Header, SpaceBetween, Table } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { CopiableText } from 'src/commons/common';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { Toggle } from '@amzn/awsui-components-react-v3';
import { ClassificationBadge } from 'src/components/catalog/common';
import { EmptyState } from 'src/commons/EmptyState';
import { PageHeader } from 'src/components/common/PageHeader';

export interface datasetsReviewProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  registerItems: any[];
  selectedDatabaseName: string;
  catalogName: string;
  associateTemplate: boolean;
  selectedTemplate: any;
}

export const DatasetsReview = (props: datasetsReviewProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const columnDefinitions = [
    {
      id: 'dataset',
      header: 'Dataset name',
      cell: (item) => item.label,
      minWidth: 250,
    },
    {
      id: 'database',
      header: 'Database name',
      cell: (item) => item.labelTag,
      minWidth: 250,
    },
    {
      id: 'isPII',
      header: 'Has PII data',
      cell: (item) => <Toggle checked={item?.tags?.includes('PII') || false} disabled={true} />,
      minWidth: 200,
    },
    {
      id: 'classification',
      header: 'Data classification level',
      cell: (item) => <ClassificationBadge classification={item.dataClassification} />,
      minWidth: 200,
    },
  ];

  const handleRefresh = async () => {
    setLoadingResource(true);
    setItems(props.registerItems);
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const { items, collectionProps } = useCollection(allItems, {
    filtering: {
      empty: <EmptyState title={'No datasets to register'} subtitle={'No datasets were found.'} />,
    },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const getRegisterMetadata = () => {
    const details = [];

    {
      props.activeWorkspace?.accountId !== undefined &&
        details.push(<CopiableText name={'Account ID'} key={'Account ID'} value={props.activeWorkspace?.accountId} />);
    }

    {
      props.catalogName !== undefined &&
        details.push(<CopiableText name={'Catalog name'} key={'Catalog name'} value={props.catalogName} />);
    }

    {
      props.selectedDatabaseName !== undefined &&
        details.push(<CopiableText name={'Database name'} key={'Database name'} value={props.selectedDatabaseName} />);
    }

    return details;
  };

  return (
    <>
      <Container header={<Header variant={'h2'}>Register dataset details</Header>}>
        <ColumnLayout columns={3} borders='horizontal'>
          {getRegisterMetadata()}
        </ColumnLayout>
      </Container>

      <SpaceBetween size='l' direction={'vertical'}>
        <Table
          {...collectionProps}
          loadingText='Loading datasets...'
          loading={loadingResource}
          columnDefinitions={columnDefinitions}
          items={items}
          resizableColumns={true}
          header={
            <Header variant={'h2'} counter={`(${allItems.length})`}>
              Datasets to register
            </Header>
          }
          empty={<EmptyState title={'No resource links'} subtitle={'No resource links were found.'} />}
        />

        {props.associateTemplate && (
          <Container header={<Header variant='h2'>Template to associate with the above datasets</Header>}>
            <ColumnLayout columns={4} borders='horizontal'>
              <CopiableText name='Template ID' key='templateID' value={props.selectedTemplate?.templateId} />
              <CopiableText name='Name' key='templateName' value={props.selectedTemplate?.name} />
              <CopiableText name='Description' key='templateDescription' value={props.selectedTemplate?.description} />
              <CopiableText name='Type' key='templateType' value={props.selectedTemplate?.templateType} />
            </ColumnLayout>
          </Container>
        )}
      </SpaceBetween>
    </>
  );
};
