import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { createGroupDetailLink, createWorkspaceDetailLink, Page } from 'src/routes';
import * as React from 'react';
import { StatusIndicator } from '@amzn/awsui-components-react-v3/polaris';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { Link } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Container,
  Header,
  KeyValuePairs,
  Modal,
  SpaceBetween,
  Table,
  Textarea,
} from '@amzn/awsui-components-react-v3';
import {
  convertApprovalTemplateIDToLink,
  convertApprovalWorkflowIDToLink,
} from 'src/components/permissions/myPermissions/common';
import { useState } from 'react';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';

export const tagBasedColumnDefinition = (isIncomingRequests): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'requestId',
    header: 'Request ID',
    cell: (item) =>
      item.requestId ? <Link to={Page.WORKSPACE_MY_REQUESTS + '/' + item.requestId}>{item.requestId}</Link> : '',
    minWidth: '200px',
  },
  {
    id: 'resourceTag',
    header: 'Resource tag',
    cell: (item) => item.resourceTag,
    minWidth: '200px',
    sortingField: 'resourceTag',
  },
  isIncomingRequests
    ? {
        id: 'requestedWorkspace',
        header: 'Requested owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedWorkspace)
                ? createWorkspaceDetailLink(item.requestedWorkspace)
                : createGroupDetailLink(item.requestedWorkspace)
            }
          >
            {item.requestedWorkspace}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedWorkspace',
      }
    : {
        id: 'requestedTo',
        header: 'Requested to owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedTo)
                ? createWorkspaceDetailLink(item.requestedTo)
                : createGroupDetailLink(item.requestedTo)
            }
          >
            {item.requestedTo}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedTo',
      },
  {
    id: 'requestedBy',
    header: 'Requested by',
    cell: (item) => item.requestedBy,
    minWidth: '100px',
    sortingField: 'requestedBy',
  },
  {
    id: 'requestedTime',
    header: 'Requested time',
    cell: (item) => item.requestedTime,
    minWidth: '100px',
    sortingField: 'requestedTime',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => (
      <div>
        {item.status == 'PENDING' && <StatusIndicator type='pending'>Pending</StatusIndicator>}
        {item.status == 'APPROVED' && <StatusIndicator>Approved</StatusIndicator>}
        {item.status == 'DENIED' && <StatusIndicator type='error'>Denied</StatusIndicator>}
        {item.status == 'CANCELED' && <StatusIndicator type='stopped'>Canceled</StatusIndicator>}
        {item.status == 'RUNNING' && <StatusIndicator type='pending'>Running</StatusIndicator>}
        {item.status == 'FAILED' && <StatusIndicator type='error'>Failed</StatusIndicator>}
      </div>
    ),
    minWidth: '125px',
    sortingField: 'status',
  },
  {
    id: 'statusReason',
    header: 'Status reason',
    cell: (item) => item.statusReason,
    minWidth: '125px',
    sortingField: 'statusReason',
  },
];

export const resourceBasedColumnDefinition = (isIncomingRequests): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'requestId',
    header: 'Request ID',
    cell: (item) =>
      item.requestId ? <Link to={Page.WORKSPACE_MY_REQUESTS + '/' + item.requestId}>{item.requestId}</Link> : '',
    minWidth: '200px',
  },
  {
    id: 'permissionType',
    header: 'Permission Type',
    cell: (item) => item.permissionType,
    minWidth: '150px',
    sortingField: 'permissionType',
  },
  {
    id: 'catalogId',
    header: 'Catalog',
    cell: (item) => item.catalogId,
    minWidth: '200px',
    sortingField: 'catalogId',
  },
  {
    id: 'databaseName',
    header: 'Database name',
    cell: (item) => item.databaseName,
    minWidth: '200px',
    sortingField: 'databaseName',
  },
  {
    id: 'tableName',
    header: 'Table name',
    cell: (item) => (item.tableName != undefined ? item.tableName : 'All Tables'),
    minWidth: '200px',
    sortingField: 'tableName',
  },
  {
    id: 'fgapName',
    header: 'Fine grain policy',
    cell: (item) =>
      item.fgapId ? (
        <Link to={Page.VIEW_FINE_GRAIN_ACCESS_POLICY.replace(':id', item.fgapId)}>{item.fgapName}</Link>
      ) : (
        ''
      ),
    minWidth: '200px',
    sortingField: 'fgapName',
  },
  isIncomingRequests
    ? {
        id: 'requestedWorkspace',
        header: 'Requested owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedWorkspace)
                ? createWorkspaceDetailLink(item.requestedWorkspace)
                : createGroupDetailLink(item.requestedWorkspace)
            }
          >
            {item.requestedWorkspace}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedWorkspace',
      }
    : {
        id: 'requestedTo',
        header: 'Requested to owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedTo)
                ? createWorkspaceDetailLink(item.requestedTo)
                : createGroupDetailLink(item.requestedTo)
            }
          >
            {item.requestedTo}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedTo',
      },
  {
    id: 'requestedBy',
    header: 'Requested by',
    cell: (item) => item.requestedBy,
    minWidth: '100px',
    sortingField: 'requestedBy',
  },
  {
    id: 'requestedTime',
    header: 'Requested time',
    cell: (item) => item.requestedTime,
    minWidth: '100px',
    sortingField: 'requestedTime',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => (
      <div>
        {item.status == 'PENDING' && <StatusIndicator type='pending'>Pending</StatusIndicator>}
        {item.status == 'RUNNING' && <StatusIndicator type='pending'>Running</StatusIndicator>}
        {item.status == 'APPROVED' && <StatusIndicator>Approved</StatusIndicator>}
        {item.status == 'DENIED' && <StatusIndicator type='error'>Denied</StatusIndicator>}
        {item.status == 'FAILED' && <StatusIndicator type='error'>Failed</StatusIndicator>}
        {item.status == 'CANCELED' && <StatusIndicator type='stopped'>Canceled</StatusIndicator>}
      </div>
    ),
    minWidth: '125px',
    sortingField: 'status',
  },
  {
    id: 'statusReason',
    header: 'Status reason',
    cell: (item) => item.statusReason,
    minWidth: '100px',
    sortingField: 'statusReason',
  },
];

export const ResourceBasedRequestInformation = ({ request }) => (
  <SpaceBetween size={'m'} direction={'vertical'}>
    <Container header={<Header variant={'h2'}>Request details</Header>}>
      <KeyValuePairs
        columns={4}
        items={[
          { label: 'Requested by', value: request.requestedBy },
          request.requestWorkspace
            ? {
                label: 'Requested workspace',
                value: (
                  <Link
                    to={
                      isValidWorkspace(request.requestWorkspace)
                        ? createWorkspaceDetailLink(request.requestWorkspace)
                        : createGroupDetailLink(request.requestWorkspace)
                    }
                  >
                    {request.requestWorkspace}
                  </Link>
                ),
              }
            : undefined,
          request.decidedBy ? { label: 'Decided by', value: request.decidedBy } : undefined,
          request.owner
            ? {
                label: 'Data owner',
                value: (
                  <Link
                    to={
                      isValidWorkspace(request.owner)
                        ? createWorkspaceDetailLink(request.owner)
                        : createGroupDetailLink(request.owner)
                    }
                  >
                    {request.owner}
                  </Link>
                ),
              }
            : undefined,
          { label: 'Status', value: StatusIcon({ status: request.status }) },
          request.statusReason ? { label: 'Status reason', value: request.statusReason } : undefined,
          { label: 'Request date', value: request.timeOfRequest },
          request.timeOfDecision ? { label: 'Decision date', value: request.timeOfDecision } : undefined,
          { label: 'External account', value: request.principal },
          { label: 'Catalog ID', value: request.catalogId },
          request.clusterId ? { label: 'Cluster ID', value: request.clusterId } : undefined,
          { label: 'Database name', value: request.databaseName },
          request.schemaName ? { label: 'Schema name', value: request.schemaName } : undefined,
          { label: 'Table name', value: request.tableName },
          request.fgapName ? { label: 'Fine grain policy', value: request.fgapName } : undefined,
          request.columnNames
            ? {
                label: `Columns${request.columnWildCard ? ` (${request.columnWildCard})` : ``}`,
                value: request.columnNames,
              }
            : undefined,
          request.columnWildCard ? { label: 'Column wildcard', value: request.columnWildCard } : undefined,
          { label: 'Permissions', value: request.permissions },
          { label: 'Grantable permissions', value: request.grantablePermissions },
          { label: 'Use case', value: request.useCase ? request.useCase : ' ' },
          request.reasonOfAction ? { label: 'Reason for action', value: request.reasonOfAction } : undefined,
          request.simTickets?.length > 0
            ? {
                label: 'SIM ticket links',
                value: (
                  <div>
                    {request.simTickets.map((st) => {
                      return (
                        <>
                          <Link to={st?.simTicketLink}>{st?.simTemplateName}</Link> <br />
                        </>
                      );
                    })}
                  </div>
                ),
              }
            : undefined,
        ].filter((item) => item != undefined)}
      />
    </Container>
    {request.approvalsWorkflow?.length > 0 && requestApprovalsWorkflowInfoTable(request)}
  </SpaceBetween>
);

export const requestApprovalsWorkflowInfoTable = (request) => {
  return (
    <Table
      columnDefinitions={[
        {
          id: 'approvalTemplateId',
          header: 'Approval template ID',
          cell: (e) => (
            <a href={convertApprovalTemplateIDToLink(e['approvalTemplateId'])} target='_blank'>
              {e['approvalTemplateId']}
            </a>
          ),
        },
        {
          id: 'approvalWorkflowId',
          header: 'Approval workflow ID',
          cell: (e) => (
            <a href={convertApprovalWorkflowIDToLink(e['approvalWorkflowId'])} target='_blank'>
              {e['approvalWorkflowId']}
            </a>
          ),
        },
        {
          id: 'approvalWorkflowStatus',
          header: 'Approval workflow status',
          cell: (e) => e['approvalWorkflowStatus'],
        },
      ]}
      items={deDupeApprovalsInfoByApprovalWorkflowId(request.approvalsWorkflow)}
      loadingText='Loading resources'
      header={<Header>Approval workflow details</Header>}
    />
  );
};

export const deDupeApprovalsInfoByApprovalWorkflowId = (approvalsWorkflow) => {
  let uniqueApprovalWorkflowIds = new Set();
  let uniqueApprovalInfos = [];
  approvalsWorkflow.forEach((approvalsInfo) => {
    if (!uniqueApprovalWorkflowIds.has(approvalsInfo.approvalWorkflowId)) {
      uniqueApprovalWorkflowIds.add(approvalsInfo.approvalWorkflowId);
      uniqueApprovalInfos.push(approvalsInfo);
    }
  });
  return uniqueApprovalInfos;
};

export const TagBasedRequestInformation = ({ request }) => (
  <SpaceBetween size={'m'} direction={'vertical'}>
    <Container header={<Header variant={'h2'}>Request details</Header>}>
      <KeyValuePairs
        columns={4}
        items={[
          { label: 'Requested by', value: request.requestedBy },
          request.requestWorkspace
            ? {
                label: 'Requested workspace',
                value: (
                  <Link
                    to={
                      isValidWorkspace(request.requestWorkspace)
                        ? createWorkspaceDetailLink(request.requestWorkspace)
                        : createGroupDetailLink(request.requestWorkspace)
                    }
                  >
                    {request.requestWorkspace}
                  </Link>
                ),
              }
            : undefined,
          request.decidedBy ? { label: 'Decided by', value: request.decidedBy } : undefined,
          request.owner
            ? {
                label: 'Data owner',
                value: (
                  <Link
                    to={
                      isValidWorkspace(request.owner)
                        ? createWorkspaceDetailLink(request.owner)
                        : createGroupDetailLink(request.owner)
                    }
                  >
                    {request.owner}
                  </Link>
                ),
              }
            : undefined,
          { label: 'Status', value: StatusIcon({ status: request.status }) },
          request.statusReason ? { label: 'Status reason', value: request.statusReason } : undefined,
          { label: 'Request date', value: request.timeOfRequest },
          request.timeOfDecision ? { label: 'Decision date', value: request.timeOfDecision } : undefined,
          { label: 'External account', value: request.principal },
          { label: 'Resource tag', value: request.resourceTag },
          { label: 'Permissions', value: request.permissions },
          { label: 'Grantable permissions', value: request.grantablePermissions },
          request.useCase ? { label: 'Use case', value: request.useCase } : undefined,
          request.reasonOfAction ? { label: 'Reason for action', value: request.reasonOfAction } : undefined,
          request.simTickets?.length > 0
            ? {
                label: 'SIM ticket links',
                value: (
                  <div>
                    {request.simTickets.map((st) => {
                      return (
                        <>
                          <Link to={st?.simTicketLink}>{st?.simTemplateName}</Link> <br />
                        </>
                      );
                    })}
                  </div>
                ),
              }
            : undefined,
        ].filter((item) => item != undefined)}
      />
    </Container>
    {request.approvalsWorkflow?.length > 0 && requestApprovalsWorkflowInfoTable(request)}
  </SpaceBetween>
);

interface DenyOrCancelModalProps {
  modalVisible: boolean;
  action: string;
  closeModal: any;
  buttonLoading: boolean;
  submitAction: any;
  approvalWorkflowStatusOutOfSync: boolean;
  textAreaPlaceholder: string;
  reasonOfAction: string;
  setReasonOfAction: any;
}

export const DenyOrCancelModal = (props: DenyOrCancelModalProps) => {
  const [isInvalid, setIsInvalid] = useState(true);

  return (
    <Modal
      visible={props.modalVisible}
      header={props.action === 'cancel' ? 'Enter reason for canceling' : 'Enter reason for denying'}
      onDismiss={() => props.closeModal()}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => props.closeModal()}>
              No
            </Button>
            <Button variant='primary' loading={props.buttonLoading} onClick={() => props.submitAction(props.action)}>
              Yes
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {props.approvalWorkflowStatusOutOfSync && props.action == 'deny' && (
        <div>
          {' '}
          <Alert statusIconAriaLabel='Warning' type='warning'>
            The approval workflow associated with this request has been approved or pending. Make sure you want to deny
            this request.
          </Alert>
          <br />
        </div>
      )}
      <Textarea
        value={props.reasonOfAction}
        onChange={({ detail }) => {
          props.setReasonOfAction(detail.value);
          setIsInvalid(!props.reasonOfAction || props.reasonOfAction.length == 0);
        }}
        placeholder={props.textAreaPlaceholder}
        invalid={isInvalid}
      />
      Are you sure you want to {props.action} this request?
    </Modal>
  );
};
