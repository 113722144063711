import * as React from 'react';
import { useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';

import { Alert, Button, Flashbar, StatusIndicator, TextContent } from '@amzn/awsui-components-react-v3';
import { getWorkspace, validatePrerequisites } from 'src/api/permissions';
import {
  DataZoneMembershipDesignationInfoList,
  ValidationDetailList,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { isProd } from 'src/api/config';

export interface ValidatePrerequisitesContentProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
  workspaceId: string;
  awsAccountId: string;
  region: string;
  clusterId: string;
  databaseName: string;
  secretArn: string;
  workgroupName: string;
  setPrerequisitesPassed: any;
  membershipList: DataZoneMembershipDesignationInfoList;
  errorMessage: string;
}

export const ValidatePrerequisitesContent = (props: ValidatePrerequisitesContentProps) => {
  const [redirect, _] = useState<string>(undefined);
  const [result, setResult] = useState<ValidationDetailList>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState([]);

  const resourceTypeMapping = {
    WorkspaceRolePermission: 'Workspace role validation',
    RedshiftClusterValidation: 'Redshift cluster validation',
    RedshiftServerlessValidation: 'Redshift serverless validation',
    MembershipDesignationValidation: 'Project membership validation',
  };

  const statusToIndicator = (isValid: boolean) => {
    return <StatusIndicator type={isValid ? 'success' : 'error'}>{isValid ? 'Success' : 'Error'}</StatusIndicator>;
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {};

  const startPrerequisitesCheck = async () => {
    setLoading(true);
    try {
      const workspaceRegion = (await getWorkspace({ workspaceId: props.workspaceId })).workspace?.region;
      const validatePrerequisitesResult = await validatePrerequisites({
        dataZonePrerequisites: {
          workspaceRoleInfo: {
            awsAccountId: props.awsAccountId,
            region: workspaceRegion,
            // allow only prod accounts in prod Omni-DZ and non-prod in non-prod Omni-DZ. This can change in the future
            // when DataZone releases Project Scopes feature.
            awsAccountClassificationExpected: isProd() ? ['Production'] : ['NonProduction'],
          },
          membershipList: props.membershipList,
          redshiftInfo: {
            awsAccountId: props.awsAccountId,
            region: props.region,
            clusterName: props.clusterId,
            databaseName: props.databaseName,
            secretArn: props.secretArn,
            workgroupName: props.workgroupName,
          },
        },
        ownerId: props.workspaceId,
      });
      setResult(validatePrerequisitesResult.dataZonePrerequisitesResult.dataZonePrerequisitesValidations);
      // if all validations passed, we can proceed
      if (
        !validatePrerequisitesResult.dataZonePrerequisitesResult.dataZonePrerequisitesValidations
          .map((validation) => validation.isValid)
          .includes(false)
      ) {
        props.setPrerequisitesPassed(true);
      }
      setLoading(false);
    } catch (err) {
      let errorMsg = 'Failed to validate prerequisites. ' + err.message;
      console.error(errorMsg);
      setLoading(false);
      props.setPrerequisitesPassed(false);
      setNotifications([
        {
          type: 'error',
          content: errorMsg,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  if (redirect) {
    if (redirect) return <Redirect push to={redirect} />;
  }

  return (
    <>
      {props.errorMessage && <Alert type={'error'}>{props.errorMessage}</Alert>}
      <Flashbar items={notifications}></Flashbar>
      <br />
      <Button onClick={startPrerequisitesCheck}>Start prerequisites check</Button>
      {loading && (
        <>
          <br />
          <StatusIndicator type='loading'>Loading results</StatusIndicator>
        </>
      )}
      {result &&
        result.map((resultItem) => (
          <TextContent>
            <p>
              <h3>{resourceTypeMapping[resultItem.validatedResource]}</h3>
              {statusToIndicator(resultItem.isValid)}
              {resultItem.isValid == false && (
                <>
                  <br />
                  {resultItem.cause}
                </>
              )}
            </p>
            <br />
          </TextContent>
        ))}
    </>
  );
};
