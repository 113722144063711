import {
  Header,
  Container,
  FormField,
  CodeEditor,
  SpaceBetween,
  ButtonDropdown,
  Button,
  ButtonDropdownProps,
} from '@amzn/awsui-components-react-v3';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { iStep } from '../utils/types';

import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import { isSourceS3, validateStepThree } from '../utils/validation';
import { TethysLocalStorage } from '../../common/keys';
import { testStrings } from '../../common/testStrings';
import { RegisterDataSetRequest, TargetColumn, TargetSchema } from 'aws-sdk/clients/tethyscontractservicelambda';
import { initialRegisterDataSetRequest } from '../utils/requestPayload';
import { SourceSchemaType } from '../../common/constants';

const i18nStrings = {
  loadingState: 'Loading code editor',
  errorState: 'There was an error loading the code editor.',
  errorStateRecovery: 'Retry',
  editorGroupAriaLabel: 'Code editor',
  statusBarGroupAriaLabel: 'Status bar',
  cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
  errorsTab: 'Errors',
  warningsTab: 'Warnings',
  preferencesButtonAriaLabel: 'Preferences',
  paneCloseButtonAriaLabel: 'Close',
  preferencesModalHeader: 'Preferences',
  preferencesModalCancel: 'Cancel',
  preferencesModalConfirm: 'Confirm',
  preferencesModalWrapLines: 'Wrap lines',
  preferencesModalTheme: 'Theme',
  preferencesModalLightThemes: 'Light themes',
  preferencesModalDarkThemes: 'Dark themes',
};

const sharedDropdownTypes = [
  { id: 'varchar', text: 'String' },
  { id: 'bigint', text: 'BigInt' },
  { id: 'decimal', text: 'Decimal' },
  { id: 'boolean', text: 'Boolean' },
];

interface SchemaUtils<F, V, T extends SourceSchemaType> {
  sourceType: T;
  dropdownOptions: ButtonDropdownProps.ItemOrGroup[];
  addField(field: F): void;
  updateSchema(schema: V): void;
  resetSchema(): void;
  getSchema(): string;
}

type DDBFieldType = 'varchar' | 'bigint' | 'boolean' | 'decimal' | 'double';

function ddbSchemaUtils(
  request: RegisterDataSetRequest,
  setRequest: (r: RegisterDataSetRequest) => void,
): SchemaUtils<DDBFieldType, TargetSchema, 'DynamoDB'> {
  const ddbFieldPresets: Record<DDBFieldType, TargetColumn> = {
    varchar: {
      Name: '',
      Type: 'string',
      DynamoDBSourceType: 'S',
      SourceName: '',
    },
    bigint: { Name: '', Type: 'long', DynamoDBSourceType: 'N', SourceName: '' },
    boolean: {
      Name: '',
      Type: 'boolean',
      DynamoDBSourceType: 'BOOL',
      SourceName: '',
    },
    decimal: {
      Name: '',
      Type: 'decimal',
      Scale: 6,
      Precision: 38,
      DynamoDBSourceType: 'N',
      SourceName: '',
    },
    double: {
      Name: '',
      Type: 'double',
      DynamoDBSourceType: 'N',
      SourceName: '',
    },
  } as const;

  const updateSchema = (schema: TargetSchema) => {
    setRequest({
      ...request,
      DataContract: {
        ...request.DataContract,
        DataProperties: {
          ...request.DataContract.DataProperties,
          TargetSchema: schema,
        },
      },
    });
  };

  return {
    sourceType: 'DynamoDB',
    addField(field) {
      const schemaDefinition = request.DataContract.DataProperties.TargetSchema ?? [];
      const newSchema: TargetSchema = [...schemaDefinition, ddbFieldPresets[field]];

      updateSchema(newSchema);
    },
    updateSchema,
    getSchema() {
      return JSON.stringify(request.DataContract.DataProperties.TargetSchema || '', null, '\t');
    },
    resetSchema() {
      const { TargetSchema, ...DataProperties } = request.DataContract.DataProperties;
      setRequest({
        ...request,
        DataContract: {
          ...request.DataContract,
          DataProperties: {
            ...DataProperties,
            TargetSchema: TargetSchema || initialRegisterDataSetRequest.DataContract.DataProperties.TargetSchema,
          },
        },
      });
    },
    dropdownOptions: [...sharedDropdownTypes, { id: 'double', text: 'Double' }],
  };
}

type DelimitedFieldType =
  | 'varchar'
  | 'bigint'
  | 'decimal'
  | 'boolean'
  | 'date'
  | 'timestamp-millis'
  | 'timestamp-micros';
type AVROColumn = { name: string; type: string; scale?: number; precision?: number; logicalType?: string };

function delimitedSchemaUtils(
  request: RegisterDataSetRequest,
  setRequest: (r: RegisterDataSetRequest) => void,
): SchemaUtils<DelimitedFieldType, string, 'S3'> {
  const delimitedFieldPresets: Record<DelimitedFieldType, AVROColumn> = {
    varchar: { name: '', type: 'string' },
    bigint: { name: '', type: 'long' },
    boolean: { name: '', type: 'boolean' },
    decimal: {
      name: '',
      scale: 6,
      precision: 38,
      type: 'bytes',
      logicalType: 'decimal',
    },
    'timestamp-micros': {
      name: '',
      type: 'long',
      logicalType: 'timestamp-micros',
    },
    'timestamp-millis': {
      name: '',
      type: 'long',
      logicalType: 'timestamp-millis',
    },
    date: {
      name: '',
      type: 'long',
      logicalType: 'date',
    },
  } as const;

  const updateSchema = (schema: string) => {
    setRequest({
      ...request,
      DataContract: {
        ...request.DataContract,
        DataProperties: {
          ...request.DataContract.DataProperties,
          SchemaDefinition: schema,
        },
      },
    });
  };

  return {
    sourceType: 'S3',
    addField(field) {
      try {
        const schemaDefinition = JSON.parse(request.DataContract.DataProperties.SchemaDefinition);
        const newSchema = JSON.stringify(
          {
            ...schemaDefinition,
            fields: [...schemaDefinition.fields, delimitedFieldPresets[field]],
          },
          null,
          '\t',
        );

        updateSchema(newSchema);
      } catch (e) {
        console.log(e, request);
      }
    },
    updateSchema,
    getSchema() {
      return request.DataContract.DataProperties.SchemaDefinition ?? '';
    },
    resetSchema() {
      const { SchemaDefinition, TargetSchema, ...DataProperties } = request.DataContract.DataProperties;
      setRequest({
        ...request,
        DataContract: {
          ...request.DataContract,
          DataProperties: {
            ...DataProperties,
            SchemaDefinition:
              SchemaDefinition || initialRegisterDataSetRequest.DataContract.DataProperties.SchemaDefinition,
          },
        },
      });
    },
    dropdownOptions: [
      ...sharedDropdownTypes,
      {
        text: 'Timestamps',
        items: [
          { id: 'date', text: 'Date' },
          {
            id: 'timestamp-millis',
            text: 'Timestamp (ms)',
          },
          {
            id: 'timestamp-micros',
            text: 'Timestamp (μs)',
          },
        ],
      },
    ],
  };
}

interface iSchemaStep extends iStep {
  setJsonValid(isValid: boolean): void;
}

export const SchemaStep = ({ request, isCreate, isUpdate, isValidated, setJsonValid, setRequest }: iSchemaStep) => {
  const [preferences, setPreferences] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [ace, setAce] = useState<any>();

  useEffect(() => {
    import('ace-builds')
      .then((ace) =>
        import('ace-builds/webpack-resolver').then(() => {
          ace.config.set('useStrictCSP', true);
          ace.config.set('loadWorkerFromBlob', false);
          setAce(ace);
        }),
      )
      .finally(() => setLoading(false));
  }, []);

  const { addField, updateSchema, resetSchema, getSchema, dropdownOptions, sourceType } = isSourceS3(request)
    ? delimitedSchemaUtils(request, setRequest)
    : ddbSchemaUtils(request, setRequest);

  useEffect(() => {
    if (!isUpdate) {
      resetSchema();
    }
  }, [request.DataSource.SourceType, isUpdate]);

  return (
    <SpaceBetween size='xl'>
      <Container
        header={
          <Header
            variant='h2'
            actions={
              <SpaceBetween direction='horizontal' size='s'>
                <ButtonDropdown
                  onItemClick={({ detail }) =>
                    addField(detail.id as any /* dropdownOptions must contain ids of the field type */)
                  }
                  items={dropdownOptions}
                >
                  Add field
                </ButtonDropdown>
                {isCreate && isSourceS3(request) && (
                  <Button
                    onClick={() =>
                      localStorage.setItem(TethysLocalStorage, request.DataContract.DataProperties.SchemaDefinition)
                    }
                  >
                    Save draft
                  </Button>
                )}
              </SpaceBetween>
            }
          >
            {isSourceS3(request) ? 'AWS Glue Schema Registry' : 'Tethys schema'}
          </Header>
        }
      >
        <FormField errorText={isValidated ? validateStepThree(request).SchemaDefinition : ''}>
          <CodeEditor
            ace={ace}
            loading={loading}
            language='json'
            onValidate={(v) => setJsonValid(v.detail.annotations.length === 0)}
            data-testid={testStrings.contractRegistration.step3.schema}
            preferences={preferences}
            i18nStrings={i18nStrings}
            themes={{
              dark: ['tomorrow_night_bright'],
              light: ['dawn'],
            }}
            value={getSchema()}
            onChange={({ detail }) => {
              try {
                if (sourceType === 'S3') {
                  updateSchema(detail.value);
                  JSON.parse(detail.value);
                } else {
                  updateSchema(JSON.parse(detail.value) as TargetSchema);
                }
              } catch (_) {}
            }}
            onPreferencesChange={(e) => setPreferences(e.detail)}
          />
        </FormField>
      </Container>
    </SpaceBetween>
  );
};
