import * as React from 'react';
import { useEffect, useState } from 'react';

import { getWorkspace } from 'src/api/permissions';
import { Redirect } from 'react-router-dom';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { ResourceNotFound } from 'src/commons/ResourceNotFound';
import { WorkspaceDetailContainer } from 'src/components/workspaces/common/WorkspaceDetailContainer';

import { PageHeader } from 'src/components/common/PageHeader';

export interface WorkspaceDetailPageProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
}

export const WorkspaceDetailPage = (props: WorkspaceDetailPageProps) => {
  const [workspace, setWorkspace] = useState(undefined);
  const [refreshButtonLoading, setRefreshButtonLoading] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleRefresh = async () => {
    setLoading(true);
    setRefreshButtonLoading(true);
    try {
      let getWorkspaceResponse = await getWorkspace({
        workspaceId: props.match.params.workspaceId,
      });

      setWorkspace(getWorkspaceResponse.workspace);

      setRefreshButtonLoading(false);
    } catch (err) {
      console.log('Exception when get workspace for group', err);
      setRefreshButtonLoading(false);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  if (workspace != null && workspace.workspaceId == props.activeWorkspace?.workspaceId) {
    return <Redirect push to={`/current-workspace/${props.activeWorkspace.workspaceId}`} />;
  }

  if (!workspace && !loading) {
    return (
      <ResourceNotFound
        title={'Workspace not found'}
        subtitle={
          `The given workspace is not valid, or you do not have permission to view it. Please\n` +
          ' check the URL for mistakes and try again.'
        }
      />
    );
  }

  return (
    <>
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
            loading: refreshButtonLoading,
          },
        ]}
        header={workspace?.workspaceName}
      />

      {workspace && <WorkspaceDetailContainer workspace={workspace} />}
    </>
  );
};
