import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { paginationLabels } from 'src/commons/tables';
import { getGlossary, listMetadataFields, listMetadataValues } from 'src/api/catalog';
import MetadataFieldValueModal from 'src/components/workspaces/common/metadataValueModal';
import { Link } from '@amzn/awsui-components-react-v3/polaris';
import { METADATA_ENABLED } from 'src/commons/constants';
import { isValidUrl } from 'src/commons/validationUtils';
import { EmptyState } from 'src/commons/EmptyState';

export interface MetadataFormFieldValueDetailsTableProps {
  formId: any;
  formOwnerId: string;
  activeGroup: any;
  activeWorkspace: any;
  setNotification: (header: any, message: string) => void;
  resource: any;
  resourceOwnerIds: any;
}

export const MetadataFormFieldValueDetailsTable = (props: MetadataFormFieldValueDetailsTableProps) => {
  const [editFieldValueModalVisible, setEditFieldValueModalVisible] = useState(false);
  const [createFieldValueModalVisible, setCreateFieldValueModalVisible] = useState(false);
  const [, setButtonLoading] = useState(false);
  const [loadingFormFields, setLoadingFormFields] = useState(false);
  const [, setLoadingFieldValues] = useState(false);
  const [metadataFormFields, setMetadataFormFields] = useState([]);
  const [metadataFieldValues, setMetadataFieldValues] = useState([]);
  const [combinedFieldValues, setCombinedFieldValues] = useState([]);
  const [selectedField, setSelectedField] = useState(undefined);
  const formId = props.formId;
  const resource = props.resource;
  const ownerIds = props.resourceOwnerIds;

  const [, setFieldValueTableMessage] = useState('No field values available');
  const [fieldsTableMessage, setFieldsTableMessage] = useState('No fields available');

  const { items, collectionProps, paginationProps } = useCollection(combinedFieldValues, {
    filtering: {
      noMatch: '',
      empty: <EmptyState title={fieldsTableMessage} subtitle={'No metadata forms found.'} />,
    },
    pagination: { pageSize: 25 },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  useEffect(() => {
    if (formId) {
      handleRefresh();
    }
  }, [formId]);

  useEffect(() => {
    generateCombinedFieldValues();
  }, [metadataFormFields, metadataFieldValues]);

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedFieldItem = selectedItems[0];
    if (selectedFieldItem) {
      setSelectedField(selectedFieldItem);
    }
  }, [collectionProps.selectedItems]);

  const handleRefresh = async () => {
    setButtonLoading(true);
    setSelectedField(undefined);
    setLoadingFormFields(true);
    setLoadingFieldValues(true);
    const formFields = await getMetaDataFormFields();
    const valueList = await getMetaDataValuesForField();
    setMetadataFormFields(formFields);
    setMetadataFieldValues(valueList);
    setLoadingFormFields(false);
    setLoadingFieldValues(false);
  };

  const generateCombinedFieldValues = async () => {
    setCombinedFieldValues([]);
    let newCombinedFieldValues = [];
    for (const field of metadataFormFields) {
      const fieldName = field.Name;
      const fieldType = field.FieldType;
      const fieldId = field.MetadataFieldId;
      const fieldConfig = field.FieldConfig;
      let value = undefined;
      let fieldValue = undefined;
      for (const fieldValueDetail of metadataFieldValues) {
        if (fieldValueDetail.Id == fieldId) {
          fieldValue = fieldValueDetail;
          value = await convertValueToString(fieldValueDetail.Value);
        }
      }
      const tableItem = {
        Name: fieldName,
        FieldType: fieldType,
        Value: value,
        MetadataFieldId: fieldId,
        GlossaryValues: fieldValue?.Value?.GlossaryValueList,
        FieldConfig: fieldConfig,
      };
      newCombinedFieldValues.push(tableItem);
    }
    setCombinedFieldValues(newCombinedFieldValues);
  };

  const convertValueToString = async (value) => {
    if (!value) {
      return '';
    }
    if (value.StringValue) {
      return value.StringValue;
    } else if (value.BooleanValue != null) {
      return value.BooleanValue ? 'true' : 'false';
    } else if (value.NumberValue) {
      return String(value.NumberValue);
    } else if (value.GlossaryValueList) {
      const glossaryValueIds = value.GlossaryValueList;
      let glossaryNamesAndIds = [];
      let getGlossaryResponse;
      for (const glossary of glossaryValueIds) {
        getGlossaryResponse = await getGlossary({
          GlossaryId: glossary.GlossaryId,
          OwnerId: glossary.OwnerId,
        });
        glossaryNamesAndIds.push({
          Name: getGlossaryResponse.GlossaryDetails?.Name,
          Id: getGlossaryResponse.GlossaryDetails?.GlossaryId,
          GlossaryValue: getGlossaryResponse.GlossaryDetails?.GlossaryValue,
          GlossaryOwnerId: getGlossaryResponse.GlossaryDetails?.OwnerId,
        });
      }
      return (
        <>
          {glossaryNamesAndIds.map((nameAndId) => (
            <>
              <Link href={`/glossaries/${nameAndId.Id}/${nameAndId.GlossaryOwnerId}`}>
                {nameAndId.Name} - {nameAndId.GlossaryValue}
              </Link>
              <br />
            </>
          ))}
        </>
      );
    }
  };

  const metadataFormFieldColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'fieldName',
      header: 'Name',
      cell: (item) => item.Name,
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.FieldType.replace('Field#', ''),
      minWidth: 200,
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item) => {
        if (isValidUrl(item.Value)) {
          return (
            <Link href={item.Value} external={true}>
              {item.Value}
            </Link>
          );
        }
        return item.Value;
      },
      minWidth: 200,
    },
  ];

  const getActiveGroupOrWorkspace = () => {
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    return activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
  };

  const userIsOwner = () => {
    const ownerId = getActiveGroupOrWorkspace();
    return ownerIds?.includes(ownerId);
  };

  const getMetaDataFormFields = async () => {
    setMetadataFormFields([]);
    try {
      let formFields = [];
      let filteredEnabledFields = [];
      let listMetadataFormFieldsResult = await listMetadataFields({
        MetadataFormId: props.formId,
      });
      filteredEnabledFields = listMetadataFormFieldsResult?.MetadataFieldsList.filter((field) => {
        return field.Status == METADATA_ENABLED;
      });
      formFields.push(...filteredEnabledFields);
      while (listMetadataFormFieldsResult.NextToken != undefined) {
        listMetadataFormFieldsResult = await listMetadataFields({
          MetadataFormId: props.formId,
          NextToken: listMetadataFormFieldsResult.NextToken,
        });
        filteredEnabledFields = listMetadataFormFieldsResult?.MetadataFieldsList.filter((field) => {
          return field.Status == METADATA_ENABLED;
        });
        formFields.push(...filteredEnabledFields);
      }
      return formFields;
    } catch (err) {
      setFieldsTableMessage(`Unable to load fields. ${err.message}`);
      if (err.statusCode >= 500) {
        await props.setNotification('Error', `Error in fetching form fields. ${err.message}`);
      }
    }
  };

  const getMetaDataValuesForField = async () => {
    setMetadataFieldValues([]);
    try {
      let result = await listMetadataValues({
        ResourceArn: resource,
        MetadataFormId: props.formId,
      });
      let valueList = result?.MetadataValuesList;
      while (result.NextToken != undefined) {
        result = await listMetadataValues({
          ResourceArn: resource,
          MetadataFormId: props.formId,
          NextToken: result.NextToken,
        });
        valueList.push(...result?.MetadataValuesList);
      }
      //setMetadataFieldValues(valueList);
      return valueList;
    } catch (err) {
      setFieldValueTableMessage(`Unable to load values. ${err.message}`);
      if (err.statusCode >= 500) {
        await props.setNotification('Error', `Error in fetching field values. ${err.message}`);
      }
    }
  };

  const handleMetadataFormFieldButtonAction = async (e) => {
    if (e.detail.id === 'createMetadataFieldValue') {
      setCreateFieldValueModalVisible(true);
    } else if (e.detail.id === 'editMetadataFieldValue') {
      setEditFieldValueModalVisible(true);
    }
  };

  const metadataFormFieldsButtonOptions = () => {
    return (
      <SpaceBetween size={'s'} direction={'horizontal'}>
        <Button variant={'icon'} iconName={'refresh'} onClick={handleRefresh} />
        {userIsOwner() && (
          <ButtonDropdown
            items={[
              {
                text: 'Create value',
                id: 'createMetadataFieldValue',
                disabled: !selectedField || selectedField?.Value != undefined,
              },
              {
                text: 'Edit value',
                id: 'editMetadataFieldValue',
                disabled: !selectedField || !(selectedField?.Value != undefined),
              },
            ]}
            onItemClick={handleMetadataFormFieldButtonAction}
          >
            Actions
          </ButtonDropdown>
        )}
      </SpaceBetween>
    );
  };

  return (
    <>
      <MetadataFieldValueModal
        formId={props.formId}
        formOwnerId={props.formOwnerId}
        resource={props.resource}
        setNotification={props.setNotification}
        close={() => {
          setEditFieldValueModalVisible(false);
        }}
        visible={editFieldValueModalVisible}
        ownerId={getActiveGroupOrWorkspace()}
        metadataFormField={selectedField}
        handleRefresh={handleRefresh}
        modalType={'Edit'}
      />
      <MetadataFieldValueModal
        formId={props.formId}
        formOwnerId={props.formOwnerId}
        resource={props.resource}
        setNotification={props.setNotification}
        close={() => {
          setCreateFieldValueModalVisible(false);
        }}
        visible={createFieldValueModalVisible}
        ownerId={getActiveGroupOrWorkspace()}
        metadataFormField={selectedField}
        handleRefresh={handleRefresh}
        modalType={'Create'}
      />
      <Table
        {...collectionProps}
        selectionType='single'
        loadingText='Loading form fields...'
        loading={loadingFormFields}
        columnDefinitions={metadataFormFieldColumnDefinitions}
        items={items}
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No fields</b>
            <Box variant='p' color='inherit'>
              No fields to display.
            </Box>
          </Box>
        }
        resizableColumns={true}
        header={
          <Header counter={`(${items.length})`} actions={metadataFormFieldsButtonOptions()}>
            Metadata fields
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
    </>
  );
};

export default MetadataFormFieldValueDetailsTable;
