import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Flashbar } from '@amzn/awsui-components-react-v3';
import { auditWorkspace, getWorkspace, syncWorkspace } from 'src/api/permissions';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { WorkspaceDetailContainer } from 'src/components/workspaces/common/WorkspaceDetailContainer';

import { PageHeader } from 'src/components/common/PageHeader';

export interface WorkspaceDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  workspace: any;
  setActiveTabId: any;
  refresh: any;
}

export const WorkspaceDetail = (props: WorkspaceDetailProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [workspace, setWorkspace] = useState(undefined);
  const [auditButtonLoading, setAuditButtonLoading] = useState(false);
  const [syncButtonLoading, setSyncButtonLoading] = useState(false);
  const [refreshButtonLoading, setRefreshButtonLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const init = async () => {
    props.setActiveTabId('detail');
    if (!props.activeGroup && !props.match.params.workspaceId) return;
    try {
      setWorkspace(props.workspace);
    } catch (err) {
      console.log('Exception when get workspace for group', err);
    }
  };

  const handleRefresh = async () => {
    setRefreshButtonLoading(true);
    try {
      props.refresh();
      let getWorkspaceResponse = await getWorkspace({
        workspaceId: props.match.params.workspaceId,
      });
      setWorkspace(getWorkspaceResponse.workspace);
      setRefreshButtonLoading(false);
    } catch (err) {
      console.log('Exception when get workspace for group', err);
      setRefreshButtonLoading(false);
    }
    setDisableButton(false);
  };

  useEffect(() => {
    init();
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const performEditWorkspace = () => {
    setRedirect('/workspaces/' + props.match.params.workspaceId + '/update');
  };

  const performAuditWorkspace = () => {
    setAuditButtonLoading(true);
    setNotifications([
      {
        type: 'success',
        content: `Start audit workspace.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
    setDisableButton(true);
    try {
      auditWorkspace({ workspaceId: workspace?.workspaceId });
      setAuditButtonLoading(false);
    } catch (err) {
      console.log('Exception when audit workspace', err);
      setNotifications([
        {
          type: 'error',
          content: err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setAuditButtonLoading(false);
    }
  };

  const performSyncWorkspace = () => {
    setSyncButtonLoading(true);
    setNotifications([
      {
        type: 'success',
        content: `Start sync workspace.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
    setDisableButton(true);
    try {
      syncWorkspace({ workspaceId: workspace?.workspaceId });
      setSyncButtonLoading(false);
    } catch (err) {
      console.log('Exception when sync workspace', err);
      setNotifications([
        {
          type: 'error',
          content: err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setSyncButtonLoading(false);
    }
  };

  if (redirect) return <Redirect push to={redirect} />;
  return (
    <>
      <Flashbar items={notifications}></Flashbar>
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
            loading: refreshButtonLoading,
          },
          {
            text: 'CloudFormation drift check',
            onItemClick: performAuditWorkspace,
            disabled:
              workspace?.status == 'Active_Syncing' ||
              workspace?.status == 'Active_Auditing' ||
              workspace?.status == 'Active_Running' ||
              disableButton,
            loading: auditButtonLoading,
          },
          {
            text: 'Fix my workspace',
            onItemClick: performSyncWorkspace,
            disabled:
              workspace?.status == 'Active_Syncing' ||
              workspace?.status == 'Active_Auditing' ||
              workspace?.status == 'Active_Running' ||
              disableButton,
            loading: syncButtonLoading,
          },
          {
            text: 'Edit',
            onItemClick: performEditWorkspace,
          },
        ]}
        header={workspace?.workspaceName}
      />

      {workspace && <WorkspaceDetailContainer workspace={workspace} />}
    </>
  );
};
