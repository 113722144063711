import * as React from 'react';
import { useEffect, useState } from 'react';
import { CatalogDetails } from 'src/components/workspaces/dataRegister/glue/catalogDetails';

import { scrollUp } from 'src/components/utils/navigation';
import { Alert, Box, Button, Flashbar, Link, SpaceBetween, Wizard } from '@amzn/awsui-components-react-v3';
import { GlueDatasets } from 'src/components/workspaces/dataRegister/glue/glueDatasets';
import { GlueDatabases } from 'src/components/workspaces/dataRegister/glue/glueDatabases';

import { registerCatalog, registerDatabase, registerDataSets, syncDataSets } from 'src/api/catalog';
import { associateTemplateToResources, createDataPermission } from 'src/api/permissions';
import { DatasetsReview } from 'src/components/workspaces/dataRegister/glue/datasetsReview';
import { ValidatePrerequisite } from 'src/components/workspaces/dataRegister/glue/validatePrerequisite';
import {
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  defaultClassificationOption,
  LAKE_FORMATION_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { AccessTemplates } from 'src/components/workspaces/dataRegister/glue/accessTemplates';
import { createDatabaseDetailLink } from 'src/routes';
import { RegisterDatabaseRequest } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { Modal } from '@amzn/awsui-components-react-v3';

export interface WorkspaceGlueRegisterProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  toggleHelp: any;
}

export const WorkspaceGlueRegister = (props: WorkspaceGlueRegisterProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [registerItems, setRegisterItems] = useState([]);
  const [catalogName, setCatalogName] = useState(undefined);
  const [catalogDescription, setCatalogDescription] = useState(undefined);
  const [CTI, setCTI] = useState(undefined);
  const [getCatalogSuccess, setGetCatalogSuccess] = useState(false);
  const [selectedDatabaseName, setSelectedDatabaseName] = useState(undefined);
  const [selectedDatabaseDescription, setSelectedDatabaseDescription] = useState(undefined);
  const [selectedAllowSNSSubscription, setSelectedAllowSNSSubscription] = useState(false);
  const [selectedAutoTableOnboard, setSelectedAutoTableOnboard] = useState(false);
  const [selectedDatabaseRegistered, setSelectedDatabaseRegistered] = useState(true);
  const [isDefaultCatalog, setIsDefaultCatalog] = useState(false); // if catalog onboarded with trusted service
  const [isGroupBasedCatalog, setIsGroupBasedCatalog] = useState(false); // if catalog onboarded with Group based model
  const [registerDataPermissionType, setRegisterDataPermissionType] = useState(undefined);

  const [hasAllTablePermission, setHasAllTablePermission] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [satisfiedPrerequisite, setSatisfiedPrerequisite] = useState(false);
  const [associateTemplate, setAssociateTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [piiModalVisible, setPiiModalVisible] = useState(false);
  const [piiNoticeShown, setPiiNoticeShown] = useState(false);

  const PUBLIC = 'Public';

  const registerCatalogToHC = async () => {
    let registerCatalogRequest = {
      CatalogId: props.activeWorkspace.accountId,
      Region: props.activeWorkspace.region,
      Name: catalogName,
      Owner: props.activeWorkspace.workspaceId,
      CTI: CTI,
      Description: catalogDescription,
      DataAccessRole: props.activeWorkspace.workspaceRoleArn,
      DataClassification: PUBLIC,
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
    };
    await registerCatalog(registerCatalogRequest);
  };

  const registerLFDatabaseToHC = async () => {
    let registerDatabaseRequest: RegisterDatabaseRequest = {
      CreatedBy: props.activeWorkspace.workspaceId,
      Region: props.activeWorkspace.region,
      DatabaseName: selectedDatabaseName,
      CatalogId: props.activeWorkspace.accountId,
      Description: selectedDatabaseDescription,
      Owners: [props.activeWorkspace.workspaceId],
      AllowSNSEventSubscription: selectedAllowSNSSubscription,
      AutoTableOnboard: selectedAutoTableOnboard,
      DataAccessRole: props.activeWorkspace.workspaceRoleArn,
      DataClassification: PUBLIC,
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
    };
    await registerDatabase(registerDatabaseRequest);
  };

  const transferRegisterItems = () => {
    const newItems = registerItems.map((item) => ({
      DatabaseName: selectedDatabaseName,
      TableName: item.label,
      DataSetName: item.label,
      PrimaryOwner: props.activeWorkspace.workspaceId,
      TableOwners: [props.activeWorkspace.workspaceId],
      PII: item?.tags.includes('PII') ?? false,
      DataClassification: item.dataClassification ?? defaultClassificationOption.value,
      TableState: 'Active',
    }));
    return newItems;
  };

  const notifyError = (message) => {
    setNotifications([
      {
        type: 'error',
        content: message,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
    scrollUp();
  };

  const hasPII = () => {
    if (registerItems) {
      return registerItems.find((item) => item?.tags.includes('PII')) !== undefined;
    }
    return false;
  };

  const dismissModal = () => {
    setPiiModalVisible(false);
  };

  const registerDatasetsToHC = async () => {
    let registerRequest = {
      CreatedBy: props.activeWorkspace.workspaceId,
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
      CatalogId: props.activeWorkspace.accountId,
      Region: props.activeWorkspace.region,
      DataSetList: transferRegisterItems(),
      DataAccessRole: props.activeWorkspace.workspaceRoleArn,
    };

    let registerResponse;
    try {
      registerResponse = await registerDataSets(registerRequest);
    } catch (err) {
      console.error(err);
      throw err;
    }
    const idList = registerResponse.DataSetList.map((e) => e.Id);
    const syncRequest = {
      IdList: idList,
    };
    try {
      await syncDataSets(syncRequest);
    } catch (err) {
      console.log('Exception when syncing dataset', err);
      return;
    }
    if (associateTemplate) {
      let promises = [];
      try {
        idList.forEach((id) => {
          promises.push(
            associateTemplateToResources({
              templateId: selectedTemplate.templateId,
              resourceId: id,
            }),
          );
        });
        await Promise.all(promises);
      } catch (err) {
        notifyError('Unable to associate templates to resources');
      }
    }
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const createTableResourcePermission = async () => {
    try {
      if (registerDataPermissionType == 'ALLTablePermission') {
        let request = {
          ownerId: props.activeWorkspace.workspaceId,
          dataPermission: {
            ownerId: props.activeWorkspace.workspaceId,
            dataPermissionOwnerId: props.activeWorkspace.workspaceId,
            option: DATA_PERMISSION_PUBLISHER_OPTION,
            type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
            dataPublisherAuditRole: props.activeWorkspace.workspaceRoleArn,
            dataConsumerRole: null,
            dataPublisherRole: props.activeWorkspace.workspaceRoleArn,
            region: props.activeWorkspace.region,
            resource: {
              table: {
                databaseName: selectedDatabaseName,
                catalogId: props.activeWorkspace.accountId,
                tableWildcard: {},
              },
            },
            permissions: ['SELECT'],
            permissionsWithGrantOption: ['SELECT'],
            dataLakePrincipal: props.activeWorkspace.workspaceRoleArn,
          },
        };
        await createDataPermission(request);
      }
    } catch (err) {
      console.error('Exception when create data permission ', err);
      throw err;
    }
  };

  const handleSubmit = async () => {
    setLoadingButton(true);
    if (registerItems == undefined || registerItems.length == 0) {
      notifyError(`Please select dataset(s) to register`);
      return;
    }

    if (satisfiedPrerequisite == false) {
      notifyError('Please make sure selected datasets satisfy prerequisites.');
      setLoadingButton(false);
      return;
    }

    setNotifications([
      {
        loading: true,
        content: `Datasets are being registered.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);

    try {
      if (!hasAllTablePermission) {
        await createTableResourcePermission();
      }
      await registerDatasetsToHC();
      setNotifications([
        {
          type: 'success',
          content: `Successfully registered the datasets.`,
          action: (
            <Button
              href={createDatabaseDetailLink(
                LAKE_FORMATION_DATASOURCE_ID,
                props.activeWorkspace.accountId,
                undefined,
                undefined,
                selectedDatabaseName,
                props.activeWorkspace.region,
              )}
            >
              View database
            </Button>
          ),
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } catch (e) {
      setLoadingButton(false);
      notifyError(`Failed to register the selected datasets. ${e.message}`);
    }
    setLoadingButton(false);
    scrollUp();
  };

  const i18nStrings = {
    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Submit',
    optional: 'optional',
  };

  const handleWizardNavigation = async (detail) => {
    setNotifications([]); // reset notifications
    if (activeStepIndex == 0 && detail.requestedStepIndex == 1) {
      if (getCatalogSuccess && (isGroupBasedCatalog || isDefaultCatalog)) {
        try {
          await registerCatalogToHC();
          setNotifications([
            {
              type: 'success',
              content: `Successfully registered catalog`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } catch (err) {
          notifyError(`Failed to register catalog. ${err.message}`);
          console.log(err);
          return;
        }
      } else if (!getCatalogSuccess) {
        try {
          await registerCatalogToHC();
          setNotifications([
            {
              type: 'success',
              content: `Successfully registered catalog`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } catch (err) {
          notifyError(`Failed to register catalog. ${err.message}`);
          console.log(err);
          return;
        }
      }
    }

    if (activeStepIndex == 1 && detail.requestedStepIndex == 2) {
      if (selectedDatabaseName == undefined) {
        notifyError('Select a database to continue');
        return;
      }
      if (!selectedDatabaseRegistered) {
        try {
          await registerLFDatabaseToHC();
          setNotifications([
            {
              type: 'success',
              content: `Successfully registered database`,
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        } catch (err) {
          console.log(err);
          notifyError(`Database register error` + err.message);
          return;
        }
      }
    }

    if (activeStepIndex == 2 && detail.requestedStepIndex == 1) {
      setSelectedDatabaseName(undefined);
      setSelectedDatabaseDescription(undefined);
      setSelectedDatabaseRegistered(true);
    }

    if (activeStepIndex == 2 && detail.requestedStepIndex == 3) {
      if (registerItems == undefined || registerItems.length == 0) {
        notifyError('No items selected, select at least one item.');
        setLoadingButton(false);
        return;
      }
      if (hasPII() && !piiNoticeShown) {
        setPiiModalVisible(true);
        setPiiNoticeShown(true);
        return;
      }
    }

    if (activeStepIndex == 3 && detail.requestedStepIndex == 4) {
      if (registerItems) {
        if (associateTemplate && selectedTemplate === undefined) {
          notifyError('Access template is not selected, select an access template to continue.');
          return;
        }
        if (!associateTemplate && hasPII()) {
          notifyError('Access template association is required when registering PII datasets.');
          return;
        }
        if (hasPII() && selectedTemplate?.accessManagementInfo?.approvalsWorkflowTemplate?.autoApproval) {
          notifyError('PII datasets cannot be associated with templates that have auto approval enabled.');
          return;
        }
      }
    }

    setActiveStepIndex(detail.requestedStepIndex);
  };

  return (
    <div>
      <SpaceBetween size={'xs'}>
        <Flashbar items={notifications}></Flashbar>
        <Wizard
          activeStepIndex={activeStepIndex}
          steps={[
            {
              title: 'Register/Select catalog',
              description: 'Select the registered catalog or register a new catalog before the next step.',
              info: (
                <Link variant='info' onFollow={props.toggleHelp}>
                  {' '}
                  Info{' '}
                </Link>
              ),
              content: (
                <>
                  <CatalogDetails
                    {...props}
                    CTI={CTI}
                    catalogName={catalogName}
                    description={catalogDescription}
                    setCTI={setCTI}
                    setCatalogName={setCatalogName}
                    setDescription={setCatalogDescription}
                    setGetCatalogSuccess={setGetCatalogSuccess}
                    getCatalogSuccess={getCatalogSuccess}
                    setIsDefaultCatalog={setIsDefaultCatalog}
                    isDefaultCatalog={isDefaultCatalog}
                    setIsGroupBasedCatalog={setIsGroupBasedCatalog}
                    isGroupBasedCatalog={isGroupBasedCatalog}
                  />
                </>
              ),
            },
            {
              title: 'Register/Select database',
              description: 'Select a registered database or register a new database before the next step.',
              info: (
                <Link variant='info' onFollow={props.toggleHelp}>
                  {' '}
                  Info{' '}
                </Link>
              ),
              content: (
                <>
                  <GlueDatabases
                    {...props}
                    selectedDatabaseDescription={selectedDatabaseDescription}
                    selectedDatabaseName={selectedDatabaseName}
                    selectedAllowSNSSubscription={selectedAllowSNSSubscription}
                    selectedAutoTableOnboard={selectedAutoTableOnboard}
                    setSelectedDatabaseDescription={setSelectedDatabaseDescription}
                    setSelectedDatabaseName={setSelectedDatabaseName}
                    setSelectedAllowSNSSubscription={setSelectedAllowSNSSubscription}
                    setSelectedAutoTableOnboard={setSelectedAutoTableOnboard}
                    setSelectedDatabaseRegistered={setSelectedDatabaseRegistered}
                  />
                </>
              ),
            },
            {
              title: 'Select datasets',
              description: 'Select datasets in the selected database',
              info: (
                <Link variant='info' onFollow={props.toggleHelp}>
                  {' '}
                  Info{' '}
                </Link>
              ),
              content: (
                <>
                  <Modal
                    visible={piiModalVisible}
                    header={'Notice regarding PII data registration'}
                    onDismiss={dismissModal}
                    footer={
                      <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                          <Button variant='primary' onClick={dismissModal}>
                            Ok
                          </Button>
                        </SpaceBetween>
                      </Box>
                    }
                  >
                    <Alert>
                      You are marking your dataset(s) as containing Personally Identifiable Information. To ensure the
                      proper handling and protection of this sensitive data, you will be required to associate an Omni
                      approval templates in the next step. Review the PII and data classification values are accurate
                      before proceeding.
                    </Alert>
                  </Modal>
                  <GlueDatasets
                    {...props}
                    setRegisterItems={setRegisterItems}
                    registerItems={registerItems}
                    selectedDatabaseName={selectedDatabaseName}
                    setRegisterDataPermissionType={setRegisterDataPermissionType}
                    setHasAllTablePermission={setHasAllTablePermission}
                    setNotifications={setNotifications}
                  />
                </>
              ),
            },
            {
              title: 'Associate templates',
              description:
                'You can associate access templates to your datasets, when someone requests access to your ' +
                'datasets they will have to satisfy the requirements defined in the template. i.e. create a SIM ticketing or an Amazon approval workflow.',
              info: (
                <Link variant='info' onFollow={props.toggleHelp}>
                  {' '}
                  Info{' '}
                </Link>
              ),
              content: (
                <AccessTemplates
                  {...props}
                  registerItems={registerItems}
                  selectedDatabaseName={selectedDatabaseName}
                  catalogName={catalogName}
                  setNotifications={setNotifications}
                  associateTemplate={associateTemplate}
                  setAssociateTemplate={setAssociateTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                />
              ),
            },
            {
              title: 'Review datasets to register',
              description: 'Review selected datasets in the selected database',
              info: (
                <Link variant='info' onFollow={props.toggleHelp}>
                  {' '}
                  Info{' '}
                </Link>
              ),
              content: (
                <>
                  <DatasetsReview
                    {...props}
                    registerItems={registerItems}
                    selectedDatabaseName={selectedDatabaseName}
                    catalogName={catalogName}
                    selectedTemplate={selectedTemplate}
                    associateTemplate={associateTemplate}
                  />
                </>
              ),
            },
            {
              title: 'Validate prerequisites',
              description: 'Validate selected datasets satisfy prerequisites',
              info: (
                <Link variant='info' onFollow={props.toggleHelp}>
                  {' '}
                  Info{' '}
                </Link>
              ),
              content: (
                <>
                  <ValidatePrerequisite
                    {...props}
                    registerItems={registerItems}
                    selectedDatabaseName={selectedDatabaseName}
                    catalogName={catalogName}
                    setLoadingValidation={setLoadingButton}
                    setSatisfiedPrerequisite={setSatisfiedPrerequisite}
                    containsPII={hasPII()}
                    setErrorMessage={notifyError}
                  />
                </>
              ),
            },
          ]}
          onNavigate={({ detail }) => handleWizardNavigation(detail)}
          i18nStrings={i18nStrings}
          onSubmit={handleSubmit}
          onCancel={() => {
            setActiveStepIndex(0);
          }}
          isLoadingNextStep={loadingButton}
        />
      </SpaceBetween>
    </div>
  );
};
