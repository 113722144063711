import * as React from 'react';
import { getResource } from '../../../api/resourcesmanager';
import { RMPageHeader, ErrorAlert, coloredStatus, LabeledTextColumns, AuditLogsCard } from '../components';
import { dateString } from '../helpers';
import { Page } from 'src/routes';
import { CARDS_CONTENT_TYPE } from 'src/commons/constants';
import { useState, useEffect } from 'react';
import { Container, Header } from '@amzn/awsui-components-react-v3';

export interface resourceDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}
export const ResourceDetails = (props: resourceDetailProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [resource, setResource] = useState(null);

  useEffect(() => {
    props.setContentType(CARDS_CONTENT_TYPE);
    handleRefresh();
  }, [props.activeGroup]);

  /**
   * Asynchronously fetches the resources from the RM API and updates the state.
   */
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const resource = await getResource({
        id: props.match.params.id,
      });
      setResource(resource);
      setLoading(false);
    } catch (err) {
      setError({ ...err, while: 'LOADING RESOURCE' });
      setResource(null);
      setLoading(false);
    }
  };

  const copiable = true;

  return (
    <>
      {ErrorAlert(error)}
      <RMPageHeader subheader={resource?.name} />
      <Container header={<Header variant={'h2'}>Resource details</Header>}>
        <LabeledTextColumns
          labeledTextList={[
            {
              label: 'Resource ID',
              value: resource?.id,
              copiable,
            },
            { label: 'Name', value: resource?.name },
            {
              label: 'Description',
              value: resource?.description,
            },
          ]}
          loading={loading}
        />
        <LabeledTextColumns
          labeledTextList={[
            { label: 'Type', value: resource?.type },
            {
              label: 'Status',
              value: coloredStatus(resource?.status),
            },
            {
              label: 'Status reason',
              value: resource?.statusReason,
            },
            {
              label: 'EC2 subnet ID',
              value: resource?.ec2SubnetId,
              copiable,
            },
            {
              label: 'Owner (Group ID)',
              value: resource?.groupId,
              copiable,
            },
            {
              label: 'Resource group ID',
              value: resource?.resourceGroupId,
              url: Page.RESOURCEGROUP_DETAILS.replace(':id', resource?.resourceGroupId),
              copiable,
            },
            {
              label: 'Is backup?',
              value: resource?.isBackup ? 'YES' : 'NO',
            },
            {
              label: 'Create date',
              value: dateString(resource?.createDate),
            },
            {
              label: 'Update date',
              value: dateString(resource?.updateDate),
            },
          ]}
          columns={3}
          loading={loading}
        />
      </Container>
      <AuditLogsCard auditLogs={resource?.auditLogs} loading={loading} />
    </>
  );
};
