import * as React from 'react';
import {
  Button,
  Flashbar,
  Form,
  Header,
  FormField,
  Textarea,
  Select,
  Multiselect,
  ColumnLayout,
  Toggle,
  Input,
  Container,
} from '@amzn/awsui-components-react-v3';
import { Link, Redirect } from 'react-router-dom';
import { scrollUp } from '../utils/navigation';
import { Page } from 'src/routes';
import { convertTemplatesToOptions, createNotification, getNotificationTemplates } from 'src/api/notifications';
import { getStage } from 'src/api/config';
import { useEffect, useState } from 'react';
import { getSchemasForDatabase, listCatalogs, listDatabases, listDatasetsForDatabase } from 'src/api/catalog';
import {
  DATA_PERMISSION_IAM_TYPE,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  FORM_CONTENT_TYPE,
  TEMPLATE_CATALOG_RESOURCE_TYPE,
  TEMPLATE_DATABASE_RESOURCE_TYPE,
  TEMPLATE_REDSHIFT,
  TEMPLATE_SCHEMA_RESOURCE_TYPE,
  TEMPLATE_TABLE_RESOURCE_TYPE,
} from 'src/commons/constants';
import { fetchActiveConsumerDataPermissions } from 'src/components/permissions/myBaselining/baseliningUtils';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { getDatasetUpperLevelResourceId } from 'src/components/utils/hybridCatalog/idUtil';

export interface CreateNotificationFormProps {
  catalogMap: any;
  activeWorkspace: any;
  setContentType: any;
  activeGroup: string;
  username: string;
}

//props and state variables needed for the create notification page
export const CreateNotificationForm = (props: CreateNotificationFormProps) => {
  const [notifications, setNotifications] = useState([]);
  const [datasetsStatusType] = useState(null);
  const [tables, setTables] = useState([]);
  const [buttonCreateLoading, setButtonCreateLoading] = useState(false);
  const [buttonConfirmLoading, setButtonConfirmLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [content, setContent] = useState(null);
  const [priorityLevel, setPriorityLevel] = useState(null);
  const [resourceType, setResourceType] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [subject, setSubject] = useState(null);
  const [databases, setDatabases] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [templates, setTemplates] = useState(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  let ownerId = props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup;
  const [catalogs, setCatalogs] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState(undefined);
  const [selectedTable, setSelectedTable] = useState(null);
  const [schemasInfo, setSchemasInfo] = useState(null);
  const [allConsumers, setAllConsumers] = useState([]);
  const [selectedConsumers, setSelectedConsumers] = useState(allConsumers);
  const [deselected, setDeselection] = useState(false);

  //for IAM permissions if you would like to add
  // async function processGroups() {
  //
  //     const groups =
  //         await getGroupsConsumingDataset({datasetId: tablesInfo.tagResourceId});
  //     let groupOptions = convertGroupsToOption(groups);
  //     for (let groupOption of groupOptions) {
  //         groupIdList.push(groupOption);
  //     }
  //
  //     groupIdList = groupIdList.filter(
  //         (option, index, self) =>
  //             index ===
  //             self.findIndex(
  //                 (t) =>
  //                     t.id === option.id && t.description === option.description
  //             )
  //     );
  //     setAllConsumers(groupIdList);
  // }

  //create notification
  const handleCreate = async () => {
    setButtonCreateLoading(true);

    let response;
    try {
      response = await createNotification({
        SenderId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
        ReceiverIdList: selectedConsumers.map((item) => item['label'].toString()),
        PriorityLevel: priorityLevel.value,
        Subject: subject,
        Content: content,
        EmailNeeded: sendEmail,
      });
    } catch (e) {
      console.log(e);
      setButtonCreateLoading(false);
      setNotifications([
        {
          type: 'error',
          content: `Notification create failed.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }

    setButtonCreateLoading(false);

    if (response.Message == 'create success') {
      setNotifications([
        {
          type: 'success',
          content: `Notification created.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'error',
          content: `Notification create failed.` + response.Message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }

    scrollUp();
  };

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    fetchTemplates();
  }, []);

  const getEmailAddress = () => {
    const stage = getStage();
    if (stage === 'beta') {
      return 'omni-notification-beta-iad@no-reply.datalake.aws.a2z.com';
    } else if (stage === 'gamma_iad') {
      return 'omni-notification-gamma-iad@no-reply.datalake.aws.a2z.com';
    } else if (stage === 'gamma_pdx') {
      return 'omni-notification-gamma-pdx@no-reply.datalake.aws.a2z.com';
    } else if (stage === 'prod_iad') {
      return 'omni-notification-prod-iad@no-reply.datalake.aws.a2z.com';
    } else if (stage === 'prod_pdx') {
      return 'omni-notification-prod-pdx@no-reply.datalake.aws.a2z.com';
    } else if (stage === 'preprod_zhy') {
      return 'omni-notification-preprod-zhy@no-reply.datalake.aws.a2z.org.cn';
    } else if (stage === 'prod_zhy') {
      return 'omni-notification-prod-zhy@no-reply.datalake.aws.a2z.org.cn';
    } else if (stage === 'gamma_lck') {
      return 'omni-notification-gamma-lck@no-reply.datalake.sc2s-a2z.sgov.gov';
    } else if (stage === 'gamma_dca') {
      return 'omni-notification-gamma-dca@no-reply.datalake.c2s-a2z.ic.gov';
    } else if (stage === 'gamma_ncl') {
      return 'omni-notification-gamma-ncl@no-reply.datalake.a2z.adc-e.uk';
    } else if (stage === 'prod_lck') {
      return 'omni-notification-prod-lck@no-reply.datalake.sc2s-a2z.sgov.gov';
    } else if (stage === 'prod_dca') {
      return 'omni-notification-prod-dca@no-reply.datalake.c2s-a2z.ic.gov';
    } else if (stage === 'prod_ncl') {
      return 'omni-notification-prod-ncl@no-reply.datalake.a2z.adc-e.uk';
    } else {
      return 'omni-notification-beta-iad@no-reply.datalake.aws.a2z.com';
    }
  };

  //fetches the consumers once the select resource confirmation button is clicked. fetchActiveConsumerDataPermissions
  //matches the related consumer permissions with the publisher permissions based on the selected resource. It returns
  //a list of readonly consumer objects which are converted into an OptionDefinition array to allow for consumer selection
  //in the multiselect React component.
  const fetchConsumers = async () => {
    setButtonConfirmLoading(true);
    try {
      let resourceInput: unknown;
      if (resourceType.label == 'Database') {
        resourceInput = selectedDatabase;
      } else if (resourceType.label == 'Table') {
        resourceInput = selectedTable;
      } else if (resourceType.label == 'Schema') {
        resourceInput = schemasInfo;
      }

      let consumers = await fetchActiveConsumerDataPermissions(resourceInput);

      console.log(consumers);
      let allConsumerOptions = [];
      const duplicates = new Set<string>();

      for (const consumer of consumers) {
        if (!duplicates.has(consumer.ownerId)) {
          let item = {
            label: consumer.ownerId,
            value: consumer.ownerId,
          };
          allConsumerOptions.push(item);
        }
        duplicates.add(consumer.ownerId);
      }
      setAllConsumers(allConsumerOptions);
      setSelectedConsumers(allConsumerOptions);
    } catch (e) {
      console.log(e);
      setButtonConfirmLoading(false);
    }
    setButtonConfirmLoading(false);
  };

  const fetchTemplates = async () => {
    const templates = await getNotificationTemplates({
      NotificationOwnerId: props.activeGroup,
    });
    setTemplates(convertTemplatesToOptions(templates));
  };

  const setContentAccordingToTemplate = async (e) => {
    setSelectedTemplate(e.detail.selectedOption);
    setContent(e.detail.selectedOption.templateContent);
  };

  //once the resource type is selected, getCatalogs() is called. If the user chooses a database for example, another dropdown
  //will pop up once the corresponding catalog that the database is a part of has been selected.
  const handleResourceTypeOnClick = (event) => {
    setResourceType(event.detail.selectedOption);
    if (
      event.detail.selectedOption.label.includes(TEMPLATE_CATALOG_RESOURCE_TYPE) ||
      event.detail.selectedOption.label.includes(TEMPLATE_DATABASE_RESOURCE_TYPE) ||
      event.detail.selectedOption.label.includes(TEMPLATE_TABLE_RESOURCE_TYPE) ||
      event.detail.selectedOption.label.includes(TEMPLATE_SCHEMA_RESOURCE_TYPE)
    ) {
      getCatalogs();
    }
  };

  //calls the API needed to fetch all the catalogs. Catalogs are filtered based on whether the owner has permission for them.
  //the catalog list response is converted into a format that can be used in the React component for selecting catalogs.
  const getCatalogs = async () => {
    try {
      let request = { NextToken: null };
      const catalogs = await listCatalogs(request);
      let catalogList = catalogs.CatalogInfoList.filter((item) => item.Owner == ownerId);
      setCatalogs(
        catalogList.map((catalog) => {
          return { value: catalog, label: `${catalog.CatalogId} (${catalog.Name})` };
        }),
      );
    } catch (err) {
      console.log('Exception when get catalogs', err);
    }
  };

  //gets all the datasets (tables) based on the database selected by the user by calling the GetDataSetsFromHybridCatalogDatabase API.
  //The parameters returned from the API response are then fed into buildTableResource which converts the API response parameters
  //into a datatype that can be used to fetch the corresponding consumers of that dataset/table.
  const getTables = async (database) => {
    try {
      let nextToken = '';
      let tableList = [];

      while (nextToken != null) {
        try {
          let datasets;
          const datasourceId = database.DataSourceId != null ? database.DataSourceId : getDataSource(database);

          if (nextToken != '') {
            datasets = await listDatasetsForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: datasourceId,
              ClusterIdentifier: database.ClusterIdentifier,
              SchemaName: database.Schema,
              Region: database.Region,
              NextToken: nextToken,
            });
          } else {
            datasets = await listDatasetsForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: datasourceId,
              ClusterIdentifier: database.ClusterIdentifier,
              SchemaName: database.Schema,
              Region: database.Region,
            });
          }

          tableList = tableList.concat(datasets.DataSetList);
          nextToken = datasets.NextToken;
        } catch (err) {
          console.log('Exception when fetch datasets for database', err);
          break;
        }
      }
      tableList = tableList.filter((table) => table.PrimaryOwner == ownerId);
      setTables(
        tableList.map((table) => {
          return buildTableResource(table);
        }),
      );
    } catch (err) {
      console.log('Exception when fetch tables', err);
    }
  };

  //maps the parameters returned from the GetDataSetsFromHybridCatalogDatabase API call to an object with the necessary
  //parameters to fetch consumers.
  const buildTableResource = (dataset) => {
    const dataSourceId = dataset.IdInfo.DataSourceId.charAt(0).toUpperCase() + dataset.IdInfo.DataSourceId.slice(1);

    return {
      accountId: dataset.IdInfo.CatalogId,
      region: dataset.IdInfo.Region,
      type: 'TABLE',
      dataCatalogObjectDetails: {
        dataSourceId: dataSourceId,
        databaseName: dataset.IdInfo.DatabaseName,
        schemaName: dataset.IdInfo.SchemaName,
        tableName: dataset.IdInfo.TableName,
      },
      dpType:
        dataset.IdInfo.DataSourceId == 'redshift' ? DATA_PERMISSION_REDSHIFT_TYPE : DATA_PERMISSION_LAKE_FORMATION_TYPE,
      tagResourceId: dataset.Id,
      tagUpperLevelResourceId: getDatasetUpperLevelResourceId(dataset),
      value: dataset.DataSetName,
      label: dataset.DataSetName,
      clusterIdentifier: dataset.clusterIdentifier ? dataset.clusterIdentifier : null,
      catalogId: dataset.IdInfo.CatalogId,
    };
  };

  const getDataSource = (resourceDetails) => {
    if (resourceDetails.ClusterIdentifier != null) {
      return 'redshift';
    } else if (resourceDetails.ClusterIdentifier == null && resourceDetails.DataAccessRole == null) {
      return 'glue';
    } else {
      return 'glueLF';
    }
  };

  const getSchema = async (database) => {
    try {
      let nextToken = '';
      let schemaList = [];

      while (nextToken != null) {
        try {
          let schemas;
          if (nextToken != '') {
            schemas = await getSchemasForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: database.DataSourceId,
              ClusterIdentifier: database.ClusterIdentifier,
              Region: database.Region,
              NextToken: nextToken,
            });
          } else {
            schemas = await getSchemasForDatabase({
              DatabaseName: database.DatabaseName,
              CatalogId: database.CatalogId,
              DataSourceId: database.DataSourceId,
              ClusterIdentifier: database.ClusterIdentifier,
              Region: database.Region,
            });
          }
          schemaList = schemaList.concat(schemas.SchemaInfoList);
          nextToken = schemas.NextToken;
        } catch (err) {
          console.log('Exception when fetch schemas for database', err);
          break;
        }
      }

      setSchemas(
        schemaList.map((schemaItem) => {
          return buildSchemaResource(schemaItem);
        }),
      );
    } catch (err) {
      console.log('Exception when fetch schemas', err);
    }
  };

  const getDatabases = async (catalog) => {
    try {
      let request = {
        NextToken: null,
        DatabaseKeyList: [
          {
            CatalogId: catalog.CatalogId,
            Region: catalog.Region,
            DataSourceId: catalog.DataSourceId,
            ClusterIdentifier: catalog.ClusterIdentifier,
            RedshiftWorkgroupName: catalog.RedshiftWorkgroupName,
          },
        ],
      };

      let databases = await listDatabases(request);
      let databaseInfoList = databases.DatabaseInfoList;

      while (databases.NextToken != null) {
        request.NextToken = databases.NextToken;
        databases = await listDatabases(request);
        databaseInfoList.push(...databases.DatabaseInfoList);
      }
      let databasesInCatalog = databaseInfoList.map((database) => {
        return {
          CatalogId: database.CatalogId,
          DatabaseName: database.DatabaseName,
          Region: database.Region,
          DataSourceId: catalog.DataSourceId,
          ClusterIdentifier: database.ClusterIdentifier,
          DataAccessRole: database.DataAccessRole,
        };
      });

      databasesInCatalog = databasesInCatalog.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.CatalogId === value.CatalogId && t.DatabaseName === value.DatabaseName),
      );
      setDatabases(
        databasesInCatalog.map((databasesInCatalogItem) => {
          return buildDatabaseResource(databasesInCatalogItem);
        }),
      );
    } catch (err) {
      console.log('Exception when fetching databases', err);
    }
  };

  //changes database response parameters to be passed into the fetch consumers API
  const buildDatabaseResource = (database) => {
    const isGlueLF = database.DataSourceId != 'redshift';
    database.value = database.DatabaseName;
    database.label = database.DatabaseName;
    database.dataCatalogObjectDetails = {
      dataSourceId: isGlueLF ? 'GlueLF' : 'Redshift',
      databaseName: database.DatabaseName,
    };
    database.type = 'DATABASE';
    database.accountId = database.CatalogId;
    database.region = database.Region;
    database.dpType = isGlueLF ? DATA_PERMISSION_LAKE_FORMATION_TYPE : DATA_PERMISSION_IAM_TYPE;
    database.description = isGlueLF ? 'GlueLF' : 'Redshift';
    database.dataSourceId = database.DataSourceId;
    return database;
  };

  //changes schema response parameters to be passed into the fetch consumers API
  const buildSchemaResource = (schema) => {
    if (!schema) return {};

    schema.value = schema.Schema;
    schema.label = schema.Schema;
    schema.dataCatalogObjectDetails = {
      dataSourceId: 'Redshift',
      databaseName: schema.DatabaseName,
    };
    schema.schemaName = schema.dataCatalogObjectDetails.Schema;
    schema.type = 'SCHEMA';
    schema.accountId = schema.CatalogId;
    schema.catalogId = schema.CatalogId;
    schema.region = schema.Region;
    schema.dpType = DATA_PERMISSION_REDSHIFT_TYPE;
    schema.tagResourceId = `DS-redshift|A-${schema.CatalogId}|CI-${schema.ClusterIdentifier}|DN-${schema.DatabaseName}|SN-${schema.Schema}`;

    return schema;
  };

  const generateCatalogId = (catalog) => {
    const dataSource = catalog.DataSourceId ? catalog.DataSourceId : getDataSource(catalog);
    if (dataSource == 'redshift') {
      return `DS-${dataSource}|A-${catalog.CatalogId}|CI-${catalog.ClusterIdentifier}|R-${catalog.Region}`;
    } else {
      return `DS-${dataSource}|A-${catalog.CatalogId}|R-${catalog.Region}`;
    }
  };

  const generateDatasetId = (dataset) => {
    return dataset.Id;
  };

  const generateSchemaId = (schema) => {
    const dataSource = schema.DataSourceId ? schema.DataSourceId : getDataSource(schema);
    return `DS-${dataSource}|A-${schema.CatalogId}|CI-${schema.ClusterIdentifier}|DN-${schema.DatabaseName}|SN-${schema.Schema}|R-${schema.Region}`;
  };

  const generateDatabaseId = (database) => {
    const dataSource = database.DataSourceId ? database.DataSourceId : getDataSource(database);
    if (dataSource == 'redshift') {
      return `DS-${dataSource}|A-${database.CatalogId}|CI-${database.ClusterIdentifier}|DN-${database.DatabaseName}|R-${database.Region}`;
    } else {
      return `DS-${dataSource}|A-${database.CatalogId}|DN-${database.DatabaseName}|R-${database.Region}`;
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header description='You can create and send notifications here.' variant='h1'>
            Create new notification
          </Header>
        }
        actions={
          <div>
            <Button variant='link' onClick={() => setRedirect(Page.NOTIFICATIONS)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleCreate} loading={buttonCreateLoading}>
              Create
            </Button>
          </div>
        }
      >
        <Container header={<Header variant={'h2'}>Notification details</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <>
                <FormField label={<div>Subject</div>} description='The subject of the notification.'>
                  <Input
                    onChange={(event) => setSubject(event.detail.value)}
                    placeholder='Please input notification subject'
                    ariaRequired={true}
                    value={subject}
                  />
                </FormField>{' '}
                <FormField
                  label={<div>Notification template</div>}
                  description='Please select notification template to use.'
                >
                  <Select
                    selectedAriaLabel='Selected'
                    options={templates}
                    onChange={(e) => setContentAccordingToTemplate(e)}
                    placeholder='Please select the notification content template that you want to use'
                    errorText='Error fetching type.'
                    recoveryText='Retry'
                    empty={'No types available'}
                    filteringType='auto'
                    filteringAriaLabel='Filter origins'
                    ariaRequired={true}
                    statusType={datasetsStatusType}
                    loadingText='Loading templates...'
                    selectedOption={selectedTemplate}
                  />
                </FormField>
                <Link to={Page.NOTIFICATION_TEMPLATES}>Manage notification templates</Link>{' '}
                <FormField label={<div>Content</div>} description='The content of the notification to be sent.'>
                  <Textarea
                    name='content'
                    placeholder='Please input notification content'
                    value={content}
                    ariaRequired={true}
                    onChange={(e) => setContent(e.detail.value)}
                    rows={10}
                  />
                </FormField>{' '}
                <FormField label={<div>Priority</div>} description='Notification priority level'>
                  <Select
                    selectedAriaLabel='Selected'
                    options={[
                      {
                        value: 'LOW',
                        label: 'Low',
                      },
                      {
                        value: 'MEDIUM',
                        label: 'Medium',
                      },
                      {
                        value: 'HIGH',
                        label: 'High',
                      },
                    ]}
                    onChange={(event) => setPriorityLevel(event.detail.selectedOption)}
                    placeholder='Please select notification priority'
                    errorText='Error fetching type.'
                    recoveryText='Retry'
                    empty={'No types available'}
                    filteringType='auto'
                    filteringAriaLabel='Filter origins'
                    ariaRequired={true}
                    selectedOption={priorityLevel}
                  />
                </FormField>{' '}
                <FormField
                  label={<div>Resource</div>}
                  description='Choose whether the notification is for a dataset or database.'
                >
                  <Select
                    selectedAriaLabel='Selected'
                    options={[
                      {
                        value: 'DATASET',
                        label: 'Table',
                      },
                      {
                        value: 'DATABASE',
                        label: 'Database',
                      },

                      {
                        value: 'SCHEMA',
                        label: 'Schema',
                      },
                    ]}
                    onChange={(event) => handleResourceTypeOnClick(event)}
                    placeholder='Please select resource type'
                    errorText='Error fetching type.'
                    recoveryText='Retry'
                    empty={'No types available'}
                    filteringType='auto'
                    filteringAriaLabel='Filter origins'
                    ariaRequired={true}
                    selectedOption={resourceType}
                  />
                </FormField>{' '}
                {resourceType != null &&
                  (resourceType.label == 'Catalog' ||
                    resourceType.label == 'Database' ||
                    resourceType.label == 'Table' ||
                    resourceType.label == 'Schema') && (
                    <>
                      <FormField label={<div>Catalog</div>} description={<div>Select the catalog.</div>}>
                        <Select
                          selectedAriaLabel='Selected'
                          options={catalogs}
                          onChange={(event) => {
                            setSelectedCatalog(event.detail.selectedOption);
                            getDatabases(event.detail.selectedOption.value);
                            if (resourceType.label.includes(TEMPLATE_CATALOG_RESOURCE_TYPE)) {
                              generateCatalogId(event.detail.selectedOption.value);
                            }
                            setSchemasInfo(null);
                            setSelectedTable(null);
                          }}
                          placeholder='Please select the catalog Id'
                          errorText='Error fetching type.'
                          recoveryText='Retry'
                          empty={'No types available'}
                          filteringType='auto'
                          filteringAriaLabel='Filter origins'
                          ariaRequired={true}
                          selectedOption={selectedCatalog}
                        />
                      </FormField>
                    </>
                  )}{' '}
                {resourceType != null &&
                  selectedCatalog != null &&
                  (resourceType.label == 'Database' ||
                    resourceType.label == 'Table' ||
                    resourceType.label == 'Schema') && (
                    <>
                      <FormField label={<div>Database</div>} description={<div>Select the database.</div>}>
                        <Select
                          selectedAriaLabel='Selected'
                          onChange={(event) => {
                            setSelectedDatabase(event.detail.selectedOption);
                            setSchemasInfo(null);
                            setSelectedTable(null);
                            if (event.detail.selectedOption.description.includes(TEMPLATE_REDSHIFT)) {
                              generateDatabaseId(event.detail.selectedOption.value);
                              getSchema(event.detail.selectedOption);
                            } else {
                              getTables(event.detail.selectedOption);
                            }
                          }}
                          options={databases}
                          placeholder='Please select the database name'
                          errorText='Error fetching type.'
                          recoveryText='Retry'
                          empty={'No types available'}
                          filteringType='auto'
                          filteringAriaLabel='Filter origins'
                          ariaRequired={true}
                          selectedOption={selectedDatabase}
                        />
                      </FormField>
                    </>
                  )}{' '}
                {selectedDatabase != null &&
                  selectedDatabase.DataSourceId == 'redshift' &&
                  (resourceType.label == 'Schema' || resourceType.label == 'Table') && (
                    <>
                      <FormField label={<div>Schema</div>} description={<div>Select the schema.</div>}>
                        <Select
                          selectedAriaLabel='Selected'
                          options={schemas}
                          onChange={(event) => {
                            setSchemasInfo(event.detail.selectedOption);
                            setSelectedTable(null);
                            if (resourceType.label.includes(TEMPLATE_SCHEMA_RESOURCE_TYPE)) {
                              generateSchemaId(event.detail.selectedOption);
                            }
                            getTables(event.detail.selectedOption);
                          }}
                          placeholder='Please select the schema name'
                          errorText='Error fetching type.'
                          recoveryText='Retry'
                          empty={'No types available'}
                          filteringType='auto'
                          filteringAriaLabel='Filter origins'
                          ariaRequired={true}
                          selectedOption={schemasInfo}
                        />
                      </FormField>
                    </>
                  )}{' '}
                {selectedDatabase != null &&
                  (selectedDatabase.DataSourceId == 'redshift' ? schemasInfo != null : schemasInfo == null) &&
                  resourceType.label == 'Table' && (
                    <FormField label={<div>Table</div>} description={<div>Select the table.</div>}>
                      <Select
                        selectedAriaLabel='Selected'
                        options={tables}
                        onChange={(event) => {
                          setSelectedTable(event.detail.selectedOption);
                          if (resourceType.label.includes(TEMPLATE_TABLE_RESOURCE_TYPE)) {
                            generateDatasetId(event.detail.selectedOption.value);
                          }
                        }}
                        placeholder='Please select the table name'
                        errorText='Error fetching type.'
                        recoveryText='Retry'
                        empty={'No types available'}
                        filteringType='auto'
                        filteringAriaLabel='Filter origins'
                        ariaRequired={true}
                        selectedOption={selectedTable}
                      />
                    </FormField>
                  )}
              </>{' '}
              <>
                <FormField label='Confirm resource'>
                  <Button variant='primary' onClick={fetchConsumers} loading={buttonConfirmLoading}>
                    Confirm
                  </Button>
                </FormField>
              </>{' '}
              <FormField
                label={<div>Select consumers</div>}
                description='Please select and edit the consuming groups and workspaces you want to send the notification to.'
              >
                <Multiselect
                  options={allConsumers}
                  selectedOptions={deselected ? selectedConsumers : allConsumers}
                  onChange={({ detail }) => {
                    setDeselection(true);
                    setSelectedConsumers(detail.selectedOptions as OptionDefinition[]);
                  }}
                  deselectAriaLabel={(e) => `Remove ${e.label}`}
                  placeholder='Please select receivers who consume the resource'
                  selectedAriaLabel='Selected'
                  filteringType='auto'
                  filteringAriaLabel='Filter origins'
                />
              </FormField>
              <>
                <FormField
                  label='Send Email'
                  description={'Send email(s) to the selected group(s) from email ' + getEmailAddress()}
                >
                  <Toggle onChange={(e) => setSendEmail(e.detail.checked)} checked={sendEmail} />
                </FormField>
              </>
            </div>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
