import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  TextFilter,
  Pagination,
  CollectionPreferences,
  CollectionPreferencesProps,
  Table,
  Header,
  StatusIndicator,
} from '@amzn/awsui-components-react-v3/polaris';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { EmptyState } from 'src/commons/EmptyState';
import { defaultWrapLinesPreference, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  DATAFI_DQ_TYPE,
  DQ_RULE_FAIL_STATUS,
  DQ_RULE_PASS_STATUS,
} from 'src/components/catalog/dataquality/dqConstants';

export interface DataQualityTrendsTableProps {
  dataQualityMetrics: any;
}

export const DataQualityTrendsTable = (props: DataQualityTrendsTableProps) => {
  const [tableMessage] = useState('No data quality trends');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: true,
    pageSize: 25,
  });
  const [columnDefinitions, setColumnDefinitions] = useState([]);
  const datafiTrendsColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => (item.trendID ? item.trendID : '-'),
      minWidth: 200,
      width: 250,
    },
    {
      id: 'metricDefinition',
      header: 'Metric definition',
      cell: (item) => (item.metric ? item.metric : '-'),
      minWidth: 250,
      width: 300,
    },
    {
      id: 'result',
      header: 'Result',
      cell: (item) => {
        switch (item?.result) {
          case DQ_RULE_PASS_STATUS:
            return <StatusIndicator>Rule passed</StatusIndicator>;
          case DQ_RULE_FAIL_STATUS:
            return <StatusIndicator type={'error'}>Rule failed</StatusIndicator>;
          default:
            return item?.result;
        }
      },
      minWidth: 150,
      width: 150,
      sortingField: 'result',
    },
    {
      id: 'groupByKeys',
      header: 'Groupby keys',
      cell: (item) => (item.grouped ? item.grouped.join(', ') : '-'),
      minWidth: 150,
      width: 150,
      sortingField: 'groupByKeys',
    },
    {
      id: 'groupByValues',
      header: 'Groupby values',
      cell: (item) => (item.groupingValues ? item.groupingValues : '-'),
      minWidth: 150,
      width: 150,
      sortingField: 'groupByValues',
    },
    {
      id: 'evaluationCriteria',
      header: 'Evaluation criteria',
      cell: (item) => (item.trendAnalyzer ? item.trendAnalyzer : '-'),
      minWidth: 150,
      width: 150,
      sortingField: 'evaluationCriteria',
    },
    {
      id: 'sampleSize',
      header: 'Sample size',
      cell: (item) => (item.numMetrics ? item.numMetrics : '-'),
      minWidth: 150,
      width: 150,
      sortingField: 'sampleSize',
    },
    {
      id: 'evaluatedMetrics',
      header: 'Evaluated metrics',
      cell: (item) => (item.metrics ? item.metrics.join(', ') : '-'),
      minWidth: 250,
      width: 300,
    },
    {
      id: 'evaluationMessage',
      header: 'Evaluation message',
      cell: (item) => (item.message ? item.message : '-'),
      minWidth: 250,
      width: 300,
    },
  ];

  const getDQTrendsFromDataQualityMetric = (dataQualityMetric) => {
    try {
      if (dataQualityMetric.Type === DATAFI_DQ_TYPE) {
        return dataQualityMetric.Data.trends;
      }
      return [];
    } catch (e) {
      return [];
    }
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    getDQTrendsFromDataQualityMetric(props.dataQualityMetrics),
    {
      filtering: {
        empty: <EmptyState title={tableMessage} subtitle='No data quality trends to display.' />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  const setColumnDefinitionsFromType = (dataQualityType: string) => {
    switch (dataQualityType) {
      case DATAFI_DQ_TYPE:
        setColumnDefinitions(datafiTrendsColumnDefinitions);
        break;
      default:
        setColumnDefinitions(null);
    }
  };

  useEffect(() => {
    setColumnDefinitionsFromType(props.dataQualityMetrics.Type);
  }, [props.dataQualityMetrics]);

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <Header
            counter={`(${getDQTrendsFromDataQualityMetric(props.dataQualityMetrics).length})`}
            description='Data quality trends from recent runs'
          >
            Data quality trends
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter data quality by rules or data quality results'
            filteringPlaceholder='Filter data quality by rules or data quality results'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        empty={<EmptyState title={tableMessage} subtitle='No data quality trends to display.' />}
      />
    </>
  );
};
