import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  FlashbarProps,
  Header,
  Modal,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { getSubscription, deleteSubscription } from 'src/api/catalog';
import { labeledTextFromList, RMPageHeader } from '../../resourcesmanager/components';
import { dateString } from '../../resourcesmanager/helpers';
import { CARDS_CONTENT_TYPE } from 'src/commons/constants';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';

export interface eventSubscriptionDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

export const EventSubscriptionDetails = (props: eventSubscriptionDetailProps) => {
  const [loading, setLoading] = useState(true);
  const [eventSubscription, setEventSubscription] = useState(null);
  const [unsubscribeModalVisible, setUnsubscribeModalVisible] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    props.setContentType(CARDS_CONTENT_TYPE);
    handleRefresh();
  }, [props.activeGroup, props.activeWorkspace]);

  /**
   * Asynchronously fetches the eventsubscriptions from the HC API and updates the state.
   */
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const response = await getSubscription({
        Id: props.match.params.id,
      });
      setEventSubscription(response.Subscription);
      setLoading(false);
    } catch (err) {
      setEventSubscription(null);
      setLoading(false);
    }
  };

  const openDeleteModal = () => {
    setUnsubscribeModalVisible(true);
  };

  const getInfoFromSubscriptions = (subscription, infoName) => {
    if (!subscription) return null;
    switch (infoName) {
      case 'ownerIdLink':
        if (subscription.OwnerId.startsWith('wks-')) return `/search/workspaces/${subscription.OwnerId}`;
        else return `/groups/${subscription.OwnerId}`;
    }
  };

  const handleDeleteSubscription = async () => {
    try {
      setIsAction(true);
      await deleteSubscription({ Id: eventSubscription.Id });
    } catch (err) {
      setIsAction(false);
      setUnsubscribeModalVisible(false);
      setNotifications([
        {
          type: 'error' as FlashbarProps.Type,
          content: `Failed to delete subscription.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      console.error(err);
    }
    setIsAction(false);
    setUnsubscribeModalVisible(false);
    setNotifications([
      {
        type: 'success' as FlashbarProps.Type,
        content: `Successfully deleted subscription.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
    await handleRefresh();
  };

  return (
    <>
      <Flashbar items={notifications} />

      <Modal
        visible={unsubscribeModalVisible}
        header={[`Unsubscribe?`]}
        onDismiss={() => {
          setUnsubscribeModalVisible(false);
          setIsAction(false);
        }}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  setUnsubscribeModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' loading={isAction} onClick={handleDeleteSubscription}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <TextContent>
          Are you sure you want to unsubscribe from events for the resource:{' '}
          <strong>{eventSubscription?.ResourceId}</strong> for <strong>{eventSubscription?.SubscriberId}</strong>{' '}
          account?
          <br />
          <br />
          <p>
            This account will no longer receive events for the resource, which can cause issues with any dependencies on
            these events.
          </p>
        </TextContent>
      </Modal>

      <SpaceBetween size='l'>
        <RMPageHeader
          buttons={[
            {
              text: 'Unsubscribe',
              onItemClick: openDeleteModal,
              disabled:
                eventSubscription?.Status === 'InActive' ||
                props.activeWorkspace?.workspaceId !== eventSubscription?.OwnerId,
            },
          ]}
          subheader={eventSubscription?.name}
        />

        <Container header={<Header variant='h2'> Event Subscription details </Header>}>
          <ColumnLayout columns={2} borders='horizontal'>
            {labeledTextFromList(
              [
                {
                  label: 'Event Subscription ID',
                  value: eventSubscription?.Id,
                  copiable: true,
                },
                {
                  label: 'Resource ID',
                  value: eventSubscription?.ResourceId,
                  copiable: true,
                },
                {
                  label: 'Subscriber ID',
                  value: eventSubscription?.SubscriberId,
                  copiable: true,
                },
                {
                  label: 'Owner (Group ID)',
                  value: getInfoFromSubscriptions(eventSubscription, 'ownerIdLink') ? (
                    <Link to={getInfoFromSubscriptions(eventSubscription, 'ownerIdLink')}>
                      {eventSubscription?.OwnerId}
                    </Link>
                  ) : (
                    eventSubscription?.OwnerId
                  ),
                  copiable: true,
                },
                {
                  label: 'Type',
                  value: eventSubscription?.Type,
                },
                {
                  label: 'Status',
                  value: <StatusIcon status={eventSubscription?.Status} />,
                },
                {
                  label: 'Created by',
                  value: eventSubscription?.CreatedBy,
                  copiable: true,
                },
                {
                  label: 'Created on',
                  value: dateString(eventSubscription?.CreatedOn),
                },
                {
                  label: 'Updated by',
                  value: eventSubscription?.UpdatedBy,
                  copiable: true,
                },
                {
                  label: 'Updated on',
                  value: dateString(eventSubscription?.UpdatedOn),
                },
                {
                  label: 'KMS key ARN',
                  value: eventSubscription?.EventMetadata?.EventKMSKeyARN,
                  copiable: true,
                },
                {
                  label: 'SNS topic ARN',
                  value: eventSubscription?.EventMetadata?.EventSNSTopicARN,
                  copiable: true,
                },
              ],
              loading,
            )}
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </>
  );
};
