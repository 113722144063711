import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Container,
  Input,
  Header,
  Textarea,
  SpaceBetween,
  Select,
  ExpandableSection,
  Toggle,
  Link as LinkV3,
  Link,
} from '@amzn/awsui-components-react-v3';
import { createTemplate } from '../../api/permissions';
import { Redirect } from 'react-router-dom';
import { scrollUp } from '../utils/navigation';
import * as validate from '../../commons/validationUtils';
import { Page } from '../../routes/Paths';
import { validateApprovalUrl, validateTemplateUrl } from '../../commons/validationUtils';
import { FORM_CONTENT_TYPE } from 'src/commons/constants';
import { getAmazonApprovalURL, isApprovalsServiceAvailable } from 'src/api/config';

export interface CreateTemplateComponentProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  toggleHelp: any;
}

export const CreateTemplateComponent = (props: CreateTemplateComponentProps) => {
  const [notifications, setNotifications] = useState([]);
  const [, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const [description, setDescription] = useState(null);
  const [simTemplateName, setSimTemplateName] = useState(null);
  const [simTemplateLink, setSimTemplateLink] = useState(null);
  const [redirect, setRedirect] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [templateType, setTemplateType] = useState(null);
  const [approvalTemplateLink, setApprovalTemplateLink] = useState(null);
  const [approvalTemplateTitle, setApprovalTemplateTitle] = useState(null);
  const [autoApproval, setAutoApproval] = useState(false);
  const [allowMergeApprovalWorkflow, setAllowMergeApprovalWorkflow] = useState(false);

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
  }, []);

  const handleConfirm = async () => {
    setLoading(true);
    let accessManagementInfo = {};
    if (simTemplateName && simTemplateName.length > 0) {
      accessManagementInfo['simTemplates'] = [
        {
          simTemplateLink: simTemplateLink,
          simTemplateName: simTemplateName,
        },
      ];
    }
    if (approvalTemplateLink && approvalTemplateLink.length > 0) {
      accessManagementInfo['approvalsWorkflowTemplate'] = {
        approvalTemplateLink: approvalTemplateLink,
        approvalTemplateTitle: approvalTemplateTitle,
        autoApproval: autoApproval,
        allowMergeApprovalWorkflow: allowMergeApprovalWorkflow,
      };
    }
    let request = {
      name: templateName,
      description: description,
      ownerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
      accessManagementInfo: accessManagementInfo,
    };
    try {
      const createResponse = await createTemplate(request);
      const status = createResponse.message == 'Success' ? 'success' : 'error';
      setButtonLoading(false);
      setRedirect({
        pathname: Page.TEMPLATES,
        state: {
          status,
          message: `Template created successfully with template id: ${createResponse.templateId}`,
        },
      });
    } catch (e) {
      setNotifications([
        {
          header: 'error',
          type: 'error',
          content: e.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    setLoading(false);
    scrollUp();
  };

  const allFieldsSet = () => {
    return (
      validate.isNotFalsy(templateName) &&
      validate.isNotFalsy(description) &&
      ((validate.isNotFalsy(simTemplateName) && validate.isNotFalsy(simTemplateLink)) ||
        (validate.isNotFalsy(approvalTemplateTitle) && validate.isNotFalsy(approvalTemplateLink)))
    );
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header
            variant='h1'
            description='Please specify the details of the template.'
            info={
              <LinkV3 variant='info' onFollow={props.toggleHelp}>
                {' '}
                Info{' '}
              </LinkV3>
            }
          >
            Create new template
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.TEMPLATES)}>
              Cancel
            </Button>
            <Button variant='primary' loading={buttonLoading} onClick={handleConfirm} disabled={!allFieldsSet()}>
              Create template
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header variant={'h2'}>Template details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Name</div>} description='Name of the template'>
                <Input
                  name='template-details'
                  ariaRequired={true}
                  onChange={(event) => setTemplateName(event.detail.value)}
                  invalid={templateName !== null && validate.isFalsy(templateName)}
                  value={templateName}
                />
              </FormField>
              <FormField label={'Description'} description='A brief description about the template.'>
                <Textarea
                  onChange={({ detail }) => setDescription(detail.value)}
                  value={description}
                  invalid={description !== null && validate.isFalsy(description)}
                />
              </FormField>
              <FormField
                label={<div>Template type</div>}
                description='The type of template to be created. Currently only access management template is supported.'
              >
                <Select
                  selectedOption={templateType}
                  onChange={({ detail }) => setTemplateType(detail.selectedOption)}
                  options={[{ label: 'Access Management', value: 'Access management' }]}
                  selectedAriaLabel='Selected'
                  placeholder='please select the template type'
                  errorText='Error fetching type.'
                  recoveryText='Retry'
                  empty={'No types available'}
                  filteringType='auto'
                  filteringAriaLabel='Filter origins'
                  ariaRequired={true}
                />
              </FormField>

              {templateType != null && templateType.label == 'Access Management' && (
                <>
                  <ExpandableSection defaultExpanded headerText='SIM template'>
                    <FormField
                      label={<div>SIM template name</div>}
                      description='A name to associate with the template URL.'
                    >
                      <Input
                        name='template-details'
                        placeholder='Name of the SIM template'
                        ariaRequired={true}
                        onChange={(event) => setSimTemplateName(event.detail.value)}
                        invalid={simTemplateName !== null && validate.isFalsy(simTemplateName)}
                        value={simTemplateName}
                      />
                    </FormField>
                    <br />
                    <FormField
                      label={'SIM template link '}
                      description='A link to fill out a ticket using this template.'
                    >
                      <Input
                        onChange={({ detail }) => setSimTemplateLink(detail.value.trim())}
                        ariaRequired={true}
                        value={simTemplateLink}
                        placeholder='https://sim.amazon.com/123456789'
                        invalid={simTemplateLink != null && validateTemplateUrl(simTemplateLink) == false}
                      />
                    </FormField>
                  </ExpandableSection>
                  {isApprovalsServiceAvailable() && (
                    <ExpandableSection defaultExpanded headerText='Approval template'>
                      <FormField
                        label={'Approval template title '}
                        description='A title of the approval template for use on Omni.'
                      >
                        <Input
                          onChange={({ detail }) => setApprovalTemplateTitle(detail.value)}
                          ariaRequired={true}
                          value={approvalTemplateTitle}
                          placeholder='My approval template'
                          invalid={approvalTemplateTitle != null && validate.isFalsy(approvalTemplateTitle)}
                        />
                      </FormField>
                      <br />
                      <FormField
                        label={<div>Approval template link</div>}
                        description={
                          <div>
                            A link to create the approval workflow from a template. Click&nbsp;
                            <Link external href={getAmazonApprovalURL()}>
                              here
                            </Link>{' '}
                            to create approval template{' '}
                          </div>
                        }
                      >
                        <Input
                          name='template-details'
                          placeholder='https://approvals.amazon.com/TemplateDetails/1234'
                          ariaRequired={true}
                          onChange={(event) => setApprovalTemplateLink(event.detail.value.trim())}
                          invalid={approvalTemplateLink !== null && validateApprovalUrl(approvalTemplateLink) == false}
                          value={approvalTemplateLink}
                        />
                      </FormField>
                      <br />
                      <Toggle
                        onChange={({ detail }) => setAutoApproval(detail.checked)}
                        checked={autoApproval}
                        description='Automatically approve or deny requests in Omni based on the approval workflow status.'
                      >
                        Auto approval
                      </Toggle>
                      <br />
                      <Toggle
                        onChange={({ detail }) => setAllowMergeApprovalWorkflow(detail.checked)}
                        checked={allowMergeApprovalWorkflow}
                        description='Allow merging approval workflows. If enabled, the request will be merged to the
                           existing approval workflow. If disabled, will create a new approval workflow.'
                      >
                        Allow merge workflows
                      </Toggle>
                    </ExpandableSection>
                  )}
                </>
              )}
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
