import { Alert } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

interface ResourceNotFoundProps {
  title: string;
  subtitle: string;
}

export const ResourceNotFound = ({ title, subtitle }: ResourceNotFoundProps) => (
  <Alert type={'error'} header={title}>
    {subtitle}
  </Alert>
);
