import { Box, Button, Modal, ModalProps, SpaceBetween, TextContent } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { pop } from './helpers';

interface iClosingModal extends ModalProps {
  onClose(): void;

  action?(): void;
}

export const UnauthorizedModal = ({
  header = 'Insufficient permission',
  closeAriaLabel = 'Close modal',
  ...props
}: iClosingModal) => {
  const content = pop(
    props,
    'content',
    <TextContent>
      'You are not authorized to perform the operations on this service! You haven\'t onboarded please onboard' + ' to
      MetricStore.'
    </TextContent>,
  );

  const footer = (
    <Box float='right'>
      <SpaceBetween direction='horizontal' size='xs'>
        <Button variant='primary' onClick={props.onClose}>
          Okay
        </Button>
      </SpaceBetween>
    </Box>
  );

  return (
    <Modal footer={footer} header={header} closeAriaLabel={closeAriaLabel} onDismiss={props.onClose} {...props}>
      {content}{' '}
      <a href='https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/MetricStore/UserGuide/'>
        Onboarding steps
      </a>
    </Modal>
  );
};
