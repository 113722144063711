import { RegisterDataSetRequest, RegisterContractVersionRequest } from 'aws-sdk/clients/tethyscontractservicelambda';
import { getDataset, updateDataset } from '../../../api/ingestion';
import { iRegisterDataset } from './index';

import { Button } from '@amzn/awsui-components-react-v3';
import { scrollUp } from '../../utils/navigation';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Page } from 'src/routes';
import * as React from 'react';

import { iTethysRouteProps } from '../common/types';
import { getDataSetId } from '../common/utils';
import { WIZARD_CONTENT_TYPE } from 'src/commons/constants';
import { initialRegisterDataSetRequest, region } from './utils/requestPayload';
import { validateStep } from './utils/validation';
import { TethysWizard } from './utils/wizard';
import { docs } from '../common/constants';

interface iUpdateContract extends iRegisterDataset {
  params: iTethysRouteProps;
}

export const UpdateContract = ({
  params,
  username,
  activeGroup,
  toggleHelp,
  setContentType,
  setNotifications,
}: iUpdateContract) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [validationStep, setValidationStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState<string>();
  const [id] = useState<string>(getDataSetId(params));
  const [isJsonValid, setJsonValid] = useState(true); // Used to validate schema on Step 3.

  useEffect(() => {
    setContentType(WIZARD_CONTENT_TYPE);
    fetchDataset();
  }, []);

  const fetchDataset = async () => {
    try {
      setLoading(true);
      const dataset = await getDataset({ DataSetId: id }); // TODO: Add Catch with Flashbar.
      setRequest({
        // Creating object as getDatasetResponse type is not compatible with updateDatasetRequest.
        TableName: dataset.TableName,
        DataSetName: dataset.TableName,
        DataSource: dataset.DataSource,
        DatabaseName: dataset.DatabaseName,
        CatalogId: dataset.CatalogId,
        DataContract: dataset.DataContract,
        PrimaryOwner: dataset.PrimaryOwner,
        CreatedBy: dataset.CreatedBy,
        Region: region, // TODO: Include attribute on API.
        RegistrationSource: 'OMNI_WIZARD',
      });
      setLoading(false);
    } catch (e) {
      setNotifications([
        {
          type: 'error',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: 'Error retrieving data contract.',
          action: (
            <Button onClick={() => window.open(docs.oncall, '_blank', 'noopener,noreferrer')}> Contact oncall </Button>
          ),
        },
      ]);
    }
  };

  const [request, setRequest] = useState<RegisterDataSetRequest>({
    ...initialRegisterDataSetRequest,
    CreatedBy: username, // TODO: This should not be part of the update request.
    PrimaryOwner: activeGroup, // This should be fetched from Gladstone when performing the request.
  });

  const nextStep = (step: number) => {
    setValidationStep(step);
    const isForwardStep = step > activeStepIndex;
    if (!isForwardStep) setActiveStepIndex(step);

    const isStepValid = validateStep(step, request, false); // TODO: set input as object.
    if (!isStepValid) return; // For forward steps, only advance if payload valid.
    if (activeStepIndex === 2 && !isJsonValid) return; // Schema step, isJsonValid determined by ACE

    setActiveStepIndex(step);
  };

  const navigate = () => {
    setNotifications([]);
    setRedirect(Page.PUBLISHER_TABLES);
  };

  const submit = async () => {
    setLoading(true);
    const payload: RegisterContractVersionRequest = {
      DataSetId: id,
      DataContract: request.DataContract,
    };

    try {
      await updateDataset(payload);
      setNotifications([
        {
          type: 'success',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: 'Data contract updated successfully.',
          action: <Button onClick={navigate}>Visit your datasets</Button>,
        },
      ]);
    } catch (e) {
      setNotifications([
        {
          type: 'error',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: e.message,
        },
      ]);
      setLoading(false);
    }
    scrollUp();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <TethysWizard
      isUpdate
      loading={loading}
      request={request}
      validationStep={validationStep}
      activeStepIndex={activeStepIndex}
      setActiveStepIndex={setActiveStepIndex}
      setJsonValid={setJsonValid}
      setRedirect={setRedirect}
      setRequest={setRequest}
      toggleHelp={toggleHelp}
      nextStep={nextStep}
      submit={submit}
    />
  );
};
