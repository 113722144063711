import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Alert,
  AlertProps,
  AttributeEditor,
  Box,
  Button,
  Cards,
  CollectionPreferencesProps,
  Container,
  Input,
  Modal,
  NonCancelableCustomEvent,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { CollectionPreferences, Header, Pagination, SpaceBetween } from '@amzn/awsui-components-react-v3/polaris';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  ARIA_LABELS,
  getFilterCounterText,
  PAGINATION_ARIA_LABELS,
  TABLE_EMPTY_STATE,
  TABLE_NO_MATCH_STATE,
} from 'src/components/permissions/myBaselining/baseliningUtils';
import { BaseChangeDetail } from '@amzn/awsui-components-react-v3/polaris/input/interfaces';
import { editGroup, editWorkspace, listRoles } from 'src/api/permissions';
import { isValidUser } from 'src/commons/validationUtils';
import * as validate from 'src/commons/validationUtils';
import { getGroupMechanismIdLink, getPhoneToolLink, getRequestSubmittedWithAutoApproval } from 'src/commons/common';
import { scrollUp } from 'src/components/utils/navigation';

export interface ManageRolesProps {
  ownerId: string;
  roleMapping: any;
  allowAdd?: boolean;
  allowEdit?: boolean;
  allowRemove?: boolean;
  headerText?: string;
  description?: string;
}

export const ManageRoles = ({
  allowAdd = true,
  allowEdit = true,
  allowRemove = true,
  description,
  headerText,
  ownerId,
  roleMapping,
}: ManageRolesProps) => {
  const CARD_DEFINITIONS = {
    header: (item) => getInfo(item, 'cardHeader'),
    sections: [
      {
        id: 'description',
        header: 'Description',
        content: (item) => getInfo(item, 'description'),
      },
      {
        id: 'users',
        header: 'Users',
        content: (item) => getInfo(item, 'users'),
      },
      {
        id: 'groupingIds',
        header: 'Grouping IDs',
        content: (item) => getInfo(item, 'groupingIds'),
      },
    ],
  };

  const VISIBLE_CONTENT_OPTIONS = [
    {
      label: 'Role properties',
      options: [
        { id: 'description', label: 'Description' },
        { id: 'users', label: 'Users' },
        { id: 'groupingIds', label: 'Grouping IDs' },
      ],
    },
  ];

  const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 roles' },
    { value: 20, label: '20 roles' },
  ];

  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    visibleContent: ['description', 'users', 'groupingIds'],
  };

  const DEFAULT_HEADER_TEXT = 'Omni roles';
  const DEFAULT_DESCRIPTION =
    'These roles are created by Omni, and default Omni permissions have been defined ' +
    'to help you manage Omni permissions for your group or workspace members. ' +
    'Omni permissions define a collection of operations that a user can perform from Omni UI.';

  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingEditRole, setLoadingEditRole] = useState(false);
  const [users, setUsers] = useState([]);
  const [groupingIds, setGroupingIds] = useState([]);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success' as AlertProps.Type);
  const [modalVisible, setModalVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(roles, {
    filtering: {
      empty: <TABLE_EMPTY_STATE resourceName='Role' />,
      noMatch: <TABLE_NO_MATCH_STATE onClearFilter={() => actions.setFiltering('')} />,
    },
    pagination: {
      pageSize: preferences && preferences.pageSize ? preferences.pageSize : 10,
    },
    selection: {},
  });

  useEffect(() => {
    handleRefresh();
  }, [ownerId, roleMapping]);

  const handleRefresh = async () => {
    setLoadingRoles(true);
    if (ownerId && roleMapping) {
      const roles = [];
      try {
        const listRolesResult = await listRoles({ ownerId });
        if (listRolesResult && listRolesResult['roles']) {
          roles.push(...listRolesResult['roles']);
        }
      } catch (e) {
        console.error('Unable to list roles.', e);
      }
      setRoles(roles);
    }
    setLoadingRoles(false);
  };

  const handleEditRole = async () => {
    setModalVisible(true);
    setAlertVisible(false);
    const role = collectionProps.selectedItems[0];
    const roleId = role.roleId;
    const roleMembers = roleId in roleMapping && roleMapping[roleId].roleMembers ? roleMapping[roleId].roleMembers : [];
    const groupingIds =
      roleId in roleMapping && roleMapping[roleId].groupingMechanismIds ? roleMapping[roleId].groupingMechanismIds : [];

    setUsers(roleMembers);
    setGroupingIds(groupingIds);
  };

  const confirmEditRole = async () => {
    setLoadingEditRole(true);

    const invalidUsers = users.filter((user) => !validate.isValidIAMPrincipal(user) && !validate.isValidUser(user));
    if (invalidUsers.length) {
      changeAlert('error', 'Invalid user found: ' + invalidUsers);
      setLoadingEditRole(false);
      return;
    }

    const invalidGroupingIds = groupingIds.filter((id) => !validate.isValidGroupingMechanismId(id));
    if (invalidGroupingIds.length) {
      changeAlert('error', 'Invalid grouping ID found: ' + invalidGroupingIds);
      setLoadingEditRole(false);
      return;
    }

    try {
      const role = collectionProps.selectedItems[0];
      const roleId = role.roleId;
      roleMapping[roleId] = {
        groupingMechanismIds: groupingIds,
        roleMembers: users,
      };
      let successMessage: any = getRequestSubmittedWithAutoApproval();
      if (!ownerId.includes('wks-')) {
        await editGroup({
          groupId: ownerId,
          roleMapping,
        });
      } else {
        await editWorkspace({
          workspaceId: ownerId,
          roleMapping,
        });
        successMessage = `Successfully edited ${role.roleName}.`;
      }
      changeAlert('success', successMessage);
    } catch (e) {
      changeAlert('error', `Error when trying to submit request: ${e.message}`);
      console.error(e);
    }
    scrollUp();
    setLoadingEditRole(false);
  };

  const getInfo = (role, infoName) => {
    if (!role || !roleMapping) return null;

    const roleId = role.roleId;
    const existsInRoleMapping = roleId in roleMapping;
    const roleMembers = existsInRoleMapping && roleMapping[roleId].roleMembers ? roleMapping[roleId].roleMembers : [];
    const groupingIds =
      existsInRoleMapping && roleMapping[roleId].groupingMechanismIds ? roleMapping[roleId].groupingMechanismIds : [];

    switch (infoName) {
      case 'cardHeader':
        return role.roleDisplayName ?? role.roleName;

      case 'description':
        return role.description;

      case 'users':
        if (!roleMembers.length) return <div>-- No users --</div>;
        const users = [];
        for (let member of roleMembers) {
          if (isValidUser(member)) {
            users.unshift(<div key={member}>{getPhoneToolLink(member)}</div>);
          } else {
            users.push(<div>{member}</div>);
          }
        }
        return users;

      case 'groupingIds':
        if (!groupingIds.length) return <div>-- No groups --</div>;
        return groupingIds.map((id: string) => <div>{getGroupMechanismIdLink(id)}</div>);
    }
  };

  const changeAlert = (type, message) => {
    setAlertType(type);
    setAlertVisible(true);
    setAlertMessage(message);
  };

  const getOnUserInputHandler = (e: NonCancelableCustomEvent<BaseChangeDetail>, index: number) => {
    const {
      detail: { value },
    } = e;
    const tmpItems = [...users];
    tmpItems[index] = value;
    setUsers(tmpItems);
  };

  const getOnGroupingIdInputHandler = (e: NonCancelableCustomEvent<BaseChangeDetail>, index: number) => {
    const {
      detail: { value },
    } = e;
    const tmpItems = [...groupingIds];
    tmpItems[index] = value;
    setGroupingIds(tmpItems);
  };

  const getEditRoleModal = () => {
    if (!collectionProps.selectedItems[0]) return;

    const roleName = collectionProps.selectedItems[0].roleName;
    return (
      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        closeAriaLabel='Close modal'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => setModalVisible(false)} disabled={loadingEditRole}>
                Cancel
              </Button>
              <Button variant='primary' onClick={confirmEditRole} loading={loadingEditRole}>
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={roleName}
        size='large'
      >
        <div style={{ marginBottom: '10px' }} id='alert-bar'>
          <Alert type={alertType} dismissible={true} visible={alertVisible} onDismiss={() => setAlertVisible(false)}>
            {alertMessage}
          </Alert>
        </div>

        <SpaceBetween size='xs'>
          <Container header={<Header variant='h3'>Users</Header>}>
            <AttributeEditor
              disableAddButton={!allowAdd || users.length === 100}
              empty='There are no users.'
              addButtonText='Add'
              removeButtonText='Remove'
              isItemRemovable={() => allowRemove}
              items={users}
              definition={[
                {
                  label: 'User',
                  control: (item, index) => (
                    <Input value={item} disabled={!allowEdit} onChange={(e) => getOnUserInputHandler(e, index)} />
                  ),
                },
              ]}
              onAddButtonClick={() => setUsers([...users, ''])}
              onRemoveButtonClick={({ detail: { itemIndex } }) => {
                const tmpItems = [...users];
                tmpItems.splice(itemIndex, 1);
                setUsers(tmpItems);
              }}
            />
          </Container>
          <Container header={<Header variant='h3'>Grouping IDs</Header>}>
            <AttributeEditor
              disableAddButton={!allowAdd || groupingIds.length === 5}
              empty='There are no groupings.'
              addButtonText='Add'
              removeButtonText='Remove'
              isItemRemovable={() => allowRemove}
              items={groupingIds}
              definition={[
                {
                  label: 'Grouping',
                  control: (item, index) => (
                    <Input value={item} disabled={!allowEdit} onChange={(e) => getOnGroupingIdInputHandler(e, index)} />
                  ),
                },
              ]}
              onAddButtonClick={() => setGroupingIds([...groupingIds, ''])}
              onRemoveButtonClick={({ detail: { itemIndex } }) => {
                const tmpItems = [...groupingIds];
                tmpItems.splice(itemIndex, 1);
                setGroupingIds(tmpItems);
              }}
            />
          </Container>
        </SpaceBetween>
      </Modal>
    );
  };

  return (
    <>
      {getEditRoleModal()}
      <Cards
        {...collectionProps}
        stickyHeader={true}
        cardDefinition={CARD_DEFINITIONS}
        visibleSections={preferences.visibleContent}
        cardsPerRow={[
          {
            cards: 1,
          },
          {
            minWidth: 1025,
            cards: 2,
          },
        ]}
        loading={loadingRoles}
        loadingText='Loading roles'
        items={items}
        selectionType='single'
        variant='full-page'
        ariaLabels={ARIA_LABELS}
        header={
          <Header
            variant='awsui-h1-sticky'
            description={description ?? DEFAULT_DESCRIPTION}
            actions={
              <Button disabled={collectionProps.selectedItems.length === 0} onClick={handleEditRole}>
                Edit
              </Button>
            }
          >
            {headerText || DEFAULT_HEADER_TEXT}
          </Header>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter roles'
            filteringPlaceholder='Find roles'
            countText={getFilterCounterText(filteredItemsCount)}
            disabled={loadingRoles}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={PAGINATION_ARIA_LABELS(paginationProps.pagesCount)}
            disabled={loadingRoles}
          />
        }
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            disabled={loadingRoles}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SIZE_OPTIONS,
            }}
            visibleContentPreference={{
              title: 'Select visible columns',
              options: VISIBLE_CONTENT_OPTIONS,
            }}
          />
        }
      />
    </>
  );
};
