import { Button, ButtonDropdown, Header, HeaderProps, SpaceBetween } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

export const PageHeader = ({
  buttons,
  header,
  variant = 'h2',
  description = undefined,
  info = undefined,
  additionalItems = undefined,
  counter = undefined,
}) => {
  return (
    <Header
      variant={variant as HeaderProps.Variant}
      description={description}
      counter={counter}
      actions={
        <SpaceBetween direction='horizontal' size='s'>
          {buttons.map((button, key) =>
            !button.items ? (
              <Button
                href={button.href || ''}
                disabled={button.disabled || false}
                iconName={button.icon || ''}
                onClick={button.onItemClick}
                key={key}
                loading={button.loading || false}
                variant={button.variant || 'normal'}
              >
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown
                variant={button.variant}
                items={button.items}
                key={key}
                onItemClick={button.onItemClick}
                loading={button.loading}
              >
                {button.text}
              </ButtonDropdown>
            ),
          )}
          {additionalItems}
        </SpaceBetween>
      }
      info={info}
    >
      {header}
    </Header>
  );
};
