import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Cards, CollectionPreferencesProps, Flashbar } from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { listResources } from 'src/api/resourcesmanager';
import { listDataSets, syncDataSets } from 'src/api/catalog';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

import { PageHeader } from 'src/components/common/PageHeader';

export interface WorkspaceDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  workspace: any;
  setActiveTabId: any;
  refresh: any;
}

export const ClusterManagement = (props: WorkspaceDetailProps) => {
  const [resources, setResources] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);
  const [selectedResource, setSelectedResource] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoadingResources(true);
    const workspace = props.workspace;
    const workspaceConfig = workspace.workspaceConfig;
    let workspaceResources = (workspaceConfig?.workspaceRedshiftResources ?? []).map((resource) => resource.clusterId);
    let clusters = [];
    try {
      let response = await listResources({
        groupId: workspace?.workspaceId,
        resourceGroupId: workspaceConfig?.workspaceRedshiftResourceGroupIds?.[0],
        status: 'RUNNING',
      });
      clusters = response.resources
        .filter((item) => item.type == 'REDSHIFT_CLUSTER' && workspaceResources.includes(item.name))
        .map((cluster) => {
          return {
            name: cluster.name,
            owner: cluster.groupId,
            region: cluster.region,
            defaultUser: cluster.redshiftDefaultUser,
            masterUser: cluster.redshiftMasterUser,
            defaultDatabase: cluster.redshiftDefaultDatabase,
            clusterName: cluster.name,
            description: cluster.description,
            lastUpdateTime: cluster.updateDate,
            createTime: cluster.createDate,
            status: cluster.status,
            statusReason: cluster.statusReason,
            accountId: cluster.accountId,
          };
        });
    } catch (err) {
      console.error(
        `Unable to list resources for workspace ${workspace.workspaceId} and resource group ${workspaceConfig?.workspaceRedshiftResourceGroupIds?.[0]}`,
        err,
      );
    }
    setResources(clusters);
    setLoadingResources(false);
  };

  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps } = useCollection(resources, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const getDatasetStrFromId = (id) => {
    let arr = id.split('|');
    return id.substring(arr[0].length + arr[1].length + arr[2].length + 3);
  };

  const syncAllDatasetsInSelectedWorkspace = async () => {
    if (!selectedResource) {
      return;
    }

    let dataSets = await listDataSets({});
    let datasetList = dataSets.DataSetList;
    while (dataSets.NextToken != null) {
      dataSets = await listDataSets({ NextToken: dataSets.NextToken });
      datasetList.push(...dataSets.DataSetList);
    }
    let idList = datasetList
      .filter(
        (item) =>
          item.IdInfo?.CatalogId == selectedResource.accountId &&
          item.ClusterIdentifier == selectedResource.clusterName,
      )
      .map((item) => item.Id);
    const syncRequest = {
      IdList: idList,
    };
    let response;
    try {
      response = await syncDataSets(syncRequest);
    } catch (err) {
      console.log('Exception when syncing dataset', err);
      setNotifications([
        {
          type: 'error',
          content: err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      return;
    }
    let failedDatasetItems = [];
    for (let datasetItem of response.IdList) {
      if (datasetItem.Message != 'Success') {
        failedDatasetItems.push(datasetItem);
      }
    }
    if (failedDatasetItems.length == 0) {
      setNotifications([
        {
          type: 'success',
          content: `Succeeded sync datasets`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'error',
          content: `${failedDatasetItems.map((item) => {
            return getDatasetStrFromId(item.Id) + ' failed because ' + item.Reason;
          })}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  useEffect(() => {
    const { selectedItems } = collectionProps;
    if (!selectedItems.length) return;
    const selectedItem = selectedItems[selectedItems.length - 1];
    setSelectedResource(selectedItem);
  }, [collectionProps.selectedItems]);

  const handleAction = async (e) => {
    if (e.detail.id === 'datasets') {
      setLoadingButton(true);
      await syncAllDatasetsInSelectedWorkspace();
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Flashbar items={notifications}></Flashbar>

      <Cards
        {...collectionProps}
        ariaLabels={{
          itemSelectionLabel: (_, t) => `select ${t.name}`,
          selectionGroupLabel: 'Item selection',
        }}
        cardDefinition={{
          header: (e) => e.clusterName,
          sections: [
            {
              id: 'clusterName',
              header: 'Cluster name',
              content: (e) => e.clusterName,
            },
            {
              id: 'mgmtUser',
              header: 'Management DB user',
              content: (e) => e.defaultUser,
            },
            {
              id: 'masterUser',
              header: 'Master DB user',
              content: (e) => e.masterUser,
            },
            {
              id: 'database',
              header: 'Default database',
              content: (e) => e.defaultDatabase,
            },
            {
              id: 'region',
              header: 'Region',
              content: (e) => e.region,
            },
            {
              id: 'description',
              header: 'Description',
              content: (e) => e.description,
            },
            {
              id: 'createDate',
              header: 'Create date',
              content: (e) => e.createTime,
            },
            {
              id: 'status',
              header: 'Status',
              content: (e) => <StatusIcon status={e.status} />,
            },
            {
              id: 'statusReason',
              header: 'Status Reason',
              content: (e) => e.statusReason,
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        items={items}
        loading={loadingResources}
        loadingText='Loading resources'
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No clusters</b>
            <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
              No clusters to display.
            </Box>
          </Box>
        }
        visibleSections={[
          'description',
          'region',
          'clusterName',
          'mgmtUser',
          'masterUser',
          'database',
          'createDate',
          'nodeNumber',
          'status',
          'statusReason',
        ]}
        header={
          <PageHeader
            buttons={[
              {
                text: '',
                icon: 'refresh',
                onItemClick: handleRefresh,
              },
              {
                text: 'Sync',
                onItemClick: handleAction,
                loading: loadingButton,
                items: [
                  {
                    text: 'Datasets',
                    id: 'datasets',
                    disabled: !selectedResource,
                  },
                ],
              },
            ]}
            header={'Redshift cluster resources'}
          />
        }
        selectionType={'single'}
      />
    </>
  );
};
