import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { Link } from 'react-router-dom';
import { listDatasetsForDatabase } from 'src/api/catalog';
import { createDatasetDetailLink } from 'src/routes';
import { REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { GetDataSetsFromHybridCatalogDatabaseRequest } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { EmptyState } from 'src/commons/EmptyState';

import { PageHeader } from 'src/components/common/PageHeader';

export interface WSDatasetListProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  dataSourceId: string;
  catalogId: any;
  databaseName: any;
  match: any;
  schemaName: any;
  clusterIdentifier: any;
  redshiftWorkgroupName: string;
  region: any;
}

export const WSDatasetList = (props: WSDatasetListProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingDatasets, setLoadingDatasets] = useState(false);

  const handleRefresh = async () => {
    setLoadingDatasets(true);
    let listDatasetsForDatabaseRequest: GetDataSetsFromHybridCatalogDatabaseRequest = {
      DatabaseName: props.databaseName,
      CatalogId: props.catalogId,
      DataSourceId: props.dataSourceId ?? REDSHIFT_DATASOURCE_ID,
      ClusterIdentifier: props.clusterIdentifier,
      RedshiftWorkgroupName: props.redshiftWorkgroupName,
      Region: props.region,
      SchemaName: props.schemaName,
    };
    const getSchemasForDatabaseResponse = await listDatasetsForDatabase(listDatasetsForDatabaseRequest);
    setItems(getSchemasForDatabaseResponse.DataSetList);
    setLoadingDatasets(false);
  };

  useEffect(() => {
    handleRefresh();
  }, [props.catalogId, props.dataSourceId]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'datasetName',
      header: 'Dataset name',
      cell: (item) => <Link to={createDatasetDetailLink(item?.Id)}>{item.DataSetName}</Link>,
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Dataset name',
          key: 'DataSetName',
          groupValuesLabel: 'Dataset names',
        },
      ],
    },
  });
  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingDatasets}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        empty={<EmptyState title={'No datasets'} subtitle={'There are no datasets registered in this schema.'} />}
        header={<PageHeader buttons={[]} counter={`(${allItems.length})`} header={'Datasets'} />}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatasets}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
