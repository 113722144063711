import * as React from 'react';
import { useEffect, useState } from 'react';
import { ColumnLayout, Container, Form, FormField, Header, Input, Select } from '@amzn/awsui-components-react-v3';
import { convertRedshiftClustersToOptions, listCatalogs } from 'src/api/catalog';
import { FOUNDRY_TRUSTED_ROLES, REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { listRedshiftClusters } from 'src/api/permissions';
import { isValidClusterUser } from 'src/commons/validationUtils';
import { getFoundryRoleARN } from 'src/commons/common';

export interface CatalogDetailsProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  CTI: any;
  catalogName: any;
  description: any;
  setCTI: any;
  setCatalogName: any;
  setDescription: any;
  setGetCatalogSuccess: any;
  getCatalogSuccess: boolean;
  setIsDefaultCatalog: any;
  isDefaultCatalog: boolean;
  setIsGroupBasedCatalog: any;
  isGroupBasedCatalog: boolean;
  clusterName: any;
  redshiftDbUserName: any;
  setClusterName: any;
  setRedshiftDbUserName: any;
  isDbUserRegistered: boolean;
  setIsDbUserRegistered: any;
  selectedCluster: any;
  setSelectedCluster: any;
  isDbUserDisabled: boolean;
  setIsDbUserDisabled: any;
  toggleHelp: any;
}

export const RedshiftCatalogDetails = (props: CatalogDetailsProps) => {
  const [clusters, setClusters] = useState(undefined);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const getCatalog = async (clusterName) => {
    try {
      const catalogs = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: props.activeWorkspace.accountId,
              ClusterIdentifier: clusterName,
              Region: props.activeWorkspace.region,
              DataSourceId: REDSHIFT_DATASOURCE_ID,
            },
          ],
        },
      });
      if (catalogs !== undefined && catalogs.CatalogInfoList !== undefined && catalogs.CatalogInfoList.length > 0) {
        let registeredCatalog = undefined;
        for (let catalog of catalogs.CatalogInfoList) {
          if (catalog.ClusterIdentifier == clusterName) {
            registeredCatalog = catalog;
          }
        }
        if (registeredCatalog == undefined) {
          props.setCTI(undefined);
          props.setDescription(undefined);
          props.setCatalogName(undefined);
          props.setGetCatalogSuccess(false);
          return;
        }
        props.setGetCatalogSuccess(true);
        if (FOUNDRY_TRUSTED_ROLES.includes(registeredCatalog.DataAccessRole)) {
          props.setIsDefaultCatalog(true);
        } else if (
          registeredCatalog.DataAccessRole ==
          getFoundryRoleARN(props.activeWorkspace.region, props.activeWorkspace.accountId)
        ) {
          props.setCTI(registeredCatalog?.CTI);
          props.setDescription(registeredCatalog?.Description);
          props.setCatalogName(registeredCatalog?.Name);
          props.setGetCatalogSuccess(true);
        } else {
          props.setIsGroupBasedCatalog(true);
        }
      }
    } catch (err) {
      console.log('Exception when get catalog', err);
    }
  };

  const getClusters = async () => {
    let listRedshiftClustersResponse = await listRedshiftClusters({
      workspaceId: props.activeWorkspace.workspaceId,
      accountId: props.activeWorkspace.accountId,
      region: props.activeWorkspace.region,
    });
    setClusters(convertRedshiftClustersToOptions(listRedshiftClustersResponse.redshiftClusters));
  };

  const handleRefresh = async () => {
    await getClusters();
  };

  const setContentAccordingToClusterName = async (e) => {
    props.setSelectedCluster(e.detail.selectedOption);
    await getCatalog(e.detail.selectedOption.value);
    props.setClusterName(e.detail.selectedOption.value);
    let resource = props.activeWorkspace.workspaceConfig.workspaceRedshiftResources.filter(
      (cluster) => cluster.clusterId == e.detail.selectedOption.value && cluster.clusterStatus == 'RUNNING',
    );
    // cluster already registered
    if (resource != null && resource.length == 1) {
      props.setRedshiftDbUserName(resource[0].redshiftDbUser);
      props.setIsDbUserDisabled(true);
      props.setIsDbUserRegistered(true);
    } else {
      // pre-populate with master user
      const cluster = clusters.find((cluster) => e.detail.selectedOption.value == cluster.value);
      props.setIsDbUserDisabled(false);
      props.setRedshiftDbUserName(cluster.masterUser);
      props.setIsDbUserRegistered(false);
    }
  };

  return (
    <div>
      <br />
      <Form>
        <Container header={<Header variant='h2'>Catalog details</Header>}>
          <ColumnLayout>
            <FormField label={'Account ID'} description='AWS account ID that owns the Redshift cluster'>
              <Input
                placeholder='Input the account ID'
                ariaRequired={true}
                value={props.activeWorkspace.accountId}
                disabled
              />
            </FormField>

            <FormField label={'Catalog owner'} description='Workspace that is associated with the AWS account'>
              <Input
                placeholder='Input the workspace'
                ariaRequired={true}
                value={props.activeWorkspace.workspaceName}
                disabled
              />
            </FormField>

            <FormField label={'Region'} description='Region of the cluster'>
              <Input ariaRequired={true} value={'us-east-1'} disabled />
            </FormField>

            <FormField
              label={'Redshift cluster'}
              description='Select a cluster to register, only encrypted clusters can be onboarded.'
            >
              <Select
                selectedOption={props.selectedCluster}
                options={clusters}
                selectedAriaLabel='Selected'
                onChange={(e) => {
                  setContentAccordingToClusterName(e);
                }}
                placeholder='Select a cluster'
              />
            </FormField>

            <FormField
              label={'Redshift database user name'}
              description='Input the Redshift database user that Omni will use to access your cluster. It must exist and have permissions to [create, grant, revoke, drop] user/role and [grant, revoke] table/schema.'
              errorText={
                props.redshiftDbUserName == null || isValidClusterUser(props.redshiftDbUserName)
                  ? ''
                  : 'User name does not match regex ^[a-z_]+[w$]*$'
              }
            >
              <Input
                ariaRequired={true}
                value={props.redshiftDbUserName}
                onChange={(event) => props.setRedshiftDbUserName(event.detail.value)}
                disabled={props.isDbUserDisabled}
                placeholder='Enter Redshift DB user name here'
              />
            </FormField>

            <FormField
              label={'Catalog name'}
              description='This is the name that your consumers will see when browsing datasets.'
            >
              {props.getCatalogSuccess && !props.isDefaultCatalog && !props.isGroupBasedCatalog ? (
                <Input placeholder='Enter catalog name here' ariaRequired={true} value={props.catalogName} disabled />
              ) : (
                <Input
                  placeholder='Enter catalog name here'
                  ariaRequired={true}
                  onChange={(event) => props.setCatalogName(event.detail.value)}
                  value={props.catalogName}
                />
              )}
            </FormField>

            <FormField label={'Catalog description'} description='Detailed description about the data in this catalog'>
              {props.getCatalogSuccess && !props.isDefaultCatalog && !props.isGroupBasedCatalog ? (
                <Input placeholder='Enter description here' ariaRequired={true} value={props.description} disabled />
              ) : (
                <Input
                  placeholder='Enter description here'
                  ariaRequired={true}
                  onChange={(event) => props.setDescription(event.detail.value)}
                  value={props.description}
                />
              )}
            </FormField>

            <FormField label={'CTI'} description='Input CTI for your catalog.'>
              {props.getCatalogSuccess && !props.isDefaultCatalog && !props.isGroupBasedCatalog ? (
                <Input
                  placeholder='Enter CTI here. Format: Catalog/Type/Item'
                  ariaRequired={true}
                  value={props.CTI}
                  disabled
                />
              ) : (
                <Input
                  placeholder='Enter CTI here. Format: Catalog/Type/Item'
                  ariaRequired={true}
                  onChange={(event) => props.setCTI(event.detail.value)}
                  value={props.CTI}
                />
              )}
            </FormField>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
};
