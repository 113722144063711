import { useEffect, useState } from 'react';
import { getDataSetsFromHybridCatalogDatabase } from '../../../../api/catalog';
import { listDataPermissions } from '../../../../api/permissions';
import { Database } from 'aws-sdk/clients/glue';
import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { Status } from '../permissionSelector/use-columns';
import {
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  LAKE_FORMATION_DATASOURCE_ID,
} from 'src/commons/constants';
import { isMPData } from 'src/components/workspaces/common/common';

const ALL_TABLES_OPTION = (dataPermission): any => ({
  Id: 'All|Tables',
  Owners: dataPermission.ownerId,
  IdInfo: {
    DatabaseName:
      dataPermission.resource.table == undefined
        ? dataPermission.resource.tableWithColumns?.databaseName
        : dataPermission.resource.table.databaseName,
    CatalogId:
      dataPermission.resource.table == undefined
        ? dataPermission.resource.tableWithColumns?.catalogId
        : dataPermission.resource.table.catalogId,
    TableName: 'All Tables',
    Region: dataPermission.region,
  },
  LakeFormationRoleARN: dataPermission.audit['PublisherRole'],
  LakeFormationAuditARN: dataPermission.audit['PublisherAuditRole'],
  DataSetName: 'Click here to select all entries below.',
});

const DATA_ID = (table, database, catalogId, region): string =>
  `DS-glueLF|A-${catalogId}|DN-${database}|TN-${table}|R-${region}`;

export const useTables = (database: Database | undefined) => {
  const [status, setStatus] = useState<Status>('loading');
  const [tables, setTables] = useState<HybridCatalogDataSet[]>([]);
  const getTables = async () => {
    let listDatasetPermissionRequest = {
      ownerId: database['Owners'][0],
      region: database['Region'],
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };

    let databaseName = database['DatabaseName'];
    let catalogId = database['CatalogId'];

    try {
      let listDatasetPermissionResponse = await listDataPermissions(listDatasetPermissionRequest);
      let datasetPermissions = [...listDatasetPermissionResponse.dataPermissionList];
      //Loop and get remaining tables
      while (listDatasetPermissionResponse.nextToken != null) {
        listDatasetPermissionRequest.nextToken = listDatasetPermissionResponse.nextToken;
        listDatasetPermissionResponse = await listDataPermissions(listDatasetPermissionRequest);
        datasetPermissions.push(...listDatasetPermissionResponse.dataPermissionList);
      }

      if (datasetPermissions !== undefined) {
        let tablePermissionList = [];
        let columnPermissionList = [];
        for (let permission of datasetPermissions) {
          if (
            permission.resource.table !== undefined &&
            permission.resource.table.databaseName == databaseName &&
            permission.resource.table.catalogId == catalogId
          ) {
            if (permission.resource.table.name == null) {
              await getAllTables(permission);
              return;
            } else {
              tablePermissionList.push(permission);
            }
          } else if (
            permission.resource.tableWithColumns !== undefined &&
            permission.resource.tableWithColumns.databaseName == databaseName &&
            permission.resource.tableWithColumns.catalogId == catalogId
          ) {
            if (permission.resource.tableWithColumns.name == 'ALL_TABLES') {
              await getAllTables(permission);
              return;
            } else {
              columnPermissionList.push(permission);
            }
          }
        }
        let tableItems = [];

        tableItems.push(...transformTableDataPermissionToTables(tablePermissionList));
        tableItems.push(...transformColumnDataPermissionToTables(columnPermissionList));
        tableItems = tableItems.filter((tableItem) => !isMPData(catalogId, databaseName, tableItem));
        setTables(tableItems ? tableItems : []);
      }
    } catch (err) {
      console.log('Exception list table permission', err);
    }
  };

  const transformTableDataPermissionToTables = (dataPermissionList) => {
    const convertedTables = dataPermissionList.map((item) => {
      const transform = {};
      transform['Id'] = DATA_ID(
        item.resource.table.name,
        item.resource.table.databaseName,
        item.resource.table.catalogId,
        item.region,
      );
      transform['DataId'] = item.dataPermissionId;
      transform['Owners'] = item.ownerId;
      //Id Info
      const idInfo = {};
      idInfo['DatabaseName'] = item.resource.table.databaseName;
      idInfo['CatalogId'] = item.resource.table.catalogId;
      idInfo['TableName'] = item.resource.table.name;
      idInfo['Region'] = item.region;
      transform['IdInfo'] = idInfo;
      transform['Columns'] = undefined;
      transform['ColumnWildCard'] = undefined;
      transform['LakeFormationRoleARN'] = item.audit['PublisherRole'];
      transform['LakeFormationAuditARN'] = item.audit['PublisherAuditRole'];
      transform['DataSetName'] = item.resource.table.name; //This doesn't exist from the share.. if this is needed its in hybrid catalog, another query though
      return transform;
    });
    return convertedTables;
  };

  const transformColumnDataPermissionToTables = (dataPermissionList) => {
    const convertedTables = dataPermissionList.map((item) => {
      const transform = {};
      transform['Id'] = DATA_ID(
        item.resource.tableWithColumns.name,
        item.resource.tableWithColumns.databaseName,
        item.resource.tableWithColumns.catalogId,
        item.region,
      );
      transform['DataId'] = item.dataPermissionId;
      transform['Owners'] = item.ownerId;
      //Id Info
      const idInfo = {};
      idInfo['DatabaseName'] = item.resource.tableWithColumns.databaseName;
      idInfo['CatalogId'] = item.resource.tableWithColumns.catalogId;
      idInfo['TableName'] = item.resource.tableWithColumns.name;
      idInfo['Region'] = item.region;
      transform['IdInfo'] = idInfo;
      transform['Columns'] = item.resource.tableWithColumns.columnNames;
      transform['ColumnWildCard'] = item.resource.tableWithColumns.columnWildcard;
      transform['LakeFormationRoleARN'] = item.audit['PublisherRole'];
      transform['LakeFormationAuditARN'] = item.audit['PublisherAuditRole'];
      transform['DataSetName'] = item.resource.tableWithColumns.name; //This doesn't exist from the share.. if this is needed its in hybrid catalog, another query though
      return transform;
    });
    return convertedTables;
  };

  //If Share is all tables (also all columns)
  const getAllTables = async (dataPermission) => {
    //Set catalogID and Database to get first batch of tables
    let request = {
      DatabaseName: database['DatabaseName'],
      CatalogId: database['CatalogId'],
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
      NextToken: undefined,
    };
    let response = await getDataSetsFromHybridCatalogDatabase(request);
    let dataSetList = [...response.DataSetList];
    //Loop and get remaining tables
    while (response.NextToken != null) {
      request.NextToken = response.NextToken;
      response = await getDataSetsFromHybridCatalogDatabase(request);
      dataSetList.push(...response.DataSetList);
    }

    //Add Arn from Share to Tables
    dataSetList.forEach((dataSet) => {
      dataSet.LakeFormationRoleARN = dataPermission.audit['PublisherRole'];
      dataSet.Owners = dataPermission.ownerId;
    });
    //All Tables option.
    const selectAllOption = ALL_TABLES_OPTION(dataPermission);

    // Disable display mp related tables for group level access.
    let displayTableList = [selectAllOption, ...dataSetList];
    displayTableList = displayTableList.filter(
      (item) => !isMPData(database['CatalogId'], database['DatabaseName'], item['IdInfo']?.TableName),
    );
    //Set all Tables for selection
    setTables(dataSetList ? displayTableList : []);
  };

  useEffect(() => {
    if (!database) {
      setTables([]);
      setStatus('finished');
      return;
    }
    setStatus('loading');

    getTables()
      .then(() => setStatus('finished'))
      .catch(() => setStatus('error'));
  }, [database]);

  return { status, tables };
};
