import * as React from 'react';
import { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  CollectionPreferences,
  Input,
  Modal,
  TextFilter,
  Table,
  Pagination,
  Header,
} from '@amzn/awsui-components-react-v3/polaris';

import { scrollUp } from '../utils/navigation';

import { getTagBasedWorkspaceAccess, cancelTagBasedWorkspaceAccess } from '../../api/permissions';
import { EmptyState } from 'src/commons/EmptyState';
import { tagsColumnDefinitions } from 'src/components/workspace_access/utils';
import { Flashbar } from '@amzn/awsui-components-react-v3';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

import { PageHeader } from 'src/components/common/PageHeader';

export interface TagsTableProps {
  setContentType: any;
  isIncomingRequests: boolean;
  activeGroup?: string;
  activeWorkspace?: any;
}

export interface TagsTableState {
  filterText: string;
}

// const DEFAULT_CANCEL_REASON = 'Please cancel the request.';

interface iTagsTable {
  isIncomingRequests: boolean;
  setContentType(contentType: string): void;
  activeGroup: string;
  activeWorkspace: any;
}

export function TagsTable({ isIncomingRequests, setContentType, activeGroup, activeWorkspace }: iTagsTable) {
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [, setSelected] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [columnDefinitions] = useState(tagsColumnDefinitions);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelRequestLoading, setCancelRequestLoading] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: (
          <EmptyState
            title='No instances'
            subtitle='No instances to display.'
            action={<Button>Create instance</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title='No matches'
            subtitle='We can’t find a match.'
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 25 },
      sorting: {},
      selection: {},
    },
  );

  const { selectedItems } = collectionProps;

  function convertToStructure(a) {
    let structure;
    if (isIncomingRequests) {
      structure = {
        publisherWorkspaceId: activeWorkspace.workspaceId,
        consumerWorkspaceId: a['Workspace'],
        tagPair: a['Tag'],
        tagType: a['TagType'],
        region: a['Region'],
      };
    } else {
      structure = {
        publisherWorkspaceId: a['Workspace'],
        consumerWorkspaceId: activeWorkspace.workspaceId,
        tagPair: a['Tag'],
        tagType: a['TagType'],
        region: a['Region'],
      };
    }

    return structure;
  }

  // component did mount
  useEffect(() => {
    setContentType(TABLE_CONTENT_TYPE);
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, []);

  // component did update
  useEffect(() => {
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, [activeGroup, activeWorkspace, isIncomingRequests]);

  const transformData = (items) => {
    const transformedData = [];
    items.forEach((item) => {
      const transform = {};
      transform['Workspace'] = item.workspaceId ?? '';
      transform['Group'] = item.groupId ?? '';
      transform['Tag'] = item.tagPair ?? '';
      transform['TagType'] = item.tagType ?? '';
      transform['Permission'] = item.permissions ?? '';
      transform['Region'] = item.region ?? '';
      transform['Status'] = item.status ?? '';
      transform['Reason'] = item.statusReason ?? '';
      transformedData.push(transform);
    });
    return transformedData;
  };

  const handleCancel = async () => {
    setCancelRequestLoading(true);

    try {
      await cancelTagBasedWorkspaceAccess({
        tagBasedWorkspaces: selectedItems.map((a) => convertToStructure(a)),
      });
      await handleRefresh();
      setNotifications([
        {
          type: 'success',
          content: 'Permission cancel request submitted',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Permission cancel failed. Reason: ${err.message}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      console.log(err);
    }

    setCancelRequestLoading(false);
    setCancelReason('');
    setCancelModalVisible(false);
    scrollUp();
  };

  const handleRefresh = async () => {
    if (!activeWorkspace) return;
    setRequestsLoading(true);
    const tagPermissions = [];
    try {
      let nextToken = undefined;
      let getWorkspaceTagBasedAccessResult;
      do {
        let tagAccessPermissionsRequest = {
          ownerId: activeWorkspace.workspaceId,
          isIncoming: isIncomingRequests,
        };
        /* backend do not have pagination, can be uncommented once backend is fixed
        if (nextToken != undefined) {
          tagAccessPermissionsRequest['nextToken'] = nextToken;
        }*/
        if (isIncomingRequests) {
          getWorkspaceTagBasedAccessResult = await getTagBasedWorkspaceAccess(tagAccessPermissionsRequest);
        } else {
          getWorkspaceTagBasedAccessResult = await getTagBasedWorkspaceAccess(tagAccessPermissionsRequest);
        }
        nextToken = getWorkspaceTagBasedAccessResult?.nextToken;
        tagPermissions.push(...getWorkspaceTagBasedAccessResult.tagBasedWorkspaces);
      } while (nextToken != undefined);
      setAllItems(transformData(tagPermissions));
      setRequestsLoading(false);
      setSelected(undefined);
    } catch (err) {}
  };

  const options = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Cancel',
        onItemClick: () => setCancelModalVisible(true),
        loading: requestsLoading,
        disabled: selectedItems.length === 0,
      },
    ];
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        loadingText='Loading permissions...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={
          <PageHeader buttons={options()} counter={`(${allItems.length})`} header={'Workspace tag-based access'} />
        }
        empty={<EmptyState title='No workspaces' subtitle='No workspaces were found.' />}
        loading={requestsLoading}
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            preferences={{
              pageSize: 25,
            }}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
                { value: 100, label: '100 items' },
              ],
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Enable to wrap table cell content, disable to truncate text.',
            }}
          />
        }
        selectionType='multi' //{isIncomingRequests ? 'multi' : 'single'}
        selectedItems={selectedItems}
        filter={
          <TextFilter
            {...filterProps}
            filteringPlaceholder='Find resources'
            filteringAriaLabel='Filter IAM access requests'
            countText={`${filteredItemsCount} ${filteredItemsCount > 1 ? 'matches' : 'match'}`}
          />
        }
        pagination={<Pagination {...paginationProps} />}
      ></Table>
      <Modal
        onDismiss={() => setCancelModalVisible(false)}
        visible={cancelModalVisible}
        size='small'
        footer={
          <span className='awsui-util-f-r'>
            <Button
              variant='link'
              onClick={() => {
                setCancelModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={handleCancel} loading={cancelRequestLoading}>
              Confirm
            </Button>
          </span>
        }
        header={'Please provide the reason for the request cancellation.'}
      >
        <Input
          placeholder='Please provide your reason.'
          onChange={(e) => setCancelReason(e.detail.value)}
          value={cancelReason}
        />
      </Modal>
    </>
  );
}
