import * as React from 'react';
import { Button, Cards, CollectionPreferencesProps, Header, Icon, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getWorkspacesForGroups } from 'src/api/permissions';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { createCurrentWSDetailLink, Page } from 'src/routes';
import { EmptyState } from 'src/commons/EmptyState';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

import { PageHeader } from 'src/components/common/PageHeader';

export interface BrowseWorkspacesProps {
  setContentType: any;
  activeGroup: string;
  username: string;
}

export const BrowseWorkspaces = (props: BrowseWorkspacesProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [workspaces, setWorkspaces] = useState([]);
  const [refreshButtonLoading, setRefreshButtonLoading] = useState(false);
  const [loadingWorkspaces, setLoadingWorkspaces] = useState(false);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setLoadingWorkspaces(true);
    setRefreshButtonLoading(true);
    let groupIdList = [];
    groupIdList.push(props.activeGroup);
    let getWorkspacesForGroupsResult;
    try {
      getWorkspacesForGroupsResult = await getWorkspacesForGroups({
        groupIds: groupIdList,
      });
      // will be like Map<GroupId, WorkspacesByGroupResult>
      // WorkspacesByGroupResult be like {succeeded:true, message:"", workspaces:[wks1,wks2]}
      const groupWorkspacesMap = getWorkspacesForGroupsResult.workspaces;
      const workspacesByGroup = groupWorkspacesMap[props.activeGroup];
      if (workspacesByGroup !== undefined && workspacesByGroup.succeeded) {
        setWorkspaces(workspacesByGroup.workspaces);
      } else {
        console.log('Failed to get workspaces for group.', workspacesByGroup?.message);
      }
      setLoadingWorkspaces(false);
      setRefreshButtonLoading(false);
    } catch (err) {
      console.log('Exception when get workspaces for group', err);
      setRefreshButtonLoading(false);
      setLoadingWorkspaces(false);
    }
  };

  const redirectToCreateWorkspace = () => {
    setRedirect(Page.CREATE_WORKSPACE);
  };

  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps } = useCollection(workspaces, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Cards
      {...collectionProps}
      ariaLabels={{
        itemSelectionLabel: (_, t) => `select ${t.name}`,
        selectionGroupLabel: 'Item selection',
      }}
      cardDefinition={{
        header: (e) => <Link to={createCurrentWSDetailLink(e.workspaceId)}>{e.workspaceName}</Link>,
        sections: [
          {
            id: 'workspaceName',
            header: 'Workspace name',
            content: (e) => e.workspaceName,
          },
          {
            id: 'accountId',
            header: 'Account ID',
            content: (e) => e.accountId,
          },
          {
            id: 'group',
            header: 'Group',
            content: (_) => props.activeGroup,
          },
          {
            id: 'workspaceId',
            header: 'Workspace ID',
            content: (e) => e.workspaceId,
          },
          {
            id: 'supportedServices',
            header: 'Supported services',
            content: (e) =>
              e.supportedServices == undefined || e.supportedServices.length == 0
                ? 'No services supported'
                : e.supportedServices?.filter((item) => item !== 'FoundryAccessLogging')?.join(', '),
          },
          {
            id: 'region',
            header: 'Region',
            content: (e) => e.region,
          },
          {
            id: 'owner',
            header: 'Owner',
            content: (e) => e.owner,
          },
          {
            id: 'lastUpdate',
            header: 'Last update',
            content: (e) =>
              e.workspaceConfig != undefined && e.workspaceConfig.lastExecutedWorkflowType != undefined
                ? e.workspaceConfig.lastExecutedWorkflowType + ' ' + e.updateDate
                : e.updateDate,
          },
          {
            id: 'status',
            header: 'Status',
            content: (e) => {
              let currentStatus = <></>;

              switch (e.status) {
                case 'Active_Sync':
                  currentStatus = (
                    <div>
                      <Icon variant='success' name='status-positive' /> In sync
                    </div>
                  );
                  break;
                case 'Active_Syncing':
                  currentStatus = (
                    <div>
                      <Icon name='status-pending' variant='success' /> Syncing
                    </div>
                  );
                  break;
                case 'Active_Auditing':
                  currentStatus = (
                    <div>
                      <Icon name='status-pending' variant='success' /> Auditing
                    </div>
                  );
                  break;
                case 'Active_Running':
                  currentStatus = (
                    <div>
                      <Icon name='status-pending' variant='success' /> Running
                    </div>
                  );
                  break;
                case 'Active_Outdated':
                  currentStatus = (
                    <div>
                      <Icon name='status-warning' variant='warning' /> Outdated
                    </div>
                  );
                  break;
                case 'Active_Error':
                  currentStatus = (
                    <div>
                      <Icon name='status-warning' variant='warning' /> Error
                    </div>
                  );
                  break;
                case 'Inactive':
                  currentStatus = (
                    <div>
                      <Icon name='status-warning' variant='warning' /> Inactive
                    </div>
                  );
                  break;
              }
              return (
                <>
                  {currentStatus}
                  <div>
                    completed tasks: {e.workspaceConfig['workflowCompletedTaskCount']} /{' '}
                    {e.workspaceConfig['workflowTotalTaskCount']}
                  </div>
                </>
              );
            },
          },
        ],
      }}
      cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
      items={items}
      loading={loadingWorkspaces}
      loadingText='Loading workspaces'
      empty={<EmptyState title='No workspaces' subtitle='No workspaces were found.' />}
      visibleSections={[
        'group',
        'accountId',
        'description',
        'permissions',
        'region',
        'owner',
        'status',
        'lastUpdate',
        'completedTasks',
        'supportedServices',
      ]}
      header={
        <Header
          actions={
            <SpaceBetween size={'s'} direction={'horizontal'}>
              <Button variant={'icon'} iconName={'refresh'} onClick={handleRefresh} loading={refreshButtonLoading} />
              <Button variant={'primary'} onClick={redirectToCreateWorkspace}>
                Create new workspace
              </Button>
            </SpaceBetween>
          }
          variant={'h2'}
        >
          Workspaces in your group
        </Header>
      }
    />
  );
};
