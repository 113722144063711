import {
  Pagination,
  Table,
  CollectionPreferences,
  CollectionPreferencesProps,
  PropertyFilter,
  Header,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { Link } from 'react-router-dom';
import { createDatabaseDetailLink, createDatasetDetailLink } from 'src/routes';
import { LAKE_FORMATION_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { EmptyState } from 'src/commons/EmptyState';

import { PageHeader } from 'src/components/common/PageHeader';

export interface ResourceLinkTableProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  visible: boolean;
  match: any;
  dismiss: any;
  resourceLinkList: any;
}

export const ResourceLinkTable = (props: ResourceLinkTableProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingResource, setLoadingResource] = useState(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });
  const columnDefinitions = [
    {
      id: 'catalogId',
      header: 'CatalogId',
      cell: (item) => item.catalogId,
      minWidth: 200,
      width: 200,
    },
    {
      id: 'database',
      header: 'Database name',
      cell: (item) => item.databaseName,
      minWidth: 200,
      width: 200,
    },
    {
      id: 'dataset',
      header: 'Dataset name',
      cell: (item) => (item.tableName == undefined ? '*' : item.tableName),
      minWidth: 200,
      width: 200,
    },
    {
      id: 'region',
      header: 'Region',
      cell: (item) => (item.region == undefined ? '' : item.region),
      minWidth: 100,
      width: 100,
    },
    {
      id: 'sharedResource',
      header: '' + 'Source resource',
      cell: (item) =>
        item.tableName == undefined ? (
          <Link
            to={createDatabaseDetailLink(
              LAKE_FORMATION_DATASOURCE_ID,
              item?.sourceCatalogId,
              undefined,
              undefined,
              item?.sourceDatabase,
              item?.region,
            )}
          >
            {item.sourceResource}
          </Link>
        ) : (
          <Link
            to={createDatasetDetailLink(
              `DS-glueLF|A-${item?.sourceCatalogId}|DN-${item?.sourceDatabase}|TN-${item?.sourceDataset}|R-${item?.region}`,
            )}
          >
            {item.sourceResource}
          </Link>
        ),
      minWidth: 400,
      width: 600,
    },
  ];

  const handleRefresh = async () => {
    setLoadingResource(true);

    let resources = props.resourceLinkList;
    for (let resource of resources) {
      let source = resource.sourceResource;
      const arr = source.split('/');
      resource.sourceDatabase = arr[1];
      resource.sourceDataset = arr[2] == '*' ? undefined : arr[2];
    }
    setItems(resources);
    setLoadingResource(false);
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, [props.resourceLinkList]);

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: <EmptyState title={'No resource links'} subtitle={'No resource links were found.'} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Database name',
          key: 'databaseName',
          groupValuesLabel: 'Database names',
        },
        {
          propertyLabel: 'Dataset name',
          key: 'datasetName',
          groupValuesLabel: 'Dataset names',
        },
        {
          propertyLabel: 'Source resource',
          key: 'sourceResource',
          groupValuesLabel: 'Source resources',
        },
      ],
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading resource links...'
        loading={loadingResource}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <PageHeader
            buttons={[
              {
                text: '',
                icon: 'refresh',
                onItemClick: handleRefresh,
              },
            ]}
            counter={`(${allItems.length})`}
            header={'Resource links for the shared data'}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        empty={<EmptyState title={'No resource links'} subtitle={'No resource links were found.'} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingResource}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
