import { RegisterDataSetRequest } from 'aws-sdk/clients/tethyscontractservicelambda';
import { registerDataset } from '../../../api/ingestion';
import { cleanUndefinedProperties, isSourceS3, validateStep } from './utils/validation';

import { scrollUp } from '../../utils/navigation';
import { Redirect, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Page } from 'src/routes';
import * as React from 'react';

import { AppLayoutProps, FlashbarProps, Button, Alert, Box, TextContent } from '@amzn/awsui-components-react-v3';
import { WIZARD_CONTENT_TYPE } from 'src/commons/constants';
import { TethysLocalStorage } from '../common/keys';
import { initialRegisterDataSetRequest } from './utils/requestPayload';
import { TethysWizard } from './utils/wizard';
import { ImportManifestModal } from './manifests/ManifestModal';
import { buildRegistrationFromManifest } from './manifests/importManifest';
import { DataSetManifest, getManifestDatasetName } from './manifests/manifest';

export const IMPORT_MANIFEST_PARAM = 'import';

export interface iIngestionComponentProps {
  toggleHelp(): void;
  activeGroup: string;
  setContentType(contentType: AppLayoutProps.ContentType): void;
}

export interface iRegisterDataset extends iIngestionComponentProps {
  username: string;
  setNotifications(notifications: FlashbarProps.MessageDefinition[]): void;
}

export const RegisterDataset = ({
  username,
  activeGroup,
  toggleHelp,
  setContentType,
  setNotifications,
}: iRegisterDataset) => {
  const location = useLocation();
  const searchParameters = new URLSearchParams(location['search']);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [validationStep, setValidationStep] = useState(0);
  const [redirect, setRedirect] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isJsonValid, setJsonValid] = useState(true); // Used to validate schema on Step 3.
  const [importModalVisible, setImportModalVisible] = useState<boolean>(
    searchParameters.get(IMPORT_MANIFEST_PARAM) === 'true',
  );
  const [importedManifest, setImportedManifest] = useState<DataSetManifest>();

  useEffect(() => {
    setContentType(WIZARD_CONTENT_TYPE);
  }, []);

  const [request, setRequest] = useState<RegisterDataSetRequest>({
    ...initialRegisterDataSetRequest,
    CreatedBy: username, // This should be fetched from Gladstone.
    PrimaryOwner: activeGroup, // This should be fetched from Gladstone. Docs need update.
  });

  const nextStep = (step: number) => {
    setValidationStep(step);
    const isForwardStep = step > activeStepIndex;
    if (!isForwardStep) setActiveStepIndex(step);

    const isStepValid = validateStep(step, request, true);
    if (!isStepValid) return; // For forward steps, only advance if payload valid.
    if (activeStepIndex === 2 && !isJsonValid) return; // Schema step, isJsonValid determined by ACE
    if (step === 1 && isSourceS3(request)) enrichSchema();

    setActiveStepIndex(step);
  };

  // Add the name & namespace of the schema based on the dataset name & database.
  const enrichSchema = () => {
    const schemaDefinition = JSON.parse(request.DataContract.DataProperties?.SchemaDefinition ?? '{}');

    schemaDefinition.name = request.DataSetName;
    schemaDefinition.namespace = request.DatabaseName;

    const SchemaDefinition = JSON.stringify(schemaDefinition, null, '\t');

    setRequest({
      ...request,
      DataContract: {
        ...request.DataContract,
        DataProperties: {
          ...request.DataContract.DataProperties,
          SchemaDefinition,
        },
      },
    });
  };

  const navigate = () => {
    setNotifications([]);
    setRedirect(Page.PUBLISHER_TABLES);
  };

  const submit = async () => {
    setLoading(true);
    try {
      cleanUndefinedProperties(request);
      console.log('submitting', request);
      await registerDataset(request);
      setNotifications([
        {
          type: 'success',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: 'Data contract registered successfully.',
          action: <Button onClick={navigate}>Visit your datasets</Button>,
        },
      ]);
      localStorage.removeItem(TethysLocalStorage);
    } catch (e: any) {
      setNotifications([
        {
          type: 'error',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: e.message,
        },
      ]);
      setLoading(false);
    }
    scrollUp();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      {importedManifest ? (
        <Box margin={{ bottom: 'l' }}>
          <Alert type='success' header='Manifest imported' statusIconAriaLabel='Success'>
            Details have been populated using <Box variant={'strong'}>{getManifestDatasetName(importedManifest)}</Box>
            <Box variant='span' margin={{ left: 'xxs', right: 'xxs' }}>
              from
            </Box>
            <TextContent>
              <em>{importedManifest.source_site}</em>
            </TextContent>
          </Alert>
        </Box>
      ) : null}
      <TethysWizard
        isCreate
        loading={loading}
        request={request}
        setJsonValid={setJsonValid}
        validationStep={validationStep}
        activeStepIndex={activeStepIndex}
        setActiveStepIndex={setActiveStepIndex}
        setRedirect={setRedirect}
        setRequest={setRequest}
        toggleHelp={toggleHelp}
        nextStep={nextStep}
        submit={submit}
      />
      <ImportManifestModal
        visible={importModalVisible}
        onClose={() => setImportModalVisible(false)}
        onSubmit={(manifest) => {
          const request = buildRegistrationFromManifest(manifest, username, activeGroup);
          // FIXME: also DDB uses TargetSchema, not SchemaDefinition, which doesn't exist in the manifest
          if (request) {
            setRequest(request);
            setImportedManifest(manifest);
            setImportModalVisible(false);
          }
        }}
      />
    </>
  );
};
