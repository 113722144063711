import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Container,
  Header,
  Link,
  Pagination,
  PropertyFilter,
  Select,
  Table,
  TokenGroup,
} from '@amzn/awsui-components-react-v3';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getDataSetsFromHybridCatalogDatabase, getDataSourceTables } from 'src/api/catalog';
import { listDataPermissions } from 'src/api/permissions';
import {
  DATA_CLASSIFICATION_LEVELS_URL,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  dataClassificationOptions,
  defaultClassificationOption,
  LAKE_FORMATION_DATASOURCE_ID,
  PII_DEFINITION_URL,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { Toggle } from '@amzn/awsui-components-react-v3';
import { registerStatus } from 'src/commons/common';
import { ClassificationBadge } from 'src/components/catalog/common';
import { PageHeader } from 'src/components/common/PageHeader';
import { EmptyState } from 'src/commons/EmptyState';

export interface GlueDatasetsProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  setRegisterItems: any;
  registerItems: any[];
  selectedDatabaseName: string;
  setRegisterDataPermissionType: any;
  setHasAllTablePermission: any;
  setNotifications: any;
}

export const GlueDatasets = (props: GlueDatasetsProps) => {
  const [glueTables, setGlueTables] = useState([]);
  const [loadingDatasets, setLoadingDatasets] = useState(false);
  const [selectedDatasets, setSelectedDatasets] = useState(undefined);
  const [autoRegisterDatasets, setAutoRegisterDatasets] = useState(undefined); // used for group to workspace migration
  const [piiDatasets, setPIIDatasets] = useState({});
  const [classifications, setClassifications] = useState({});

  const getClassificationOption = (item) => {
    return (
      dataClassificationOptions.find((c) => c.value == item.DataClassification) ??
      classifications[item.Name] ??
      defaultClassificationOption
    );
  };

  const isItemDisabled = (item) => {
    return item.registered !== undefined || !isDatasetSelected(item.Name);
  };

  const getAllDatasetsFromHCForSelectedCatalogAndDatabase = async () => {
    let request = {
      DatabaseName: props.selectedDatabaseName,
      CatalogId: props.activeWorkspace.accountId,
      NextToken: undefined,
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
    };
    let response = await getDataSetsFromHybridCatalogDatabase(request);
    let dataSetList = [...response.DataSetList];
    while (response.NextToken != null) {
      request.NextToken = response.NextToken;
      response = await getDataSetsFromHybridCatalogDatabase(request);
      dataSetList.push(...response.DataSetList);
    }
    return dataSetList;
  };

  const handleRefresh = async () => {
    setLoadingDatasets(true);
    let glueTablesMap = new Map();
    let getDataSourceTablesRequest = {
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
      CatalogId: props.activeWorkspace.accountId,
      DatabaseName: props.selectedDatabaseName,
      Region: props.activeWorkspace.region,
      NextToken: null,
    };
    try {
      let tableList = [];
      let getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
      tableList = getDataSourceTablesResponse.TableList;
      while (getDataSourceTablesResponse.NextToken != null) {
        getDataSourceTablesRequest.NextToken = getDataSourceTablesResponse.NextToken;
        getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
        tableList.push(...getDataSourceTablesResponse.TableList);
      }
      setGlueTables(tableList);
      for (let table of tableList) {
        glueTablesMap.set(table.Name, table);
      }
    } catch (err) {
      setLoadingDatasets(false);
      console.log('Error getting glue tables from: ', props.selectedDatabaseName, err);
    }
    let hcRegisteredDatasets = await getAllDatasetsFromHCForSelectedCatalogAndDatabase();
    let autoRegisterDatasetsList = [];
    for (let dataSet of hcRegisteredDatasets) {
      if (
        dataSet?.DataAccessRole == props.activeWorkspace.workspaceRoleArn &&
        glueTablesMap.has(dataSet?.IdInfo.TableName)
      ) {
        let registeredTableInfo = glueTablesMap.get(dataSet?.IdInfo.TableName);
        registeredTableInfo.registered = true;
        registeredTableInfo.PII = dataSet?.PII ?? false;
        registeredTableInfo.DataClassification = dataSet?.DataClassification;
        glueTablesMap.set(dataSet?.IdInfo.TableName, registeredTableInfo);
      }
      // if migrating from group to workspace register all existing datasets
      if (glueTablesMap.has(dataSet?.IdInfo.TableName)) {
        let registeredTableInfo = glueTablesMap.get(dataSet?.IdInfo.TableName);
        if (registeredTableInfo.registered == undefined || !registeredTableInfo.registered) {
          registeredTableInfo.makeSelectedByDefault = true;
          glueTablesMap.set(dataSet?.IdInfo.TableName, registeredTableInfo);
          // insert into autoSelectRegisterDatasetList
          autoRegisterDatasetsList.push({
            label: dataSet?.IdInfo.TableName,
            labelTag: dataSet?.IdInfo.DatabaseName,
            disabled: true,
            pii: false, // all datasets at group are non PII
            tags: [],
          });
        }
      }
    }
    setAutoRegisterDatasets(autoRegisterDatasetsList);
    props.setRegisterItems(autoRegisterDatasetsList);
    let haveAllTablePermission = await checkAllTablePermission();
    props.setHasAllTablePermission(haveAllTablePermission);
    setLoadingDatasets(false);
  };

  const isDatasetSelected = (name) => selectedDatasets?.find((e) => e?.Name == name) !== undefined;

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'datasetName',
      header: 'Dataset name',
      cell: (item) => item.Name,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => registerStatus(item.registered !== undefined),
      minWidth: 200,
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (_) => props.selectedDatabaseName,
      minWidth: 200,
    },
    {
      id: 'isPII',
      header: (
        <>
          Has PII data{' '}
          <Link external={true} href={PII_DEFINITION_URL}>
            info
          </Link>
        </>
      ),
      cell: (item) => (
        <Toggle
          onChange={({ detail }) => {
            setPIIDatasets((prevState) => ({
              ...prevState,
              [item.Name]: detail.checked,
            }));
          }}
          checked={
            piiDatasets[item.Name] || // marked as pii by the user
            item.PII || // registered and pii
            (piiDatasets[item.Name] === undefined && item.PII === undefined) // default to pii
          }
          disabled={isItemDisabled(item)}
        />
      ),
      minWidth: 200,
    },
    {
      id: 'dataClassification',
      header: (
        <>
          Classification level{' '}
          <Link external={true} href={DATA_CLASSIFICATION_LEVELS_URL}>
            info
          </Link>
        </>
      ),
      cell: (item) => (
        <ClassificationBadge classification={getClassificationOption(item)?.value} disabled={isItemDisabled(item)} />
      ),
      minWidth: 200,
      editConfig: {
        ariaLabel: 'Type',
        editIconAriaLabel: 'editable',
        disabledReason: (item) => {
          if (isItemDisabled(item)) {
            return 'You can only change the classification level of datasets you select.';
          }
        },
        editingCell: (item, { setValue }) => {
          return (
            <Select
              selectedOption={getClassificationOption(item)}
              autoFocus={true}
              expandToViewport={true}
              onChange={({ detail }) => {
                setValue(detail.selectedOption.value);
                setClassifications((prevState) => ({
                  ...prevState,
                  [item.Name]: detail.selectedOption,
                }));
              }}
              options={dataClassificationOptions}
              disabled={isItemDisabled(item)}
            />
          );
        },
      },
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(
    glueTables,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: { keepSelection: true },
      propertyFiltering: {
        filteringProperties: [
          {
            propertyLabel: 'Dataset name',
            key: 'Name',
            groupValuesLabel: 'Dataset names',
          },
        ],
      },
    },
  );

  useEffect(() => {
    const { selectedItems } = collectionProps;
    setSelectedDatasets(selectedItems);
  }, [collectionProps.selectedItems]);

  useEffect(() => {
    handleDatasetsUpdate();
  }, [selectedDatasets, piiDatasets, classifications]);

  const handleDatasetsUpdate = () => {
    let itemsToBeRegistered = [];
    for (let data of selectedDatasets ?? []) {
      let tags = [];
      if (piiDatasets[data?.Name] || piiDatasets[data?.Name] == undefined) {
        tags.push('PII');
      }
      if (classifications[data?.Name]) {
        tags.push(classifications[data?.Name].label);
      } else {
        tags.push(defaultClassificationOption.label);
      }
      itemsToBeRegistered.push({
        label: data?.Name,
        labelTag: props.selectedDatabaseName,
        disabled: false,
        pii: piiDatasets[data.Name] ?? false,
        dataClassification: classifications[data?.Name]?.value ?? defaultClassificationOption.value,
        tags: tags,
      });
    }
    // for group to workspace migration
    for (let data of autoRegisterDatasets ?? []) {
      itemsToBeRegistered.push(data);
    }
    props.setRegisterItems(itemsToBeRegistered);
    props.setRegisterDataPermissionType('ALLTablePermission');
  };

  const checkAllTablePermission = async () => {
    let request = {
      ownerId: props.activeWorkspace.workspaceId,
      resource: {
        table: {
          databaseName: props.selectedDatabaseName,
          catalogId: props.activeWorkspace.accountId,
          tableWildcard: {},
        },
      },
      region: props.activeWorkspace.region,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      option: DATA_PERMISSION_PUBLISHER_OPTION,
    };
    try {
      let listAllTablesPermissionResponse = await listDataPermissions(request);
      if (
        listAllTablesPermissionResponse !== undefined &&
        listAllTablesPermissionResponse.dataPermissionList.length > 0
      ) {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingDatasets}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        submitEdit={() => {}}
        header={
          <Header variant={'h2'} counter={`(${glueTables.length})`}>
            Lake Formation datasets
          </Header>
        }
        selectionType='multi'
        isItemDisabled={(glueTable) => glueTable.registered || glueTable.makeSelectedByDefault}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        empty={<EmptyState title={'No datasets'} subtitle={'No datasets were found.'} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatasets}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />

      <br />
      <br />
      {(selectedDatasets || autoRegisterDatasets) && (
        <>
          {props.registerItems.length !== 0 && (
            <Container
              header={
                <Header variant='h2' description='Registers the selected and existing legacy datasets to workspace'>
                  Datasets to register
                </Header>
              }
            >
              {/*Token group includes selected and autoselected datasets to register*/}
              <TokenGroup
                items={props.registerItems}
                alignment='vertical'
                onDismiss={({ detail: { itemIndex } }) => {
                  props.setRegisterItems([
                    ...props.registerItems.slice(0, itemIndex),
                    ...props.registerItems.slice(itemIndex + 1),
                  ]);
                }}
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};
