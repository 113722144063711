import { getRegion, getStage } from 'src/api/config';
import { Alert } from '@amzn/awsui-components-react-v3';
import React from 'react';

export const DZ_GET_STARTED_WIKI_URL =
  'https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Omni/OmniDataZone/';

export const DZ_PORTAL_ALPHA_IAD = 'https://dzd_dg6czr1i9510dj.datazone.us-east-1.on.aws';
export const DZ_PORTAL_BETA_IAD = 'https://dzd_cq59m36doacyw7.datazone.us-east-1.on.aws';
export const DZ_PORTAL_GAMMA_IAD = 'https://dzd_bb078415azr1uv.datazone.us-east-1.on.aws';
export const DZ_PORTAL_PROD_IAD = 'https://dzd_3j2akeui3b0rdz.datazone.us-east-1.on.aws';

export const dataZonePortalUrl = () => {
  const stage = getStage();
  switch (stage) {
    case 'local':
      return DZ_PORTAL_ALPHA_IAD;
    case 'beta':
      return DZ_PORTAL_BETA_IAD;
    case 'gamma_iad':
      return DZ_PORTAL_GAMMA_IAD;
    case 'prod_iad':
      return DZ_PORTAL_PROD_IAD;
  }
  return '';
};

export const dataZoneIsAvailable = () => {
  return getRegion() == 'us-east-1';
};

export const DataZoneUnavailableHeader = () => {
  return (
    <Alert type={'error'} header={'Could not load page'}>
      Omni-DataZone integration is only available in the <b>us-east-1</b> region.
    </Alert>
  );
};
