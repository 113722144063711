import * as React from 'react';
import { useEffect, useState } from 'react';
import { Textarea, ColumnLayout, Container, Header } from '@amzn/awsui-components-react-v3';
import { getNotification, updateNotifications } from 'src/api/notifications';
import { NotificationComments } from 'src/components/notifications/comment';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface NotificationDetailPageProps {
  activeWorkspace: any;
  setContentType: any;
  match: any;
  activeGroup: string;
  username: string;
}

export const NotificationDetailPage = (props: NotificationDetailPageProps) => {
  const [notification, setNotification] = useState({});

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
    props.setContentType(TABLE_CONTENT_TYPE);
  }, [props.match.params.NotificationId]);

  const handleRefresh = async () => {
    try {
      const notification = await getNotification({
        NotificationId: props.match.params.NotificationId,
        NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
      });

      setNotification(notification.Notification);
      await updateNotifications({
        NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
        NotificationIdList: [notification.Notification.NotificationId],
        MarkRead: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Container header={<Header variant='h2'>{notification['Subject']}</Header>}>
        <Textarea value={notification['Content']} readOnly rows={10} />
      </Container>
      <br />

      <Container header={<Header variant={'h2'}>Details</Header>}>
        <ColumnLayout columns={4} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>Sender</div>
            <div>
              {' '}
              <a href={`https://phonetool.amazon.com/users/${notification['SenderId']}`}>{notification['SenderId']} </a>
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Sender group or workspace</div>
            <div> {notification['NotificationOwnerId']}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Priority</div>
            <div> {notification['PriorityLevel']}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Email sent</div>
            <div> {notification['EmailSent'] ? 'Sent' : 'Not Sent'}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Create time</div>
            <div> {new Date(notification['CreateTime']).toLocaleString()}</div>
          </div>
        </ColumnLayout>
      </Container>
      {notification['NotificationBatchId'] && notification['NotificationId'] && (
        <NotificationComments
          {...props}
          notificationBatchId={notification['NotificationBatchId']}
          notificationId={notification['NotificationId']}
        />
      )}
    </>
  );
};
