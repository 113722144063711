import * as React from 'react';
import { useState, useEffect } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { editDataLakeRole, getGroupsConsumingDataset } from 'src/api/permissions';
import { Box, Button, Header, Link, Table, SpaceBetween, Modal } from '@amzn/awsui-components-react-v3';
import { Page } from 'src/routes';
import { EmptyState } from 'src/commons/EmptyState';

export interface IamConsumersTableProps {
  dataset: any;
  datasetId: string;
  activeGroup: string;
  onFailure: any;
  onSuccess: any;
}

const IamConsumersTable = (props: IamConsumersTableProps) => {
  const [allItems, setAllItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);

  const columnDefinitions = [
    {
      id: 'groupId',
      header: 'Group ID',
      cell: (item) => (item ? <Link href={Page.GROUP + '/' + item.Id}>{item.Id}</Link> : ''),
      minWidth: 200,
    },
  ];

  const getGroups = async () => {
    const groupsConsumingDataset = await getGroupsConsumingDataset({
      datasetId: props.datasetId,
    });

    const groups = groupsConsumingDataset.groupsList;
    const formattedGroups = groups.map((e) => ({ Id: e.groupId }));

    setAllItems(formattedGroups);
    setLoading(false);
  };

  const handleRevoke = async () => {
    setRevokeLoading(true);

    const response = await editDataLakeRole({
      groupId: collectionProps.selectedItems[0].Id,
      datasetsToDelete: [props.datasetId],
    });

    const message = response.message;
    closeModal();
    getGroups();

    if (message == 'SUCCEEDED') props.onSuccess('Successfully revoked the access.');
    else props.onFailure('Successfully revoked the access.');
  };

  const openModal = () => setModalVisible(true);
  const closeModal = () => {
    setModalVisible(false);
    setRevokeLoading(true);
  };

  const { items, collectionProps } = useCollection(allItems, {
    filtering: {},
    pagination: { pageSize: 25 },
    sorting: {},
    selection: {},
  });

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <>
      <Modal
        onDismiss={() => {
          closeModal();
        }}
        visible={modalVisible}
        size='medium'
        header='Confirm revocation'
        footer={
          <Box float={'right'}>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </Button>
              <Button variant='primary' onClick={handleRevoke} loading={revokeLoading} disabled={revokeLoading}>
                Revoke
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        Are you sure you want to revoke this access? The following groups will have their permission to this dataset
        removed:
        <ul>
          {collectionProps.selectedItems.map((e) => (
            <li>{e.Id}</li>
          ))}
        </ul>
      </Modal>

      <Table
        {...collectionProps}
        loadingText='Loading groups'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        selectionType='single'
        header={
          <Header
            counter={`(${items.length})`}
            actions={
              <Button
                onClick={() => {
                  openModal();
                }}
                disabled={collectionProps.selectedItems.length < 1}
              >
                Revoke access
              </Button>
            }
          >
            Consuming groups
          </Header>
        }
        empty={<EmptyState title='No groups' subtitle='No one is consuming this dataset.' />}
        loading={loading}
      />
    </>
  );
};

export default IamConsumersTable;
