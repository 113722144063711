import { getMetricCollection } from 'src/api/metricstore';
import { ITableUserFriendlyDataDefinition } from 'src/components/common/csvDownloaderWrapper';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { ViewType } from 'src/components/metricstore/helpers';
import { MetricStore } from '@amzn/metric-store-typescript-client';
import { createMetricDefinitionLink } from 'src/routes';
import { Link } from 'react-router-dom';
import React from 'react';

type MetricData = MetricStore.MetricCollectionData;
type MetricCollectionResponse = MetricStore.GetMetricCollectionDataOutput;

interface ProcessedMetricItem {
  Metric: string;
  Id: string;
  [key: string]: string | number;
}

// Since we don't generate enums in the Smithy build files, it shows up as a type in the typescript definition and hence
// cannot be imported and used directly. To fix, change is required in smithy package build definition.
export enum MetricError {
  EMPTY = 'EMPTY',
  ERROR = 'ERROR',
  INVALID = 'INVALID',
  FORBIDDEN = 'FORBIDDEN',
}

// Frontend display value enum
export enum MetricErrorDisplayValue {
  MISSING = 'MISSING',
  INVALID = 'INVALID',
  FORBIDDEN = 'FORBIDDEN',
  ERROR = 'ERROR',
}

// Now we can use the enum values for comparison
function mapMetricError(error: MetricStore.MetricError): MetricErrorDisplayValue {
  switch (error) {
    case MetricError.EMPTY:
    case MetricError.ERROR:
      return MetricErrorDisplayValue.MISSING;
    case MetricError.INVALID:
      return MetricErrorDisplayValue.INVALID;
    case MetricError.FORBIDDEN:
      return MetricErrorDisplayValue.FORBIDDEN;
    default:
      return MetricErrorDisplayValue.ERROR; // fallback case
  }
}

/**
 * Calculates and adds increment values (MoM, WoW, QoQ, YoY) to metric data.
 * Note: The below function assumes that the intervalsArray contains the complete metric intervals in ascending
 * order. Also assumes that the latest metric datapoint corresponds to an interval value which is in the past.
 *
 * @param {ProcessedMetricItem[]} allMetricData - Array of processed metric items
 * @param {string[]} intervalsArray - Array of column names in ascending order
 * @param {ViewType} viewType - Type of view (MONTHLY, WEEKLY, QUARTERLY, YEARLY)
 * @returns {ProcessedMetricItem[]} Metric data with added increment values
 */
function addIncrementValuesColumn(
  allMetricData: ProcessedMetricItem[],
  intervalsArray: string[],
  viewType: ViewType,
): ProcessedMetricItem[] {
  // Define increment types with their display names
  const incrementTypes: Record<ViewType, string> = {
    [ViewType.MONTHLY]: 'MoM',
    [ViewType.WEEKLY]: 'WoW',
    [ViewType.QUARTERLY]: 'QoQ',
    [ViewType.YEARLY]: 'YoY',
  };
  // Fallback to YoY
  let incrementType = incrementTypes[viewType] || 'YoY';
  intervalsArray.push(incrementType);

  const currentValueIndex = intervalsArray.length - 2;
  const previousValueIndex = intervalsArray.length - 3;

  return allMetricData.map((metric) => {
    let incrementValue: string = 'N/A';
    const currentValue = Number(metric[intervalsArray[currentValueIndex]]);
    const previousValue = Number(metric[intervalsArray[previousValueIndex]]);
    try {
      // Check for falsy metric values https://developer.mozilla.org/en-US/docs/Glossary/Falsy
      if (!+previousValue || !currentValue) {
        throw new Error('Invalid data for division operator');
      }
      incrementValue = (((currentValue - previousValue) / previousValue) * 100).toFixed(2) + '%';
    } catch (error) {
      incrementValue = 'N/A';
    }
    return {
      ...metric,
      [incrementType]: incrementValue,
    };
  });
}

export function getColumnDefinitionCollectionView(tableData: string[]) {
  let columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
  let csvColumnDefinition: ITableUserFriendlyDataDefinition[] = [];
  let csvDataColumn: { [key: string]: string } = {};
  csvDataColumn['Metric'] = '';
  columnDefinitions.push({
    id: 'Metric',
    header: 'Metric',
    cell: (item) => <Link to={createMetricDefinitionLink(item['Id'])}>{item['Metric']}</Link>,
    minWidth: 300,
    sortingField: 'Metric',
  });

  for (const cellData of tableData) {
    columnDefinitions.push({
      id: cellData,
      header: cellData,
      cell: (item) => {
        const value = item[cellData];
        if (Object.values(MetricErrorDisplayValue).includes(value)) {
          return (
            <span
              style={{
                color: '#D13212', // Red to indicate erroneous value
                fontSize: '0.8em', // Small font size to maintain readability of the webpage
              }}
            >
              {value}
            </span>
          );
        }
        return value;
      },
      minWidth: 150,
      sortingField: cellData,
    });
    csvDataColumn[cellData] = '';
  }

  console.log('column definition is:', columnDefinitions);

  for (const key in csvDataColumn) {
    csvColumnDefinition.push({
      header: key,
      friendlyName: (csvData: Array<Record<string, string>>) => csvData[key],
    });
  }
  return [columnDefinitions, csvColumnDefinition];
}

/**
 * Fetches and processes metric collection data, handling the loading state, data transformation,
 * and error management for the metric collection view.
 *
 * @async
 * @param {string} metricCollectionId - Unique identifier for the metric collection to fetch
 * @param {Function} setMetricData - State setter function for updating interval data
 * @param {Function} setLoadingTableData - State setter function for managing loading state
 * @param {Function} setAllMetricItems - State setter function for updating processed metric items
 * @param {Function} setError - State setter function for handling errors
 * @param {ViewType} viewType - Type of view (MONTHLY, WEEKLY, etc.)
 * @returns {Promise<void>} Promise that resolves when all operations are complete
 */
export const handleRefresh = async (
  metricCollectionId: string,
  setMetricData: (intervals: string[]) => void,
  setLoadingTableData: (isLoading: boolean) => void,
  setAllMetricItems: (items: ProcessedMetricItem[]) => void,
  setError: (error: Error) => void,
  viewType: ViewType,
): Promise<void> => {
  try {
    setLoadingTableData(true);
    // Fetching data from backend via api
    const metricCollectionData: MetricCollectionResponse = await getMetricCollection({
      metricCollectionId,
      viewType,
    });

    const { intervals, data } = metricCollectionData;
    //Processes the metrics into the required format for display on frontend.
    const processedMetricItems: ProcessedMetricItem[] = processMetricData(data, intervals);
    // The required order of display on Omni is reverse of the api response. Hence, reversing the intervals array.
    const reverseIntervals = [...intervals].reverse();
    // Add increment values
    const metricsWithIncrements = addIncrementValuesColumn(processedMetricItems, reverseIntervals, viewType);

    // Updates the UI state variables with the processed data.
    setMetricData(reverseIntervals);
    setAllMetricItems(metricsWithIncrements);
  } catch (error) {
    console.error('Error fetching metric collection data:', error);
    setError(error);
  } finally {
    setLoadingTableData(false);
  }
};

/**
 * Processes raw metric data and transforms it into a structured format with interval-based values.
 *
 * @param {MetricData[]} data - Array of metric data objects containing names, ids, values or potential errors
 * @param {string[]} intervals - Array of time intervals for the metric data points
 * @returns {ProcessedMetricItem[]} Array of processed metrics with interval-based values
 */
const processMetricData = (data: MetricData[], intervals: string[]): ProcessedMetricItem[] => {
  return data.map((metricData) => {
    // Create the metric base with required fields
    const baseMetric = {
      Metric: metricData.name,
      Id: metricData.id,
    };

    // Handles the case where the entire metric is erroneous. Includes cases such as FORBIDDEN or INVALID metrics.
    if (metricData.error) {
      return intervals.reduce(
        (acc, interval) => ({
          ...acc,
          [interval]: metricData.error,
        }),
        baseMetric,
      );
    }

    // Returns empty string for cases where the metric data is available but is erroneous.
    return intervals.reduce(
      (acc, interval, index) => ({
        ...acc,
        [interval]: metricData.values[index]?.error
          ? mapMetricError(metricData.values[index].error)
          : metricData.values[index]?.value,
      }),
      baseMetric,
    );
  });
};
