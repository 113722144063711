import { useEffect, useState } from 'react';
import { FORM_CONTENT_TYPE, hcMetadataFormType, hcMetadataStatus } from 'src/commons/constants';
import { Redirect } from 'react-router-dom';
import * as React from 'react';
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Page } from 'src/routes';
import { createMetadataForm } from 'src/api/catalog';

export interface CreateMetadataFormProps {
  setContentType: any;
  location: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const CreateMetadataForm = (props: CreateMetadataFormProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [formType, setFormType] = useState(undefined);
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    props.setContentType(FORM_CONTENT_TYPE);
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
  }, []);

  const handleCreateMetadataForm = async () => {
    try {
      let createFormResult = await createMetadataForm({
        OwnerId: ownerId,
        Name: name,
        Description: description,
        Status: status.value,
        FormType: formType.value,
      });
      if (createFormResult.Message == 'Success') {
        setRedirect({
          pathname: Page.METADATA_FORMS,
          state: {
            status: 'success',
            message: 'Form created successfully',
          },
        });
      } else {
        await setNotification('error', `Failed to create form. Reason: ${createFormResult.Message}`);
      }
    } catch (err) {
      setNotification('error', `Error in creating form. ${err.message}`);
    } finally {
      setButtonLoading(false);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'error') {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      <Flashbar items={notifications} />
      <Form
        header={
          <Header variant='h1' description='Please specify the details of your policy.'>
            Create metadata form
          </Header>
        }
        actions={
          <SpaceBetween direction='horizontal' size='s'>
            <Button variant='link' onClick={() => setRedirect(Page.METADATA_FORMS)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={buttonLoading}
              onClick={handleCreateMetadataForm}
              disabled={!name || !description || !formType || !status}
            >
              {'Create'}
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header variant='h2'>Form details</Header>}>
          <ColumnLayout>
            <SpaceBetween size='m'>
              <FormField label={<div>Owner</div>} description='Owner of the form.'>
                <Input name='ownerId' placeholder='Form owner' value={ownerId} ariaRequired={true} disabled={true} />
              </FormField>
              <FormField label={<div>Name</div>} description='Name of the form.'>
                <Input
                  name='name'
                  placeholder='Name of the form'
                  value={name}
                  onChange={({ detail }) => setName(detail.value)}
                  ariaRequired={true}
                />
              </FormField>
              <FormField label={<div>Description</div>} description='Description of the form.'>
                <Input
                  name='description'
                  placeholder='Description of the form'
                  value={description}
                  onChange={({ detail }) => setDescription(detail.value)}
                  ariaRequired={true}
                />
              </FormField>
              <FormField label={<div>Type</div>} description='Select a form type'>
                <Select
                  name='formType'
                  selectedOption={formType}
                  ariaRequired={true}
                  placeholder='Choose a type'
                  onChange={({ detail }) => setFormType(detail.selectedOption)}
                  options={hcMetadataFormType}
                  selectedAriaLabel='Selected'
                />
              </FormField>
              <FormField label={<div>Status</div>} description='Set it to enabled to be visible to consumers'>
                <Select
                  name='status'
                  selectedOption={status}
                  ariaRequired={true}
                  placeholder='Choose a type'
                  onChange={({ detail }) => setStatus(detail.selectedOption)}
                  options={hcMetadataStatus}
                  selectedAriaLabel='Selected'
                />
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </Form>
    </>
  );
};

export default CreateMetadataForm;
