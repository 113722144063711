import * as React from 'react';
import {
  ColumnLayout,
  Container,
  ExpandableSection,
  Header,
  KeyValuePairs,
  StatusIndicator,
  Toggle,
  TokenGroup,
} from '@amzn/awsui-components-react-v3';
import { Link } from '@amzn/awsui-components-react-v3/polaris';
import {
  GLOBAL_TEMPLATE_MODIFICATION_REQUEST_TICKET_TEMPLATE_LINK,
  GLOBAL_TEMPLATE_RULE_NAME_TO_USER_FRIENDLY_RULE_NAME,
  VALIDATE_IS_ISENGARD_ACCOUNT,
  VALIDATE_IS_ISENGARD_ACCOUNT_CUSTOM_PATTERN,
  VALIDATE_IS_PRODUCTION_ACCOUNT,
  VALIDATE_IS_PRODUCTION_ACCOUNT_CUSTOM_PATTERN,
} from 'src/commons/constants';

export const TemplateDetailsComponent = ({ template }) => (
  <Container header={<Header variant={'h2'}>Template details</Header>}>
    <KeyValuePairs
      columns={4}
      items={[
        { label: 'Owner', value: template.ownerId },
        { label: 'Template ID', value: template.templateId },
        { label: 'Template name', value: template.name },
        { label: 'Scope', value: template.scope },
        { label: 'Template type', value: template.templateType },
        template?.accessManagementInfo != undefined && template.accessManagementInfo?.simTemplates != undefined
          ? {
              label: 'SIM template link',
              value: (
                <Link href={template.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink} external={true}>
                  {'SIM template'}
                </Link>
              ),
            }
          : undefined,
        template?.accessManagementInfo != undefined &&
        template.accessManagementInfo?.approvalsWorkflowTemplate != undefined
          ? {
              label: 'Approval template link',
              value: (
                <Link
                  href={template.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink}
                  external={true}
                >
                  {'Approval template'}
                </Link>
              ),
            }
          : undefined,
        template?.accessManagementInfo != undefined &&
        template.accessManagementInfo?.approvalsWorkflowTemplate != undefined
          ? {
              label: 'Auto approval',
              value: (
                <StatusIndicator
                  type={
                    template.accessManagementInfo?.approvalsWorkflowTemplate?.autoApproval.toString() == 'true'
                      ? 'success'
                      : 'stopped'
                  }
                >
                  {template.accessManagementInfo?.approvalsWorkflowTemplate?.autoApproval.toString() == 'true'
                    ? 'Enabled'
                    : 'Disabled'}
                </StatusIndicator>
              ),
            }
          : undefined,
        template?.accessManagementInfo != undefined &&
        template.accessManagementInfo?.approvalsWorkflowTemplate != undefined
          ? {
              label: 'Auto merge workflows',
              value: (
                <StatusIndicator
                  type={
                    template.accessManagementInfo?.approvalsWorkflowTemplate?.allowMergeApprovalWorkflow.toString() ==
                    'true'
                      ? 'success'
                      : 'stopped'
                  }
                >
                  {template.accessManagementInfo?.approvalsWorkflowTemplate?.allowMergeApprovalWorkflow.toString() ==
                  'true'
                    ? 'Enabled'
                    : 'Disabled'}
                </StatusIndicator>
              ),
            }
          : undefined,
      ].filter((item) => item != undefined)}
    />
    {getTemplateRules(template)}
  </Container>
);

const getUserFriendlyRuleName = (ruleName) => {
  if (!ruleName) return ruleName;
  let after =
    ruleName in GLOBAL_TEMPLATE_RULE_NAME_TO_USER_FRIENDLY_RULE_NAME
      ? GLOBAL_TEMPLATE_RULE_NAME_TO_USER_FRIENDLY_RULE_NAME[ruleName]
      : ruleName;
  return after;
};

const getRuleExceptionItems = (baseKey, rules) => {
  // Base rule is disabled. No exceptions should exist.
  if (!rules[baseKey]) return [];

  const items = [];
  Object.entries(rules).forEach(([key, value]) => {
    switch (baseKey) {
      case VALIDATE_IS_ISENGARD_ACCOUNT:
        if (key.startsWith(VALIDATE_IS_ISENGARD_ACCOUNT_CUSTOM_PATTERN) && !value) {
          const accountId = key.split(VALIDATE_IS_ISENGARD_ACCOUNT_CUSTOM_PATTERN)[1];
          items.push({
            label: accountId,
            description: `Account is exempted from this rule`,
            disabled: true,
          });
        }
        break;
      case VALIDATE_IS_PRODUCTION_ACCOUNT:
        if (key.startsWith(VALIDATE_IS_PRODUCTION_ACCOUNT_CUSTOM_PATTERN) && !value) {
          const accountId = key.split(VALIDATE_IS_PRODUCTION_ACCOUNT_CUSTOM_PATTERN)[1];
          items.push({
            label: accountId,
            description: `Account is exempted from this rule`,
            disabled: true,
          });
        }
        break;
    }
  });
  return items;
};

export const getTemplateRules = (template) => {
  if (
    !template ||
    !template.accessManagementInfo ||
    !template.accessManagementInfo.booleanRules ||
    !Object.keys(template.accessManagementInfo.booleanRules).length
  )
    return;

  const rules = template.accessManagementInfo.booleanRules;
  const rulesToDisplay = [VALIDATE_IS_ISENGARD_ACCOUNT, VALIDATE_IS_PRODUCTION_ACCOUNT];
  rulesToDisplay.sort();
  const ruleDivs = [];
  rulesToDisplay.forEach((key) => {
    if (!(key in rules)) return;
    const ruleName = getUserFriendlyRuleName(key);
    const exceptionItems = getRuleExceptionItems(key, rules);
    ruleDivs.push(
      <div>
        <div>
          <Toggle checked={rules[key]} disabled={true}>
            {ruleName}
          </Toggle>
        </div>
        <div>
          <TokenGroup items={exceptionItems} />
        </div>
      </div>,
    );
  });

  const descriptionMsg = `Rules for your ${
    template.resourceId
  } ${template.scope.toLowerCase()}. To modify, submit a request `;
  return (
    <ExpandableSection
      headerText='Rules'
      defaultExpanded={true}
      headerDescription={
        (
          <>
            {descriptionMsg}
            <Link variant='info' href={GLOBAL_TEMPLATE_MODIFICATION_REQUEST_TICKET_TEMPLATE_LINK}>
              here
            </Link>
            .
          </>
        ) as unknown as string // headerDescription excepts a string
      }
    >
      <ColumnLayout columns={4} variant='text-grid'>
        {ruleDivs}
      </ColumnLayout>
    </ExpandableSection>
  );
};
