import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Flashbar,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
  TextContent,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  createDataPermission,
  editTag,
  getTaggedResources,
  getTags,
  getTagSharedWorkspaces,
  importLakeFormationTags,
  listDataPermissions,
} from '../../api/permissions';

import { Page } from '../../routes/Paths';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import TaggedResourcesTable from './taggedResourcesTable';
import TagSharedWorkspacesTable from './tagSharedWorkspacesTable';
import {
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  LAKEFORMATION_TAG_TYPE,
  REDSHIFT_TAG_TYPE,
  TABLE_CONTENT_TYPE,
  TABLE_RESOURCE_TYPE,
} from 'src/commons/constants';
import { EmptyState } from 'src/commons/EmptyState';
import { getFoundryRoleARN } from 'src/commons/common';
import { PageHeader } from 'src/components/common/PageHeader';

export interface TagsProps {
  username: string;
  setContentType: any;
  match: any;
  activeGroup: string;
  activeWorkspace: any;
}

const Tags = (props: TagsProps) => {
  // "globally" constant data, relative to this specific page
  const [redirect, setRedirect] = useState(undefined);
  const { state } = useLocation();
  const [ownerId, setOwnerId] = useState('');

  // filtered backend data
  const [tags, setTags] = useState([]);
  const [taggedResources, setTaggedResources] = useState([]);
  const [tagSharedWorkspaces, setTagSharedWorkspaces] = useState([]);

  // loading spinners
  const [loadingTags, setLoadingTags] = useState(true);
  const [loadingTaggedResources, setLoadingTaggedResources] = useState(false);
  const [loadingTagSharedWorkspaces, setLoadingTagSharedWorkspaces] = useState(false);

  // element management variables
  const [importLFTagsModalVisible, setImportLFTagsModalVisible] = useState(false);
  const [deleteTagModalVisible, setDeleteTagModalVisible] = useState(false);
  const [publishTagModalVisible, setPublishTagModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [tableMessage, setTableMessage] = useState('No tag members');

  // currently selected tag's data
  const [tagPair, setTagPair] = useState('');
  const [tagType, setTagType] = useState('');
  const [tagCatalogId, setTagCatalogId] = useState('');
  const [tagRegion, setTagRegion] = useState('');
  const [isTagPublished, setIsTagPublished] = useState(false);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(tags, {
    filtering: {
      noMatch: '',
      empty: <EmptyState title='No tags' subtitle='No tags to display.' />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const tagsColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'accountId',
      header: 'Account',
      cell: (tag) => tag.catalogId,
      minWidth: '200px',
      sortingField: 'account',
    },
    {
      id: 'region',
      header: 'Region',
      cell: (tag) => tag.region,
      minWidth: '200px',
      sortingField: 'region',
    },
    {
      id: 'tagKey',
      header: 'Key',
      cell: (tag) => tag.tagKey,
      minWidth: '200px',
      sortingField: 'tagKey',
    },
    {
      id: 'tagValue',
      header: 'Value',
      cell: (tag) => tag.tagValue,
      minWidth: '200px',
      sortingField: 'tagValue',
    },
    {
      id: 'type',
      header: 'Tag type',
      cell: (tag) => (tag.tagType == REDSHIFT_TAG_TYPE ? REDSHIFT_TAG_TYPE : LAKEFORMATION_TAG_TYPE),
      minWidth: '200px',
    },
  ];

  // loads workspace data and ownerId of tags first upon page load, required for tags to load
  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);

    const activeWorkspace = props.activeWorkspace;

    if (activeWorkspace) {
      const ownerId = props.match.params.id ? props.match.params.id : activeWorkspace.workspaceId;
      setOwnerId(ownerId);

      // update notifications after being redirected to this page (from create/edit tag)
      // since they're new Redirects, this hook function will run every time
      // state will be changed only when page URL changes (but not on refresh)
      if (state) {
        setNotification(state.status, state.message);
      }
    } else {
      setRedirect(Page.HOME);
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, [ownerId]);

  // loads resources and IAM tables after tag is selected
  useEffect(() => {
    checkIsTagPublished();

    setLoadingTaggedResources(true);
    setLoadingTagSharedWorkspaces(true);

    fetchTaggedResources();
    fetchTagSharedWorkspaces();
  }, [tagPair, tagType, tagCatalogId, tagRegion]);

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedTag = selectedItems[0];
    if (selectedTag !== undefined) {
      setTagPair(selectedTag.tagPair);
      setTagCatalogId(selectedTag.catalogId);
      setTagRegion(selectedTag.region);
      selectedTag.tagType != null ? setTagType(selectedTag.tagType) : setTagType(LAKEFORMATION_TAG_TYPE);
    }
  }, [collectionProps.selectedItems]);

  // ------------------------------ REFRESH BUTTON ------------------- //
  const handleRefresh = async () => {
    setLoadingTags(true);
    setLoadingTaggedResources(true);
    setLoadingTagSharedWorkspaces(true);
    setTagPair('');
    setTagCatalogId('');
    setTagRegion('');
    setIsTagPublished(true);

    // refreshes all tables on page since there's only one refresh button. can be split into multiple buttons if needed
    await fetchTags();
    await fetchTaggedResources();
    await fetchTagSharedWorkspaces();
  };

  const fetchTags = async () => {
    setLoadingTags(true);
    try {
      const tagsResponse = await getTags({
        ownerId,
      });
      const tags = tagsResponse.tags;

      // filter for publisher only tag entries, not consumers' tags, since database stories the shares too
      // ACCOUNT_TAG is guaranteed to be unique already, so any duplicates are indications of data errors
      let publisherTags = tags.filter((tag) => {
        return tag.statusCustomerType.includes('Publisher') && tag.resourceId === 'ACCOUNT_TAG';
      });
      setTags(publisherTags);
    } catch (err) {
      setTableMessage('Unable to load tag members.');
    }
    setLoadingTags(false);
  };

  const fetchTaggedResources = async () => {
    setLoadingTaggedResources(true);
    try {
      const lfTaggedResourcesResponse = await getTaggedResources({
        tagPair,
        catalogId: tagCatalogId,
        region: tagRegion,
        type: LAKEFORMATION_TAG_TYPE,
      });
      const redshiftTaggedResourcesResponse = await getTaggedResources({
        tagPair,
        catalogId: tagCatalogId,
        region: tagRegion,
        type: REDSHIFT_TAG_TYPE,
      });

      // filter for resources with unique resource type + name combination since there may be multiple resource entries in backend
      // https://stackoverflow.com/a/70406623/3587699
      let resources = lfTaggedResourcesResponse.resources;
      resources.push(...redshiftTaggedResourcesResponse.resources);
      const uniqueResources = [
        ...new Map(resources.map((v) => [JSON.stringify([v.resourceType, v.resourceName]), v])).values(),
      ];
      setTaggedResources(uniqueResources);
    } catch (err) {
      setTableMessage('Unable to load any resources associated with tag.');
    }
    setLoadingTaggedResources(false);
  };

  const fetchTagSharedWorkspaces = async () => {
    setLoadingTagSharedWorkspaces(true);
    try {
      const lfTagSharedWorkspaces = await getTagSharedWorkspaces({
        tagPair,
        catalogId: tagCatalogId,
        region: tagRegion,
        type: LAKEFORMATION_TAG_TYPE,
      });
      const redshiftTagSharedWorkspaces = await getTagSharedWorkspaces({
        tagPair,
        catalogId: tagCatalogId,
        region: tagRegion,
        type: REDSHIFT_TAG_TYPE,
      });
      let sharedWorkspaces = lfTagSharedWorkspaces.sharedWith;
      sharedWorkspaces.push(...redshiftTagSharedWorkspaces.sharedWith);

      setTagSharedWorkspaces(sharedWorkspaces);
    } catch (err) {
      setTableMessage('Failed to fetch workspaces consuming tag: ' + tagPair);
    }
    setLoadingTagSharedWorkspaces(false);
  };

  // ---------------------------- TAG BUTTONS ------------------ //
  const handleEditTag = async () => {
    if (tagCatalogId !== '') {
      setRedirect({
        pathname: Page.EDIT_TAGS_TEMPLATE,
        state: {
          tagCatalogId,
          tagRegion,
          tagKey: tagPair.split(':')[0],
          tagValue: tagPair.split(':')[1],
          tagType: tagType,
        },
      });
    } else {
      setNotification('Cannot Edit', 'Please select a tag to edit');
    }
  };

  const handleDeleteTag = async () => {
    setButtonLoading(true);
    try {
      // if array is empty, means that tag is not published yet, so feel free to delete

      // if tag selected has more than one value, delete the value instead of the whole tag
      // editTag will delete tag on backend if there are no more values attached to it
      const [tagKey, tagValue] = tagPair.split(':');
      const editTagResponse = await editTag({
        ownerId,
        type: tagType,
        tagKey,
        catalogId: tagCatalogId,
        region: tagRegion,
        tagValuesToAdd: [],
        tagValuesToDelete: [tagValue],
      });

      setDeleteTagModalVisible(false);

      const status = editTagResponse.status == 'Success' ? 'success' : 'error';

      if (status === 'error') {
        setNotification(status, editTagResponse.message);
      } else {
        setNotification('', 'Your tag pair has been deleted.');
      }
    } catch (e) {
      setDeleteTagModalVisible(false);
      setNotification('Failed to submit your request', `error: ${e}`);
    }
    setButtonLoading(false);
    handleRefresh();
  };

  const handlePublishTag = async () => {
    setButtonLoading(true);
    const tagKey = tagPair.split(':')[0];
    const tagValue = tagPair.split(':')[1];
    let dpResource = undefined;
    if (tagType === REDSHIFT_TAG_TYPE) {
      dpResource = {
        redshiftTagPolicy: {
          tagKey: tagKey,
          tagValue: tagValue,
          catalogId: tagCatalogId,
        },
      };
    } else {
      dpResource = {
        lFTagPolicy: {
          resourceType: TABLE_RESOURCE_TYPE,
          expression: [
            {
              tagKey: tagKey,
              tagValues: [tagValue],
            },
          ],
          catalogId: tagCatalogId,
        },
      };
    }
    try {
      await createDataPermission({
        ownerId,
        dataPermission: {
          ownerId,
          dataPermissionOwnerId: ownerId,
          option: DATA_PERMISSION_PUBLISHER_OPTION,
          type: tagType,
          dataPublisherRole: getFoundryRoleARN(tagRegion, tagCatalogId),
          dataConsumerRole: null,
          region: tagRegion,
          resource: dpResource,
          permissions: ['SELECT'],
          permissionsWithGrantOption: ['SELECT'],
          dataLakePrincipal: getFoundryRoleARN(tagRegion, tagCatalogId),
        },
      });

      setNotification('success', `Tag ${tagPair} from ${tagCatalogId} published successfully.`);
      handleRefresh();
    } catch (e) {
      setNotification('Failed to publish your tag', `error: ${e}`);
    }
    setPublishTagModalVisible(false);
    setButtonLoading(false);
  };

  const handleImportLFTag = async () => {
    setButtonLoading(true);
    try {
      let importLFTagsResponse = await importLakeFormationTags({
        workspaceId: props.activeWorkspace.workspaceId,
        accountId: props.activeWorkspace.accountId,
        region: props.activeWorkspace.region,
      });
      let importedTagCount = importLFTagsResponse.importedTagsCount;
      setImportLFTagsModalVisible(false);
      if (importedTagCount == 0) {
        setNotification('success', 'No new tags in workspace to import');
      } else {
        setNotification('success', 'Successfully imported tags to Foundry. Tags imported: ' + importedTagCount);
      }

      handleRefresh();
    } catch (err) {
      console.log('Error importing the tags from workspace account.', err);
      setImportLFTagsModalVisible(false);
      setNotification('error', 'Error importing the tags from workspace account. ' + err.message);
    }
    setButtonLoading(false);
  };

  const checkIsTagPublished = async () => {
    if (tagRegion === '' || tagPair === '' || tagCatalogId === '' || tagType === '') {
      return false;
    }

    // check if tag is already published; if it is, disable the delete button
    // TODO: one possible expansion is to check for option = Consumer, meaning publishers can delete tags if there are no consumer
    // but this would require EditTag to remove the "Publisher" entry from Data Permissions table as well, which is not implemented yet
    const tagKey = tagPair.split(':')[0];
    const tagValue = tagPair.split(':')[1];
    let dpResource = undefined;
    if (tagType === REDSHIFT_TAG_TYPE) {
      dpResource = {
        redshiftTagPolicy: {
          tagKey: tagKey,
          tagValue: tagValue,
          catalogId: tagCatalogId,
        },
      };
    } else {
      dpResource = {
        lFTagPolicy: {
          resourceType: TABLE_RESOURCE_TYPE,
          expression: [
            {
              tagKey: tagKey,
              tagValues: [tagValue],
            },
          ],
          catalogId: tagCatalogId,
        },
      };
    }
    const publishedTagPermissionList = await listDataPermissions({
      ownerId,
      region: tagRegion,
      resource: dpResource,
      status: DATA_PERMISSION_STATUS_ACTIVE, // inactive records exist for record audit history, cannot be turned into active, so we know only active entries matter for published check
      type: tagType,
      option: DATA_PERMISSION_PUBLISHER_OPTION,
    });
    // if tag has no data permissions (empty array), then it is not published
    const tagPermissions = publishedTagPermissionList.dataPermissionList;
    if (tagPermissions == undefined || tagPermissions.length == 0) {
      setIsTagPublished(false);
    } else {
      setIsTagPublished(true);
    }
  };

  // ---------------------------- HELPER FUNCTIONS ------------------ //
  const setNotification = (header, message) => {
    if (header === '' || header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const buttonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleButtonAction,
        items: [
          {
            text: 'Edit',
            id: 'editTag',
            disabled: tagPair === '',
          },
          {
            text: 'Delete',
            id: 'deleteTag',
            disabled: tagPair === '' || isTagPublished,
          },
          {
            text: 'Import LF tags',
            id: 'importTags',
          },
        ],
      },
      {
        text: 'Publish',
        onItemClick: () => {
          if (tagPair !== '') {
            setPublishTagModalVisible(true);
          }
        },
        disabled: tagPair === '' || isTagPublished,
      },
      {
        text: 'Create tag',
        variant: 'primary',
        onItemClick: () => setRedirect(Page.CREATE_TAGS_TEMPLATE),
      },
    ];
  };
  const handleButtonAction = async (e) => {
    if (e.detail.id === 'editTag') {
      handleEditTag();
    } else if (e.detail.id === 'deleteTag') {
      if (tagCatalogId !== '') setDeleteTagModalVisible(true);
    } else if (e.detail.id === 'importTags') {
      if (props.activeWorkspace.accountId !== undefined) {
        setImportLFTagsModalVisible(true);
      }
    }
  };

  return (
    <div>
      {redirect && <Redirect push to={redirect} />}

      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>

      <SpaceBetween size='l'>
        <Table
          {...collectionProps}
          loadingText='Loading tags...'
          loading={loadingTags}
          columnDefinitions={tagsColumnDefinitions}
          items={items}
          selectionType='single'
          variant='stacked'
          empty={<EmptyState title='No tags' subtitle='No tags to display.' />}
          wrapLines={false}
          resizableColumns={true}
          header={<PageHeader buttons={buttonOptions()} header={'Permissions tags'} />}
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter resources'
              filteringPlaceholder='Find resources'
              countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
            />
          }
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={{
                title: 'Page size',
                options: [
                  { value: 5, label: '5 items' },
                  { value: 10, label: '10 items' },
                  { value: 25, label: '25 items' },
                  { value: 50, label: '50 items' },
                ],
              }}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
        />

        {/* Display the table only after a tag has been selected, to avoid overloading user with empty information */}
        {tagPair && (
          <TaggedResourcesTable
            taggedResources={taggedResources}
            tableMessage={tableMessage}
            loadingTaggedResources={loadingTaggedResources}
          />
        )}

        {/* Display the table only after a tag has been selected, to avoid overloading user with empty information */}
        {tagPair && (
          <TagSharedWorkspacesTable
            tagSharedWorkspaces={tagSharedWorkspaces}
            tableMessage={tableMessage}
            loadingTagSharedWorkspaces={loadingTagSharedWorkspaces}
          />
        )}

        <Modal
          visible={importLFTagsModalVisible}
          header={[`Import LakeFormation Tags`]}
          onDismiss={() => setImportLFTagsModalVisible(false)}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='link'
                  onClick={() => {
                    setImportLFTagsModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant='primary' loading={buttonLoading} onClick={handleImportLFTag}>
                  Import
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <Box variant={'p'}>
            This will import all the existing tags from this workspace account on to Foundry. You will still need to
            publish the imported tags for your consumers to request access to them.
          </Box>
        </Modal>

        <Modal
          visible={deleteTagModalVisible}
          header={[`Confirm deletion`]}
          onDismiss={() => setDeleteTagModalVisible(false)}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='link'
                  onClick={() => {
                    setDeleteTagModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant='primary' loading={buttonLoading} onClick={handleDeleteTag}>
                  Confirm
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <TextContent>
            Are you sure you want to delete the <strong>{tagPair}</strong> tag from <strong>{tagCatalogId}</strong>{' '}
            account?
            <br />
            <br />
            <strong>This will also delete the entire tag if it is the only remaining tag value pair!</strong>
            {/* TODO: add "type delete to confirm" box */}
          </TextContent>
        </Modal>

        <Modal
          visible={publishTagModalVisible}
          header={[`Publish?`]}
          onDismiss={() => setPublishTagModalVisible(false)}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='link'
                  onClick={() => {
                    setPublishTagModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant='primary' loading={buttonLoading} onClick={handlePublishTag}>
                  Confirm
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <TextContent>
            Are you sure you want to publish the <strong>{tagPair}</strong> permission tag from{' '}
            <strong>{tagCatalogId}</strong> account?.
            <br />
            <br /> Publishing the tag will allow other workspaces to request access to it (and its associated
            resources), you can approve/deny those requests.
          </TextContent>
        </Modal>
      </SpaceBetween>
    </div>
  );
};

export default Tags;
