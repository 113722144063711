import * as React from 'react';
import { ColumnLayout, Container, Header, Textarea } from '@amzn/awsui-components-react-v3';
import { getNotificationBatches, getNotifications } from 'src/api/notifications';

//include respond comment information if the comment respond to another comment
export const CommentLine = ({ comment }) => (
  <>
    <Container
      disableHeaderPaddings
      disableContentPaddings
      header={
        <Header
          description={
            comment.RespondNotificationCommentId != null && (
              <div>
                &nbsp;&nbsp;&nbsp;Replied to {comment.RespondUserId} ({comment.SenderId}):{' '}
                {comment.RespondNotificationCommentContent}
              </div>
            )
          }
        >
          <h5>
            &nbsp;&nbsp;{comment.NotificationOwnerId} ({comment.SenderId}) commented at{' '}
            {new Date(comment.CreateTime).toLocaleString()}
          </h5>
        </Header>
      }
    >
      <Textarea value={comment.NotificationCommentContent} ariaRequired={true} readOnly></Textarea>
    </Container>
  </>
);

//list data for inbox notifications
export const NotificationInboxDetail = ({ notification }) => (
  <ColumnLayout columns={4} variant='text-grid'>
    <div>
      <div className='awsui-util-label'>Sender Alias</div>
      <div>
        <a href={`https://phonetool.amazon.com/users/${notification.selectedSenderUserId}`}>
          {notification.selectedSenderUserId}
        </a>
      </div>
    </div>
    <div>
      <div className='awsui-util-label'>Sender Group</div>
      <div> {notification.selectedSenderGroupId}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Priority Level</div>
      <div> {notification.selectedPriorityLevel}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Email Sent</div>
      <div> {notification.selectedEmailSent ? 'Sent' : 'Not Sent'}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Create Time</div>
      <div> {new Date(notification.selectedCreateTime).toLocaleString()}</div>
    </div>
  </ColumnLayout>
);

//list data for archived notifications
export const NotificationArchiveDetail = ({ notification }) => (
  <ColumnLayout columns={4} variant='text-grid'>
    <div>
      <div className='awsui-util-label'>Sender Alias</div>
      <div> {notification.selectedSenderUserId}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Sender Group</div>
      <div> {notification.selectedSenderGroupId}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Priority Level</div>
      <div> {notification.selectedPriorityLevel}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Email sent</div>
      <div> {notification.selectedEmailSent ? 'Sent' : 'Not Sent'}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Create Time</div>
      <div> {notification.selectedCreateTime}</div>
    </div>

    <div>
      <div className='awsui-util-label'>Archive Time</div>
      <div> {notification.selectedArchiveTime}</div>
    </div>
    <div>
      <div className='awsui-util-label'>Archived by</div>
      <div> {notification.selectedArchivedBy}</div>
    </div>
  </ColumnLayout>
);

//list data for notifications sent by group
export const NotificationSentDetail = ({ notification }) => (
  <div>
    <ColumnLayout columns={1} variant='text-grid'>
      <div>
        <div className='awsui-util-label'>Receiver Group List</div>
        <div>{notification.selectedGroupIdList.join('; ')}</div>
      </div>
    </ColumnLayout>
    <ColumnLayout columns={4} variant='text-grid'>
      <div>
        <div className='awsui-util-label'>Sender Alias</div>
        <div>
          <a href={`https://phonetool.amazon.com/users/${notification.selectedUserId}`}>
            {notification.selectedUserId}{' '}
          </a>
        </div>
      </div>

      <div>
        <div className='awsui-util-label'>Sender Group</div>
        <div> {notification.selectedSenderGroupId}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Priority Level</div>
        <div> {notification.selectedPriorityLevel}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Email sent</div>
        <div> {notification.selectedEmailSent ? 'Sent' : 'Not Sent'}</div>
      </div>

      <div>
        <div className='awsui-util-label'>Create Time</div>
        <div> {new Date(notification.selectedCreateTime).toLocaleString()}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Last Update Time</div>
        <div> {new Date(notification.selectedLastCommentTime).toLocaleString()}</div>
      </div>
    </ColumnLayout>
  </div>
);

//update unread notifications
export const updateNotificationIcon = async (activeGroup, user) => {
  let request = {
    ReceiverId: activeGroup,
    Archive: false,
    Unread: true,
    NextToken: '',
  };

  let response = await getNotifications(request);
  let notificationList = [...response.NotificationList];
  let workspaceCount = response.WorkspaceCount;

  //Loop and get remaining tables
  while (response.NextToken != null) {
    request = {
      ReceiverId: activeGroup,
      Archive: false,
      Unread: true,
      NextToken: response.NextToken,
    };
    response = await getNotifications(request);
    notificationList.push(...response.NotificationList);
  }

  let notificationBatchRequest = {
    NotificationOwnerId: activeGroup,
    NextToken: '',
  };

  let notificationBatchResponse = await getNotificationBatches(notificationBatchRequest);

  let notificationBatchList = [...notificationBatchResponse.NotificationBatchList];

  while (notificationBatchResponse.NextToken != null) {
    notificationBatchRequest = {
      NotificationOwnerId: activeGroup,
      NextToken: notificationBatchResponse.NextToken,
    };
    notificationBatchResponse = await getNotificationBatches(notificationBatchRequest);
    notificationBatchList.push(...notificationBatchResponse.NotificationBatchList);
  }
  let commentedSentNotificationList = [];
  for (let notificationBatch of notificationBatchList) {
    if (
      notificationBatch.NotificationOwnerId == user &&
      notificationBatch.ReviewerList[notificationBatch.NotificationOwnerId] != null
    ) {
      commentedSentNotificationList.push(notificationBatch);
    }
  }
  return {
    unreadNotifications: notificationList,
    unreadNotificationBatches: commentedSentNotificationList,
    workspaceCount: workspaceCount,
  };
};
