import React from 'react';
import { WEBSITE_NAME } from '../commons/constants';
import { Header, HelpPanel, Icon, Link, TextContent } from '@amzn/awsui-components-react-v3';
import { DZ_GET_STARTED_WIKI_URL } from 'src/components/datazone/constants';

export const catalogHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Catalog</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/qMthADdQhY3n/AWS-Data-Lake-Catalog-Service-Design-Doc'>
              How the catalog works
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      Data Lake Catalog is a data catalog service which retrieves, stores, and syncs the metadata of datasets in the
      Data Lake or other Glue catalogs. All changes are tracked cross-account between the service account and the data
      source account and processed by the Catalog Service. Information about a given dataset (table) is kept such as
      ownership, input and output formats, S3 location, as well as a history of schema and partition keys.
    </p>
    <p>
      You can find the available datasets by using the search bar. Once you find a dataset that you are interested in,
      you can click on it to see all the details.
    </p>
  </HelpPanel>
);

export const catalogDetailsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Dataset details</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/qMthADdQhY3n/AWS-Data-Lake-Catalog-Service-Design-Doc'>
              How the catalog works
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      The Data Lake Catalog stores a complete history of all the changes to any of the fields you see here. In the
      metadata history dropdown, you can view older versions of the metadata, for example, if there was a schema change
      and you'd like to see the old columns.
    </p>
    <p>
      The request access button sends a request to the dataset owners for your group's Data Lake Role to be safelisted
      for the dataset. Once the request is sent, the owners can approve or deny the request. If it is approved, you can
      access and subscribe to the data.
    </p>
  </HelpPanel>
);

export const permissionsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Permissions</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataLake/Gladstone/DataLakeRoleAccess'>
              How can I use my Data Lake Role?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I create and manage my Lake Formation requests?
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      When you onboard to <b>{WEBSITE_NAME}</b>, a role is created for your group. You can request access to the
      datasets in the Catalog section and see those datasets here. You can also add and remove principals that can
      assume the Data Lake role for your group.
    </p>
    <p>
      Once your access is approved you can use your Data Lake role to read the data using AWS Services such as Redshift,
      Spectrum or EMR.
    </p>
  </HelpPanel>
);

export const myDatasetsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>My datasets</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://aws.amazon.com/lake-formation/'>What is Lake Formation?</a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I create and manage my Lake Formation requests?
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      In this page you can see all the datasets you have been granted access to, either through IAM or Lake Formation
      permissions. Datasets shared using Lake Formation are called dataset shares.
    </p>
    <p>
      A dataset share is a mechanism of sharing datasets. It contains all the metadata of the share itself. You have the
      abilities to <b>audit</b>, which validates the permissions, and <b>deactivate</b>, which revokes the permissions
      of the share. At the moment an audit failure doesn't mean permissions don't exist, it just doesn't match what's in
      the source account.
    </p>
    <p>
      Looking at an individual dataset share will contain more information. A<b>consumer</b> share will show whats being
      consumed. A <b>publisher</b> share will show what's being shared and who is consuming that dataset.
    </p>
  </HelpPanel>
);

export const publishDataSetShareHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Lake Formation sharing</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://aws.amazon.com/lake-formation/'>What is Lake Formation?</a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HPublishingRoles'>
              How do I create the audit and sharing roles?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I create and manage my Lake Formation requests?
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      Fill out this form for the datasets you would like shared via Lake Formation shares. After you enter both roles
      and a region, Omni will let you select the table(s) you want to share.
    </p>
    <p>
      Once your publish your data, you will need to monitor the request page to approve requests for accessing your
      data.
    </p>
  </HelpPanel>
);

export const createLakeFormationRequestHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Lake Formation permission request</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://aws.amazon.com/lake-formation/'>What is Lake Formation?</a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HConsumingRole'>
              How do I create a Resource Access Manager role?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I submit a Lake Formation Request?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormation/'>
              How do I use my Lake Formation shares?
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      Fill out this form for the datasets you would like shared via Lake Formation shares. Instead of using an external
      AWS Account ID, <b>{WEBSITE_NAME}</b> requires a role that has Resource Access Manager (RAM) access. This allows
      us to auto accept all RAM shares created from Lake Formation.
    </p>
    <p>
      Once your access is approved you can set up your permissions on your compute roles in the Lake Formation console.
    </p>
  </HelpPanel>
);

export const helpContentNewCustomers = (
  <HelpPanel
    header={<Header variant={'h2'}>Onboarding</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Documentation'>Data Lake services</a>
          </li>
          <li>
            <a href='https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_principal.html'>
              IAM principals
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      To onboard, you will need to submit an onboarding request with information about your team, data access and
      processing, use case, and valid IAM principal(s) to assume your Data Lake role.
    </p>
    <p>
      Once the request is submitted, Data Lake oncall will review and process it. A group then will be created for your
      team with you and your teammates as group members.
    </p>
    <p>
      Once onboarded, you can use Data Lake services with ease to support your operational or reporting and analytical
      data use.
    </p>
  </HelpPanel>
);

export const publisherHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Published table actions</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/fNs9Ap6IJGY5/Publisher-as-Managed-Service'>Learn more about Publisher</a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      Backfill: When a pipeline which has already been set up and has been streaming in past has an interruption such as
      service issues, AWS outage, etc., then source team can re-run the recent batch of files to be processed against
      the last verfied snapshot.
    </p>
    <p>
      Dedupe: Whenever there are lot of updates that happen on a table, it can happen that the updates in the stream can
      lose the order of changes received. Please select Dedupe column to avoid overlap of updates.
    </p>
    <p>
      Initial Dump: In order to get a full dump of your table, please select
      <b>Initial Dump</b> from the Actions menu. Once the Initial Dump button is clicked, a new execution will be
      created that will show up on "Full dump executions" with the status.
    </p>
  </HelpPanel>
);

export const notificationHelp = (
  <HelpPanel header={<Header variant={'h2'}>Notifications</Header>}>
    <p>
      You can see all the notifications for your group in the Inbox tab, all your archived notifications in the Archive
      tab, and all the notifications your group has sent from the Sent tab. Also, you can add comments to notifications
      and the notification sender would get notified. Both notification sender and other groups who received the same
      notification can share the comments.
    </p>
  </HelpPanel>
);

export const createNotificationHelp = (
  <HelpPanel header={<Header variant={'h2'}>Create new notifications</Header>}>
    <p>
      When creating a new notification, you can choose to send notifications to any groups who have access to your
      datasets. You can create templates for common notifications you would like to send.
    </p>
  </HelpPanel>
);

const monoSpan = (text) => <span style={{ fontFamily: 'monospace' }}>{text}</span>;

export const resourcegroupsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Resource groups</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/Y7sbAhfIKL7x/ResourcesManager-Design'>Resource Manager</a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      <b>Resource groups: </b>
      Resource Manager is responsible for adaptively scaling your active resources, keeping resources up-to-date, and
      managing their security. From your resource groups dashboard, you can complete the following actions:
    </p>
    <p>
      <ul>
        <li>
          Create/Clone: Create a new resource group from scratch, or clone the configuration of an existing group to a
          new one.
        </li>
        <li>Search: Filter the groups displayed on the table based on the displayed properties.</li>
        <li>Inspect: See the details of the resource group's configuration.</li>
        <li>Edit: Update the resource group's configuration.</li>
        <li>
          Toggle status: Quickly {monoSpan('ENABLE')}, {monoSpan('DISABLE')}, or {monoSpan('DELETE')} a group.
        </li>
      </ul>
    </p>
    <p>
      <i>Note: </i>
      EMR clusters are currently the only supported resource type.
    </p>
    <hr />
    <p>
      <b>Templates: </b> You may save a particular resource group's configuration as a template, and then{' '}
      {monoSpan('Quick Create')} (under {monoSpan('Actions')}) new groups from a selected Template.
    </p>
  </HelpPanel>
);

export const bootstrapactionsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Bootstrap actions</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/Y7sbAhfIKL7x/ResourcesManager-Design'>Resource Manager</a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      <b>Bootstrap actions: </b>
      Bootstrap actions are executable S3-hosted files which are run (with user-defined arguments) on newly created
      resources under the supervision of Resources Manager. You may create, inspect, search, and edit bootstrap actions
      from this table. You may delete or clone a bootstrap action from its details page.
    </p>
  </HelpPanel>
);

export const groupsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Groups</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/zaNcA7uR5KyP/Data-Lake-Gladstone-Design#RXF9CAUHRyX'>
              How Omni groups work
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      When you onboard to <b>{WEBSITE_NAME}</b>, a group is created for your team. You and your teammates that were
      specified in the onboarding request are group members. In this page, you can request to edit your group details or
      add new members to your group.
    </p>
  </HelpPanel>
);

export const workspace_access_help = (
  <HelpPanel
    header={<Header variant={'h2'}>Existing workspace access</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/kNdoArqSvcYV/Foundry-Lake-Formation-Catalog-Import'>
              How Omni Tags work with Lake Formation
            </a>
            {/* TODO: add design for Redshift tags when designed */}
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      Incoming means the existing permissions that other Omni users have to access your tags and resources. Outgoing
      means the existing permissions that you have to other Omni users’ tags and resources.
    </p>
  </HelpPanel>
);

export const new_access_request_help = (
  <HelpPanel header={<Header variant={'h2'}>New access requests</Header>}>
    <p>
      Incoming requests are requests from other Omni users to gain access to your tags and resources. Outgoing requests
      are requests that you have made to other Omni users to gain access to their tags and resources. You may view the
      history of both kinds of requests
    </p>
  </HelpPanel>
);

export const tagsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Tags</Header>}
    footer={
      <TextContent>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/kNdoArqSvcYV/Foundry-Lake-Formation-Catalog-Import'>
              How Omni Tags work with Lake Formation
            </a>
            {/* TODO: add design for Redshift tags when designed */}
          </li>
        </ul>
      </TextContent>
    }
  >
    <p>
      When you onboard to <b>{WEBSITE_NAME}</b>, you can create tags and attach them on your data catalog. These tags
      can be shared with other Omni teams and help manage access control. Any team (e.g. AWS account) to which these
      tags are shared have access to all datasets tagged.
    </p>
  </HelpPanel>
);

export const tethysHelp = (
  <HelpPanel
    header={<Header variant={'h2'}> Welcome to Tethys </Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a href='https://broadcast.amazon.com/videos/546915' target='_blank' rel='noopener noreferrer'>
              Demo
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Tethys/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Tethys Wiki
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Tethys/OnboardingGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              Onboarding guide
            </a>
          </li>
          <li>
            <a href='https://broadcast.amazon.com/videos/628085' target='_blank' rel='noopener noreferrer'>
              WebDevCon presentation
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>Tethys is a data ingestion service to easily publish data into AWS Data Lake.</p>

      <p>
        Tethys automatically standardizes data storage format optimizing it for data lake workloads. It also supports
        schema evolution, allowing you to quickly update your systems. Tethys performs schema, and contract validation
        to enforce data quality. Powered by a serverless architecture, you can publish data without worrying about
        provisioning, and mantaining infrastructure.
      </p>

      <p>
        Tethys accelerates data availability, enables data-driven decision making, and saves engineering time by
        simplifying data ingestion.
      </p>

      <p>
        To get started please send us a message to
        <Link
          external
          externalIconAriaLabel='Opens in a new tab'
          href='https://amzn-aws.slack.com/archives/C03KS0T6V0B'
        >
          #tethys-interest
        </Link>
      </p>
    </TextContent>
  </HelpPanel>
);

export const registerRedshiftCatalogHelp = (
  <HelpPanel
    header={<Header variant={'h2'}> Register Redshift catalog </Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HRedshiftPermissionManagement'
              target='_blank'
              rel='noopener noreferrer'
            >
              Onboarding guide
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HRegisteringData-1'
              target='_blank'
              rel='noopener noreferrer'
            >
              Demo
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/OmniAPIUserGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        You can register your encrypted Redshift clusters to {WEBSITE_NAME}. Once you register your cluster you can also
        register individual databases, schemas and tables.
        <br />
        <br />
        <b>Note: Only redshift standard identifiers are supported.</b>
      </p>
    </TextContent>
  </HelpPanel>
);

export const registerLakeFormationCatalogHelp = (
  <HelpPanel
    header={<Header variant={'h2'}> Register Lake Formation catalog </Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HLakeformationPermissionManagement'
              target='_blank'
              rel='noopener noreferrer'
            >
              Onboarding guide
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HRegisteringData'
              target='_blank'
              rel='noopener noreferrer'
            >
              Demo
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/OmniAPIUserGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HLakeformationpermissionsFAQ'
              target='_blank'
              rel='noopener noreferrer'
            >
              FAQ
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        You can register your LakeFormation catalog to {WEBSITE_NAME}. Once you register your catalog you can also
        register individual databases and tables.
        <br />
        <br />
      </p>
    </TextContent>
  </HelpPanel>
);

export const searchHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Search</Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/Search/'
              target='_blank'
              rel='noopener noreferrer'
            >
              User guide
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/Search/#HDemo'
              target='_blank'
              rel='noopener noreferrer'
            >
              Demo
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/OmniAPIUserGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        The advanced Search feature on {WEBSITE_NAME} enables users to perform full text search on various{' '}
        {WEBSITE_NAME} entities like datasets, columns, databases, catalogs etc. You can now quickly discover the
        datasets with minimal metadata. Search feature is powered by AWS OpenSearch.
        <br />
        <br />
        To search just write any word in the search bar, {WEBSITE_NAME} will fetch the most relevant records according
        to your search; After you fetch records you can switch between the different tabs to see each type and you can
        filter them further using the filter bar of each type of record.
        <br />
        <br />
        You refine search by adding more words after the initial search and using multiple <b>AND</b> or <b>OR</b>{' '}
        expressions.
      </p>
    </TextContent>
  </HelpPanel>
);

export const templatesHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Templates</Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/Templates/'
              target='_blank'
              rel='noopener noreferrer'
            >
              User guide
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/Templates/#HDemoVideo'
              target='_blank'
              rel='noopener noreferrer'
            >
              Demo
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/OmniAPIUserGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        Templates are entities that you can associate with your Omni resources. The most common way to use them is to
        define a SIM template and associate it with your resources for access management purposes. If a dataset has
        templates associated with it, those templates will be displayed to your consumers when they try to request
        access. They will create a ticket by filling out the SIM template, and then you can view the details of that
        ticket before approving or denying the request.
        <br />
        <br />
      </p>
    </TextContent>
  </HelpPanel>
);

export const workspaceDatasetsHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Browse catalog</Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HDataconsumers'
              target='_blank'
              rel='noopener noreferrer'
            >
              User guide
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HRequestingaccesstothedata'
              target='_blank'
              rel='noopener noreferrer'
            >
              Demo
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/OmniAPIUserGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HFAQ'
              target='_blank'
              rel='noopener noreferrer'
            >
              FAQ
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        {WEBSITE_NAME} enables consumers to browse the datasets from other teams and request access to them, the access
        can be requested either for an specific resource or for a set of resources tagged with a permission tag.
        <br />
        <br />
      </p>
    </TextContent>
  </HelpPanel>
);

export const redshiftQueryHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Redshift query</Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HAccessingtherequesteddata-1'
              target='_blank'
              rel='noopener noreferrer'
            >
              User guide
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HUsingRedshiftQueryEditor'
              target='_blank'
              rel='noopener noreferrer'
            >
              Demo
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/OmniAPIUserGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Foundry#HFAQ'
              target='_blank'
              rel='noopener noreferrer'
            >
              FAQ
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        {WEBSITE_NAME} enables consumers to request access to data in redshift clusters of other workspaces. Once your
        access is granted, {WEBSITE_NAME} creates a user for you in the provider's cluster in the format{' '}
        <b>foundry_yourusername</b>.
        <br />
        <br />
        You can federate to the redshift query editor console of the provider. After your first federation you need to
        configure your connection by selecting the cluster and selecting
        <dl>
          <dt>Authentication</dt>
          <dd>Temporary credentials</dd>
          <dt>Database</dt>
          <dd>name_of_the_database_in_omni</dd>
          <dt>User name</dt>
          <dd>foundry_yourusername</dd>
        </dl>
      </p>
    </TextContent>
  </HelpPanel>
);

const advisoryFooter = () => {
  return (
    <TextContent>
      <h3>
        Learn more <Icon name='external' />
      </h3>
      <ul>
        <li>
          <a
            href='https://w.amazon.com/bin/view/AWSDataPlatform/Omni/Workspaces/#HOmniDataAdvisory'
            target='_blank'
            rel='noopener noreferrer'
          >
            User guide
          </a>
        </li>
      </ul>
    </TextContent>
  );
};

export const advisoriesHelp = (
  <HelpPanel header={<Header variant={'h2'}>Data advisories</Header>} footer={advisoryFooter()}>
    <TextContent>
      <p>
        A data advisory is a notification on {WEBSITE_NAME} that is inherently linked to a resource (table, database,
        etc). It includes a title and content, a set of impacted resources, a set of attached tickets, and
        links/resources to get more information. Data providers can use advisories to send bulk notifications to all the
        customers of their resources. Use this page to browse for advisories by owner, status, create date, and
        resource.
      </p>
    </TextContent>
  </HelpPanel>
);

export const advisoryDetailHelp = (
  <HelpPanel header={<Header variant={'h2'}>Advisory detail</Header>} footer={advisoryFooter()}>
    <TextContent>
      <p>
        This is the detail page for a data advisory. On this page, you can view the content of the advisory itself, plus
        the associated resources, tickets, and contact info. If you are the owner of the advisory, you can edit or
        notify the customers from here.
      </p>
    </TextContent>
  </HelpPanel>
);

export const advisorySendNotificationHelp = (
  <HelpPanel header={<Header variant={'h2'}>Send advisory notification</Header>} footer={advisoryFooter()}>
    <TextContent>
      <p>
        From this page, you can send a notification to all the customers of the advisory. The title and content are
        pre-populated from the advisory itself, but you can make edits here. Edits made on this page will reflect in the
        notifications sent via Email, Slack, and Omni notification, but they will not modify the content of the advisory
        itself.
      </p>
      <p>
        Select customers to send to with the checkbox lists. These customers are populated from the advisory itself. To
        modify the list of customers, go back to the advisory details page and select "Edit."
      </p>
    </TextContent>
  </HelpPanel>
);

export const advisoryCreateOrUpdateHelp = (
  <HelpPanel header={<Header variant={'h2'}>Create or update advisory</Header>} footer={advisoryFooter()}>
    <TextContent>
      <p>
        This form allows you to create or edit an advisory. You can create an advisory from scratch, or create from a
        template or save as a template in the "Advisory templates..." button dropdown at the bottom. After filling out
        every section, select "Submit" to create or update the advisory.
      </p>
    </TextContent>
  </HelpPanel>
);

export const advisoryTemplatesHelp = (
  <HelpPanel header={<Header variant={'h2'}>Advisory templates</Header>} footer={advisoryFooter()}>
    <TextContent>
      <p>
        An advisory template contains all the same details as an advisory, except for attached tickets. In other words,
        a template stores a title, content, impacted resources, customers, and contact information. Templates can be
        used to quickly create advisories by reusing the content from a template instead of creating from scratch. On
        this page, you can edit or delete your advisory templates. Click on any template to view its full details.
      </p>
    </TextContent>
  </HelpPanel>
);

export const advisoryTemplateDetailHelp = (
  <HelpPanel header={<Header variant={'h2'}>Advisory template detail</Header>} footer={advisoryFooter()}>
    <TextContent>
      <p>
        An advisory template contains all the same details as an advisory, except for attached tickets. In other words,
        a template stores a title, content, impacted resources, customers, and contact information. Templates can be
        used to quickly create advisories by reusing the content from a template instead of creating from scratch. This
        page shows all the details for a given template. You can edit or delete the template, or create a new advisory
        from it.
      </p>
    </TextContent>
  </HelpPanel>
);

export const dataZoneManageHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Manage DataZone resources</Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a href={DZ_GET_STARTED_WIKI_URL} target='_blank' rel='noopener noreferrer'>
              Omni-DataZone user guide
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        On this page, you can view resources you have onboarded in DataZone and federate into the AWS-wide DataZone
        portal. Select the "Onboard" button to create a new project and environment. You can select the "Open DataZone
        portal" button to enter the portal, authenticating with your Midway SSO identity. If you have not created a
        project and environment yet, you can still open the portal with read-only permissions to browse the available
        data.
      </p>
    </TextContent>
  </HelpPanel>
);

export const dataZoneOnboardHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Onboard to Omni-DataZone</Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a href={DZ_GET_STARTED_WIKI_URL} target='_blank' rel='noopener noreferrer'>
              Omni-DataZone user guide
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>
        Use this page to onboard a new project and/or environment to DataZone. Currently, Redshift Cluster and Redshift
        Serverless are supported. Select which group and workspace to use. Remember, the project name will be the same
        as the selected group name, and the Redshift resources must belong to the AWS account associated with the
        selected workspace.
        <br />
        <br />
        Afterwards, provide POSIX groups to grant DataZone permissions to. You can choose Data Steward or Data Consumer
        roles. POSIX group ID's should start with the prefix "omnidz-" or "omni-dz-".
        <br />
        <br />
        Finally, run a prerequisites check, then review and submit.
      </p>
    </TextContent>
  </HelpPanel>
);

export const defaultHelp = (
  <HelpPanel
    header={<Header variant={'h2'}>Welcome to {WEBSITE_NAME}</Header>}
    footer={
      <TextContent>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni'
              target='_blank'
              rel='noopener noreferrer'
            >
              Onboarding guide
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/OmniAPIUserGuide'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
        </ul>
      </TextContent>
    }
  >
    <TextContent>
      <p>{WEBSITE_NAME} is a one-stop portal to discover, manage access, publish and subscribe to data.</p>
    </TextContent>
  </HelpPanel>
);
