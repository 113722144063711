import { Link, Redirect, useLocation } from 'react-router-dom';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { listMetadataEntitiesAssociatedToResource } from 'src/api/catalog';
import { METADATA_BUSINESS_GLOSSARY, METADATA_ENABLED, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import {
  Box,
  CollectionPreferences,
  CollectionPreferencesProps,
  Flashbar,
  Header,
  Pagination,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels } from 'src/commons/tables';
import { EmptyState } from 'src/commons/EmptyState';

import { PageHeader } from 'src/components/common/PageHeader';

export interface BusinessGlossariesProps {
  setContentType: any;
  activeGroup: string;
  match: any;
  activeWorkspace?: any;
  resource?: any;
  isOwner: boolean;
}

export const BusinessGlossaries = (props: BusinessGlossariesProps) => {
  const { state } = useLocation();
  const [redirect] = useState(undefined);
  const [loadingGlossaries, setLoadingGlossaries] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [businessGlossaries, setBusinessGlossaries] = useState([]);
  const [, setSelectedGlossaryId] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const resource = props.resource;
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    businessGlossaries,
    {
      filtering: {
        noMatch: '',
        empty: <EmptyState title={'No glossaries'} subtitle={'No business glossaries found.'} />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  const glossaryColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'glossaryName',
      header: 'Name',
      cell: (item) => <Link to={`/glossaries/${item.GlossaryId}/${item.OwnerId}`}>{item.Name}</Link>,
      minWidth: 200,
      sortingField: 'glossaryName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Description,
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.GlossaryType.replace('Glossary#', ''),
      minWidth: 200,
    },
    {
      id: 'value',
      header: 'Value',
      cell: (item) => item.GlossaryValue,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
    if (state) {
      setNotification(state.status, state.message);
    }
  }, []);

  useEffect(() => {
    if (ownerId) {
      handleRefresh();
    }
  }, [ownerId]);

  const handleRefresh = async () => {
    setLoadingGlossaries(true);
    setSelectedGlossaryId(undefined);
    await getGlossariesForResource();
  };

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedGlossary = selectedItems[0];
    if (selectedGlossary) {
      setSelectedGlossaryId(selectedGlossary.GlossaryId);
    }
  }, [collectionProps.selectedItems]);

  const getGlossariesForResource = async () => {
    try {
      // get all glossaries for owner and filter on the results
      let glossariesForResource = [];
      let filteredEnabledGlossaries = [];
      let result = await listMetadataEntitiesAssociatedToResource({
        ResourceArn: resource,
        MetadataEntityFilter: METADATA_BUSINESS_GLOSSARY,
      });
      filteredEnabledGlossaries = result.GlossaryList.filter((glossary) => {
        return glossary.Status == METADATA_ENABLED;
      });
      glossariesForResource.push(...filteredEnabledGlossaries);
      while (result.NextToken != undefined) {
        result = await listMetadataEntitiesAssociatedToResource({
          ResourceArn: resource,
          MetadataEntityFilter: METADATA_BUSINESS_GLOSSARY,
          NextToken: result.NextToken,
        });
        filteredEnabledGlossaries = result.GlossaryList.filter((glossary) => {
          return glossary.Status == METADATA_ENABLED;
        });
        glossariesForResource.push(...filteredEnabledGlossaries);
      }
      setBusinessGlossaries(glossariesForResource);
    } catch (err) {
      await setNotification('error', `Unable to load glossaries. ${err.message}`);
    } finally {
      setLoadingGlossaries(false);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'error') {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (redirect) return <Redirect push to={redirect} />;

  const businessGlossaryButtonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
    ];
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        loadingText='Loading glossaries...'
        loading={loadingGlossaries}
        columnDefinitions={glossaryColumnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No glossaries</b>
            <Box variant='p' color='inherit'>
              No glossaries to display.
            </Box>
          </Box>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        resizableColumns={true}
        header={
          <PageHeader
            buttons={businessGlossaryButtonOptions()}
            counter={`(${items.length})`}
            header={'Business glossaries'}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={notificationsPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
    </>
  );
};

export default BusinessGlossaries;
