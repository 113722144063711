import { Container, FormField, Header, Icon, KeyValuePairs } from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import * as React from 'react';

export interface WorkspaceDetailContainerProps {
  workspace: any;
}

export const WorkspaceDetailContainer = (props: WorkspaceDetailContainerProps) => {
  const catalogManagerEnabled = props.workspace.supportedServices?.includes('FoundryCatalogManager');
  const redshiftManagerEnabled = props.workspace.supportedServices?.includes('FoundryRedshiftManager');
  const analyticsManagerEnabled = props.workspace.supportedServices?.includes('FoundryAnalyticsManager');
  const dataZoneManagerEnabled = props.workspace.supportedServices?.includes('FoundryDataZoneManager');

  const getStatus = (item) => {
    let currentStatus = <></>;

    switch (item?.status) {
      case 'Active_Sync':
        currentStatus = (
          <div>
            <Icon variant='success' name='status-positive' /> In sync
          </div>
        );
        break;
      case 'Active_Syncing':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Syncing
          </div>
        );
        break;
      case 'Active_Auditing':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Auditing
          </div>
        );
        break;
      case 'Active_Running':
        currentStatus = (
          <div>
            <Icon name='status-pending' variant='success' /> Running
          </div>
        );
        break;
      case 'Active_Outdated':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Outdated
          </div>
        );
        break;
      case 'Active_Error':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Error
          </div>
        );
        break;
      case 'Inactive':
        currentStatus = (
          <div>
            <Icon name='status-warning' variant='warning' /> Inactive
          </div>
        );
        break;
    }
    return (
      <>
        {currentStatus}
        <div>
          completed tasks: {item?.workspaceConfig['workflowCompletedTaskCount']} /{' '}
          {item?.workspaceConfig['workflowTotalTaskCount']}
        </div>
      </>
    );
  };

  const serviceStatus = (serviceName, successful, description) => {
    if (successful) {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-positive' variant='success' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    } else {
      let label = (
        <div>
          {serviceName}
          <Icon name='status-negative' variant='warning' />
        </div>
      );
      return <FormField label={label} description={description}></FormField>;
    }
  };

  return (
    <Container header={<Header variant={'h2'}>Workspace details</Header>}>
      <KeyValuePairs
        columns={4}
        items={[
          { label: 'Workspace ID', value: props.workspace?.workspaceId },
          { label: 'Group', value: <Link to={`/groups/${props.workspace?.groupId}`}>{props.workspace?.groupId}</Link> },
          { label: 'Owner', value: props.workspace?.owner },
          { label: 'Account ID', value: props.workspace?.accountId },
          { label: 'Region', value: 'us-east-1' },
          {
            label: 'Last update',
            value:
              props.workspace?.workspaceConfig != undefined &&
              props.workspace.workspaceConfig?.lastExecutedWorkflowType != undefined
                ? props.workspace.workspaceConfig.lastExecutedWorkflowType + ' ' + props.workspace.updateDate
                : props.workspace?.updateDate,
          },
          { label: 'Status', value: getStatus(props.workspace) },
        ]}
      />
      {catalogManagerEnabled != null && redshiftManagerEnabled != null && analyticsManagerEnabled != null && (
        <KeyValuePairs
          columns={1}
          items={[
            {
              label: 'Supported services',
              value: (
                <>
                  {serviceStatus(
                    'FoundryCatalogManager ',
                    catalogManagerEnabled,
                    'Enables you to perform glue catalog management on Omni',
                  )}
                  {serviceStatus(
                    'FoundryRedshiftManager ',
                    redshiftManagerEnabled,
                    'Enables you to perform redshift management on Omni',
                  )}
                  {serviceStatus(
                    'FoundryAnalyticsManager ',
                    analyticsManagerEnabled,
                    'Enables you to perform analytics on the data through Omni',
                  )}
                  {serviceStatus(
                    'FoundryDataZoneManager ',
                    dataZoneManagerEnabled,
                    'Enables you to onboard to the Omni managed DataZone domain',
                  )}
                </>
              ),
            },
          ]}
        />
      )}
    </Container>
  );
};
