import * as React from 'react';
import { useState, useEffect } from 'react';

import { Box, LineChart, Header, Container, Button } from '@amzn/awsui-components-react-v3/polaris';
import { DATAFI_DQ_TYPE, DQ_RULE_PASS_STATUS, GLUE_DQ_TYPE } from 'src/components/catalog/dataquality/dqConstants';

export interface DataQualityLineChartProps {
  dataQualityReports: any;
  loadingDataQuality: boolean;
}

export const DataQualityLineChart = (props: DataQualityLineChartProps) => {
  const [lineChartSeriesData, setLineChartSeriesData] = useState([]);
  const [lineChartXDomain, setLineChartXDomain] = useState([]);

  const createLineChartSeriesData = (dataQualityReports) => {
    const series = new Map();
    let min = 0;
    let max = 0;
    for (let i = 0; i < dataQualityReports.length; i++) {
      let passedRules = 0;
      let totalRules = 0;
      //Calculate DQ Score
      if (dataQualityReports[i].Type === GLUE_DQ_TYPE) {
        for (let j = 0; j < dataQualityReports[i]?.Data?.metrics?.length; j++) {
          totalRules++;
          if (dataQualityReports[i].Data?.metrics[j]?.result === DQ_RULE_PASS_STATUS) {
            passedRules++;
          }
        }
      } else if (dataQualityReports[i].Type === DATAFI_DQ_TYPE) {
        for (let j = 0; j < dataQualityReports[i]?.Data?.rules?.length; j++) {
          totalRules++;
          if (dataQualityReports[i].Data?.rules[j]?.result === DQ_RULE_PASS_STATUS) {
            passedRules++;
          }
        }
        for (let j = 0; j < dataQualityReports[i].Data?.trends?.length; j++) {
          totalRules++;
          if (dataQualityReports[i].Data?.trends[j]?.result === DQ_RULE_PASS_STATUS) {
            passedRules++;
          }
        }
      }
      //Update min and max
      if (min == 0 || dataQualityReports[i].CreatedOn < min) min = dataQualityReports[i].CreatedOn;
      if (max == 0 || dataQualityReports[i].CreatedOn > max) max = dataQualityReports[i].CreatedOn;
      //Update series data (multiple rule sets)
      if (series.has(dataQualityReports[i].Namespace)) {
        series.get(dataQualityReports[i].Namespace).push({
          x: new Date(dataQualityReports[i].CreatedOn),
          y: (passedRules / totalRules) * 100,
        });
      } else {
        series.set(dataQualityReports[i].Namespace, [
          {
            x: new Date(dataQualityReports[i].CreatedOn),
            y: (passedRules / totalRules) * 100,
          },
        ]);
      }
    }
    let lineChartSeriesData = Array.from(series, ([namespace, data]) => ({
      title: namespace,
      type: 'line',
      data: data,
    }));

    if (lineChartSeriesData.length > 0) {
      //Add in baseline
      // @ts-ignore
      lineChartSeriesData.push({ title: 'Performance goal', type: 'threshold', y: 80 });
      setLineChartXDomain([new Date(min), new Date(max)]);
      setLineChartSeriesData(lineChartSeriesData);
    } else {
      setLineChartXDomain([]);
      setLineChartSeriesData([]);
    }
  };

  useEffect(() => {
    createLineChartSeriesData(props.dataQualityReports);
  }, [props.dataQualityReports]);

  return (
    <Container
      header={
        <Header
          variant='h2'
          description='The trend of data quality rules over the last ten runs, with the tenth run being the most recent.'
        >
          Data quality snapshot
        </Header>
      }
    >
      <LineChart
        series={lineChartSeriesData}
        xDomain={lineChartXDomain}
        yDomain={[0, 100]}
        xScaleType='time'
        i18nStrings={{
          xTickFormatter: (e) =>
            e
              .toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
              })
              .split(',')
              .join('\n'),
        }}
        statusType={props.loadingDataQuality ? 'loading' : 'finished'}
        ariaLabel='Data quality line chart'
        height={300}
        xTitle='Last run(s)'
        yTitle='Data quality score (%)'
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No data available</b>
            <Box variant='p' color='inherit'>
              There is no data available
            </Box>
          </Box>
        }
        noMatch={
          <Box textAlign='center' color='inherit'>
            <b>No matching data</b>
            <Box variant='p' color='inherit'>
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
      />
    </Container>
  );
};
