import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3/polaris';

import { deleteTemplate, dissociateTemplateFromResources, listTemplates } from '../../api/permissions';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Page } from '../../routes/Paths';
import { defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels } from 'src/commons/tables';
import {
  Box,
  Button,
  Flashbar,
  Modal,
  TableProps,
  Link as LinkV3,
  StatusIndicator,
  TextContent,
  Header,
} from '@amzn/awsui-components-react-v3';
import TemplateResourcesTable from 'src/components/templates/templateResourcesTable';
import { ConfirmModal } from 'src/components/utils/modal';
import { AssociateTemplateModal } from 'src/components/templates/associateTemplateModal';
import {
  TABLE_CONTENT_TYPE,
  TEMPLATE_NONE,
  TEMPLATE_TYPE_ACCESS_MANAGEMENT,
  TEMPLATE_TYPE_GLOBAL_ACCESS_MANAGEMENT,
} from 'src/commons/constants';
import { isGlobalTemplate } from 'src/components/utils/template';
import { EmptyState } from 'src/commons/EmptyState';

import { PageHeader } from 'src/components/common/PageHeader';

export interface TemplatesTableProps {
  setContentType: any;
  activeGroup: string;
  toggleHelp: any;
  activeWorkspace?: any;
}

export const TemplatesTable = (props: TemplatesTableProps) => {
  const { state } = useLocation();
  const [allItems, setAllItems] = useState([]);
  const [templatesResult, setTemplatesResult] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [loadingTemplates, setLoadingTemplates] = useState(undefined);
  const [tableMessage, setTableMessage] = useState('No templates');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateResources, setTemplateResources] = useState([]);
  const [deleteTemplateModalVisible, setDeleteTemplateModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteText, setDeleteText] = useState(undefined);
  const [templateTypes] = useState([TEMPLATE_TYPE_ACCESS_MANAGEMENT, TEMPLATE_TYPE_GLOBAL_ACCESS_MANAGEMENT]);
  const [associateModalVisible, setAssociateModalVisible] = useState(false);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      noMatch: '',
      empty: <EmptyState title={tableMessage} subtitle={'No templates to display.'} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const templateColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'templateName',
      header: 'Name',
      cell: (item) => <Link to={`templates/${item.templateId}/${item.resourceId}`}>{item.name.split()}</Link>,
      minWidth: 200,
      sortingField: 'templateName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.templateType,
      minWidth: 100,
      sortingField: 'type',
    },
    {
      id: 'simTemplateLink',
      header: 'SIM template link',
      cell: (item) =>
        item.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink ? (
          <a href={item.accessManagementInfo?.simTemplates?.[0]?.simTemplateLink} target='_blank'>
            {'Sim template link'}
          </a>
        ) : (
          'None'
        ),
      minWidth: 200,
    },
    {
      id: 'approvalTemplateLink',
      header: 'Approval template link',
      cell: (item) =>
        item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink ? (
          <a href={item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink} target='_blank'>
            {'Approval template link'}
          </a>
        ) : (
          'None'
        ),
      minWidth: 200,
    },
    {
      id: 'autoApproval',
      header: 'Auto approval',
      cell: (item) => {
        if (item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink) {
          const autoApproval = item.accessManagementInfo?.approvalsWorkflowTemplate?.autoApproval;
          return autoApproval !== undefined ? (
            <StatusIndicator type={autoApproval.toString() == 'true' ? 'success' : 'stopped'}>
              {autoApproval.toString() == 'true' ? 'Enabled' : 'Disabled'}
            </StatusIndicator>
          ) : (
            'None'
          );
        } else {
          return 'None';
        }
      },
      minWidth: 200,
    },
    {
      id: 'allowMergeApprovals',
      header: 'Allow merge workflows',
      cell: (item) => {
        if (item.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateLink) {
          const allowMergeApprovalWorkflow =
            item.accessManagementInfo?.approvalsWorkflowTemplate?.allowMergeApprovalWorkflow;
          return allowMergeApprovalWorkflow !== undefined ? (
            <StatusIndicator type={allowMergeApprovalWorkflow.toString() == 'true' ? 'success' : 'stopped'}>
              {allowMergeApprovalWorkflow.toString() == 'true' ? 'Enabled' : 'Disabled'}
            </StatusIndicator>
          ) : (
            'None'
          );
        } else {
          return 'None';
        }
      },
      minWidth: 200,
    },
  ];

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedItem = selectedItems[0];
    if (selectedItem !== undefined) {
      setSelectedTemplate(selectedItem);
      setSelectedTemplateId(selectedItem.templateId);
      setTemplateResources(
        templatesResult.filter((t) => {
          return selectedItem.templateId == t.templateId && t.resourceId != TEMPLATE_NONE;
        }),
      );
    }
  }, [collectionProps.selectedItems]);

  useEffect(() => {}, [templateResources]);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    if (state) {
      setNotification(state.status, state.message);
    }
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const handleRefresh = async () => {
    props.setContentType(TABLE_CONTENT_TYPE);
    if (!props.activeGroup) return;
    setLoadingTemplates(true);
    try {
      let templates = [];
      let ownerId = props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup;
      for (const type of templateTypes) {
        const listTemplatesResult = await listTemplates({
          ownerId: ownerId,
          templateType: type,
        });
        templates.push(...listTemplatesResult.templates);
      }
      setAllItems(
        templates.filter((t) => {
          return t.resourceId == TEMPLATE_NONE || isGlobalTemplate(t);
        }),
      );
      setTemplatesResult(templates);
      setLoadingTemplates(false);
      setTemplateResources(
        templates.filter((t) => {
          return selectedTemplateId == t.templateId && t.resourceId != TEMPLATE_NONE;
        }),
      );
    } catch (err) {
      setTableMessage(`Unable to load templates. ${err.message}`);
      setLoadingTemplates(false);
    }
  };

  const handleDelete = async () => {
    setButtonLoading(true);
    try {
      await deleteTemplate({
        templateId: selectedTemplateId,
      });
      await handleRefresh();
      setSelectedTemplate('');
      setNotification('success', 'Successfully deleted the template and associated resources.');
    } catch (err) {
      setNotification('error', 'Failed to delete the template.');
    }
    setButtonLoading(false);
    setDeleteTemplateModalVisible(false);
  };

  const handleEditTemplate = async () => {
    if (selectedTemplate !== '') {
      setRedirect({
        pathname: Page.EDIT_TEMPLATE,
        state: {
          template: selectedTemplate,
        },
      });
    } else {
      setNotification('Cannot edit', 'Please select a template to edit');
    }
  };

  const handleAction = async (e) => {
    if (e.detail.id === 'associate') {
      setAssociateModalVisible(true);
    }
    if (e.detail.id === 'edit') {
      await handleEditTemplate();
      setActionLoading(false);
    }
    if (e.detail.id === 'delete') {
      setDeleteText(`Template ${selectedTemplateId}`);
      setDeleteTemplateModalVisible(true);
    }
  };

  const handleDissociateResource = async (dissociateTemplateId, dissociateResourceId) => {
    setButtonLoading(true);
    try {
      await dissociateTemplateFromResources({
        templateId: dissociateTemplateId,
        resourceId: dissociateResourceId,
      });
      setButtonLoading(false);
      setTemplateResources(
        templateResources.filter((r) => {
          return r.resourceId != dissociateResourceId;
        }),
      );
      setTemplatesResult(
        templatesResult.filter((r) => {
          return r.resourceId != dissociateResourceId;
        }),
      );
      setNotification('success', 'Template dissociated successfully.');
    } catch (err) {
      setNotification('error', 'Failed to dissociate the template.');
    }
    setButtonLoading(false);
  };

  // ---------------------------- HELPER FUNCTIONS ------------------ //
  const setNotification = async (header, message) => {
    if (header === '' || header === 'success') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (redirect) return <Redirect push to={redirect} />;
  return (
    <>
      <Flashbar items={notifications}></Flashbar>
      <ConfirmModal
        visible={modalVisible}
        onClose={closeModal}
        onConfirm={handleDelete}
        variant={'delete'}
        content={deleteText}
      ></ConfirmModal>
      <AssociateTemplateModal
        templateId={selectedTemplateId}
        setNotification={setNotification}
        close={() => {
          setAssociateModalVisible(false);
        }}
        visible={associateModalVisible}
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
        handleRefresh={handleRefresh}
      ></AssociateTemplateModal>
      <SpaceBetween size='l'>
        <Table
          {...collectionProps}
          selectionType='single'
          loadingText='Loading templates...'
          loading={loadingTemplates}
          columnDefinitions={templateColumnDefinitions}
          items={items}
          wrapLines={preferences.wrapLines}
          empty={
            <Box textAlign='center' color='inherit'>
              <b>No templates</b>
              <Box variant='p' color='inherit'>
                No templates to display.
              </Box>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter resources'
              filteringPlaceholder='Find resources'
              countText={getFilterCounterText(filteredItemsCount)}
            />
          }
          resizableColumns={true}
          header={
            <>
              <PageHeader
                buttons={[
                  {
                    text: '',
                    icon: 'refresh',
                    onItemClick: handleRefresh,
                  },
                  {
                    text: 'Actions',
                    onItemClick: handleAction,
                    items: [
                      {
                        text: 'Associate resource',
                        id: 'associate',
                        // Table is single selectionType
                        disabled:
                          !collectionProps.selectedItems.length || isGlobalTemplate(collectionProps.selectedItems[0]),
                      },
                      {
                        text: 'Edit',
                        id: 'edit',
                        // Table is single selectionType
                        disabled:
                          !collectionProps.selectedItems.length || isGlobalTemplate(collectionProps.selectedItems[0]),
                      },
                      {
                        text: 'Delete',
                        id: 'delete',
                        // Table is single selectionType
                        disabled:
                          !collectionProps.selectedItems.length || isGlobalTemplate(collectionProps.selectedItems[0]),
                      },
                    ],
                    loading: actionLoading,
                  },
                  {
                    text: 'Create template',
                    variant: 'primary',
                    onItemClick: () => {
                      setRedirect(Page.CREATE_TEMPLATE);
                    },
                  },
                ]}
                counter={`(${items.length})`}
                header={'Templates'}
                info={
                  <LinkV3 variant='info' onFollow={props.toggleHelp}>
                    {' '}
                    Info{' '}
                  </LinkV3>
                }
              />
            </>
          }
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={notificationsPageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        />
        {selectedTemplate && (
          <TemplateResourcesTable
            templateResources={templateResources}
            tableMessage={tableMessage}
            notification={setNotification}
            handleDissociateResource={handleDissociateResource}
          />
        )}
        <Modal
          visible={deleteTemplateModalVisible}
          header={[`Confirm`]}
          onDismiss={() => setDeleteTemplateModalVisible(false)}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='link'
                  onClick={() => {
                    setDeleteTemplateModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant='primary' loading={buttonLoading} onClick={handleDelete}>
                  Confirm
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <TextContent>
            Are you sure you want to delete <strong>{selectedTemplateId}</strong>?
            <br />
            This will also dissociate any resources that were previously associated to the template.
          </TextContent>
        </Modal>
      </SpaceBetween>
    </>
  );
};
