export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Lake Formation Share properties',
    options: [
      { id: 'principal', label: 'Data lake principal', editable: true, visible: false },
      { id: 'catalogId', label: 'Catalog ID', editable: true, visible: false },
      { id: 'databaseName', label: 'Database', editable: true, visible: true },
      { id: 'tableName', label: 'Table', editable: true, visible: true },
      { id: 'columns', label: 'Columns', editable: true, visible: true },
      { id: 'permissions', label: 'Permissions', editable: true, visible: false },
      { id: 'grantPermissions', label: 'Grant permissions', editable: true, visible: false },
    ],
  },
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 shares' },
  { value: 30, label: '30 shares' },
  { value: 50, label: '50 shares' },
];
