import * as React from 'react';
import { useState, useEffect } from 'react';
import { NotificationBatchEntry } from 'aws-sdk/clients/awsdlomni';

import { notificationsPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Badge,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

import { Link } from 'react-router-dom';
import { deleteNotificationBatches, getNotificationBatches, updateNotificationBatch } from '../../../api/notifications';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

import { PageHeader } from 'src/components/common/PageHeader';

export interface SentNotificationsProps {
  activeWorkspace: any;
  setContentType: any;
  activeGroup: string;
  username: string;
  refreshNotificationNum: any;
  setSelectedNotificationBatch: any;
  setActiveTabId: any;
  refreshUnreadNotificationBatch: any;
}

//sent notification tab
export const SentNotifications = (props: SentNotificationsProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [tableMessage] = useState('No notifications');
  const [actionLoading, setActionLoading] = useState(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
    visibleContent: ['Subject', 'Content', 'LastCommentTime'],
  });

  useEffect(() => {
    handleRefresh();
    props.setContentType(TABLE_CONTENT_TYPE);
  }, [props.activeGroup]);

  const markRead = async (notificationBatch) => {
    await updateNotificationBatch({
      NotificationBatchId: notificationBatch.NotificationBatchId,
      MarkComment: false,
      NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
    });
    props.refreshUnreadNotificationBatch(notificationBatch.NotificationBatchId);
  };

  const renderUpdateComment = (item, unreadData, username) => {
    if (item.ReviewerList[username] != null) {
      return (
        <span>
          <Badge color='blue'>new</Badge>
          {unreadData}
        </span>
      );
    }
    return <span>{unreadData}</span>;
  };

  const columnDefinitions: TableProps.ColumnDefinition<NotificationBatchEntry>[] = [
    {
      id: 'Subject',
      header: 'Subject',
      cell: (item) => (
        <Link to={`notification-batch/${item.NotificationBatchId}`}>
          {renderUpdateComment(item, item.Subject, props.username)}
        </Link>
      ),
    },
    {
      id: 'Content',
      header: 'Content',
      cell: (item) => item.Content,
      minWidth: 300,
    },
    {
      id: 'PriorityLevel',
      header: 'Priority',
      cell: (item) => item.PriorityLevel,
    },
    {
      id: 'CreateTime',
      header: 'Create time',
      cell: (item) => new Date(item.CreateTime).toLocaleString(),
      minWidth: 200,
      sortingField: 'CreateTime',
    },
    {
      id: 'LastCommentTime',
      header: 'Last update time',
      cell: (item) => new Date(item.LastCommentTime).toLocaleString(),
      minWidth: 200,
      sortingField: 'LastCommentTime',
    },
    {
      id: 'NotificationOwnerId',
      header: 'User ID',
      cell: (item) => item.NotificationOwnerId,
    },
    {
      id: 'receiverIdList',
      header: "Receiver ID's",
      cell: (item) => item.ReceiverIdList.join(', '),
    },
    {
      id: 'NotificationBatchId',
      header: 'Notification batch ID',
      cell: (item) => item.NotificationBatchId,
    },
  ];

  const contentSelectorOptions: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
    {
      label: 'Sent notifications',
      options: columnDefinitions.map((column) => ({
        id: column.id,
        label: column.header as string,
        editable: true,
      })),
    },
  ];

  const handleRefresh = async () => {
    props.setActiveTabId('sent');
    setLoadingNotifications(true);

    if (!props.activeGroup) return;

    let request = {
      NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
      NextToken: '',
    };
    let response = await getNotificationBatches(request);

    let notificationBatchList = [...response.NotificationBatchList];
    //Loop and get remaining tables
    while (response.NextToken != null) {
      request = {
        NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
        NextToken: response.NextToken,
      };
      response = await getNotificationBatches(request);
      notificationBatchList.push(...response.NotificationBatchList);
    }
    setItems(notificationBatchList);
    setLoadingNotifications(false);
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No notifications to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        isDescending: true,
        sortingColumn: {
          sortingField: 'LastCommentTime',
        },
      },
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    if (!selectedItems.length) return props.setSelectedNotificationBatch({});

    const selectedNotification: NotificationBatchEntry = selectedItems[selectedItems.length - 1];
    props.setSelectedNotificationBatch({
      selectedContent: selectedNotification.Content,
      selectedPriorityLevel: selectedNotification.PriorityLevel,
      selectedSubject: selectedNotification.Subject,
      selectedCreateTime: selectedNotification.CreateTime,
      selectedNotificationBatchId: selectedNotification.NotificationBatchId,
      selectedReviewerList: selectedNotification.ReviewerList,
      selectedLastCommentTime: selectedNotification.LastCommentTime,
      selectedEmailSent: selectedNotification.EmailSent,
      selectedNotificationOwnerId: selectedNotification.NotificationOwnerId,
      selectedReceiverIdList: selectedNotification.ReceiverIdList,
      selectedSenderGroupId: selectedNotification.SenderId,
    });
    if (collectionProps.selectedItems[0].ReviewerList[props.username] != null) {
      markRead(collectionProps.selectedItems[0]);
    }
  }, [collectionProps.selectedItems]);

  const handleAction = async (e) => {
    if (e.detail.id === 'delete') {
      setActionLoading(true);
      await deleteNotificationBatches({
        NotificationOwnerId: props.activeWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup,
        NotificationBatchIdList: collectionProps.selectedItems.map(({ NotificationBatchId }) => NotificationBatchId),
      });
      setItems(
        allItems.filter((item) => item.NotificationBatchId != collectionProps.selectedItems[0].NotificationBatchId),
      );

      handleRefresh();
      setActionLoading(false);
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={'single'}
        loadingText='Loading notifications...'
        visibleColumns={preferences.visibleContent}
        loading={loadingNotifications}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'Delete',
                      id: 'delete',
                      disabled: !collectionProps.selectedItems.length,
                    },
                  ],
                  loading: actionLoading,
                },
              ]}
              header={'Sent notifications'}
              counter={`(${allItems.length})`}
            />
          </>
        }
        empty={
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No notifications to display.</p>
          </div>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter notifications'
            filteringPlaceholder='Find notifications'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={notificationsPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
            visibleContentPreference={{
              title: 'Visible content',
              options: contentSelectorOptions,
            }}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      />
    </>
  );
};
