import { Link, Redirect, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { associateGlossaryToResource, deleteGlossary, listGlossaries } from 'src/api/catalog';
import { METADATA_FORM_PREFIX, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { Page } from 'src/routes';
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Flashbar,
  Modal,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
  TextContent,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import * as React from 'react';
import { defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels } from 'src/commons/tables';
import BusinessGlossaryAssociationsTable from 'src/components/metadata/businessGlossaries/businessGlossaryAssociations';
import { isHCAdmin } from 'src/commons/common';
import { ResourceSelectorModal } from 'src/components/common/resourceSelector/resourceSelectorModal';
import { generateArnFromId } from 'src/components/utils/arnUtil';
import { EmptyState } from 'src/commons/EmptyState';
import { PageHeader } from 'src/components/common/PageHeader';

export interface MetadataBusinessGlossariesProps {
  setContentType: any;
  activeGroup: string;
  match: any;
  activeWorkspace?: any;
}

export const MetadataBusinessGlossaries = (props: MetadataBusinessGlossariesProps) => {
  const { state } = useLocation();
  const [redirect, setRedirect] = useState(undefined);
  const [loadingGlossaries, setLoadingGlossaries] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [businessGlossaries, setBusinessGlossaries] = useState([]);
  const [selectedGlossary, setSelectedGlossary] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [deleteGlossaryModalVisible, setDeleteGlossaryModalVisible] = useState(false);
  const [associateGlossaryModalVisible, setAssociateGlossaryModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    businessGlossaries,
    {
      filtering: {
        noMatch: '',
        empty: <EmptyState title={'No glossaries'} subtitle={'No business glossaries were found.'} />,
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  const glossaryColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'glossaryName',
      header: 'Name',
      cell: (item) => <Link to={`/glossaries/${item.GlossaryId}/${item.OwnerId}`}>{item.Name.split()}</Link>,
      minWidth: 200,
      sortingField: 'glossaryName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Description,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => (
        <StatusIndicator type={item.Status == 'Enabled' ? 'success' : 'stopped'}>{item.Status}</StatusIndicator>
      ),
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.GlossaryType.replace('Glossary#', ''),
      minWidth: 200,
    },
    {
      id: 'glossaryValue',
      header: 'Value',
      cell: (item) => item.GlossaryValue,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
    if (state) {
      setNotification(state.status, state.message);
    }
  }, []);

  useEffect(() => {
    if (ownerId) {
      handleRefresh();
    }
  }, [ownerId]);

  const handleRefresh = async () => {
    setLoadingGlossaries(true);
    setSelectedGlossary(undefined);
    await getGlossariesForOwner();
  };

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedGlossary = selectedItems[0];
    if (selectedGlossary) {
      setSelectedGlossary(selectedGlossary);
    }
  }, [collectionProps.selectedItems]);

  const getGlossariesForOwner = async () => {
    setLoadingGlossaries(true);
    try {
      let listGlossariesResult = await listGlossaries({
        OwnerId: ownerId,
      });
      let glossaries = listGlossariesResult.GlossaryDetailsList;
      while (listGlossariesResult.NextToken != undefined) {
        listGlossariesResult = await listGlossaries({
          OwnerId: ownerId,
          NextToken: listGlossariesResult.NextToken,
        });
        glossaries.push(...listGlossariesResult.GlossaryDetailsList);
      }
      setBusinessGlossaries(glossaries);
    } catch (err) {
      setNotification('Error', `Unable to load glossaries. ${err.message}`);
    } finally {
      setLoadingGlossaries(false);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'error') {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const shouldEnableAdminPermissions = () => {
    if (!selectedGlossary) return false;
    if (props.activeGroup == selectedGlossary.OwnerId || props.activeWorkspace?.workspaceId == selectedGlossary.OwnerId)
      return true;
    if (isHCAdmin(props.activeGroup)) return true;
    return false;
  };

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (redirect) return <Redirect push to={redirect} />;

  const businessGlossaryButtonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleBusinessGlossaryButtonAction,
        items: [
          {
            text: 'Edit',
            id: 'editGlossary',
            disabled: !shouldEnableAdminPermissions(),
          },
          {
            text: 'Delete',
            id: 'deleteGlossary',
            disabled: !shouldEnableAdminPermissions(),
          },
          {
            text: 'Associate',
            id: 'associateGlossary',
            disabled: !selectedGlossary,
          },
        ],
      },
      {
        text: 'Create glossary',
        variant: 'primary',
        onItemClick: () =>
          setRedirect({
            pathname: Page.CREATE_BUSINESS_GLOSSARY,
            state: {
              ownerId: ownerId,
            },
          }),
      },
    ];
  };

  const handleBusinessGlossaryButtonAction = async (e) => {
    if (e.detail.id === 'editGlossary') {
      handleEditBusinessGlossary();
    } else if (e.detail.id === 'deleteGlossary') {
      setDeleteGlossaryModalVisible(true);
    } else if (e.detail.id == 'associateGlossary') {
      setAssociateGlossaryModalVisible(true);
    }
  };

  const handleEditBusinessGlossary = async () => {
    if (selectedGlossary !== undefined) {
      setRedirect({
        pathname: Page.EDIT_BUSINESS_GLOSSARY,
        state: {
          glossaryId: selectedGlossary.GlossaryId,
        },
      });
    } else {
      setNotification('error', 'Please select a form to edit');
    }
  };

  const handleDeleteBusinessGlossary = async () => {
    setButtonLoading(true);
    try {
      let deleteGlossaryResult = await deleteGlossary({
        GlossaryId: selectedGlossary.GlossaryId,
        OwnerId: ownerId,
      });
      if (deleteGlossaryResult.Message == 'Success') {
        await setNotification('success', 'Success');
      } else {
        await setNotification('error', `Failed to delete the glossary. Reason: ${deleteGlossaryResult.Message}`);
      }
    } catch (err) {
      await setNotification('error', `Error in deleting the glossary. ${err.message}`);
    } finally {
      setButtonLoading(false);
      setDeleteGlossaryModalVisible(false);
      await handleRefresh();
    }
  };

  const handleAssociateGlossary = async (resourceId: string) => {
    try {
      const resourceArn = generateArnFromId(resourceId);
      let associateGlossaryRequest;
      if (resourceId.startsWith(METADATA_FORM_PREFIX)) {
        const isWorkspace = !!props.activeWorkspace;
        const ownerId = isWorkspace ? props.activeWorkspace.workspaceId : props.activeGroup;
        associateGlossaryRequest = {
          GlossaryId: selectedGlossary?.GlossaryId,
          OwnerId: selectedGlossary?.OwnerId,
          FormOwnerId: ownerId,
          ResourceArn: resourceId,
        };
      } else {
        associateGlossaryRequest = {
          GlossaryId: selectedGlossary?.GlossaryId,
          OwnerId: selectedGlossary?.OwnerId,
          ResourceArn: resourceArn,
        };
      }
      const associateGlossaryResult = await associateGlossaryToResource(associateGlossaryRequest);
      if (associateGlossaryResult.Message == 'Success') {
        setNotification('success', 'Successfully associated to the glossary');
      } else {
        setNotification('error', `Failure in associating form :${associateGlossaryResult.Message}`);
      }
    } catch (err) {
      setNotification('error', `Error in associating glossary :${err.message}`);
    } finally {
      setAssociateGlossaryModalVisible(false);
    }
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Modal
        visible={deleteGlossaryModalVisible}
        header={[`Confirm deletion`]}
        onDismiss={() => setDeleteGlossaryModalVisible(false)}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='primary' loading={buttonLoading} onClick={handleDeleteBusinessGlossary}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <TextContent>
          Are you sure you want to delete glossary <strong>'{selectedGlossary?.Name}'</strong>?
          <br />
          <br />
          <strong>Please delete all the associated resources before deleting the glossary.</strong>
        </TextContent>
      </Modal>
      <ResourceSelectorModal
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
        visible={associateGlossaryModalVisible}
        onSelect={handleAssociateGlossary}
        close={() => setAssociateGlossaryModalVisible(false)}
        disableGroupAndWorkspaceSelection={true}
        disableTagSelection={true}
        disableMetadataFormSelection={false}
      />
      <SpaceBetween size='l'>
        <Table
          {...collectionProps}
          selectionType='single'
          loadingText='Loading glossaries...'
          loading={loadingGlossaries}
          columnDefinitions={glossaryColumnDefinitions}
          items={items}
          wrapLines={preferences.wrapLines}
          empty={
            <Box textAlign='center' color='inherit'>
              <b>No glossaries</b>
              <Box variant='p' color='inherit'>
                No glossaries to display. Create one...
              </Box>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter resources'
              filteringPlaceholder='Find resources'
              countText={getFilterCounterText(filteredItemsCount)}
            />
          }
          resizableColumns={true}
          header={
            <>
              <PageHeader
                buttons={businessGlossaryButtonOptions()}
                counter={`(${businessGlossaries.length})`}
                header={'Business glossaries'}
              />
            </>
          }
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={notificationsPageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        />
        {selectedGlossary && (
          <BusinessGlossaryAssociationsTable
            glossary={selectedGlossary}
            ownerId={ownerId}
            setNotification={setNotification}
            activeWorkspace={props.activeWorkspace}
          />
        )}
      </SpaceBetween>
    </>
  );
};

export default MetadataBusinessGlossaries;
