import { ColumnLayout, Container, FormField, Header, Textarea } from '@amzn/awsui-components-react-v3';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import * as React from 'react';
import { useEffect } from 'react';

export interface BaselineQuestionnaireForm {
  setContentType: any;
  dataPermissionList: any[];
  disabled?: boolean;
  questionnaireList: any[];
  setQuestionnaireList: any;
}

export const BaselineQuestionnaireForm = (props: BaselineQuestionnaireForm) => {
  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    try {
      if (props.questionnaireList && props.questionnaireList.length > 0) {
        return;
      }
      let questionnaireMap = new Map<string, object[]>();
      for (let dataPermission of props.dataPermissionList) {
        if (dataPermission?.baselineInfo?.useCase != null) {
          let questionnaire = dataPermission?.baselineInfo?.useCase;
          if (questionnaireMap.has(questionnaire)) {
            questionnaireMap.get(questionnaire).push(dataPermission);
          } else {
            questionnaireMap.set(questionnaire, [dataPermission]);
          }
        }
      }
      let questionnaireList = [];
      for (const [questionnaire, dataPermissionList] of questionnaireMap) {
        const questionsAndAnswers = extractQuestionsAndAnswers(questionnaire);
        questionnaireList.push({
          originalData: JSON.parse(questionnaire),
          questionsAndAnswers: questionsAndAnswers['qaList'],
          dataPermissions: dataPermissionList,
          nextQuarter: questionsAndAnswers['nextQuarter'],
        });
      }

      props.setQuestionnaireList(questionnaireList);
    } catch (err) {
      console.error(err);
    }
  };

  const getNextQuarter = (currentQuarter: string) => {
    const [year, quarter] = currentQuarter.split('_');
    const quarterNum = parseInt(quarter.substring(1));

    if (quarterNum === 4) {
      return `${parseInt(year) + 1}_Q1`;
    } else {
      return `${year}_Q${quarterNum + 1}`;
    }
  };

  const extractQuestionsAndAnswers = (jsonString: string) => {
    try {
      const data = JSON.parse(jsonString);
      //quarter format: yyyy_Qq e.g. 2024_Q1
      let baselineQuarters = Array.from(new Set(Object.keys(data.questionnaire)));
      const sortedBaselinQuarters = baselineQuarters.sort((a, b) => {
        const [yearA, qA] = a.split('_');
        const [yearB, qB] = b.split('_');

        const yearDiff = parseInt(yearA) - parseInt(yearB);
        if (yearDiff !== 0) return yearDiff;

        return parseInt(qA.slice(1)) - parseInt(qB.slice(1));
      });
      const latestQuarter = sortedBaselinQuarters[sortedBaselinQuarters.length - 1];

      const nextQuarter = getNextQuarter(latestQuarter);

      if (!data.questionnaire.qaList) {
        data.questionnaire.qaList = data.questionnaire[latestQuarter]
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((item, _) => ({
            id: item.id,
            question: item.name,
            value: item.value,
            requiredFromTemplate: item.requiredFromTemplate,
            type: item.type,
            sortOrder: item.sortOrder,
            approvalId: item.approvalId,
            editable: item.editable,
            visible: item.visible,
          }));
      }

      return {
        qaList: data.questionnaire.qaList,
        nextQuarter: nextQuarter,
      };
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return [];
    }
  };

  const questionField = (questionnaireIndex, qaIndex) => {
    let qa = props.questionnaireList[questionnaireIndex]['questionsAndAnswers'][qaIndex];
    if (!qa.visible) {
      return;
    }
    return (
      <FormField label={qa.question}>
        <Textarea
          onChange={(e) => {
            props.setQuestionnaireList((prevState) => {
              let newState = [...prevState];
              newState[questionnaireIndex]['questionsAndAnswers'][qaIndex].value = e.detail.value;
              return newState;
            });
          }}
          value={props.questionnaireList[questionnaireIndex]['questionsAndAnswers'][qaIndex].value}
          ariaRequired={true}
          disabled={!qa.editable}
        />
      </FormField>
    );
  };

  return (
    <>
      {props.questionnaireList?.map((questionnaire, index) => {
        return (
          <>
            <Container header={<Header variant={'h2'}>Questionnaire</Header>}>
              <ColumnLayout>
                {questionnaire['questionsAndAnswers'].map((_, qaIndex) => questionField(index, qaIndex))}
              </ColumnLayout>
            </Container>
            <br />
            <br />
          </>
        );
      })}
    </>
  );
};
