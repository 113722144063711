import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ColumnLayout,
  Flashbar,
  Header,
  Select,
  Spinner,
  Table,
  Tabs,
  Link,
  Container,
} from '@amzn/awsui-components-react-v3';
import { DataBadges, DetailsPageHeader } from 'src/components/catalog/common';
import {
  convertToDgsHCResourceArn,
  CopiableText,
  DefaultRouteProps,
  fetchTemplatesForResourceId,
  isDataLakeAdmin,
  isDGSAdmin,
} from 'src/commons/common';
import { TableColumnList } from 'src/components/workspaces/dataBrowse/dataset/TablecolumnList';
import { DatasetTagList } from 'src/components/workspaces/dataBrowse/dataset/DatasetTagList';
import { dataSetDetail, listCatalogs, listDataSets, syncDataSets } from 'src/api/catalog';
import { getResourceTags, listFgaPolicies } from 'src/api/permissions';
import { AccessSelectModal } from 'src/components/workspaces/dataBrowse/dataset/accessSelectModal';
import { DatasetEditModal } from 'src/components/workspaces/dataBrowse/dataset/datasetEdit';
import { isValidPrincipalNotRoot } from 'src/commons/validationUtils';
import { isCommercialPartition } from 'src/commons/validationUtils';
import {
  createCatalogDetailLink,
  createDatabaseDetailLink,
  createFgaPolicyViewLink,
  createSchemaDetailLink,
  Page,
} from 'src/routes';
import {
  ACCESS_PROVIDER_GALAXI,
  CONTACT_PRIMARY_OWNER_FORM_NAME,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  DATAZONE_TYPE,
  DZ_GLUE_DATASOURCE_ID,
  DZ_REDSHIFT_DATASOURCE_ID,
  FGA_POLICY_TYPE_PREFIX,
  GALAXI_ACCESS_TYPE,
  GALAXI_TYPE,
  RESOURCE_ITEM,
  STATUS_ACTIVE,
  TABLE_CONTENT_TYPE,
  TABLE_STATUS_DEPRECATED,
  TAG_ITEM,
} from 'src/commons/constants';
import { DataConsumersTable } from 'src/components/permissions/myBaselining/dataConsumersTable';
import MetadataDetails from 'src/components/workspaces/common/metadataDetails';
import { AdvisoriesForResourceTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoriesForResource';
import { TemplatesForResourceDetailsTable } from 'src/components/templates/TemplatesForResourceDetailsTable';
import { enableAdvisories, galaxiServiceIntegrated } from 'src/api/config';
import { DataQuality } from 'src/components/catalog/dataquality/dataQuality';
import {
  checkIfTagIsAlreadyConsumedAndGetPublisherTag,
  generateMetaDataMap,
  getAllTableAccessItem,
  getConsumedPermissionsOfFineGrainPolicy,
  getGlossariesForResource,
  getSingleTableAccessDataPermission,
  isMPData,
  lakeFormationIcon,
  loadDZTableDataPermission,
  loadTableDataPermission,
  metadataMapHasCTI,
  ownerLinkItem,
  redshiftIcon,
} from 'src/components/workspaces/common/common';
import ContactInfo from 'src/components/workspaces/common/ContactInfo';
import { getDatasetUpperLevelResourceId } from 'src/components/utils/hybridCatalog/idUtil';
import { signedInWithWorkspace } from 'src/components/dataadvisory/constants';
import IamConsumersTable from 'src/components/catalog/datasetdetail/iamConsumers';
import UsageStatistics from 'src/components/catalog/datasetdetail/usageStatisticsDetails';
import { Redirect } from 'react-router';
import { flattenItem } from 'src/components/catalog/browsedatasets';
import { DataPermissionList } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { LineageDetail } from 'src/components/lineage/lineageDetail';
import { EmptyState } from 'src/commons/EmptyState';
import { ResourceNotFound } from 'src/commons/ResourceNotFound';

export interface WorkspaceDatasetDetailProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  match: any;
  setActiveDatasetName: any;
  cartItemIds: any[];
  addToCart: any;
  groups: any[];
  userInfo: any;
  cartItems: [];
  allowListed?: boolean;
  externalGroups: any[];
}

export const DatasetDetails = (props: WorkspaceDatasetDetailProps) => {
  const [dataset, setDataset] = useState(undefined);
  const [editDataSetModalVisible, setEditDataSetModalVisible] = useState(false);
  const [accessSelectVisible, setAccessSelectVisible] = useState(undefined);
  const [catalogName, setCatalogName] = useState(undefined);
  const [CTI, setCTI] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [options, setOptions] = useState([]);
  const [mostRecentOption, setMostRecentOption] = useState(undefined);
  const [status, setStatus] = useState('loading');
  const [option, setOption] = useState(undefined);
  const [curNextToken, setCurNextToken] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [fgaPolicies, setFgaPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncButtonLoading, setSyncButtonLoading] = useState(false);
  const [templates, setTemplates] = useState(undefined);
  const [businessGlossaries, setBusinessGlossaries] = useState(undefined);
  const [redirect, setRedirect] = useState(undefined);
  const [groupLevelDataPermissionList, setGroupLevelDataPermissionList] = useState([]);
  const [selectedPermissionType, setSelectedPermissionType] = useState(undefined);
  const [allPermissionDropdownOptions, setAllPermissionDropdownOptions] = useState(undefined);
  const [alreadyHasDataZoneAccess, setAlreadyHasDataZoneAccess] = useState(false);

  const [availableSubscribeOptions, setAvailableSubscribeOptions] = useState(undefined);

  const SUBSCRIBE_TO_DATASET = 'Subscribe to dataset';

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const fetchCatalogName = async (dataset) => {
    let catalog;
    try {
      catalog = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: dataset?.IdInfo?.CatalogId,
              ClusterIdentifier: dataset?.IdInfo?.ClusterIdentifier,
              RedshiftWorkgroupName: dataset?.IdInfo?.RedshiftWorkgroupName,
              Region: dataset?.IdInfo?.Region,
              DataSourceId: dataset?.IdInfo?.DataSourceId,
            },
          ],
        },
      });
    } catch (e) {
      console.log('Could not load catalog for dataset: ' + dataset);
      console.log('Could not load catalog : ' + catalog);
      catalog = null;
    }
    let catalogName = catalog?.CatalogInfoList[0]?.Name;
    if (catalogName == null) {
      catalogName = dataset?.IdInfo?.CatalogId;
    }
    setCatalogName(catalogName);
    let metadataMap = await generateMetaDataMap(getArn(dataset));
    if (!metadataMapHasCTI(metadataMap.get(CONTACT_PRIMARY_OWNER_FORM_NAME)?.values)) {
      setCTI(catalog?.CatalogInfoList[0]?.CTI);
    }
    return catalogName;
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      let datasetList = await listDataSets({
        Filter: {
          IdList: [props.match.params.id],
        },
      });
      const newDataset = datasetList.DataSetList[0];
      await setDataset(newDataset);

      setSelectedPermissionType(undefined);

      if (newDataset?.IdInfo?.TableName) {
        props.setActiveDatasetName(newDataset?.IdInfo?.TableName);
        if (newDataset?.TableState == TABLE_STATUS_DEPRECATED) {
          setNotifications([
            {
              type: 'error',
              content: 'This dataset is deprecated',
              dismissible: true,
              onDismiss: () => setNotifications([]),
            },
          ]);
        }
      }

      await fetchDetails();
      await setupPermissionDropdownOptions(newDataset);
      await fetchTemplatesForDataset(newDataset);
      setBusinessGlossaries(await getGlossariesForResource(getArn(newDataset)));
      if (
        newDataset?.SupportedAccessTypes?.includes('LakeFormation') &&
        ACCESS_PROVIDER_GALAXI != newDataset?.AccessProvider
      ) {
        let dataPermissionList = await loadTableDataPermission(props.activeGroup, newDataset);
        setGroupLevelDataPermissionList(dataPermissionList);
      }
      // For DataZone:
      if (newDataset?.DataSourceType?.includes('DZ')) {
        const dzPermissions: DataPermissionList = await loadDZTableDataPermission(
          props.activeWorkspace.workspaceId,
          newDataset,
        );
        setAlreadyHasDataZoneAccess(dzPermissions.length > 0);
      }
    } catch (err) {
      console.log('Failed to load dataset', err.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const getDatasetDetailsMetadata = (props: any) => {
    const datasetMetadata = [];
    if (dataset?.IdInfo?.TableName) {
      datasetMetadata.push(
        <CopiableText name={'Dataset name'} key={'Dataset name'} value={dataset?.IdInfo?.TableName} />,
      );
    }

    datasetMetadata.push(<CopiableText name={'Dataset title'} key={'Dataset title'} value={dataset?.DataSetName} />);
    if (dataset?.IdInfo?.CatalogId) {
      datasetMetadata.push(
        <CopiableText
          name={'Catalog ID'}
          key={'Catalog ID'}
          value={dataset?.IdInfo?.CatalogId}
          url={createCatalogDetailLink(
            dataset?.IdInfo?.DataSourceId,
            dataset?.IdInfo?.CatalogId,
            dataset?.IdInfo?.ClusterIdentifier,
            dataset?.IdInfo?.RedshiftWorkgroupName,
            dataset?.IdInfo?.Region,
          )}
        />,
      );
    }

    if (dataset?.Owners) {
      datasetMetadata.push(
        <CopiableText
          copiable={false}
          name={'Owner'}
          key={'Owner'}
          value={dataset?.Owners.map((owner) => ownerLinkItem(owner, props.workspaceNameMap))}
        />,
      );
    }
    // include dataset description without condition.
    // Users can view a blank one and update if needed.
    datasetMetadata.push(
      <CopiableText name={'Dataset description'} key={'Dataset description'} value={dataset?.DataSetDesc} />,
    );

    if (dataset?.IdInfo?.DatabaseName) {
      datasetMetadata.push(
        <CopiableText
          name={'Database name'}
          key={'DatabaseName'}
          value={dataset?.IdInfo?.DatabaseName}
          url={createDatabaseDetailLink(
            dataset?.IdInfo?.DataSourceId,
            dataset?.IdInfo?.CatalogId,
            dataset?.IdInfo?.ClusterIdentifier,
            dataset?.IdInfo?.RedshiftWorkgroupName,
            dataset?.IdInfo?.DatabaseName,
            dataset?.IdInfo?.Region,
          )}
        />,
      );
    }

    if (dataset?.IdInfo?.SchemaName) {
      datasetMetadata.push(
        <CopiableText
          name={'Schema name'}
          key={'SchemaName'}
          value={dataset?.IdInfo?.SchemaName}
          url={createSchemaDetailLink(
            dataset?.IdInfo?.DataSourceId,
            dataset?.IdInfo?.CatalogId,
            dataset?.IdInfo?.ClusterIdentifier,
            dataset?.IdInfo?.RedshiftWorkgroupName,
            dataset?.IdInfo?.DatabaseName,
            dataset?.IdInfo?.SchemaName,
            dataset?.IdInfo?.Region,
          )}
        />,
      );
    }

    if (dataset?.IdInfo?.ClusterIdentifier) {
      datasetMetadata.push(
        <CopiableText name={'Cluster name'} key={'ClusterName'} value={dataset?.IdInfo?.ClusterIdentifier} />,
      );
    }

    if (dataset?.IdInfo?.RedshiftWorkgroupName) {
      datasetMetadata.push(
        <CopiableText
          name={'Workgroup name'}
          key={'RedshiftWorkgroupName'}
          value={dataset?.IdInfo?.RedshiftWorkgroupName}
        />,
      );
    }

    if (dataset?.IdInfo?.Region) {
      datasetMetadata.push(<CopiableText name={'Region'} key={'Region'} value={dataset?.IdInfo?.Region} />);
    }
    if (dataset?.Id) {
      datasetMetadata.push(<CopiableText name={'ID'} key={'ID'} value={dataset?.Id} />);
    }
    if (catalogName) {
      datasetMetadata.push(
        <CopiableText
          name={'Catalog name'}
          key={'CatalogName'}
          value={catalogName}
          url={createCatalogDetailLink(
            dataset?.IdInfo?.DataSourceId,
            dataset?.IdInfo?.CatalogId,
            dataset?.IdInfo?.ClusterIdentifier,
            dataset?.IdInfo?.RedshiftWorkgroupName,
            dataset?.IdInfo?.Region,
          )}
        />,
      );
    }
    if (dataset?.CreatedBy) {
      datasetMetadata.push(<CopiableText name={'Created by'} key={'CreatedBy'} value={dataset?.CreatedBy} />);
    }
    if (dataset?.CreatedOn) {
      datasetMetadata.push(<CopiableText name={'Created on'} key={'CreatedOn'} value={dataset?.CreatedOn} />);
    }
    if (dataset?.WheeljackConfidenceFileLocation && dataset.WheeljackConfidenceFileLocation.trim()) {
      datasetMetadata.push(
        <CopiableText
          name={'Confidence files'}
          key={'Confidence files'}
          value={dataset?.WheeljackConfidenceFileLocation}
        />,
      );
    }

    if (mostRecentOption?.item || option?.item) {
      let version = mostRecentOption?.item || option?.item;

      if (version?.Location) {
        datasetMetadata.push(<CopiableText key='S3 location' name='S3 location' value={version?.Location} />);
      }

      if (version?.AdditionalLocations) {
        datasetMetadata.push(
          <CopiableText
            key='Additional locations'
            name='Additional locations'
            value={version.AdditionalLocations.join(', ')}
          />,
        );
      }

      if (version?.SourceUpdateTime) {
        datasetMetadata.push(
          <CopiableText key='Update time' name='Update time' value={version?.SourceUpdateTime?.toString()} />,
        );
      }
    }

    return datasetMetadata;
  };

  const columnDefinitions = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => item.Name,
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.Type,
      minWidth: '200px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Comment,
      minWidth: '200px',
    },
  ];

  const fgaPoliciesColumnDefinitions = [
    {
      id: 'fgapName',
      header: 'Policy name',
      cell: (item) => <Link href={createFgaPolicyViewLink(item?.id)}>{item?.tableData?.physicalName}</Link>,
      minWidth: 200,
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item) => item?.ownerId,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => item?.status,
      minWidth: 200,
    },
    {
      id: 'statusReason',
      header: 'Status reason',
      cell: (item) => item?.statusReason,
      minWidth: 200,
    },
  ];

  const fetchTemplatesForDataset = async (dataset) => {
    let fetchedTemplates = await fetchTemplatesForResourceId(dataset?.Id);
    setTemplates(fetchedTemplates);
  };

  // display "Partition Keys" section on UI
  const getPartitionKeysTable = (version) => {
    return (
      <Table
        loadingText='Loading resources'
        columnDefinitions={columnDefinitions}
        items={version?.PartitionKeys || []}
        resizableColumns
        header={
          <Header counter={version?.PartitionKeys?.length ? `(${version.PartitionKeys.length})` : '(0)'}>
            Partition keys
          </Header>
        }
        empty={<EmptyState title='No partition keys' subtitle='No partition keys found for this dataset.' />}
      />
    );
  };

  // display "Fine grain policies" section on UI
  const getFgaPoliciesTable = () => {
    return (
      <Table
        loadingText='Loading policies'
        columnDefinitions={fgaPoliciesColumnDefinitions}
        items={fgaPolicies}
        resizableColumns
        header={<Header counter={`(${fgaPolicies.length})`}>Fine grain policies</Header>}
        empty={<EmptyState title='No policies' subtitle='No fine grain policies found for this dataset.' />}
      />
    );
  };

  const getTemplateForDataset = () => {
    return <TemplatesForResourceDetailsTable items={templates} userOwnsResource={userOwnsDataset()} />;
  };

  // display "Consumers" section on UI
  const getDataConsumersTable = () => {
    if (dataset) {
      const dataSourceId = dataset.IdInfo.DataSourceId.charAt(0).toUpperCase() + dataset.IdInfo.DataSourceId.slice(1);
      const resource = {
        accountId: dataset.IdInfo.CatalogId,
        region: dataset.IdInfo.Region,
        type: 'TABLE',
        dataCatalogObjectDetails: {
          dataSourceId: dataSourceId,
          clusterIdentifier: dataset.IdInfo.ClusterIdentifier,
          databaseName: dataset.IdInfo.DatabaseName,
          schemaName: dataset.IdInfo.SchemaName,
          tableName: dataset.IdInfo.TableName,
        },
        dpType: dataSourceId === 'GlueLF' ? DATA_PERMISSION_LAKE_FORMATION_TYPE : DATA_PERMISSION_REDSHIFT_TYPE,
        tagResourceId: dataset.Id,
        tagUpperLevelResourceId: getDatasetUpperLevelResourceId(dataset),
      };

      return <DataConsumersTable {...props} resource={resource} />;
    }
  };

  const closeEditModal = () => {
    setEditDataSetModalVisible(false);
  };

  const notify = async (type, msg) => {
    setNotifications([
      {
        type: type,
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const notifySuccess = async (msg) => {
    setNotifications([
      {
        type: 'success',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };
  const notifyFailure = async (msg) => {
    setNotifications([
      {
        type: 'error',
        content: msg,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const syncDataset = async () => {
    setSyncButtonLoading(true);
    try {
      let syncRequest = {
        IdList: [props.match.params.id],
      };
      await syncDataSets(syncRequest);
      await handleRefresh();
      setNotifications([
        {
          type: 'success',
          content: 'Successfully synced dataset',
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setSyncButtonLoading(false);
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to sync dataset: ` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      setSyncButtonLoading(false);
    }
  };

  const handleAddToCart = (e) => {
    setSelectedPermissionType(e?.detail?.id);
    setAccessSelectVisible(true);
  };

  const setupPermissionDropdownOptions = async (dataset) => {
    let catalogName = await fetchCatalogName(dataset);
    let allPermissionDropdownOptions = [];

    if (props.externalGroups != null && props.externalGroups?.length > 0) {
      for (let externalGroup of props.externalGroups) {
        allPermissionDropdownOptions.push({
          label: externalGroup?.groupName,
          value: externalGroup?.groupName,
          type: GALAXI_TYPE,
          teamName: externalGroup?.teamName,
        });
      }
    }

    if (!signedInWithWorkspace(props)) {
      setAllPermissionDropdownOptions(allPermissionDropdownOptions);
      return;
    }

    let tags = await fetchTags(dataset);
    let fgaPolicies = await fetchFineGrainPoliciesOnDataset(dataset);
    let availableSubscribeItems = [];

    // set up resource drop down items
    let resourceId = `${dataset?.IdInfo?.TableName}.${dataset?.IdInfo?.DatabaseName}.${dataset?.IdInfo?.CatalogId}`;
    let permissionData = await getSingleTableAccessDataPermission(
      dataset,
      resourceId,
      props.activeWorkspace,
      props.cartItems,
    );
    let datasetPermissionId = permissionData?.permissionId;
    let isSingleDatasetConsumed = permissionData?.consumed;
    let publisherRole = permissionData?.publisherRole;
    let singleTableAccessDropDown = undefined;
    if (datasetPermissionId) {
      //block mp data table level access, setup single table dropdown item
      if (!isMPData(dataset?.IdInfo?.CatalogId, dataset?.IdInfo?.DatabaseName, dataset?.IdInfo?.TableName)) {
        singleTableAccessDropDown = {
          label: `Request access to ` + `"${dataset?.IdInfo?.TableName}"`,
          id: resourceId,
          value: resourceId,
          type: RESOURCE_ITEM,
          datasetId: dataset?.Id,
          datasetName: dataset?.IdInfo?.TableName,
          databaseName: dataset?.IdInfo?.DatabaseName,
          owner: dataset?.PrimaryOwner,
          catalogId: dataset?.IdInfo?.CatalogId,
          catalogName: catalogName,
          permissionId: datasetPermissionId,
          workspaceAccess: isSingleDatasetConsumed,
          publisherRole: publisherRole,
          principals: permissionData?.iamPrincipals,
        };
        allPermissionDropdownOptions.push(singleTableAccessDropDown);
      }
    }
    //set up all table drop down item
    let allTableDropDownItems = await getAllTableAccessItem(
      dataset,
      isSingleDatasetConsumed,
      permissionData?.iamPrincipals,
      catalogName,
      props.activeWorkspace,
      props.cartItems,
    );

    if (allTableDropDownItems && allTableDropDownItems.length !== 0) {
      // remove duplicate single table request field
      for (let allTableItem of allTableDropDownItems) {
        //block mp data db level access
        if (isMPData(allTableItem['catalogId'], allTableItem['databaseName'], allTableItem['datasetName'])) {
          continue;
        }

        if (singleTableAccessDropDown !== undefined) {
          if (allTableItem['id'] !== singleTableAccessDropDown['id']) {
            allPermissionDropdownOptions.push(allTableItem);
          }
        } else {
          allPermissionDropdownOptions.push(allTableItem);
        }
      }
    }

    if (allPermissionDropdownOptions.length > 0) {
      availableSubscribeItems.push({
        text: 'Resource access',
        id: RESOURCE_ITEM,
      });
    }
    // set up fga drop down items
    for (let fgaPolicy of fgaPolicies) {
      let consumedPermission = await getConsumedPermissionsOfFineGrainPolicy(fgaPolicy, props.activeWorkspace, dataset);
      allPermissionDropdownOptions.push({
        label: `Request access to ` + `"${fgaPolicy.tableData.physicalName}"`,
        id: fgaPolicy.id,
        fgapName: fgaPolicy.tableData.physicalName,
        type: FGA_POLICY_TYPE_PREFIX,
        value: fgaPolicy.id,
        catalogId: fgaPolicy.tableData.tableCatalogId,
        clusterId: fgaPolicy.tableData.clusterId,
        databaseName: fgaPolicy.tableData.databaseName,
        schemaName: fgaPolicy.tableData.schemaName,
        tableName: fgaPolicy.tableData.tableName,
        ownerId: fgaPolicy.ownerId,
        catalogName: catalogName,
        permissionId: fgaPolicy.dataPermissionId,
        workspaceAccess:
          consumedPermission.filter((consumedPermission) =>
            props.activeWorkspace.workspaceId.includes(consumedPermission.dataLakePrincipal),
          ).length !== 0,
        principals: consumedPermission
          .map((consumedPermission) => consumedPermission.dataLakePrincipal)
          .filter((dataLakePrincipal) => isValidPrincipalNotRoot(dataLakePrincipal)),
      });
    }
    if (fgaPolicies?.length > 0) {
      availableSubscribeItems.push({
        text: 'Fine grain policies access',
        id: FGA_POLICY_TYPE_PREFIX,
      });
    }

    // set up tag drop down items
    for (let tag of tags) {
      let tagId = `${tag.tagKey}.${tag.tagValue}.${tag.catalogId}`;
      let permissionData = await checkIfTagIsAlreadyConsumedAndGetPublisherTag(
        tag,
        dataset.Owners[0],
        dataset,
        props.activeWorkspace,
        props.cartItems,
      );
      let tagPermissionId = permissionData?.permissionId;
      let consumed = permissionData?.consumed;
      if (tagPermissionId !== undefined) {
        allPermissionDropdownOptions.push({
          label: `Request ${tag.tagKey}.${tag.tagValue} access`,
          id: tagId,
          type: TAG_ITEM,
          value: tagId,
          tagKey: tag.tagKey,
          tagValue: tag.tagValue,
          catalogId: tag.catalogId,
          owner: dataset.Owners[0],
          catalogName: catalogName,
          permissionId: tagPermissionId,
          workspaceAccess: consumed,
          principals: permissionData.iamPrincipals,
        });
        // if there are non-consumed tags, show "tags" as an option, but only once
        if (!consumed && !availableSubscribeItems?.some((item) => item?.id == TAG_ITEM)) {
          availableSubscribeItems.push({
            text: 'Tag access',
            id: TAG_ITEM,
          });
        }
      }
    }

    if (galaxiServiceIntegrated() && (props.externalGroups == null || props.externalGroups.length > 0)) {
      availableSubscribeItems.push({
        text: 'Galaxi access',
        id: GALAXI_TYPE,
      });
    }

    if (
      dataset?.IdInfo?.DataSourceId == DZ_REDSHIFT_DATASOURCE_ID ||
      dataset?.IdInfo?.DataSourceId == DZ_GLUE_DATASOURCE_ID
    ) {
      allPermissionDropdownOptions.push({
        label: `Request DataZone access`,
        value: dataset.DataZoneConfig.AssetId,
        type: DATAZONE_TYPE,
        id: dataset.Id,
        dataZoneAssetId: dataset.DataZoneConfig.AssetId,
        // dataZoneConsumerProjectId will be set in accessSelectModal
        publisherRole: (props.activeWorkspace.workspaceRoleArn as string).replace(
          props.activeWorkspace.accountId,
          dataset.IdInfo.CatalogId,
        ),
        catalogId: dataset.IdInfo.CatalogId,
        datasetName: dataset.IdInfo.TableName,
        databaseName: dataset.IdInfo.DatabaseName,
        clusterId: dataset.IdInfo.ClusterIdentifier,
        redshiftWorkgroupName: dataset.IdInfo.RedshiftWorkgroupName,
        schemaName: dataset.IdInfo.SchemaName,
        region: dataset.IdInfo.Region,
        owner: dataset.PrimaryOwner,
        catalogName: catalogName,
      });
      availableSubscribeItems.push({
        text: 'DataZone access',
        id: DATAZONE_TYPE,
      });
    }

    setAllPermissionDropdownOptions(allPermissionDropdownOptions);
    setAvailableSubscribeOptions(availableSubscribeItems);
  };

  const fetchTags = async (dataset) => {
    let tagList = [];
    let getDatasetTagsResponse;
    let getInheritedTagsResponse;
    try {
      getDatasetTagsResponse = await getResourceTags({
        resourceId: dataset?.Id,
        statusCustomerType: 'Active:Publisher',
      });
    } catch (err) {
      console.log(err);
    }
    if (dataset.ClusterIdentifier != null) {
      try {
        getInheritedTagsResponse = await getResourceTags({
          resourceId: `DS-redshift|A-${dataset?.IdInfo?.CatalogId}|CI-${dataset?.IdInfo?.ClusterIdentifier}|DN-${dataset?.IdInfo?.DatabaseName}|SN-${dataset?.IdInfo?.SchemaName}|R-${dataset?.IdInfo?.Region}`,
          statusCustomerType: 'Active:Publisher',
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        getInheritedTagsResponse = await getResourceTags({
          resourceId: `DS-glueLF|A-${dataset?.IdInfo?.CatalogId}|DN-${dataset?.IdInfo?.DatabaseName}|R-${dataset?.IdInfo?.Region}`,
          statusCustomerType: 'Active:Publisher',
        });
      } catch (err) {
        console.log(err);
      }
    }
    let tagSet = new Set();
    if (getInheritedTagsResponse != null) {
      for (let tag of getInheritedTagsResponse?.tags) {
        tagList.push(tag);
        tagSet.add(tag.tagPair);
      }
    }
    if (getDatasetTagsResponse != null) {
      for (let tag of getDatasetTagsResponse?.tags) {
        if (!tagSet.has(tag.tagPair)) {
          tagList.push(tag);
        }
      }
    }
    setTags(tagList);
    return tagList;
  };

  const fetchFineGrainPoliciesOnDataset = async (dataset) => {
    let fgaPolicyList = [];
    if (dataset) {
      try {
        let listFgaPoliciesForResourceResult = await listFgaPolicies({
          status: STATUS_ACTIVE,
          tableData: {
            tableCatalogId: dataset.IdInfo.CatalogId,
            clusterId: dataset.IdInfo.ClusterIdentifier,
            databaseName: dataset.IdInfo.DatabaseName,
            schemaName: dataset.IdInfo.SchemaName,
            tableName: dataset.IdInfo.TableName,
            region: dataset.IdInfo.Region,
          },
        });
        fgaPolicyList.push(...listFgaPoliciesForResourceResult.FineGrainAccess);
        while (listFgaPoliciesForResourceResult.nextToken) {
          listFgaPoliciesForResourceResult = await listFgaPolicies({
            status: STATUS_ACTIVE,
            nextToken: listFgaPoliciesForResourceResult.nextToken,
            tableData: {
              tableCatalogId: dataset.IdInfo.CatalogId,
              clusterId: dataset.IdInfo.ClusterIdentifier,
              databaseName: dataset.IdInfo.DatabaseName,
              schemaName: dataset.IdInfo.SchemaName,
              tableName: dataset.IdInfo.TableName,
              region: dataset.IdInfo.Region,
            },
          });
          fgaPolicyList.push(...listFgaPoliciesForResourceResult.FineGrainAccess);
        }
        setFgaPolicies(fgaPolicyList);
        return fgaPolicyList;
      } catch (err) {
        console.log('Failed to fetch Fine grain policies on dataset.' + err.message);
      }
      setFgaPolicies([]);
      return [];
    }
  };

  const versionDetail = () => {
    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: (
          <>
            <Container header={<Header>Dataset details</Header>}>
              <ColumnLayout columns={3} borders='horizontal'>
                {getDatasetDetailsMetadata(props)}
              </ColumnLayout>
            </Container>
            {dataset && <ContactInfo resource={getArn(dataset)} />}
          </>
        ),
      },
      {
        label: 'Columns',
        id: 'Columns',
        content: <TableColumnList {...props} version={mostRecentOption?.item || option?.item} />,
      },
      {
        label: 'Partition keys',
        id: 'Partition keys',
        content: getPartitionKeysTable(mostRecentOption?.item || option?.item),
      },
      {
        label: 'Data quality',
        id: 'Data quality',
        content: <DataQuality setContentType={props.setContentType} datasetId={props.match.params.id} />,
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      },
      {
        label: 'Templates',
        id: 'Templates',
        content: getTemplateForDataset(),
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      },
      {
        label: 'Metadata',
        id: 'Metadata',
        content: (
          <MetadataDetails
            resourceOwnerIds={dataset?.Owners}
            activeWorkspace={props.activeWorkspace}
            resource={getArn(dataset)}
            activeGroup={props.activeGroup}
            setContentType={props.setContentType}
            setNotification={notify}
          />
        ),
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      },
    ];

    if (signedInWithWorkspace(props)) {
      tabs.push({
        label: 'Tags',
        id: 'tags',
        content: (
          <DatasetTagList
            {...props}
            dataset={dataset}
            updateTagLabel={handleRefresh}
            notifyEditFailure={notifyFailure}
            notifyEditSuccess={notifySuccess}
          />
        ),
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      });

      tabs.push({
        label: 'Fine grain policies',
        id: 'fgaPolicies',
        content: getFgaPoliciesTable(),
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      });
    }

    if (isCommercialPartition()) {
      // insert at 4th position
      tabs.splice(3, 0, {
        label: 'Lineage',
        id: 'Lineage',
        content: <LineageDetail hcDataset={dataset} />,
      });
    }

    if (userOwnsDataset()) {
      tabs.push({
        label: 'Consumers',
        id: 'consumers',
        content: getDataConsumersTable(),
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      });
    }

    if (enableAdvisories()) {
      tabs.push({
        label: 'Advisories',
        id: 'advisories',
        content: (
          <AdvisoriesForResourceTable
            resourceArn={getArn(dataset)}
            activeGroup={props.activeGroup}
            setContentType={props.setContentType}
          />
        ),
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      });
    }

    if (!signedInWithWorkspace(props) && dataset?.SupportedAccessTypes?.includes('IAM') && userOwnsDataset()) {
      tabs.push({
        label: 'IAM consumers',
        id: 'IAM consumers',
        content: (
          <IamConsumersTable
            dataset={dataset}
            datasetId={props.match.params.id}
            activeGroup={props.activeGroup}
            onSuccess={notifySuccess}
            onFailure={notifyFailure}
          />
        ),
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      });
    }
    if (!signedInWithWorkspace(props) && !dataset?.SupportedAccessTypes?.includes('IAM')) {
      tabs.push({
        label: 'Usage statistics',
        id: 'UsageStats',
        content: <UsageStatistics dataset={dataset} />,
        disabled: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider,
      });
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  const userOwnsDataset = () => {
    if (!dataset?.Owners || !props.userInfo) return false;
    if (isDGSAdmin(props.userInfo.memberGroupIds)) return true;
    // check if dataset owner is in user workspaces
    // authZ will happen on backend APIs, based on roles
    // Safely handle potentially undefined arrays with nullish coalescing
    const allOwnerIds = new Set([
      ...(props.userInfo.memberWorkspaceIds ?? []),
      ...(props.userInfo.memberGroupIds ?? []),
    ]);
    if (dataset.PrimaryOwner && allOwnerIds.has(dataset.PrimaryOwner)) {
      return true;
    }
    return dataset.Owners.some((owner) => allOwnerIds.has(owner));
  };

  const getArn = (dataset) => {
    return convertToDgsHCResourceArn(
      dataset?.IdInfo.CatalogId,
      dataset?.IdInfo.ClusterIdentifier,
      dataset?.IdInfo.RedshiftWorkgroupName,
      dataset?.IdInfo.DatabaseName,
      dataset?.IdInfo.SchemaName,
      dataset?.IdInfo.TableName,
      undefined,
      dataset?.IdInfo.DataSourceId,
    );
  };

  const getFormattedOptions = (listOfDataSetDetailResponses) => {
    const mappedList = listOfDataSetDetailResponses.map((i) => ({
      id: i.VersionId.toString(),
      label: 'Version ' + i.VersionId,
      item: i,
    }));
    mappedList.sort(function (a, b) {
      return b.item.VersionId - a.item.VersionId;
    });
    return mappedList;
  };

  const fetchDetails = async () => {
    let dataSetDetails;
    try {
      dataSetDetails = await dataSetDetail({
        Filter: {
          IdList: [props.match.params.id],
        },
      });
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to load dataset details: ` + err.message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    // we reverse the version list so that the newest versions show first
    const formattedOptions = getFormattedOptions(dataSetDetails.DataSetDetailList);
    const noError = formattedOptions.length > 0;
    if (noError) {
      formattedOptions[0].label = formattedOptions[0].label + ' (latest)';
    }
    if (dataSetDetails.NextToken != null) {
      formattedOptions.push({
        id: 'load-more-datasets',
        label: 'Load more...',
        item: null,
      });
    }
    if (!noError) {
      setNotifications([
        {
          type: 'error',
          content: `Failed to load dataset details`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }

    const newStatus = noError ? 'finished' : 'error';
    const newOption = noError ? formattedOptions[0] : null;

    setOptions(formattedOptions);
    setMostRecentOption(newOption);
    setStatus(newStatus);
    setOption(undefined);
    setCurNextToken(dataSetDetails.NextToken);
  };

  const handleSelectChange = async (e) => {
    const selectedOption = e.detail.selectedOption;
    if (selectedOption != undefined && selectedOption.id == 'load-more-datasets') {
      const combinedOptions = [];
      for (let i = 0; i < options.length - 1; i++) {
        combinedOptions.push(options[i]);
      }

      const response = await dataSetDetail({
        NextToken: curNextToken,
      });

      combinedOptions.push(...getFormattedOptions(response.DataSetDetailList));

      if (response.NextToken != null) {
        combinedOptions.push(options[options.length - 1]);
      }

      // Note that option should remain unchanged
      setOptions(combinedOptions);
      setCurNextToken(response.NextToken);
      setOption(combinedOptions[options.length - 1]);
    } else {
      setOption(e.detail.selectedOption);
    }
  };

  const handleIamRequest = () => {
    const flattenedDataset = flattenItem(dataset, null, catalogName);
    props.addToCart([flattenedDataset]);
  };

  const getVersion = () => {
    let version;
    if (status === 'error') {
      version = 'No metadata available';
    } else if (option == undefined) {
      if (mostRecentOption == undefined) {
        // If everything is not loaded yet
        version = <Spinner size='normal' />;
      } else {
        // If we have not selected an option.  Default to most recent
        version = 'Metadata version ' + mostRecentOption.id;
      }
    } else {
      version = 'Metadata version ' + option.id;
    }
    return version;
  };

  const handleLakeFormationRequest = async (e) => {
    if (e.detail.id == GALAXI_ACCESS_TYPE) {
      setSelectedPermissionType(GALAXI_TYPE);
      setAccessSelectVisible(true);
      return;
    }

    if (groupLevelDataPermissionList?.length !== 0) {
      let to =
        groupLevelDataPermissionList == undefined || groupLevelDataPermissionList?.length > 1
          ? {
              pathname: Page.MY_DATASETS,
            }
          : {
              pathname: Page.MY_DATASETS + '/' + groupLevelDataPermissionList[0]?.dataPermissionId,
            };
      setRedirect(to);
    }
    // otherwise, request access
    else {
      const id = props.match.params.id;
      const catalogId = dataset?.IdInfo.CatalogId;
      const databaseName = dataset?.IdInfo.DatabaseName;
      const region = dataset?.IdInfo.Region;
      const tableName = dataset && dataset.IdInfo && dataset.IdInfo.TableName ? dataset.IdInfo.TableName : '';
      const to = {
        pathname: Page.CREATE_LAKE_FORMATION_PERMISSIONS,
        state: {
          tableInfo: { id },
          tableName,
          databaseName,
          catalogId,
          region,
        },
      };
      setRedirect(to);
    }
  };

  if (!dataset && !loading) {
    return (
      <ResourceNotFound
        title={'Dataset not found'}
        subtitle={
          'The given dataset is not valid, or you do not have permission to view it. Please check the URL for mistakes and try again.'
        }
      />
    );
  }

  const getButtons = () => {
    let dropdowns = [];
    if (!props.allowListed) {
      dropdowns.push({
        text: SUBSCRIBE_TO_DATASET,
        onItemClick: () => {
          setSelectedPermissionType(GALAXI_TYPE);
          setAccessSelectVisible(true);
        },
        loading: loading,
      });

      if (ACCESS_PROVIDER_GALAXI != dataset?.AccessProvider) {
        dropdowns.push({
          text: 'Onboard to Omni',
          loading: loading,
          onItemClick: () => setRedirect('/onboarding'),
          variant: 'primary',
        });
      }
      return dropdowns;
    }

    if (userOwnsDataset() || isDataLakeAdmin(props.activeGroup)) {
      dropdowns.push({
        text: 'Sync dataset',
        onItemClick: syncDataset,
        loading: syncButtonLoading,
      });
    }

    if (dataset?.SupportedAccessTypes?.includes('IAM')) {
      if (!signedInWithWorkspace(props)) {
        dropdowns.push({
          text: 'Subscribe to IAM data',
          loading: loading,
          onItemClick: handleIamRequest,
          disabled: props.cartItemIds.includes(dataset?.Id),
          id: 'IAM',
        });
      }
      return dropdowns;
    }

    if (dataset?.DataSourceType?.includes('DZ')) {
      if (signedInWithWorkspace(props)) {
        dropdowns.push({
          text: SUBSCRIBE_TO_DATASET,
          onItemClick: () => {
            setSelectedPermissionType(DATAZONE_TYPE);
            setAccessSelectVisible(true);
          },
          disabled: alreadyHasDataZoneAccess,
          loading: loading,
        });
      }
      return dropdowns;
    }

    if (dataset?.SupportedAccessTypes?.includes('Redshift')) {
      if (signedInWithWorkspace(props)) {
        dropdowns.push({
          text: SUBSCRIBE_TO_DATASET,
          onItemClick: () => {
            setSelectedPermissionType(TAG_ITEM);
            setAccessSelectVisible(true);
          },
          disabled: props.activeWorkspace == null || dataset?.TableState == TABLE_STATUS_DEPRECATED,
          loading: loading,
        });
      }
      return dropdowns;
    }

    if (ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider) {
      dropdowns.push({
        text: SUBSCRIBE_TO_DATASET,
        onItemClick: () => {
          setSelectedPermissionType(GALAXI_TYPE);
          setAccessSelectVisible(true);
        },
        loading: loading,
      });
      return dropdowns;
    }

    if (dataset?.SupportedAccessTypes?.includes('LakeFormation')) {
      if (signedInWithWorkspace(props)) {
        dropdowns.push({
          text: SUBSCRIBE_TO_DATASET,
          onItemClick: handleAddToCart,
          items: availableSubscribeOptions,
          disabled: props.activeWorkspace == null || dataset?.TableState == TABLE_STATUS_DEPRECATED,
          loading: loading,
        });
        return dropdowns;
      } else {
        const lakeFormationAccess = !(
          groupLevelDataPermissionList === undefined || groupLevelDataPermissionList.length === 0
        );

        if (galaxiServiceIntegrated() && (props.externalGroups == null || props.externalGroups.length > 0)) {
          dropdowns.push({
            text: SUBSCRIBE_TO_DATASET,
            loading: loading,
            onItemClick: (e) => handleLakeFormationRequest(e),
            items: [
              {
                text: 'Omni access',
                disabled: lakeFormationAccess || dataset?.TableState == TABLE_STATUS_DEPRECATED,
                variant: 'normal',
                id: 'LakeFormation',
              },
              {
                text: 'Galaxi access',
                variant: 'normal',
                id: 'Galaxi',
              },
              {
                text: 'View dataset permission',
                disabled: !lakeFormationAccess,
                variant: 'normal',
                id: 'LakeFormation',
              },
            ],
          });
        } else {
          if (lakeFormationAccess) {
            dropdowns.push({
              text: 'View dataset permission',
              loading: loading,
              onItemClick: (e) => handleLakeFormationRequest(e),
              variant: 'normal',
              id: 'LakeFormation',
            });
          }
          if (!lakeFormationAccess && TABLE_STATUS_DEPRECATED != dataset?.TableState) {
            dropdowns.push({
              text: SUBSCRIBE_TO_DATASET,
              loading: loading,
              onItemClick: (e) => handleLakeFormationRequest(e),
              variant: 'normal',
              id: 'LakeFormation',
            });
          }
        }
        return dropdowns;
      }
    }
    return [];
  };

  return (
    <>
      <Flashbar items={notifications} />
      {redirect && <Redirect push to={redirect} />}

      <DetailsPageHeader
        description={<DataBadges dataset={dataset} tags={tags} businessGlossaries={businessGlossaries} />}
        header={
          <>
            {dataset?.IdInfo?.ClusterIdentifier == undefined ? lakeFormationIcon : redshiftIcon} {dataset?.DataSetName}
          </>
        }
        buttons={getButtons()}
        editButton={{
          text: 'Edit',
          onItemClick: () => setEditDataSetModalVisible(true),
          hidden: ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider || !userOwnsDataset(),
          loading: false,
        }}
        versionHeader={getVersion()}
        cti={CTI}
        versionSelect={
          ACCESS_PROVIDER_GALAXI == dataset?.AccessProvider ? (
            <></>
          ) : (
            <Select
              ariaLabel={'version1'}
              options={options}
              selectedOption={option}
              loadingText='Loading...'
              placeholder='Metadata history'
              errorText='Error: Unable to load metadata history.'
              ariaRequired={true}
              onChange={handleSelectChange}
            />
          )
        }
      />
      {versionDetail()}
      <br />
      {editDataSetModalVisible && (
        <DatasetEditModal
          {...props}
          visible={editDataSetModalVisible}
          dismiss={closeEditModal}
          dataset={dataset}
          notifyEditFailure={notifyFailure}
          notifyEditSuccess={notifySuccess}
          refreshPage={handleRefresh}
        />
      )}
      {accessSelectVisible && catalogName && dataset && selectedPermissionType && allPermissionDropdownOptions && (
        <AccessSelectModal
          {...props}
          visible={accessSelectVisible}
          dismiss={() => {
            setAccessSelectVisible(false);
          }}
          dataset={dataset}
          catalogName={catalogName}
          selectedPermissionType={selectedPermissionType}
          allPermissionDropdownOptions={allPermissionDropdownOptions}
        />
      )}
    </>
  );
};
