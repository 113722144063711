import { iTethysRouteProps } from './types';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes/Paths';
import React from 'react';

export const UpdateRedirect = ({ id }: { id: string }) => (
  <Redirect push to={Page.INGESTION.UPDATE_CONTRACT.replace(':arn/:dataset', id)} />
);

export const createIngestedDatasetDetailPath = ({ arn, dataset, database }: iTethysRouteProps) =>
  arn.includes('dataset')
    ? Page.INGESTION.DETAIL_V2.replace(':arn/:database/:dataset', `${arn}/${database}/${dataset}`)
    : Page.INGESTION.DETAIL.replace(':arn/:dataset', `${arn}/${dataset}`);
