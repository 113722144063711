import React from 'react';
import { StatusIndicator } from '@amzn/awsui-components-react-v3';

export const StatusIcon = (status) => {
  switch (status['status']) {
    case 'SUCCEEDED':
      return <StatusIndicator type={'success'}>Success</StatusIndicator>;

    case 'ACTIVE':
    case 'Active':
      return <StatusIndicator type={'success'}>Active</StatusIndicator>;

    case 'AVAILABLE':
      return <StatusIndicator type={'success'}>Associated</StatusIndicator>;

    case 'FAILED':
      return <StatusIndicator type={'error'}>Failed</StatusIndicator>;

    case 'ABORTED':
      return <StatusIndicator type={'error'}>Failed</StatusIndicator>;

    case 'PENDING':
    case 'Pending':
      return <StatusIndicator type={'pending'}>Pending</StatusIndicator>;

    case 'InActive':
      return <StatusIndicator type={'stopped'}>Inactive</StatusIndicator>;

    case 'TIMEOUT':
      return <StatusIndicator type={'warning'}>Timeout</StatusIndicator>;

    case 'RUNNING':
      return <StatusIndicator type={'in-progress'}>Running</StatusIndicator>;

    case 'APPROVED':
      return <StatusIndicator type={'success'}>Approved</StatusIndicator>;

    case 'DENIED':
      return <StatusIndicator type={'error'}>Denied</StatusIndicator>;

    case 'CANCELED':
      return <StatusIndicator type={'stopped'}>Canceled</StatusIndicator>;

    default:
      return <StatusIndicator type={'warning'}>Unknown</StatusIndicator>;
  }
};
