import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { disassociateTagFromResources, getResourceTags } from 'src/api/permissions';
import { TagEditModal } from 'src/components/workspaces/dataBrowse/dataset/editTagModal';
import { EmptyState } from 'src/commons/EmptyState';
import { REDSHIFT_TAG_TYPE, SCHEMA_RESOURCE_TYPE } from 'src/commons/constants';

import { PageHeader } from 'src/components/common/PageHeader';

export interface TagListProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  schema: any;
  updateTagLabel: any;
  notifyEditFailure: any;
  notifyEditSuccess: any;
}

export const SchemaTagList = (props: TagListProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [buttonLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [addTagVisible, setAddTagVisible] = useState(false);
  const [ownSchema, setOwnSchema] = useState(false);
  const [resource, setResource] = useState(undefined);

  const fetchTags = async () => {
    let resourceId = `DS-redshift|A-${props.schema?.CatalogId}|CI-${props.schema?.ClusterIdentifier}|DN-${props.schema?.DatabaseName}|SN-${props.schema?.Schema}|R-${props.schema?.Region}`;
    let tagList = [];
    try {
      let getResourceTagsResponse = await getResourceTags({
        resourceId: resourceId,
      });
      tagList.push(...getResourceTagsResponse.tags);
    } catch (err) {
      console.log(err);
    }
    setItems(tagList);
  };

  const handleRefresh = async () => {
    setLoadingTags(true);
    await fetchTags();
    setResource({
      resourceType: SCHEMA_RESOURCE_TYPE,
      resourceName: props.schema?.Schema,
      databaseName: props.schema?.DatabaseName,
      catalogId: props.schema?.CatalogId,
      clusterId: props.schema?.ClusterIdentifier,
    });
    setOwnSchema(props.activeWorkspace != null && props.schema?.Owners.includes(props.activeWorkspace.workspaceId));
    setLoadingTags(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'tagKey',
      header: 'Key',
      cell: (item) => item?.tagKey,
      minWidth: 200,
    },
    {
      id: 'tagValue',
      header: 'Value',
      cell: (item) => item?.tagValue,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => item?.status,
      minWidth: 200,
    },
    {
      id: 'statusReason',
      header: 'Status reason',
      cell: (item) => item?.statusReason,
      minWidth: 200,
    },
  ];

  const handleAction = async (e) => {
    if (e.detail.id == 'add') {
      setAddTagVisible(true);
    } else if (e.detail.id == 'delete') {
      await handleRemove();
      fetchTags();
      props.updateTagLabel();
    }
  };

  const handleRemove = async () => {
    try {
      let request = {
        tagPair: selectedItem?.tagPair,
        catalogId: selectedItem?.catalogId,
        region: props.schema?.Region,
        type: REDSHIFT_TAG_TYPE,
        resources: [resource],
      };
      let response = await disassociateTagFromResources(request);
      if (response.succeeded) {
        props.notifyEditSuccess(response.message);
      } else {
        props.notifyEditFailure(response.message);
      }
    } catch (err) {
      props.notifyEditFailure('Tag remove failure', err);
    }
  };

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {
      empty: <EmptyState title='No tags attached' subtitle='No tags to display.' />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        isDescending: true,
        sortingColumn: {
          sortingField: 'LastCommentTime',
        },
      },
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Key',
          key: 'tagKey',
          groupValuesLabel: 'Keys',
        },
        {
          propertyLabel: 'Value',
          key: 'tagValue',
          groupValuesLabel: 'Values',
        },
      ],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    if (!selectedItems.length) return;
    setSelectedItem(selectedItems[selectedItems.length - 1]);
  }, [collectionProps.selectedItems]);

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading resource status...'
        loading={loadingTags}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            {ownSchema ? (
              <PageHeader
                buttons={[
                  {
                    text: 'Actions',
                    onItemClick: (e) => handleAction(e),
                    items: [
                      {
                        text: 'Add',
                        id: 'add',
                      },
                      {
                        text: 'Delete',
                        id: 'delete',
                        disabled: !collectionProps.selectedItems.length,
                      },
                    ],
                    loading: buttonLoading,
                  },
                ]}
                header={'Tags'}
              />
            ) : (
              <PageHeader
                buttons={[
                  {
                    text: '',
                    icon: 'refresh',
                    onItemClick: handleRefresh,
                  },
                ]}
                counter={`(${allItems.length})`}
                header={'Tags'}
              />
            )}
          </>
        }
        selectionType={ownSchema ? 'single' : undefined}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={<EmptyState title='No tags' subtitle='No tags to display.' />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingTags}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
      {addTagVisible && (
        <TagEditModal
          {...props}
          visible={addTagVisible}
          tagType={REDSHIFT_TAG_TYPE}
          dismiss={() => {
            setAddTagVisible(false);
          }}
          currentTags={allItems}
          resource={resource}
          refresh={fetchTags}
        />
      )}
    </>
  );
};
