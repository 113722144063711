import * as React from 'react';
import { Badge, Button, ButtonDropdown, ColumnLayout, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { TagsList } from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { DatabaseInfo, GetDataSetResponseStructure } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import {
  dataClassificationOptions,
  defaultClassificationOption,
  DZ_GLUE_DATASOURCE_ID,
  DZ_REDSHIFT_DATASOURCE_ID,
  GALAXI_DATASOURCE_ID,
} from 'src/commons/constants';
import { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select/interfaces';

export const DetailsPageHeader = ({ header, buttons, versionHeader, versionSelect, editButton, description, cti }) => {
  return (
    <SpaceBetween direction='vertical' size='xs'>
      <Header
        variant={'h1'}
        description={versionHeader}
        actions={
          <SpaceBetween size={'s'} direction='horizontal'>
            {!!editButton && !editButton.hidden && (
              <Button
                href={editButton.href || ''}
                iconName={editButton.icon}
                onClick={editButton.onItemClick}
                key={0}
                loading={editButton.loading}
                variant={editButton.variant || 'normal'}
              >
                {editButton.text}
              </Button>
            )}
            {versionSelect}
            {buttons.map((button, key) =>
              !button.items ? (
                <Button
                  href={button.href || ''}
                  disabled={button.disabled || false}
                  iconName={button.icon}
                  onClick={button.onItemClick}
                  key={key}
                  loading={button.loading || false}
                  variant={button.variant || 'normal'}
                >
                  {button.text}
                </Button>
              ) : (
                <ButtonDropdown
                  items={button.items}
                  key={key}
                  onItemClick={button.onItemClick}
                  loading={button.loading}
                >
                  {button.text}
                </ButtonDropdown>
              ),
            )}
          </SpaceBetween>
        }
      >
        {header}
      </Header>

      <ColumnLayout columns={2}>
        <div> {description} </div>
        <div style={{ textAlign: 'right' }}> {cti} </div>
      </ColumnLayout>
    </SpaceBetween>
  );
};

const getClassificationColor = (level: string) => {
  switch (level) {
    case 'Public':
      return 'green';
    case 'Confidential':
      return 'severity-low';
    case 'HighlyConfidential':
      return 'severity-medium';
    case 'Restricted':
      return 'severity-high';
    case 'Critical':
      return 'severity-critical';
    default:
      return 'severity-neutral';
  }
};

export const ClassificationBadge = ({ classification, disabled }: { classification: string; disabled?: boolean }) => (
  <Badge color={disabled ? 'grey' : getClassificationColor(classification)}>{classification}</Badge>
);

export const findOption = (
  options: SelectProps.Option[],
  value: string,
  defaultValue?: SelectProps.Option,
): SelectProps.Option => {
  return options.find((c) => c.value == value) ?? defaultValue;
};

export const getDataClassificationOption = (dataClassification: string): SelectProps.Option => {
  return findOption(dataClassificationOptions, dataClassification, defaultClassificationOption);
};

interface iDataBadges {
  database?: DatabaseInfo;
  dataset?: GetDataSetResponseStructure;
  tags?: TagsList;
  businessGlossaries?: [];
}

export const DataBadges = ({ dataset, tags, businessGlossaries, database }: iDataBadges) => (
  <SpaceBetween size='s' direction='horizontal'>
    {dataset?.PII ? <Badge color='red'>PII dataset</Badge> : ''}

    {dataset?.TableState && (
      <Badge color={dataset?.TableState === 'Active' ? 'green' : 'red'}>{dataset?.TableState}</Badge>
    )}

    {dataset?.DataClassification && <ClassificationBadge classification={dataset?.DataClassification} />}

    {businessGlossaries?.map((businessGlossary) => {
      return <Badge color='green'>{businessGlossary['GlossaryValue']}</Badge>;
    })}

    {(dataset?.DataSourceType == GALAXI_DATASOURCE_ID || database?.DataSourceId == GALAXI_DATASOURCE_ID) && (
      <Badge color='blue'>Galaxi</Badge>
    )}

    {(dataset?.DataSourceType == DZ_REDSHIFT_DATASOURCE_ID ||
      database?.DataSourceId == DZ_REDSHIFT_DATASOURCE_ID ||
      dataset?.DataSourceType == DZ_GLUE_DATASOURCE_ID ||
      database?.DataSourceId == DZ_GLUE_DATASOURCE_ID) && <Badge color='grey'>DataZone</Badge>}

    {tags?.map((tag, index) => {
      return (
        <Badge key={index} color='blue'>
          {tag.tagKey}.{tag.tagValue}{' '}
        </Badge>
      );
    })}
  </SpaceBetween>
);
