import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Container,
  Header,
  Pagination,
  PropertyFilter,
  Table,
  TokenGroup,
} from '@amzn/awsui-components-react-v3';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { getDataSetsFromHybridCatalogDatabase, getDataSourceTables } from 'src/api/catalog';
import { REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { isRedshiftStandardIdentifier } from 'src/commons/validationUtils';
import { registerStatus } from 'src/commons/common';
import { EmptyState } from 'src/commons/EmptyState';
import { PageHeader } from 'src/components/common/PageHeader';

export interface GlueDatasetsProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  setRegisterItems: any;
  registerItems: any[];
  selectedDatabaseName: string;
  setRegisterDataPermissionType: any;
  setNotifications: any;
  selectedSchemaName: string;
  setSelectedSchemaName: any;
  clusterName: any;
}

export const RedshiftDatasets = (props: GlueDatasetsProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingDatasets, setLoadingDatasets] = useState(false);
  const [selectedDatasets, setSelectedDatasets] = useState(undefined);

  const handleRefresh = async () => {
    setLoadingDatasets(true);
    let redshiftTableMap = new Map();
    let getDataSourceTablesRequest = {
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      CatalogId: props.activeWorkspace.accountId,
      DatabaseName: props.selectedDatabaseName,
      Region: props.activeWorkspace.region,
      ClusterIdentifier: props.clusterName,
      SchemaName: props.selectedSchemaName,
      NextToken: null,
    };
    try {
      let tableList = [];
      let getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
      tableList = getDataSourceTablesResponse.TableList;
      while (getDataSourceTablesResponse.NextToken != null) {
        getDataSourceTablesRequest.NextToken = getDataSourceTablesResponse.NextToken;
        getDataSourceTablesResponse = await getDataSourceTables(getDataSourceTablesRequest);
        tableList.push(...getDataSourceTablesResponse.TableList);
      }
      setItems(tableList);
      for (let table of tableList) {
        redshiftTableMap.set(table.Name, table);
      }
    } catch (err) {
      setLoadingDatasets(false);
      props.setNotifications([
        {
          type: 'error',
          content: `Failed to load datasets` + err.message,
          dismissible: true,
          onDismiss: () => props.setNotifications([]),
        },
      ]);
    }
    let hcRegisteredDatasets = await getAllDatasetsFromHCForSelectedSchema();
    let autoRegisterDatasetsList = [];
    for (let dataSet of hcRegisteredDatasets) {
      if (
        // dataSet?.DataClassification == 'Public' &&
        // dataSet?.DataAccessRole == props.activeWorkspace.workspaceRoleArn &&
        redshiftTableMap.has(dataSet?.IdInfo.TableName)
      ) {
        let registeredTableInfo = redshiftTableMap.get(dataSet?.IdInfo.TableName);
        registeredTableInfo.registered = true;
      }
      // if migrating from group to workspace register all existing datasets
      if (redshiftTableMap.has(dataSet?.IdInfo.TableName)) {
        let registeredTableInfo = redshiftTableMap.get(dataSet?.IdInfo.TableName);
        if (registeredTableInfo.registered == undefined || !registeredTableInfo.registered) {
          registeredTableInfo.makeSelectedByDefault = true;
          // insert into autoSelectRegisterDatadetList
          autoRegisterDatasetsList.push({
            label: dataSet?.IdInfo.TableName,
            labelTag: dataSet?.IdInfo.DatabaseName,
            disabled: true,
          });
        }
      }
    }
    props.setRegisterItems(autoRegisterDatasetsList);
    setLoadingDatasets(false);
  };

  const getAllDatasetsFromHCForSelectedSchema = async () => {
    let request = {
      DatabaseName: props.selectedDatabaseName,
      CatalogId: props.activeWorkspace.accountId,
      SchemaName: props.selectedSchemaName,
      Region: props.activeWorkspace.region,
      ClusterIdentifier: props.clusterName,
      DataSourceId: REDSHIFT_DATASOURCE_ID,
      NextToken: null,
    };
    let response = await getDataSetsFromHybridCatalogDatabase(request);
    let dataSetList = [...response.DataSetList];
    while (response.NextToken != null) {
      request.NextToken = response.NextToken;
      response = await getDataSetsFromHybridCatalogDatabase(request);
      dataSetList.push(...response.DataSetList);
    }
    return dataSetList;
  };

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'datasetName',
      header: 'Dataset name',
      cell: (item) => item.Name,
      minWidth: 200,
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (_) => props.selectedDatabaseName,
      minWidth: 200,
    },
    {
      id: 'schemaName',
      header: 'Schema name',
      cell: (_) => props.selectedSchemaName,
      minWidth: 200,
    },
    {
      id: 'clusterName',
      header: 'Cluster name',
      cell: (_) => props.clusterName,
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.TableType,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => registerStatus(item.registered !== undefined),
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: { keepSelection: true },
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Dataset name',
          key: 'Name',
          groupValuesLabel: 'Dataset names',
        },
      ],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    setSelectedDatasets(selectedItems);
  }, [collectionProps.selectedItems]);

  useEffect(() => {
    handleDatasetsUpdate();
  }, [selectedDatasets]);

  const handleDatasetsUpdate = () => {
    let itemsToBeRegistered = [];
    for (let data of selectedDatasets ?? []) {
      if (!isRedshiftStandardIdentifier(data.Name)) {
        props.setNotifications([
          {
            type: 'error',
            content: `Only redshift standard identifiers are supported. Remove '${data.Name}' to continue.`,
            dismissible: true,
            onDismiss: () => props.setNotifications([]),
          },
        ]);
        return;
      }
      itemsToBeRegistered.push({
        label: data.Name,
        labelTag: `${props.clusterName}.${props.selectedDatabaseName}.${props.selectedSchemaName}`,
        tagObject: data.tags,
      });
    }
    props.setRegisterItems(itemsToBeRegistered);
    props.setRegisterDataPermissionType('ALLTablePermission');
  };

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        loading={loadingDatasets}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={<PageHeader buttons={[]} counter={`(${allItems.length})`} header={'Redshift datasets'} />}
        selectionType='multi'
        isItemDisabled={(glueTable) => glueTable.registered || glueTable.makeSelectedByDefault}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        empty={<EmptyState title={'No datasets'} subtitle={'No datasets were found.'} />}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatasets}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />

      <br />
      <br />
      {selectedDatasets !== undefined && (
        <>
          {props.registerItems.length !== 0 && (
            <Container
              header={
                <Header variant='h2' description='Registers the selected and existing legacy datasets to workspace'>
                  Datasets to register
                </Header>
              }
            >
              {/*Token group includes selected and autoselected datasets to register*/}
              <TokenGroup
                items={props.registerItems}
                alignment='vertical'
                onDismiss={({ detail: { itemIndex } }) => {
                  props.setRegisterItems([
                    ...props.registerItems.slice(0, itemIndex),
                    ...props.registerItems.slice(itemIndex + 1),
                  ]);
                }}
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};
